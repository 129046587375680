<script>
import VTextField from "vuetify/lib/components/VTextField/VTextField.js"
import { createNamespacedHelpers, mapActions } from 'vuex'
const { mapActions:registersActions } = createNamespacedHelpers('registers')
export default {
  name: 'VTextFieldExternal',
  extends: VTextField,
  props: {
    externalRequest: {
      type: Object,
      required: true
    },

    /**
     * Função de tratamento da requisição da busca.
     * Essa função será chamada recebendo como parâmetro a Promise da chamada da busca do algolia
     */
    handler: {
      type: Function,
      required: false
    },

    /**
     * Valor em ms do intervalo de espera de parada de input até realizar a requisição.
     */
    delay: {
      type: Number,
      required: false,
      default: () => 0
    },

    filters: Array,
    filtersIn: Array,
    fieldsToSearch: Array,
    fieldsToMark: Array,
    register: String,
  },
  data() {
    return {
      timer: null,
    }
  },
  async created() {
    this.$on('click:clear', (query) => {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
    })
    
    this.$on('keydown', (e) => {
      if (e.key == 'Enter') {
        if (this.timer) {
          clearTimeout(this.timer)
          this.timer = null
        }
        const query = this.lazyValue
        this.search(query, this.filters, this.filtersIn)
      }
    })
    
    if (this.delay)
      this.$on('input', (query) => this.timeoutSearch(query, this.filters, this.filtersIn))
    else
      this.$on('input', this.search)
  },
  methods: {
    ...registersActions(['getExternal']),
    timeoutSearch(query, filters, filtersIn) {
      if (query || filters || filtersIn) {
        if (this.timer)
          clearTimeout(this.timer)
  
        this.timer = setTimeout(() => {
          this.timer = null
          this.handler(this.externalSearch(query, filters, filtersIn))
        }, this.delay)
      }
    },
    search(query, filters, filtersIn) {
      if (query || filters.length > 0 || filtersIn.length > 0)
        this.handler(this.externalSearch(query, filters, filtersIn))
    },
    externalSearch(query, filters, filtersIn) {
      const filtersInSelecteds = filtersIn ? filtersIn.filter(f => {return f.selecteds && f.selecteds.length > 0}) : []
      if (query || (filters && filters.length > 0) || (filtersInSelecteds && filtersInSelecteds.length > 0)) {
        return this.getExternal({
          ...this.externalRequest,
          register: this.register,
          search: query,
          fieldsToSearch: this.fieldsToSearch,
          fieldsToMark: this.fieldsToMark,
          filters,
          filtersIn: filtersInSelecteds
        })
      }
      else return []
    }
  }
}
</script>