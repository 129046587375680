<template>
  <section>
    <Toolbar/>
    <DataTable 
      :opt="optDataTable"
      :title="title"
      :collection="collection"
      :register="register"
      :filters="filters"
      :details="details"
      :claims="claims"
    >
      <!-- <template v-slot:buttonsToolbar>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn height=40 small text outlined v-bind="attrs" v-on="on" class="mr-4">
              Opções <v-icon right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in optionList"
              link
              :key="index"
            >
              <v-list-item-title @click="item.action">{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template> -->
    </DataTable>

    <!-- Customer Deafult -->
    <!-- <v-dialog
      v-model="dialogDefault"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title class="pl-2">
          <span class="text-h5">Custumer Padrão</span>
        </v-card-title>
        <v-card-text class="pa-0">
          <v-form @submit.prevent="validate() && submit()" v-model="validDefault" @reset.prevent="reset" ref="formDefault" autocomplete="off">
            <v-container>
              <v-row>
                <v-col cols=6>
                  <v-text-field
                    v-model="formDefault.displayName"
                    @input="updateDefaultFields"
                    label="Nome"
                    dense
                    :rules="[v => !!v || 'Nome é obrigatório']"
                  />
                </v-col>
                <v-col cols=6>
                  <v-text-field
                    label="id"
                    v-model="formDefault.id"
                    dense
                    hideDetails
                    disabled
                  />
                </v-col>
                <v-col cols=6>
                  <v-text-field
                    v-model="formDefault.email"
                    label="E-mail"
                    dense
                    :rules="[v => !!v || 'E-mail é obrigatório']"
                  />
                </v-col>
                <v-col cols=6>
                  <v-text-field
                    v-model="formDefault.password"
                    label="Senha"
                    dense
                    :rules="[v => !!v || 'Nome é obrigatório']"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
         
          <v-btn @click="dialogDefault = false" height=40 x-small outlined color="error" class="text-caption">
            <v-icon size=18 class="mr-1">mdi-cancel</v-icon>
            Cancelar
          </v-btn>
          
          <v-btn :disabled="!validDefault" @click="submit()" height=40 x-small outlined color="success" class="text-caption">
            <v-icon size=20 class="mr-1">mdi-content-save-outline</v-icon>
            Gravar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
  </section>
</template>

<script>
import Toolbar from "@/components/Templates/Toolbar"
import DataTable from "@/components/Registers/DataTable"
import { createNamespacedHelpers, mapState } from 'vuex'
const { mapState:registersState, mapActions:registersActions } = createNamespacedHelpers('registers')
const { mapState:authState } = createNamespacedHelpers('auth')
export default {
  name: "CustomersList",
  components: { DataTable, Toolbar },
  props: {
    claims: {
      type: Object,
      required: true,
      default: () => {}
    },
  },
  data () {
    return {
      title: 'Customers',
      collection: 'customers',
      register: 'customers',
      filters: [],
      orderBy: 'displayName',
      details: [],
      validDefault: true,
      dialogDefault: false,
      formDefault: {
        password: '123456'
      }
    }
  },
  computed: {
    ...mapState(['companySelected']),
    ...registersState(['registers']),
    ...authState(['user']),
    optDataTable() {
      return {
        vModel: this.vModel,
        headers: this.headers,
        items: this.registers[this.register],
        showSelect: true,
        itemKey: 'id',
        serverItemsLength: true,
        form: {
          newLabel: `Novo ${this.title}`,
          title: this.title,
          maxWidth: '800px',
          importer: true,
        },
        dispatch: []
      }
    },
    headers() {
      let headers = [
        { text: 'ID', value: 'id', copy: true, hide: true },
        { text: 'Ativo', value: 'active', checkbox: true, disabled: true },
        { text: 'Nome de Exibição', value: 'displayName' },
        { 
          text: 'Ações', 
          value: 'data-table-actions'
        },
      ]

      return headers
    },
    optionList() {
      return [
        {
          title: 'Cadastro Padrão',
          action: () => this.dialogDefault = true
        }
      ]
    }
  },
  methods: {
    ...registersActions(['observer']),
    updateDefaultFields(e) {
      const id = e.toLowerCase().replace(/[^\w]/gm, '_')
      this.formDefault.id = id
      this.formDefault.email = `ti@${id}.com.br`
    },
    async submit() {
      if (this.validate()) {
        await this.$api
          .post(`/customers/default`, { ...this.formDefault })
          .then(() => this.dialogDefault = false)
          .catch(err => console.error(err))
      }
    },
    validate() {
      return this.$refs.formDefault.validate()
    },
    initRegisters() {
      this.claims.copy = false
      this.claims.delete = false
      this.observer({
        collection: this.collection, 
        register: this.register, 
        filters: this.filters,
        orderBy: this.orderBy,
        details: this.details
      })
    }
  },
  mounted() {
    this.initRegisters()
  }
}
</script>

<style>

</style>