<script>
import Register from "@/views/Registers/Register"
import { createNamespacedHelpers } from 'vuex'
const { mapState: registersState } = createNamespacedHelpers('registers')
export default {
  name: 'UserRulesRegister',
  extends: Register,
  data() {
    return {
      rules: {
        required: v => !!v || 'Campo obrigatório',
        // email: v => /.+@.+\..+/.test(v) || 'Formato de email inválido',
      },
      routesInputs: []
    }
  },
  computed: {
    ...registersState(['registers']),
    computedInputsMaster() {     
      return [
        { type: 'divider', label: 'Dados Básicos' },
        { type: 'switch', label: 'Ativo', value: 'active', sm: 1, default: true  },
        { type: 'switch', label: 'Todos Vendedores?', value: 'seeAllSalers', sm: 3, default: false, hint: "Define se o usuário pode selecionar todos vendedores ao incluir registro.", persistentHint: true },
        // { type: 'text-field', label: 'ID', value: 'id', sm: 2,  rules: [this.rules.required] },
        // { type: 'text-field', label: 'Código', value: 'codigo', sm: 2,  rules: [this.rules.required] },
        { type: 'text-field', label: 'Nome', value: 'name', sm: 11,  rules: [this.rules.required] },
        ...this.computedRoutesInput
      ]
    },
    computedDispatch() {
      return [
        ]
    },
    computedRoutesInput() {    
      return this.routesInputs
    }
  },
  created() {      
    this.routesInputs = [
      { type: 'divider', label: 'Permissões' },
      { type: 'divider', label: 'Usuários', hideLine: true },
      { type: 'switch', label: 'Adicionar', sm: 2, value:  `claims.users.add`, default: false },
      { type: 'switch', label: 'Editar', sm: 2, value:  `claims.users.edit`, default: false },
      { type: 'switch', label: 'Excluir', sm: 2, value:  `claims.users.delete`, default: false },
      { type: 'switch', label: 'Copiar', sm: 2, value:  `claims.users.copy`, default: false },
      { type: 'switch', label: 'Visualizar', sm: 2, value:  `claims.users.view`, default: false },
      { type: 'switch', label: 'Sem Filtro', sm: 2, value:  `claims.users.noFilters`, default: false },
      { type: 'divider', label: 'Papéis de Usuários', hideLine: true },
      { type: 'switch', label: 'Adicionar', sm: 2, value:  `claims.userRules.add`, default: false },
      { type: 'switch', label: 'Editar', sm: 2, value:  `claims.userRules.edit`, default: false },
      { type: 'switch', label: 'Excluir', sm: 2, value:  `claims.userRules.delete`, default: false },
      { type: 'switch', label: 'Copiar', sm: 2, value:  `claims.users.copy`, default: false },
      { type: 'switch', label: 'Visualizar', sm: 2, value:  `claims.userRules.view`, default: false },
      { type: 'switch', label: 'Sem Filtro', sm: 2, value:  `claims.userRules.noFilters`, default: false },
      { type: 'divider', label: 'Integrações', hideLine: true },
      { type: 'switch', label: 'Adicionar', sm: 2, value:  `claims.integrations.add`, default: false },
      { type: 'switch', label: 'Editar', sm: 2, value:  `claims.integrations.edit`, default: false },
      { type: 'switch', label: 'Excluir', sm: 2, value:  `claims.integrations.delete`, default: false },
      { type: 'switch', label: 'Copiar', sm: 2, value:  `claims.users.copy`, default: false },
      { type: 'switch', label: 'Visualizar', sm: 2, value:  `claims.integrations.view`, default: false },
      { type: 'switch', label: 'Sem Filtro', sm: 2, value:  `claims.integrations.noFilters`, default: false },
      { type: 'divider', label: 'Notificações', hideLine: true },
      { type: 'switch', label: 'Adicionar', sm: 2, value:  `claims.notifications.add`, default: false },
    ];

    // const routesInput = [];
    // this.$integrations.map(i => 
    // //   this.routesInputs.push({ type: 'divider', label: i.name, hideLine: true })
    //   this.$router.options.routes.filter(r => r.register === i.register)
    //   .map(r => 
    // //     r.children.filter(c => c.claimRoute).map( c => 
    // //       this.routesInputs.push({ type: 'switch', label: c.meta.title, sm: 3, value:  `claims.${c.props.collection}.${c.claimRoute}`, default: false })
    // //     )
    //   )
    // )
    
    if (this.registers['integrations'] && this.registers['integrations'].length > 0) {
      this.routesInputs.push({ type: 'divider', label: 'Integrações (Rotas)', hideLine: true })
    }

    const integrations = this.registers['integrations'] || []
    const reports = this.registers['reports'] || []

    integrations.map(i =>  
      // const routesFiltered = this.$router.options.regis.filter(r => r.meta.menu && r.name !== 'Home')
      // for (const r of routesFiltered) {
        this.routesInputs.push(
          { type: 'divider', label: i.name, hideLine: true },
      //     ...r.children.filter(c => c.claimRoute).map( c => (
      //       { type: 'switch', label: c.meta.title, sm: 3, value:  `claims.${c.props.collection}.${c.claimRoute}`, default: false }
      //     ))
          { type: 'switch', label: 'Visualizar', sm: 2, disabled: !i.view, value:  `claims.${i.register}.view`, default: false },
          { type: 'switch', label: 'Adicionar', sm: 2, disabled: !i.add, value:  `claims.${i.register}.add`, default: false },
          { type: 'switch', label: 'Editar', sm: 2, disabled: !i.edit, value:  `claims.${i.register}.edit`, default: false },
          { type: 'switch', label: 'Excluir', sm: 2, disabled: !i.delete, value:  `claims.${i.register}.delete`, default: false },
          { type: 'switch', label: 'Copiar', sm: 2, disabled: !i.copy, value:  `claims.${i.register}.copy`, default: false },
          { type: 'switch', label: 'Aprovar', sm: 2, disabled: !i.sync, value:  `claims.${i.register}.approval`, default: false },
          { type: 'switch', label: 'Gravar ERP', sm: 2, disabled: !i.sync, value:  `claims.${i.register}.sync`, default: false },
          { type: 'switch', label: 'Sem Filtro', sm: 2, disabled: !i.view, value:  `claims.${i.register}.noFilters`, default: false }
    ))
      // }

      if (reports && reports.length > 0) {
        this.routesInputs.push({ type: 'divider', label: 'Relatórios', hideLine: true })
        // this.routesInputs.push({ type: 'switch', label: 'Monitor de Produção', sm: 3, disabled: false, value:  `claims.salesMonitor.view`, default: false })
      }
      this.routesInputs = [...this.routesInputs, ...reports.map(r => 
        ({ type: 'switch', label: r.name, sm: 3, disabled: false, value:  `claims.${'reports_'+r.id}.view`, default: false })
      )]
    }

}
</script>