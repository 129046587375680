<template>
<section id="signin" class="w-100 h-100">
    <Background/>
    
    <Header/>
    
    <v-row no-gutters class="mt-16 pt-16">
      <v-col></v-col>
      <v-col cols="10" sm="6" md="4" xl="3">
        <v-img
          :src="logo"
          max-width="70%"
          position="center center"
          class="mx-auto mb-8"
        ></v-img>

        <v-card elevation="5">
          <v-card-title class="text-h5 font-weight-normal">Login</v-card-title>
          <v-card-title v-show="askEmail" class="text-caption mt-n4 pt-0 pb-6">Informe seu e-mail para prosseguir com login.</v-card-title>
          <v-card-text>
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
              @submit.prevent
            >
              <v-text-field
                v-show="askEmail"
                v-model="user.email"
                :rules="rules.email"
                label="E-mail"
                @keyup.enter="signin"
                required
                class="mt-0 pt-0"
              />

              <v-row no-gutters align-center class="mt-2">
                <v-col cols="12" lg="9">
                  <v-alert
                    :value="res.value"
                    :type="res.type"
                    :icon="res.icon"
                    text
                    dense
                    dismissible
                    transition="scale-transition"
                    class="ma-0 mr-lg-1 wp-preline"
                  >{{res.content}}</v-alert>
                </v-col>
                <v-col cols="12" lg="3">
                  <v-btn
                    v-if="askEmail"
                    :disabled="!valid"
                    color="success"
                    @click="signin"
                    class="d-block mt-2 mb-1 ml-auto mt-lg-0"
                  >
                    Entrar
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col></v-col>
    </v-row>
  </section>
</template>

<script>
import Header from '@/components/Auth/Header.vue'
import Background from '@/components/Auth/Background.vue'
import { createNamespacedHelpers, mapMutations } from 'vuex'
const { mapActions:authActions } = createNamespacedHelpers('auth')
export default {
  name: 'FinishSignUp',
  components: {Header, Background},
  data: () => ({
    valid: true,
    askEmail: true,
    res: {
      value: false,
      type: 'info',
      icon: null,
      content: ''
    },
    user: {},
    rules: {
      email: [
        v => !!v || 'Informe o e-mail',
        v => /.+@.+\..+/.test(v) || 'Informe um e-mail válido',
      ],
    },
  }),
  methods: {
    ...mapMutations(['setLoading']),
    ...authActions(['finishSignUp']),
    intialize() {
      this.setLoading({stats: true})
      this.user.email = window.localStorage.getItem(this.$config.emailForSigninKey)
      if (this.user.email) {
        this.askEmail = false
        this.signin()
      }
      this.setLoading({stats: false})
    },
    signin() {
      if(this.user.email || this.$refs.form.validate()) {
        this.finishSignUp({email: this.user.email})
          .then(res => {
            if(!res.error) {
              this.res = {
                value: true,
                type: 'success',
                icon: 'mdi-check-circle-outline',
                content: 'Login efetuado!'
              }
            } else {
              this.res = {
                value: true,
                type: 'error',
                icon: 'mdi-alert-outline',
                content: res.error
              }
            }
            this.setLoading({stats: false})
          })
          .catch(err => {
            console.error(err)
            this.res = {
              value: true,
              type: 'error',
              icon: 'mdi-alert-outline',
              content: 'Erro interno, favor contactar o administrador.'
            }
            this.setLoading({stats: false})
          })
      }
    }
  },
  created() {
    this.intialize()
  },
  computed: {
  logo() {
      return this.$appConfig.logo ? this.$appConfig.logo :  '@/assets/logo.png'
    }
  }
}
</script>

<style lang="scss">
</style>