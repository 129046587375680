<template>
  <iframe
    :style="{ width: '100%', height: height+'px' }"
    v-bind="$attrs"
    v-on="$listeners"/>
</template>

<script>
export default {
  computed: {
    height() {
      return window.innerHeight * 0.8
    }
  }
}
</script>