<!--
  Por ora, está funcionando apenas para Ibramed. 
  Mas tem potencial de aplicar para mais clientes
-->

<template>
  <section>
    <v-dialog v-model="dialog" width="1200" @click:outside="productSelect=null;paymentSelect=null;">
      <template v-slot:activator="{on, attrs}">
        <v-btn v-bind="attrs" v-on="on" text outlined height="50" dark :max-width="$vuetify.breakpoint.lgAndDown ? '800px' : ''" class="no-text-transform justify-start overflow-hidden text-left pa-3 ml-5">
          <v-icon size="20" class="mr-2">mdi-calculator</v-icon>
          Simulador de Financiamento
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="grey darken-4 white--text"> 
          Simulador de Financiamento
        </v-card-title>

        <v-card-text v-if="products.length && payments.length">
          <v-row class="ma-4">
            <v-col cols="4" class="py-1">
              <v-select
                v-model="productSelect"
                :items="computedProducts"
                return-object
                hide-details
                label="Produto"
                dense
                class="mt-1">
                <template v-slot:prepend-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-text-field
                        prepend-icon="mdi-magnify"
                        dense
                        clearable
                        single-line
                        hide-details
                        placeholder="Filtrar..."
                        v-model="filterProduct"
                      >
                      </v-text-field>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template v-slot:selection="{ item }">
                  <v-chip link color="grey darken-3" dark label small class="mr-2">
                    {{ item.B1_COD }} - {{ item.B1_DESC.substr(0,25) }}
                  </v-chip>
                </template>
                <template v-slot:item="{ item }">
                  <v-chip link color="grey darken-3" dark label small class="mr-2">
                    {{ item.B1_COD }} - {{ item.B1_DESC }}
                  </v-chip>
                </template>
              </v-select>              
            </v-col>
            <v-col cols="4" class="py-1">
              <v-select
                v-model="paymentSelect"
                :items="computedPayments"
                return-object
                hide-details
                dense
                label="Condição de Pagamento">
                <template v-slot:prepend-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-text-field
                        prepend-icon="mdi-magnify"
                        dense
                        clearable
                        single-line
                        hide-details
                        placeholder="Filtrar..."
                        v-model="filterPayment">
                      </v-text-field>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template v-slot:selection="{ item }">
                  <v-chip link color="grey darken-3" dark label small class="mr-2">
                    {{ item.E4_CODIGO }} - {{ item.E4_DESCRI.substr(0,25) }}
                  </v-chip>
                </template>
                <template v-slot:item="{ item }">
                  <v-chip link color="grey darken-3" dark label small class="mr-2">
                    {{ item.E4_CODIGO }} - {{  item.E4_DESCRI }}
                  </v-chip>
                </template>
              </v-select>              
            </v-col>
            
            <v-col cols="4" class="py-1">   
              <v-text-field
                v-model="inputValue"
                class="mt-1"
                :name="'Valor de Entrada'"
                :label="'Valor de Entrada'"
                :placeholder="'Valor de Entrada'"
                :type="'number'"
                dense>
              </v-text-field>        
            </v-col>
          </v-row>

          <v-row v-if="this.items.length">
            <v-col cols="12">
              <v-data-table dense 
                :headers="computedHeaders"
                :items="computedItems"
                :disable-filtering="true"
                :disable-pagination="true"
                :disable-sort="true"
                :hide-default-footer="true">
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="productSelect=null;paymentSelect=null;dialog = false">
            Sair
          </v-btn>
          <v-btn
            :disabled="!(productSelect && paymentSelect)"
            color="primary"
            text
            @click="simulate()">
              Simular
          </v-btn>
        </v-card-actions>
        <v-snackbar
          :value="showError"
          absolute
          bottom
          @click="showError = false"
          color="red accent-2">
          {{ errorMessage }}
        </v-snackbar>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import {
  createNamespacedHelpers,
  mapMutations
} from "vuex";
const { mapState: registersState, mapActions: registersActions, mapMutations: registersMutations } = createNamespacedHelpers("registers");
export default {
  name: "PaymentSimulator",
  data: () => ({
    dialog: false,
    showError: false,
    productSelect: null,
    paymentSelect: null,
    inputValue: null,
    errorMessage: 'O valor da entrada é inferior ao mínimo necessário! Valores calculados com a porcentagem da condição de pagamento.',
    products: [],
    payments: [],
    filterProduct: "",
    filterPayment: "",
    items: []
  }),
  computed: {
    ...registersState(["loading"]),
    computedProducts() {
      if (!this.filterProduct) {
        return this.products
      }

      return this.products
        .filter(
          ({ B1_COD, B1_DESC }) => (B1_COD+B1_DESC)
            .trim()
            .toLowerCase()
            .includes(
              this.filterProduct.trim().toLowerCase()
            )
        )
    },
    computedPayments() {
      if (!this.filterPayment) {
        return this.payments
      }

      return this.payments
        .filter(
          ({ E4_CODIGO, E4_DESCRI }) => (E4_CODIGO+E4_DESCRI)
            .trim()
            .toLowerCase()
            .includes(
              this.filterPayment.trim().toLowerCase()
            )
        )
    },
    computedHeaders() {
      return [
        {
          'value': 'vlrEntrada',
          'text': 'Vlr. Entrada',
        },
        {
          'value': 'qtdParcelas',
          'text': 'Vlr. qtdParcela',
        },
        {
          'value': 'valorParcela',
          'text': 'Vlr. Parcela',
        }
      ]
    },
    computedItems() {
      return this.items
    }
  },
  watch: {
    dialog(open) {
      if (open) {
        this.buildData()
      }
    },
    productSelect() {
      this.items = []
      this.filterProduct = ""
      this.filterPayment = ""
    },
    paymentSelect() {
      this.items = []
      this.filterProduct = ""
      this.filterPayment = ""
    }
  },
  methods: {
    ...mapMutations(["setLoading"]),
    ...registersActions(["get", "getExternal", "getApi"]),
    async buildData() {
      this.setLoading({stats: true})
      await this.buildProducts()
      await this.buildPayments()
      this.setLoading({stats: false})
    },
    async simulate() {
      const { B1_COD } = this.productSelect
      const { E4_CODIGO } = this.paymentSelect
      this.setLoading({stats: true})
      const result = await this.getApi({ url: '/protheus/', options: { params: { path: `WsSimuladorPrice?Produto=${B1_COD.trim()}&cCond=${E4_CODIGO.trim()}&vlrPortal=${this.inputValue || 0}` } } })
      const [ {Parcela: valorParcela, Prazo: qtdParcelas, Entrada: vlrEntrada, sucesso} ] = result
      
      this.showError = !sucesso

      this.items = [
        { 
          valorParcela: `R$ ${(valorParcela*1).toLocaleString('pt-br', { minimumFractionDigits:2, maximumFractionDigits: 2 }) }`, 
          qtdParcelas, 
          vlrEntrada: `R$ ${(vlrEntrada*1).toLocaleString('pt-br', { minimumFractionDigits:2, maximumFractionDigits: 2 }) }`
        }
      ]
      this.setLoading({stats: false})
    },
    async buildProducts() {
      let query = "`SELECT SB1.R_E_C_N_O_ AS ID, B1_COD, B1_DESC "
      query += "FROM DA1${gpEmpresa} AS DA1 "
      query += "INNER JOIN SB1${gpEmpresa} AS SB1 ON SB1.D_E_L_E_T_ <> '*' AND DA1_CODPRO = B1_COD "
      query += "WHERE DA1.D_E_L_E_T_ <> '*' AND B1_MSBLQL <> '1' AND DA1_CODTAB = '040' AND DA1_ATIVO = '1' `"

      this.products = await this.getExternal({
        setRegister: false,
        idField: 'ID',
        query,
        perPage: 99999999,
        ordem: 'B1_DESC',
        forceFilter: true
      })
      
    },
    async buildPayments() {
      this.payments = await this.get({
        collection: 'SE4', 
        filters: [
          {key: "active"    , operator: '==', value: true   },
          {key: "deletado"  , operator: '==', value: false  },
          {key: "FILTROSA1" , operator: '==', value: '1'  }
        ], 
        limit: 9999,
        forceFilter: true,
        setRegister: false
      })
    }
  },
  created() {
  },
};
</script>
