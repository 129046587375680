<template>
  <v-form @submit.prevent="validate() && submit()" @reset.prevent="reset" ref="form" autocomplete="off">
    <v-card :dark="dark">
      <slot name="prepend"></slot>
      <v-card-text>
        <v-row>
          <v-col v-for="(input, index) in inputs" :key="index" v-show="input.hasOwnProperty('show') ? input.show : typeof input.hide == 'function' ? !input.hide({item: formData}) : typeof input.hide == 'string' ? !formData[input.hide] : !input.hide" :cols="input.cols || 12" :sm="input.sm" :md="input.md" :lg="input.lg" :xl="input.xl" :class="`${input.class || ''} py-0`">
            <template v-if="input.type == 'divider'">
              <v-toolbar flat dense short height="35" class="mt-2 pa-0">
                <v-toolbar-title :class="`subtitle-${input.labelSize || '2'} font-weight-bold ${color}--text ml-n4`">{{ input.label }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn v-if="input.help && input.help.length > 0" :href="input.help" target="_blank" x-small icon :color="color" class="no-hover">
                  <v-icon>mdi-help-circle-outline</v-icon>
                </v-btn>
              </v-toolbar>
              <v-divider v-show="!input.hideLine" class="mb-2"></v-divider>
            </template>

            <!-- TEXT FIELD -->
            <v-text-field
              v-else-if="input.type == 'text-field'"
              v-model="formData[input.value]"
              :class="{'highlight-v-text-field': input.highlight}"
              :name="input.value"
              :rules="(auxData[input.value] && auxData[input.value].rules) ? auxData[input.value].rules : []"
              :validate-on-blur="input.validateOnBlur"
              :label="input.label"
              :placeholder="input.placeholder"
              :hint="input.hint"
              :persistent-hint="input.persistentHint"
              :type="input.textType"
              :maxlength="input.maxlength"
              :counter="(input.maxlength) ? true : false"
              :readonly="readonly || input.readonly"
              :disabled="typeof input.disabled == 'string' ? formData[input.disabled] : typeof input.disabled == 'function' ? input.disabled({item: formData, input, user, rota, claimRoute: claimRouteMethod() }) : input.disabled"
              :filled="input.filled || typeof input.disabled == 'string' ? formData[input.disabled] : typeof input.disabled == 'function' ? input.disabled({item: formData, input, user}) : input.disabled"
              :dense="input.dense || typeof input.disabled == 'string' ? formData[input.disabled] : typeof input.disabled == 'function' ? input.disabled({item: formData, input, user}) : input.disabled"
              v-mask="input.vMask ? `${input.vMask}` : ''"
              v-on="input.on"
              @change="(value) => proccessInput(formData, input, value)"
            >
              <template v-if="input.markers" slot="prepend-inner">
                <v-badge inline :color="input.markers[formData[input.value].trim().toLowerCase()] "/>
              </template>
              <template v-if="input.isIcon" slot="prepend">
                <v-icon color="primary">
                  {{ formData[input.value] }}
                </v-icon>
              </template>
            </v-text-field>

            <!-- PASSWORD -->
            <v-text-field
              v-else-if="input.type == 'password'"
              v-model="formData[input.value]"
              :name="input.value"
              :rules="(auxData[input.value] && auxData[input.value].rules) ? auxData[input.value].rules : []"
              :label="input.label"
              :placeholder="input.placeholder"
              :hint="input.hint"
              :persistent-hint="input.persistentHint"
              :append-icon="auxData[input.value].showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="auxData[input.value].showPassword ? 'text' : 'password'"
              @click:append="auxData[input.value].showPassword = !auxData[input.value].showPassword"
              :disabled="typeof input.disabled == 'string' ? formData[input.disabled] : typeof input.disabled == 'function' ? input.disabled({item: formData, input, user, claimRoute: claimRouteMethod()}) : input.disabled"
              :readonly="readonly || input.readonly"
              :autocomplete="input.autocomplete"
            ></v-text-field>

            <!-- TEXTAREA -->
            <v-textarea
              v-else-if="input.type == 'textarea'"
              v-model="formData[input.value]"
              :name="input.value"
              :rules="(auxData[input.value] && auxData[input.value].rules) ? auxData[input.value].rules : []"
              :label="input.label"
              :placeholder="input.placeholder"
              :hint="input.hint"
              :persistent-hint="input.persistentHint"
              :disabled="typeof input.disabled == 'string' ? formData[input.disabled] : typeof input.disabled == 'function' ? input.disabled({item: formData, input, user, claimRoute: claimRouteMethod()}) : input.disabled"
              :readonly="readonly || input.readonly"
              :counter="input.counter"
              :rows="input.rows || 10"
              :filled="input.filled || typeof input.disabled == 'string' ? formData[input.disabled] : typeof input.disabled == 'function' ? input.disabled({item: formData, input, user}) : input.disabled"
              v-on="input.on"
              auto-grow
            ></v-textarea>

            <!-- SELECT -->
            <v-select
              v-else-if="input.type == 'select'"
              v-model="formData[input.value]"
              :rules="(auxData[input.value] && auxData[input.value].rules) ? auxData[input.value].rules : []"
              :label="input.label"
              :placeholder="input.placeholder"
              :hint="input.hint"
              :persistent-hint="input.persistentHint"
              :items="typeof input.items == 'string' ? registers[input.items] : input.items.params && input.items.params.includes('=>') ? evalLocal(input.items.params) : input.items"
              :item-text="typeof input.itemText == 'function' ? (item) => input.itemText({item: formData, element: item}) : input.itemText"
              :item-value="input.itemValue"
              :chips="input.chips"
              :color="input.markers && formData[input.value] ? input.markers[formData[input.value].trim().toLowerCase()] : ''"
              :small-chips="input.smallChips"
              :deletable-chips="input.deletableChips"
              :multiple="input.multiple"
              :readonly="readonly || input.readonly"
              :return-object="input.returnObject"
              :disabled="typeof input.disabled == 'string' ? formData[input.disabled] : typeof input.disabled == 'function' ? input.disabled({item: formData, input, user, claimRoute: claimRouteMethod()}) : input.disabled"
              :filled="input.filled || typeof input.disabled == 'string' ? formData[input.disabled] : typeof input.disabled == 'function' ? input.disabled({item: formData, input, user}) : input.disabled"
              :dense="input.dense || typeof input.disabled == 'string' ? formData[input.disabled] : typeof input.disabled == 'function' ? input.disabled({item: formData, input, user}) : input.disabled"
              :hide-details="input.hideDetails ? true : false"
              v-on="input.on"
              @change="(value) => proccessInput(formData, input, value)"
            >
              <template v-if="input.multiple" v-slot:prepend-item>
                <v-list-item ripple @click="selectAll({input, items: typeof input.items == 'string' ? registers[input.items] : input.items, itemValue: input.itemValue})">
                  <v-list-item-action>
                    <v-icon v-if="formData[input.value] && input.items && formData[input.value].length > 0 && formData[input.value].length >= input.items.length" :color="formData[input.value] && formData[input.value].length > 0 ? 'indigo' : ''">
                      mdi-close-box
                    </v-icon>
                    <v-icon v-else-if="formData[input.value] && input.items && formData[input.value].length > 0 && formData[input.value].length < input.items.length" :color="formData[input.value] && formData[input.value].length > 0 ? 'indigo' : ''">
                      mdi-minus-box
                    </v-icon>
                    <v-icon v-else :color="formData[input.value] && formData[input.value].length > 0 ? 'indigo' : ''">mdi-checkbox-blank-outline</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      Selecionar Todos
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
              <template v-if="input.chips" v-slot:selection="data">
                <v-chip v-if="data.index < formChips" :key="JSON.stringify(data.item)" v-bind="data.attrs" :input-value="data.selected" :disabled="data.disabled" small close :color="`${color} accent-4`" dark @click:close="input.multiple ? data.parent.selectItem(data.item) : clearField({field: input.value})" class="my-2">
                  <span>{{ typeof input.itemText == "function" ? input.itemText({item: formData, element: data.item}) : input.itemText || data.item }}</span>
                </v-chip>
                <span v-if="data.index == formChips" class="grey--text caption">(+{{ formData[input.value].length - formChips }} outro(s))</span>
              </template>
              <template v-if="input.markers && formData[input.value]" slot="prepend-inner">
                <v-icon v-if="input.icons" inline :color="input.markers[formData[input.value].trim().toLowerCase()] ">
                  {{ input.icons[formData[input.value].trim()] }}
                </v-icon>
                <v-badge v-else inline :color="input.markers[formData[input.value].trim().toLowerCase()] " class="mt-1 mr-1" />
              </template>
              <template v-if="input.icons && !input.multiple" v-slot:item="data">
                <v-icon v-if="input.icons && input.icons[data.item.value.trim()]">
                  {{ input.icons[data.item.value.trim()] }}
                </v-icon>
                {{ data.item.text }}
              </template>
            </v-select>

            <!-- AUTOCOMPLETE -->
            <v-expand-transition v-else-if="input.type == 'autocomplete'">
              <v-autocomplete
                v-model="formData[input.value]"
                :rules="(auxData[input.value] && auxData[input.value].rules) ? auxData[input.value].rules : []"
                :label="input.label"
                :items="typeof input.items == 'string' && !input.items.includes('=>') ? registers[input.items] : Array.isArray(input.items) ? input.items : auxData[input.value].items"
                :item-text="typeof input.itemText == 'function' ? (item) => input.itemText({item: formData, element: item}) : input.itemText"
                :item-value="input.itemValue"
                :return-object="input.returnObject"
                :chips="input.chips"
                :placeholder="input.placeholder"
                :hint="input.hint"
                :persistent-hint="input.persistentHint"
                :small-chips="input.smallChips"
                :deletableChips="input.deletableChips"
                :multiple="input.multiple"
                :readonly="readonly || input.readonly"
                :disabled="typeof input.disabled == 'string' ? formData[input.disabled] : typeof input.disabled == 'function' ? input.disabled({item: formData, input, user, claimRoute: claimRouteMethod()}) : input.disabled"
                :filled="input.filled || typeof input.disabled == 'string' ? formData[input.disabled] : typeof input.disabled == 'function' ? input.disabled({item: formData, input, user}) : input.disabled"
                :dense="input.dense || typeof input.disabled == 'string' ? formData[input.disabled] : typeof input.disabled == 'function' ? input.disabled({item: formData, input, user}) : input.disabled"
                :loading="auxData[input.value].loadingAutocomplete"
                :clearable="input.clearable"
                v-on="input.on"
                @update:search-input="(value) => querySelections(value, input)"
                @change="(value) => proccessInput(formData, input, value)"
              >
                <template v-if="input.fieldsToShow && input.fieldsToShow.length > 0" v-slot:item="{item}">
                  <div v-for="(field, ix) in input.fieldsToShow" :key="field">
                    <span v-if="ix > 0" class="mx-1">-</span>
                    <span v-html="item[field]" />
                  </div>
                </template>
                <template v-if="input.chips" v-slot:selection="data">
                  <v-chip v-if="data.index < formChips" :key="JSON.stringify(data.item)" v-bind="data.attrs" :input-value="data.selected" :disabled="data.disabled" :color="`${color} accent-4`" small close dark @click:close="input.multiple ? data.parent.selectItem(data.item) : clearField({field: input.value})">
                    <span>{{ data.item[input.itemText] || data.item }}</span>
                  </v-chip>
                  <span v-if="data.index == formChips" class="grey--text caption">(+{{ formData[input.value].length - formChips }} outro(s))</span>
                </template>
              </v-autocomplete>
            </v-expand-transition>
      
            <!-- CUSTOMAUTOCOMPLETE -->
            <v-expand-transition v-else-if="input.type == 'customautocomplete'">
              <v-custom-autocomplete
                v-show="!input.hide"
                v-model="formData[input.value]"
                @click:clear="autocompleteHits[input.value] = []"
                :index="input.value"
                :items="autocompleteHits[input.value]"
                :loading="autocompleteLoading[input.value]"
                :filter="(item, queryText, itemText) => input.fieldsToShow.some(x => item[x].toLowerCase().includes(queryText.toLowerCase()))"
                :handler="handlerAutocomplete"
                :handlerLoading="handlerAutocompleteLoading"
                :delay="750"
                :dependents="input.dependents"
                :asyncSearch="input.asyncSearch"
                :fieldsToShow="input.fieldsToShow"
                :fieldsToSearch="input.fieldsToSearch"
                :item="formData"
                :rules="(auxData[input.value] && auxData[input.value].rules) ? auxData[input.value].rules : []"
                :label="input.label"
                :return-object="input.returnObject"
                :item-value="input.itemValue"
                :item-text="input.itemText"
                :chips="input.chips"
                :placeholder="input.placeholder"
                :hint="input.hint"
                :persistent-hint="input.persistentHint"
                :small-chips="input.smallChips"
                :deletableChips="input.deletableChips"
                :multiple="input.multiple"
                :disabled="typeof input.disabled == 'string' ? formData[input.disabled] : typeof input.disabled == 'function' ? input.disabled({item: formData, input, user}) : input.disabled"
                :filled="input.filled || typeof input.disabled == 'string' ? formData[input.disabled] : typeof input.disabled == 'function' ? input.disabled({item: formData, input, user}) : input.disabled"
                :dense="input.dense || typeof input.disabled == 'string' ? formData[input.disabled] : typeof input.disabled == 'function' ? input.disabled({item: formData, input, user}) : input.disabled"
                :readonly="readonly || input.readonly"
                :clearable="!readonly && !input.readonly"
                :ref="`customAuto-${input.value}`"
                v-on="input.on"
                @change="(value) => proccessInput(formData, input, value)"               
              >
                <template v-slot:item="{item}">
                  <div v-for="(field, ix) in input.fieldsToShow" :key="field">
                    <span v-if="ix > 0" class="mx-1">-</span>
                    <span v-html="item._highlightResult && item._highlightResult[field] && item._highlightResult[field].matchedWords && item._highlightResult[field].matchedWords.length > 0 ? item._highlightResult[field].value : item[field]" />
                  </div>
                </template>
                <template v-if="input.chips" v-slot:selection="data">
                  <section>
                    <v-chip v-if="data.index < formChips" :key="JSON.stringify(data.item)" v-bind="data.attrs" :input-value="data.selected" :disabled="data.disabled" :color="`${color} accent-4`" small close dark @click:close="input.multiple ? data.parent.selectItem(data.item) : clearField({field: input.value})">
                      <span>{{ data.item[input.itemText] || data.item }}</span>
                    </v-chip>
                    <span v-if="data.index == formChips" class="grey--text caption">(+{{ formData[input.value].length - formChips }} outro(s))</span>
                  </section>
                </template>
                <template v-slot:append-item >
                  <v-btn class="mx-1 mt-2 ml-2" @click="callMore(input.value)">Ver Mais</v-btn>
                </template>
              </v-custom-autocomplete>
            </v-expand-transition>
            
            <!-- AUTOCOMPLETE ALGOLIA -->
            <v-expand-transition v-else-if="input.type == 'autocompletealgolia'">
              <v-autocomplete-algolia
                v-show="!input.hide"
                v-model="formData[input.value]"
                @click:clear="algoliaHits[input.value] = []"
                :index="input.index ? input.index : input.value"
                :items="input.index ? algoliaHits[input.index] : algoliaHits[input.value]"
                :filter="() => { return true }"
                :handler="handlerAlgolia"
                :delay="750"
                :attributesToHighlight="input.fieldsToShow"
                :restrictSearchableAttributes="input.fieldsToSearch"
                :facetFilters="input.facetFilters"
                :item="formData"
                :rules="(auxData[input.value] && auxData[input.value].rules) ? auxData[input.value].rules : []"
                :label="input.label"
                :return-object="input.returnObject"
                :item-value="input.itemValue"
                :item-text="input.itemText"
                :chips="input.chips"
                :placeholder="input.placeholder"
                :hint="input.hint"
                :persistent-hint="input.persistentHint"
                :small-chips="input.smallChips"
                :deletableChips="input.deletableChips"
                :multiple="input.multiple"
                :disabled="typeof input.disabled == 'string' ? formData[input.disabled] : typeof input.disabled == 'function' ? input.disabled({item: formData, input, user}) : input.disabled"
                :filled="input.filled || typeof input.disabled == 'string' ? formData[input.disabled] : typeof input.disabled == 'function' ? input.disabled({item: formData, input, user}) : input.disabled"
                :dense="input.dense || typeof input.disabled == 'string' ? formData[input.disabled] : typeof input.disabled == 'function' ? input.disabled({item: formData, input, user}) : input.disabled"
                :readonly="readonly || input.readonly"
                :clearable="!readonly && !input.readonly"
                v-on="input.on"
                @change="(value) => proccessInput(formData, input, value)"
              >
                <template v-slot:item="{item}">
                  <div v-for="(field, ix) in input.fieldsToShow" :key="field">
                    <span v-if="ix > 0" class="mx-1">-</span>
                    <span v-html="item._highlightResult && item._highlightResult[field] && item._highlightResult[field].matchedWords && item._highlightResult[field].matchedWords.length > 0 ? item._highlightResult[field].value : item[field]" />
                  </div>
                </template>
                <template v-if="input.chips" v-slot:selection="data">
                  <v-chip v-if="data.index < formChips" :key="JSON.stringify(data.item)" v-bind="data.attrs" :input-value="data.selected" :disabled="data.disabled" :color="`${color} accent-4`" small close dark @click:close="input.multiple ? data.parent.selectItem(data.item) : clearField({field: input.value})">
                    <span>{{ data.item[input.itemText] || data.item }}</span>
                  </v-chip>
                  <span v-if="data.index == formChips" class="grey--text caption">(+{{ formData[input.value].length - formChips }} outro(s))</span>
                </template>
              </v-autocomplete-algolia>
            </v-expand-transition>

            <!-- COMBOBOX -->
            <v-combobox
              v-else-if="input.type == 'combobox'"
              v-model="formData[input.value]"
              :rules="(auxData[input.value] && auxData[input.value].rules) ? auxData[input.value].rules : []"
              :label="input.label"
              :placeholder="input.placeholder"
              :hint="input.hint || 'Pressione Enter para inserir'"
              :persistent-hint="input.persistentHint"
              :items="input.items"
              :chips="input.chips"
              :small-chips="input.smallChips"
              :deletable-chips="input.deletableChips"
              :multiple="input.multiple"
              :disabled="typeof input.disabled == 'string' ? formData[input.disabled] : typeof input.disabled == 'function' ? input.disabled({item: formData, input, user}) : input.disabled"
              :readonly="readonly || input.readonly"
              :return-object="input.returnObject"
              :item-text="input.itemText"
              :item-value="input.itemValue"
              v-on="input.on"
              @change="(value) => proccessInput(formData, input, value)"
            >
              <template v-if="input.multiple" v-slot:selection="data">
                <v-chip v-bind="data.attrs" :key="JSON.stringify(data.item)" :input-value="data.selected" :disabled="data.disabled" :color="`${color} accent-4`" small close dark @click:close="input.multiple ? data.parent.selectItem(data.item) : clearField({field: input.value})" class="my-2">
                  <!-- <v-avatar
                    class="accent white--text"
                    left
                    v-text="data.item.slice(0, 1).toUpperCase()"
                  ></v-avatar> -->
                  {{ data.item[input.itemText] || data.item }}
                </v-chip>
              </template>
            </v-combobox>
            
            <!-- SWITCH -->
            <v-switch
              v-else-if="input.type == 'switch' && input.parent && formData[input.parent]"
              v-show="typeof input.hide == 'string' ? !formData[input.hide] : !input.hide"
              v-on="input.on"
              v-model="formData[input.parent][input.value]"
              :rules="(auxData[input.value] && auxData[input.value].rules) ? auxData[input.value].rules : []"
              :label="input.label"
              :placeholder="input.placeholder"
              :hint="input.hint"
              :persistent-hint="input.persistentHint"
              :disabled="typeof input.disabled == 'string' ? formData[input.disabled] : typeof input.disabled == 'function' ? input.disabled({item: formData, input, user}) : input.disabled"
              :readonly="readonly || input.readonly"
              :color="color"
              inset
              dense
              :hide-details="input.hideDetails ? true : false"
            />

            <v-switch  
              v-else-if="input.type == 'switch'"
              v-show="typeof input.hide == 'string' ? !formData[input.hide] : !input.hide"
              v-on="input.on"
              v-model="formData[input.value]" 
              :rules="(auxData[input.value] && auxData[input.value].rules) ? auxData[input.value].rules : []" 
              :label="input.label" 
              :placeholder="input.placeholder" 
              :hint="input.hint" 
              :persistent-hint="input.persistentHint" 
              :disabled="typeof input.disabled == 'string' ? formData[input.disabled] : typeof input.disabled == 'function' ? input.disabled({item: formData, input, user}) : input.disabled" :readonly="readonly || input.readonly" 
              :color="color" @change="input.onChange" 
              inset 
              dense />

            <!-- JSON EDITOR -->
            <template v-else-if="input.type == 'jsoneditor' && (typeof input.hide == 'function' ? !input.hide(formData) : !input.hide)">
              <v-toolbar flat dense short height="35" class="mt-2 pa-0">
                <v-toolbar-title :class="`subtitle-${input.labelSize || '2'} font-weight-bold ${color}--text ml-n4`">{{ input.label }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn v-if="input.help && input.help.length > 0" :href="input.help" target="_blank" x-small icon :color="color" class="no-hover">
                  <v-icon>mdi-help-circle-outline</v-icon>
                </v-btn>
              </v-toolbar>
              <v-divider v-show="!input.hideLine" class="mb-2"></v-divider>
              <v-jsoneditor v-model="formData[input.value]" :ref="input.value" :options="input.options" :plus="false" :readonly="readonly || input.readonly" @input="updateVJSF($event, input)" height="400px" min-width="auto" class="mb-6" />
              <span class="hint">{{ input.hint }}</span>
            </template>

            <!-- VJSF CHILD -->
            <template v-else-if="input.type == 'jsfChild'">
              <v-card outlined>
                <v-card-subtitle>
                  {{ input.label }}
                </v-card-subtitle>
                <v-row v-for="(item, index) in formData[input.value]" :key="index" no-gutters>
                  <v-col class="py-1">
                    <v-card>
                      <v-card-text>
                        <v-jsf v-model="formData[input.value][index].data" :schema="typeof input.schema == 'object' ? auxData[input.value][index].schema : auxData[input.schema].schema" :options="{...input.options, ...auxData[input.value][index].options}" class="d-inline-block row-child" />
                        <v-btn
                          x-small
                          icon
                          color="error"
                          @click="
                            auxData[input.value][index].options.disableAll = !auxData[input.value][index].options.disableAll;
                            formData[input.value][index].delete = !formData[input.value][index].delete;
                          "
                          class="d-inline-block"
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row :style="{padding: '0', margin: '0'}">
                  <v-col cols="12" :style="{padding: '0', margin: '0'}">
                    <v-btn
                      :style="{width: '98%'}"
                      outlined
                      small
                      color="indigo"
                      @click="
                        formData[input.value].push({});
                        auxData[input.value].push({schema: input.schema, options: {disableAll: false}});
                      "
                      class="ma-2"
                    >
                      <v-icon>mdi-plus</v-icon> Adicionar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </template>

            <!-- VJSF -->
            <template v-else-if="input.type == 'jsf'">
              <span class="d-block mb-2">{{ input.label }}</span>
              <br>
              <v-jsf v-model="formData[input.value]" 
              :schema="typeof input.schema == 'string' ? auxData[input.schema].schema : auxData[input.value].schema" 
              :options="{...input.options, ...auxData[input.value].options}">
              </v-jsf>
            </template>

            <!-- FILE -->
            <v-file-input v-else-if="input.type == 'file'" v-show="!(typeof input.hide == 'boolean') || input.hide" v-bind="formData[input.value]" :label="input.label" :prepend-icon="input.prependIcon" :disabled="typeof input.disabled == 'string' ? formData[input.disabled] : typeof input.disabled == 'function' ? input.disabled({item: formData, input, user}) : input.disabled" :readonly="readonly || input.readonly"> </v-file-input>

            <!-- PICTURE -->
            <!-- <UploadAvatar 
              v-else-if="input.type == 'picture'"
              v-show="!(typeof input.hide == 'boolean') || input.hide"
              :profile="formData" 
              @change="changeAvatar" 
              class="mb-6 text-center"/> -->

            <!-- <upload-avatar
              v-bind="formData[input.value]"
              :label="input.label"
              :disabled="(typeof input.disabled == 'string') ? formData[input.disabled] : input.disabled"
              :readonly="readonly || input.readonly"/> -->

            <!-- BUTTON -->
            <v-btn v-else-if="input.type == 'button'" v-show="!('show' in input) || input.show" v-on="input.on" @click="!readonly && proccessInput(formData, input, (input.model.apply && input.model()) || input.model)" v-bind="input.bind">
              {{ input.text }}
            </v-btn>

            <!-- LIST -->
            <v-list-item v-else-if="input.type == 'list'" v-show="!('show' in input) || input.show" class="ma-0 pa-0">
              <v-list-item-content class="ma-0 pa-0 d-inline-block">
                <v-toolbar flat dense short height="35" class="mt-2 pa-0">
                  <v-toolbar-title :class="`subtitle-2 font-weight-bold ${color}--text ml-n4`">{{ input.label }}</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn v-if="input.button" @click="!readonly && proccessInput(formData, input, (input.model.apply && input.model()) || input.model)" small outlined :color="color" class="no-hover  mr-n4">
                    <v-icon v-if="input.button.icon">{{ input.button.icon }}</v-icon>
                    <span v-if="input.button.text">{{ input.button.text }}</span>
                  </v-btn>
                </v-toolbar>
                <v-divider class="mb-2"></v-divider>
                <v-list-item-subtitle v-for="(value, key) in formData[input.value]" :key="key" :class="`${input.inline ? 'd-inline-block mr-2' : ''}`">
                  <b>{{ key }}:</b> {{ value }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <!-- COLOR PICKER -->
            <template v-else-if="input.type == 'color-picker'">
              <v-text-field :value="formData[input.value]" :errorMessages="auxData[input.value].errorMessages" :label="input.label" @change="changeColorPickerInput($event, input)">
                <template #prepend>
                  <v-menu v-model="auxData[input.value].menu" :close-on-content-click="false" @input="$event ? (auxData[input.value].model = formData[input.value]) : (formData[input.value] = auxData[input.value].model)" nudge-bottom="105" nudge-left="16" top>
                    <template #activator="{ on }">
                      <v-btn height="30px" width="30px" fab elevation="0" :color="formData[input.value]" v-on="on"> </v-btn>
                    </template>
                    <v-card>
                      <v-card-text class="pa-0">
                        <v-color-picker v-model="auxData[input.value].model" flat />
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </template>
              </v-text-field>
            </template>

            <!-- IMAGES PICKER -->
            <template v-else-if="input.type == 'images'">
              <Images
                :readonly="readonly || input.readonly"
                :disabled="input.disabled"
                :imageUrls="formData[input['value']]"
                @setImages="(images) => { formData[input['value']] = images }" />
            </template>

            <template v-else-if="input.type == 'date-picker'">
              <v-menu
                v-model="auxData[input.value].menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
                :readonly="readonly || input.readonly"
                :disabled="input.disabled"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="auxData[input.value].modelFormated"
                    :label="input.label"
                    :rules="(auxData[input.value] && auxData[input.value].rules) ? auxData[input.value].rules : []"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="formData[input.value]"
                  @input="auxData[input.value].menu = false; formatDatePickerTextField($event, input)"
                ></v-date-picker>
              </v-menu>
            </template>
          </v-col>
        </v-row>
      </v-card-text>
      <slot name="append"></slot>
    </v-card>
  </v-form>
</template>

<script>
import VJsf from "@koumoul/vjsf";
import VAutocompleteAlgolia from "../VAutocompleteAlgolia.vue";
import VCustomAutocomplete from "../VCustomAutocomplete.vue";
import Images from "../Templates/Images.vue";
import {createNamespacedHelpers, mapMutations, mapState} from "vuex";
// import UploadAvatar from '../Templates/UploadAvatar.vue'
const {mapState: registersState, mapActions: registersActions, mapMutations: registersMutations} = createNamespacedHelpers("registers");
const {mapState: authState, mapActions: authActions} = createNamespacedHelpers("auth");
export default {
  name: "Form",
  components: {VAutocompleteAlgolia, VCustomAutocomplete, VJsf, Images},
  props: {
    inputs: {
      type: Array,
      default: () => [],
    },
    color: {
      type: String,
      default: "teal",
    },
    dark: {
      type: Boolean,
      default: false,
    },
    model: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    collection: {
      required: false,
      type: String,
    },
    collectionDetails: {
      required: false,
      type: String,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      /**
       * Guarda o conteúdo (`String`) de cada input on the fly através do v-bind
       */
      formData: {},

      /**
       * Guarda os valores de dados auxiliares de cada input.
       */
      auxData: {},

      /**
       *
       */
      querySelection: null,
      formChips: 4,
      searchAlgolia: "",
      algoliaHits: {},
      autocompleteHits: {},
      autocompleteLoading: {},
      rota:'',
      trigger:false
    };
  },
  computed: {
    ...mapState(["companySelected"]),
    ...registersState(["registers", "counters"]),
    ...authState(["user", "onlineUsers"]),
    computedIsAdmin() {
      return this.user && (
        this.user.superAdmin ||
        (
          this.user.companies && 
          this.user.companies[this.companySelected.groupCompany] && 
          this.user.companies[this.companySelected.groupCompany]["claims"] && 
          this.user.companies[this.companySelected.groupCompany]["claims"]["admin"]
        )
      )
    },
  },

  watch: {
    model: {
      handler: function(newValue) {
        this.setFormData(newValue);
      },
    },
    user: {
      handler: function(newValue) {
        this.getUser(newValue);
      },
    },
    inputs: {
      handler: function() {
        this.setFormData(this.model);
      },
    },
  },

  methods: {
    ...registersActions(["setLoadedForm", "save", "getDetails", "getApi", "get", "getById"]),
    ...registersMutations(["setRegister"]),
    ...authActions(["getSA3Subs"]),
    ...mapMutations(["setLoading"]),
    callMore(index){
      this.$refs[`customAuto-${index}`][0].loadMoreFn();
    },
    async computeHide(item, input) {
      const self = {
        getById: this.getById,
        get: this.get,
        getSA3Subs: this.getSA3Subs,
        item,
        input,
        user: this.user,
        registers: this.registers,
      };

      let show = true;

      if (typeof input.hide == "function") {
        show = !(await input.hide(self));
      } else if (typeof input.hide == "string") {
        show = !this.formData[input.hide];
      } else if (typeof input.hide == "boolean") {
        show = !input.hide;
      }

      input = {...input, show};

      // this.$set(input, 'show', show)

      return show;
    },
    claimRouteMethod(){
      return this.$route.meta.claimRoute == 'edit' ? true : false
    },

    submit(event) {
      this.$emit("submit", this.getModel());
    },

    validate() {
      return this.$refs.form.validate();
    },

    // isShown(input) {
    //   if (typeof input.hide === 'boolean')
    //     return !input.hide

    //   if (typeof input.hide === 'string')
    //     return eval(input.hide)

    //   return  true
    // },

    getModel() {
      const formData = this.$_.cloneDeep(this.formData);

      // Filtra os inputs que estão com a flag send = false
      this.inputs
        .filter((input) => (typeof input.send == "function" ? !input.send(formData) : "send" in input && !input.send))
        .map((input) => input.value)
        .forEach((value) => delete formData[value]);

      const jsfInputs = this.inputs
        .filter((input) => input.type == "jsf" && input.root == true)
        .reduce((acc, input) => {
          const inputData = formData[input.value];
          delete formData[input.value];
          return {
            ...acc,
            ...inputData,
          };
        }, {});

      /**
       * Transforma os atributos com chaves compostas (ex.: 'a.b.c': 1) em objetos aninhados (ex.: a: { b: { c: 1 } } ).
       */
      const entries = Object.entries(formData);
      const [keys, vals] = entries.reduce(
        ([accKeys, accVals], [key, val]) => [
          [...accKeys, key],
          [...accVals, val],
        ],
        [[], []]
      );
      const formDataDeep = this.$_.zipObjectDeep(keys, vals);

      return {...formDataDeep, ...jsfInputs};
    },
    evalLocal(funcao){
      try {
        const user = {
          uid: this.user.uid,
          displayName: this.user.displayName,
          SA3: this.user.SA3,
          superAdmin: this.user.superAdmin,
          user: this.user
        };
        const evalFun = eval(funcao)
        const params = evalFun?.({user,company: this.companySelected})

          if (params) {
            return params
          } else {
            return 
          }
      } catch (err) {
        console.error(`Error in input select`, err);
      }
    },

    async setFormData(model) {
      /**
       * Possível generalização de condição de haver um dado auxiliar + caracteristicas do dado a ser criado + valor default.
       */
      this.inputs
        .filter((input) => ["autocomplete", "autocompletealgolia"].includes(input.type))
        .forEach((input) => {
          if (input.value in this.auxData) {
            this.auxData[input.value]["loadingAutocomplete"] = false;
            this.auxData[input.value]["searchAutocomplete"] = null;
          } else {
            this.$set(this.auxData, input.value, {loadingAutocomplete: false, searchAutocomplete: null});
          }
        });


      this.inputs
        .filter((input) => input.type === "password")
        .forEach((input) => {
          if (input.value in this.auxData) {
            this.auxData[input.value]["showPassword"] = false;
          } else {
            this.$set(this.auxData, input.value, {showPassword: false});
          }
        });


      /**
       * Atribui um valor inicial para cada input de acordo com a propriedade correspondente em this.model.
       * A forma de atribuir o valor varia de acordo com o tipo do input.
       */
      await Promise.all(
        this.inputs.map(async (input) => {
          switch (input.type) {
            case "switch": {
              let value = (typeof model[input.value] !== 'undefined') ? model[input.value] : "default" in input ? input.default : "";

              this.$set(this.formData, input.value, value);
              break;
            }
            case "text-field":
            case "password":
            case "textarea":
            case "images":
            case "file": {
              let value = model[input.value] ? model[input.value] : "default" in input ? input.default : "";

              if (input.date && !(value instanceof Date) && !isNaN(value) && typeof value != "string") {
                value = this.$moment(value.toDate()).format("DD/MM/YYYY");
              }
              else if (input.dateHour && !(value instanceof Date) && !isNaN(value) && typeof value != "string") {
                value = this.$moment(value.toDate()).format("DD/MM/YYYY - HH:mm:ss");
              }
              else if (input.dateYear && !(value instanceof Date) && !isNaN(value) && typeof value != "string") {
                value = this.$moment(value.toDate()).format("YYYY");
              }
              else if (input.datePlusWeek && !(value instanceof Date) && !isNaN(value) && typeof value != "string") {
                value = this.$moment(value.toDate()).format("DD/MM/YYYY, dddd");
              }

              this.$set(this.formData, input.value, value);
              break;
            }

            case "select":
            case "combobox":
            case "autocomplete": {
              await this.loadItems({input, model});
              break;
            }
            
            case "customautocomplete": {
              const value = model[input.value] ? model[input.value] : "default" in input ? input.default : "";
              this.$set(this.formData, input.value, value);

              if (value) this.$set(this.autocompleteHits, input.value, [value]);

              // prop item-text
              if (input.fieldsToShow) {
                input.itemText = (item) => {
                  let str = item[input.fieldsToShow[0]]?.trim();
                  input.fieldsToShow.slice(1).forEach((field) => {
                    str = str + " - " + item[field]?.trim();
                  });

                  return str;
                };
              }

              break;
            }

            case "autocompletealgolia": {
              const value = model[input.value] ? model[input.value] : "default" in input ? input.default : "";
              this.$set(this.formData, input.value, value);

              if (value) this.$set(this.algoliaHits, input.value, [value]);

              // prop item-text
              if (input.fieldsToShow) {
                input.itemText = (item) => {
                  let str = item[input.fieldsToShow[0]]?.trim();
                  input.fieldsToShow.slice(1).forEach((field) => {
                    str = str + " - " + item[field]?.trim();
                  });

                  return str;
                };
              }

              break;
            }

            case "jsoneditor": {
              this.$set(this.formData, input.value, model[input.value] ? model[input.value] : {});
              break;
            }

            case "jsfChild": {
              let _model = [];
              if (model && model[input.value])
                _model = model[input.value].map((input) => {
                  return {
                    data: input.data || input,
                    delete: false,
                  };
                });

              this.$set(this.formData, input.value, _model);

              /**
               * Ordena os inputs
               */
              if (input.schema && input.schema.properties) input.schema.properties = this.sortJsfProperties(input.schema.properties);

              let auxData = [];
              for (let m of _model) {
                auxData.push({
                  schema: input.schema,
                  options: {disableAll: false},
                });
              }

              this.$set(this.auxData, input.value, auxData);
              break;
            }

            case "jsf": {
              /**
               * Se é uma string, pega o valor do jsoneditor correspondente,
               * Se é objeto, usa ele mesmo.
               */
              const schema = typeof input.schema == "object" ? input.schema : typeof input.schema == "string" ? this.formData[input.schema] : {properties: {}};

              const value = typeof input.schema == "object" ? input.value : input.schema;

              /**
               * Ordena os inputs
               */
              this.updateVJSF(schema, {value});

              if (!this.auxData[input.value]) this.$set(this.auxData, input.value, {});

              /**
               * @TODO Modelar os atributos na raiz do formData em vez de input.value.
               * Atualmente a desestruturação só é feita na hora de enviar o form.
               */
              if (input.root) {
                if (Array.isArray(schema.allOf)) {
                  for (const obj of schema.allOf) {
                    const _model = Object.entries(model).reduce((acc, [key, value]) => { return (key in obj.props ? {...acc, [key]: value} : acc )}, {});
                    this.$set(this.formData, input.value, {...this.formData[input.value], ..._model});
                  }
                } else {
                  const _model = Object.entries(model).reduce((acc, [key, value]) => { return (key in schema.properties ? {...acc, [key]: value} : acc )}, {});
                  this.$set(this.formData, input.value, _model);
                }
              } else {
                this.$set(this.formData, input.value, this.auxData[input.value].model);
              }

              this.$set(this.auxData[input.value], "model", model[this.collectionDetails] || {});
              break;
            }

            case "color-picker": {
              const value = model[input.value] || "#000000";
              this.$set(this.formData, input.value, value);
              this.$set(this.auxData, input.value, {menu: false, model: value, errorMessages: []});
              break;
            }
          
            case "date-picker": {
              this.$set(this.auxData, input.value, {menu: false, modelFormated: ''});
              let value = model[input.value] ? model[input.value] : "default" in input ? input.default : "";
              if (!value) break
              this.formatDatePickerTextField(value, input)
              break;
            }
          }

          // Executa os v-on change na edição
          if (input.on && input.on.change) {
            input.on.change.forEach((change) => {
              change(this.formData[input.value]);
            });
          }
        })
      );
      
      // para colocar valor default
      // TO DO: deixar generica para os default's de outros campos
      this.inputs
        .filter(input => input.type === "select")
        .forEach(input => { 
          if(input.type === "select"){          
            if (input.default && (typeof input.default == 'string') && input.default.includes('=>')){
              try {
                const user = {
                  uid: this.user.uid,
                  displayName: this.user.displayName,
                  SA3: this.user.SA3,
                  superAdmin: this.user.superAdmin,
                  user: this.user
                }
                const fun = eval(input.default)
                const params = fun?.({user,company: this.companySelected})
                if(params){
                  this.formData[input.value] = params
                  // this.$set(this.formData, input.value, params)
                }

              } catch (error) {
                console.error(error);
              }
            }
          }
        })

      this.inputs.forEach((input) => {
        if (input.computeFirst) {
          this.computeValue(this.formData, input)
        }
        this.computeHide(model, input);
      });
   
      this.inputs
        .filter((input) => input.rules)
        .forEach((input) => {
          const rules = (item) => input.rules.map(el => eval(el))
          if (input.value in this.auxData) {
            this.auxData[input.value]["rules"] = rules(this.formData);
          } else {
            this.$set(this.auxData, input.value, {rules: rules(this.formData)});
          }
        });
    },

    async loadItems({input, model}) {
      const value = model[input.value] ? model[input.value] : "default" in input ? input.default : "";
      this.$set(this.formData, input.value, value);
      
      if (Array.isArray(input.items)) {
        this.$set(this.auxData, input.value, {
          ...this.auxData[input.value],
          items: input.items,
        });
      } else if (typeof input.items == "object" && !Array.isArray(input.items)) {
        if (["get", "getApi", "getById", "getSA3Subs"].indexOf(input.items.method) >= 0) {
          const user = {
            uid: this.user.uid,
            displayName: this.user.displayName,
            SA3: this.user.SA3,
            superAdmin: this.user.superAdmin,
          };

          try {
            const setParams = eval(input.items.params);
            const params = setParams?.({item: model, user, company: this.companySelected});

            if (params) {
              const items = await this[input.items.method](...params);
              this.$set(this.auxData, input.value, {
                ...this.auxData[input.value],
                items,
              });
            } else {
              this.$set(this.auxData, input.value, {
                ...this.auxData[input.value],
                items: [],
              });
            }
          } catch (err) {
            console.error(`Error in input ${input.value}`, err);
          }
        } else {
          console.warn(`input "${input.value}" has invalid object "items" prop value on attribue "method" (items.method = ${input.items.method})`);
        }
      } else if (typeof input.items == "string" && input.returnObject) {
        if (input.items.includes('=>')) {
          const items = eval(input.items)({item: model})
          this.$set(
            this.auxData, 
            input.value, 
            {
              ...this.auxData[input.value],
              items,
            }
          );
        } else if (value) {
          this.setRegister({register: input.items, data: value});    
        }
      }
    },

    clearField({field}) {
      this.formData[field] = null;
    },

    changeColorPickerInput(value, input) {
      this.auxData[input.value].errorMessages.splice(0);
      if (!value) this.formData[input.value] = "#000000";
      else if (!/^#(?:[0-9a-f]{3}){1,2}$/i.test(value)) this.auxData[input.value].errorMessages.push("Formato inválido.");
      else this.formData[input.value] = value;
    },

    formatDatePickerTextField(value, input) {
      if (value.length == 8 && !value.includes('-')) { value = `${value.substr(0,4)}-${value.substr(4,2)}-${value.substr(5,2)}` }
      value = new Date(value+" 00:00")
      if (input.dateYear && (value instanceof Date)) {
        value = this.$moment(value).format("YYYY");
      }
      else if (input.datePlusWeek && (value instanceof Date)) {
        value = this.$moment(value).format("DD/MM/YYYY, dddd");
      } else {
        value = this.$moment(value).format("DD/MM/YYYY");
      }
      this.$set(this.auxData[input.value], 'modelFormated', value);
    },

    querySelections(query, input) {
      if (!query || query == this.auxData[input.value].lastQuerySelection) return;
      else this.auxData[input.value].lastQuerySelection = query;

      if (this.auxData[input.value].querySelection) {
        clearTimeout(this.auxData[input.value].querySelection);
        this.auxData[input.value].querySelection = null;
      }

      const {collection, register, filters, orderBy} = input.search || {};
      this.auxData[input.value].querySelection = setTimeout(async () => {
        this.auxData[input.value].querySelection = null;
        if (!query || !collection || !register || !orderBy) return;

        this.auxData[input.value].loadingAutocomplete = true;
        let results = []
        this.auxData[input.value].items = results
        for (const field of input.fieldsToSearch) {
          try { results = results.concat(await this.get({collection, register, orderBy: field, filters, query: query.toUpperCase()})) } catch (error) { console.error(error) } 
          try { results = results.concat(await this.get({collection, register, orderBy: field, filters, query: query.toLowerCase()})) } catch (error) { console.error(error) } 
          try { results = results.concat(await this.get({collection, register, orderBy: field, filters, query: query._capitalize()})) } catch (error) { console.error(error) } 
        }
        results = results.sort((a, b) => a[orderBy].localeCompare(b[orderBy]))
        this.auxData[input.value].items = results
        this.auxData[input.value].loadingAutocomplete = false;
      }, 1000);
    },

    reset() {
      this.inputs.forEach((input) => {
        if (input.type == "jsoneditor") {
          this.$set(this.formData, input.value, {});
        }
      });
      this.$refs.form.reset();
    },

    resetValidation() {
      return this.$refs.form.resetValidation();
    },

    updateVJSF(data, input) {
      if (data) {
        if (!this.auxData[input.value]) this.$set(this.auxData, input.value, {});

        if (data.allOf) {
          data.allOf = data.allOf.map(d => {
            const dProps = this.sortJsfProperties(d.props)
            d.properties = dProps
            return d
          })
        } else if (data.properties) { 
          const dProps = this.sortJsfProperties(data.properties)
          data.properties = dProps
        } else if (Array.isArray(data)) {
          const properties = {};
          for (const el of data) {
            properties[el.value] = this.$_.cloneDeep(el);
            delete properties[el.value].value;
          }
          data = {
            properties,
            type: "object",
          };
        }

        this.$set(this.auxData[input.value], "schema", data);
      }
    },

    sortJsfProperties(properties) {
      if (typeof properties == "object") return Object.fromEntries(Object.entries(this.$_.cloneDeep(properties)).sort(([, a], [, b]) => a.order - b.order));
      else return properties;
    },

    /**
     * Computa os valores dos atributos de `item` que estão presentes em `headers` respeitando a ordem passada
     */
    async computeItem(item, inputs) {
      for (const input of inputs) {
        if (input.reloadItems) {
          await this.loadItems({input, model: item});
        }
        await this.computeValue(item, input);
        await this.computeHide(item, input);
      }
    },

    /**
     * Computa o atributo `header` no objeto `item`
     */
    async computeValue(item, input) {
      const self = {
        getById: this.getById,
        getApi: this.getApi,
        get: this.get,
        getSA3Subs: this.getSA3Subs,
        item,
        input,
        user: this.user,
        companySelected: this.companySelected,
        setLoading: this.setLoading,
        readonly: this.readonly
      };
      const computedValue = input.computed ? await input.computed(self) : item[input.value];

      // console.info('computedValue', item, input, input.value, computedValue)
      if (
        input.type == 'customautocomplete'
        && computedValue
        && computedValue.id
        && this.autocompleteHits
      ) {
        const registers = this.autocompleteHits[input.value]
        if (registers) {
          const ix = registers.findIndex(x => x.id == computedValue.id)
          if (ix == -1) { this.autocompleteHits[input.value].push(computedValue) }
        } else {
          this.autocompleteHits[input.value] = [computedValue]
        }
      }

      this.proccessInput(item, input, computedValue);
    
      if (input && input.on && input.on.change) { 
        for (const change of input.on.change) {
          change(computedValue) 
        }
      }

      return computedValue;
    },

    setValue(item, input, value) {
      const handledValue = input.set ? input.set(value, item) : value;

      // console.info(`setValue - ${input.value} <-`, handledValue)
      this.$set(item, input.value, handledValue);
      this.$emit("change:" + input.value, handledValue);

      return handledValue;
    },
    
    proccessInput(item, input, value) {
      this.setValue(item, input, value);
      this.proccessDependencies(item, input);
      this.computeHide(item, input);
      this.$emit("change", this.getModel());
    },

    async proccessDependencies(item, input) {
      // Os inputs/atributos a serem usados como busca não devem incluir o próprio elemento que está sendo alterado.
      const inputs = this.inputs.filter((h) => h != input);

      // Array com os dependentes diretos do input, elimina a possibilidade do elemento ser dependente *direto* dele mesmo devido ao filter.
      const directDependentAttributes = this.getDependentAttributes(input, inputs);

      // Headers a serem computados respeitando a ordem de precedência da menor posição do array pra maior.
      const dependencyChain = this.getDependencyChain(directDependentAttributes, inputs);

      // for of para respeitar a ordem do array `dependencyChain`.
      for (const dependentAttributes of dependencyChain) {
        await this.computeItem(item, dependentAttributes);
        inputs.map(async (i) => await this.computeHide(item, i));
      }
    },

    /**
     * Retorna um array de inputs que dependem *diretamente* do `input` passado no primeiro parâmetro
     */
    getDependentAttributes(input, inputs) {
      return inputs.filter((h) => h.dependents && h.dependents.includes(input.value));
    },

    /**
     * Retorna uma cadeia de inputs que são dependentes dos inputs passados no primeiro argumento.
     * A ordem dos elementos no array indicam o grau de dependência dos inputs.
     */
    getDependencyChain(dependents, inputs, dependencyChain = []) {
      if (dependents.length == 0) return dependencyChain;

      const filteredInputs = inputs.filter((h) => !dependents.includes(h));
      const newDependents = dependents.map((h) => this.getDependentAttributes(h, filteredInputs)).reduce((acc, arr) => [...acc, ...arr.filter((el) => !acc.includes(el))], []);

      return this.getDependencyChain(newDependents, filteredInputs, [...dependencyChain, dependents]);
    },

    getUser(user) {
      // ver isso aqui  depois
      if (!this.readonly) {
        const _user = user
          ? {
              uid: user?.uid,
              displayName: user.displayName,
              email: user.email,
              SA3: user.SA3,
            }
          : null;

        const input = this.inputs.find((i) => i.value == "_user") || {value: "_user"};
        this.proccessInput(this.formData, input, _user);
      }
    },

    async handlerAlgolia(req) {
      try {
        const {index, hits} = await req;
        this.$set(this.algoliaHits, index, hits);
      } catch (err) {
        console.error(err);
      }
    },

    async handlerAutocomplete(req) {
      try {
        const {index, hits, query, resetRegister = false} = await req;
        if (this.autocompleteHits[index] && !resetRegister) {
          for (const el of hits) {
            this.autocompleteHits[index]
            const ix = this.autocompleteHits[index].findIndex(x => x.id == el.id)
            if (ix > -1) { this.autocompleteHits[index][ix] = el } 
            else { this.autocompleteHits[index].push(el) } 
          }
        } else {
          this.$set(this.autocompleteHits, index, hits) 
        }
      } catch (err) {
        console.error(err);
      }
    },


    async handlerAutocompleteLoading(index, bool) {
      try {
        this.$set(this.autocompleteLoading, index, bool);
      } catch (err) {
        console.error(err);
      }
    },

    selectAll({input, items, itemValue}) {
      const {value} = input;
      this.$nextTick(() => {
        if (items && this.formData[value] && this.formData[value].length >= items.length) {
          this.proccessInput(this.formData, input, []);
        } else if (items) {
          this.proccessInput(this.formData, input, itemValue ? items.map((x) => x[itemValue]).slice() : items.slice());
        }
      });
    },
  },

  created() {
    this.setFormData(this.model);
    if (![undefined, "users"].includes(this.collection)) this.getUser(this.user);
  },
};
</script>

<style lang="scss">
.row-child {
  max-width: 98%;
}

.theme--light.v-label--is-disabled {
  color: rgba(0, 0, 0, 0.6);
}

.theme--light.v-input--is-disabled input,
.theme--light.v-input--is-disabled textarea,
.theme--light.v-select .v-select__selection--disabled {
  color: rgba(0, 0, 0, 0.87);
}

.highlight-v-text-field {
  input {
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 1.2rem;
    color: #000 !important;
  }
}
</style>
