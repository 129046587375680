<template>
  <v-app-bar
    color="black"
    flat
    dark
    app
    clipped-left
  >
    <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
    <router-link
      to="/"
      class="pl-0 pr-0 ml-2"
    >
      <img
        v-if="logo"
        :src="logo"
        height="40px"
        class="contain"
      />
      <img
        v-else
        src="@/assets/logo.png"
        height="40px"
        class="contain"
      />
    </router-link>

    <SelectCompany/>
    
    <!--
      [Início PaymentSimulator]

      Por ora, está funcionando apenas para Ibramed. 
      Mas tem potencial de aplicar para mais clientes
    -->
      <PaymentSimulator v-if="isShowSimulador && companySelected && companySelected.groupCompany === 'ibramed'"/>
    <!-- [Fim PaymentSimulator] -->

    <v-spacer></v-spacer>

    <!-- <v-btn icon class="mr-2">
      <v-icon>mdi-cog</v-icon>
    </v-btn> -->
    <v-menu 
      v-if="user"
      offset-y
      transition="scale-transition"
      origin="top center"
      >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-on="on"
          v-bind="attrs"
          text
          height=50
          elevation="0"
          class="mr-1"
        >
          <v-avatar size="40">
            <img
              v-if="user.photoURL"
              :src="user.photoURL"
              alt="Avatar"
            >
            <v-gravatar 
              v-else
              :email="user.email"
              default-img="mm"/>
          </v-avatar>
          <v-toolbar-title class="subtitle-1 no-text-transform ml-2">{{user.displayName}}</v-toolbar-title>
        </v-btn>
      </template>
      <v-list>
        <v-list-item @click="changePass">
          <template>
            <v-list-item-content>
              <v-list-item-title>Trocar senha</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon>mdi-key-change</v-icon>
            </v-list-item-icon>
          </template>
        </v-list-item>
        <v-list-item @click="signOut">
          <template>
            <v-list-item-content>
              <v-list-item-title>Sair</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon>mdi-account-arrow-right</v-icon>
            </v-list-item-icon>
          </template>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import SelectCompany from "@/components/Templates/SelectCompany"
import PaymentSimulator from "@/components/Templates/PaymentSimulator"
import { createNamespacedHelpers, mapState } from 'vuex'
const { mapState:authState, mapActions:authActions } = createNamespacedHelpers('auth')
export default {
  name: 'AppBar',
  components: {
    SelectCompany,
    PaymentSimulator
  },
  computed: {
    ...mapState(['companySelected']),
    ...authState(['user']),
    drawer: {
      get(){
        return this.$store.state.drawer
      },
      set(val){
        this.$store.commit('setDrawer', val)
      } 
    },      
    logo() {
      return this.companySelected?.logo || this.companySelected?.configs?.logo
    },
    isShowSimulador() {
      return true //this.user.superAdmin || this.user.isAdminCompanySelected
    }
  },
  methods: {
    ...authActions(['signOut']),
    changePass() {
      this.$router.push('/changePass')
    }
  },
  created() { }
}
</script>

<style>

</style>