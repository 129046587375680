import Vue from 'vue';
import Vuetify from 'vuetify';
import pt from 'vuetify/es5/locale/pt';
import '@mdi/font/css/materialdesignicons.css' 

Vue.use(Vuetify);

const vuetify = new Vuetify({
  lang: {
    locales: { pt },
    current: 'pt',
  },
  icons: {
    iconfont: 'mdiSvg',
  },
});

export default vuetify