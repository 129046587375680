<template>
  <section cols=4 class="relative">
    <canvas ref="doughnutChart" />
    <span v-if="data && data.score" id="score" class="absolute text-h4">{{ data.score }}</span>
  </section>
</template>
<script>
import { Chart, registerables } from "chart.js";
export default {
  name: "DoughnutChart",
  data() {
    return {
      chart: null
    }
  },
  props: ["data", "options"],
  watch: {
    data: {
      handler: function (data) {        
        this.updateData(data)
      },
      deep: true,
      immediate: false
    }
  },
  methods: {
    addData(label, data) {
      this.chart.data.labels.push(label);
      this.chart.data.datasets.forEach((dataset) => {
        dataset.data.push(data);
      });
      this.chart.update();
    },
    removeData() {
      this.chart.data.labels.pop();
      this.chart.data.datasets.forEach((dataset) => {
        dataset.data.pop();
      });
      this.chart.update();
    },
    updateData({values, labels, backgroundColor}) {
      this.chart.data = {
        labels,
        datasets: [{
          data: values,
          backgroundColor
        }]
      }

      this.chart.update();
    },
    renderChart() {
      let ctx = this.$refs.doughnutChart.getContext("2d");
      this.chart = new Chart(ctx, {
        type: "doughnut",
        options: {
          cutout: '70%',
          plugins: {
            legend: {
              display: false
            }
          },
          maintainAspectRatio: false,
          // scales: {
          //   x: [{
          //     gridLines: {
          //       display: false
          //     }
          //   }],
          //   y: [{
          //     gridLines: {
          //       display: false
          //     }
          //   }]
          // }
        },
      });
    },
  },
  created() {
    Chart.register(...registerables)
  },
  mounted() {
    this.renderChart();
    if (this.data && this.data.values) this.updateData(this.data)
  },
};
</script>

<style scoped lang="scss">
  @import 'vuetify/src/styles/styles.sass';

  #score {
    left: 31%;
    top: 45%;
  }

  // @media #{map-get($display-breakpoints, 'lg-and-down')} {
  //   #score {
  //     left: 30%;
  //     top: 45%;
  //   }
  // }
</style>