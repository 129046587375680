<template>
  <v-dialog
    v-model="dialog"
  >
    <template #activator="{ on, attrs }">
      <v-btn v-bind="attrs" 
              v-on="on" 
              :height="heightButton" 
              x-small 
              :fab="fabButton" 
              :icon="iconButton" 
              :title="titleButton"
              :outlined="!iconButton"
              :color="color" 
              :class="classes">
        <slot name='buttonContent'/>
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="headline grey lighten-3 pa-2">
        <v-icon class="ma-1">{{ iconTitle }}</v-icon> 
        {{ title }}
        <v-spacer></v-spacer>
        <v-btn
          height=32
          x-small
          color="info"
          class="mr-2 text-caption"
          :outlined="activeComponent !== ''"
          @click="componentChoice()"
          >
          {{this.companySelected.groupCompany == 'cermag' ? 'Limite de crédito' : 'Info'  }}          
        </v-btn>
        <v-btn
          height=32
          x-small
          color="warning"
          class="mr-2 text-caption"
          :outlined="activeComponent !== 'titAbertos'"
          @click="componentChoice('titAbertos')"
          >
          Tit. Aberto
        </v-btn>
        <v-btn
          v-if="!['cermag','wdcnet'].includes(this.companySelected.groupCompany)"
          height=32
          x-small
          color="success"
          class="mr-2 text-caption"
          :outlined="activeComponent !== 'titBaixados'"
          @click="componentChoice('titBaixados')"
          >
          Tit. Baixados
        </v-btn>
        <v-btn
          v-if="!['cermag','wdcnet'].includes(this.companySelected.groupCompany)"
          height=32
          x-small
          color="info"
          class="mr-2 text-caption"
          :outlined="activeComponent !== 'pedidos'"
          @click="componentChoice('pedidos')"
          >
          Pedidos
        </v-btn>
        <v-btn
          v-if="!['wdcnet'].includes(this.companySelected.groupCompany)"
          height=32
          x-small
          color="teal"
          class="mr-2 text-caption"
          :outlined="activeComponent !== 'faturamento'"
          @click="componentChoice('faturamento')"
          >
          Faturamento
        </v-btn>
        <!-- <v-btn
          height=32
          x-small
          color="info"
          class="mr-2 text-caption"
          :outlined="activeComponent !== 'referencias'"
          @click="componentChoice('referencias')"
          >
          Referências
        </v-btn> -->
        <v-btn
          v-if="!['cise_capital', 'cermag','wdcnet'].includes(this.companySelected.groupCompany)"
          height=32
          x-small
          color="error"
          class="mr-2 text-caption"
          :outlined="activeComponent !== 'histCobrancas'"
          @click="componentChoice('histCobrancas')"
          >
          Hist. Cobrança
        </v-btn>
      </v-card-title>

      <v-card-text class="pa-3">
        <v-row>
          <v-col v-for="(input, index) in inputsHeaders" :key="index" :cols="2"  :class="`py-0`"> 
            <v-text-field
              :value="formartFields({header: input, value: computedModel[input.value]})"
              v-if="computedModel && computedModel[input.value]"
              :label="input.label"
              v-mask="input.vMask ? `${input.vMask}` : ''"
              disabled
            />
          </v-col>
        </v-row>

        <v-divider class="pa-2" v-if="activeComponent === ''"></v-divider>

        <v-row v-if="activeComponent === ''">
          <v-col v-for="(input, index) in inputsSubHeaders" :key="index" :cols="2" :class="`py-0`"> 
            <v-text-field
              v-if="computedModel && computedModel[input.value]"
              :value="formartFields({header: input, value: computedModel[input.value]})"
              :label="input.label"
              v-mask="input.vMask ? `${input.vMask}` : ''"
              disabled
            />
          </v-col>
        </v-row>

        <Bills
          v-if="activeComponent !== ''"
          :title="computedTitleBills"
          :openCard="true"
          :headers="[...computedHeadersBills, ...actions]"
          :items="computedItemsBills"
          :color="computedColorBills" 
          :itemsPerPage="computedItemsPerPageBills"
          @clickRow="(item) => clickFilter(item)"
          :dueDateField="computedDueDateField"
          :customActions="computedCustomActions"
        />

        <Bills
          v-if="activeSubComponent !== ''"
          :title="computedTitleSubBills"
          :openCard="true"
          :headers="computedHeadersSubBills"
          :items="computedItemsSubBills"
          :color="computedColorBills" 
          :showToolbar="false"
          :itemsPerPage="computedItemsPerPageBills"
        />

      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          text
          outlined
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
          Fechar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers, mapMutations, mapState } from 'vuex'
const { mapActions:registersActions } = createNamespacedHelpers('registers')
import Bills from "@/components/Registers/Bills"
export default {
  components: {
    Bills
  },
  name: 'CustomerPosition',
  props: {
    color: {
      type: String,
      default: 'primary'
    },
    heightButton: {
      type: Number,
      default: 40
    },
    iconButton: {
      type: Boolean,
      default: false
    },
    fabButton: {
      type: Boolean,
      default: false
    },
    titleButton: {
      type: String,
      default: "Posição de Cliente"
    },
    classes: {
      type: String,
      default: 'mr-2 text-caption'
    },
    title: {
      type: String,
      default: 'Posição de Clientes'
    },
    iconTitle: {
      type: String,
      default: 'mdi-account-credit-card-outline'
    },
    inputsHeaders: {
      type: Array,
      default: () => []
    },
    inputsSubHeaders: {
      type: Array,
      default: () => []
    },
    model: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      dialog: false,
      activeComponent: '',
      activeSubComponent: '',
      filterBills: '',
      filterSubBills: '',
      itemsBills: [],
      itemsSubBills: [],
      dueDateField: '',
      headersBills: [],
      headersSubBills: [],
      titleBills: "",
      colorBills: "",
      itemsPerPageBills: 10,
      customActions: [],
      modelLoaded: {}
    }
  },
  computed: {
    ...mapState(['companySelected']),
    actions() {
      return this.computedCustomActions && this.computedCustomActions.length > 0 ? [{ text: 'Ações', value: 'data-table-actions', align: 'center', tdClass: 'text-center text-no-wrap' }] : []
    },
    computedModel() {
      return this.modelLoaded
    },
    computedCustomActions() {
      if (!this.customActions || ['cermag' ].includes(this.companySelected.groupCompany))
        return []    
      return this.customActions.filter(_action => _action.data ? _action.data.listRegister : _action.listRegister).map(_action => {
        const action = _action.data ? {..._action.data} : { ..._action }

        if (!action.color)
          action.color = 'primary'

        if (!action.actionRunner || action.actionRunner.apply)
          action.actionRunner = '() => console.warn(`missing action function for custom action "${action.text}."`)'

        return action
      })
    },
    computedDueDateField() {
      return this.dueDateField
    },
    computedHeadersBills() {
      return this.headersBills
    },
    computedHeadersSubBills() {
      return this.headersSubBills
    },
    computedItemsBills() {
      return this.itemsBills
    },
    computedItemsSubBills() {
      return this.itemsSubBills
    },
    computedTitleBills() {
      return this.titleBills
    },
    computedTitleSubBills() {
      return this.titleSubBills
    },
    computedColorBills() {
      return this.colorBills
    },
    computedItemsPerPageBills() {
      return this.itemsPerPageBills
    }
  },
  methods: {
    ...mapMutations(['setLoading']),
    ...registersActions(['get', 'getExternal', 'getById']),
    formartFields({header, value}){
      let maskedValue = ``

      const prefix = header.prefix ? header.prefix + ' ' : ''

      // valor numérico
      if (header.type == 'float' || header.type == 'integer') {
        maskedValue =  (value*1).toLocaleString('pt-br', { minimumFractionDigits: header.precisionText, maximumFractionDigits: header.precisionText })
      //data em formato string, geralmente como vem do Protheus (YYYYMMDD)
      } else if ((header.date || header.type === 'date-picker') && !(value instanceof Date) && !isNaN(value) && typeof value === "string") {
        maskedValue = (!value || value && value.trim() === '') ? '' :this.$moment(value, 'YYYYMMDD').format("DD/MM/YYYY");
      } else if (header.date && !(value instanceof Date) && !isNaN(value) && typeof value !== "string") {
        maskedValue = this.$moment(value.toDate()).format("DD/MM/YYYY");
      } else if (header.dateHour && !(value instanceof Date) && !isNaN(value) && typeof value !== "string") {
        maskedValue = this.$moment(value.toDate()).format("DD/MM/YYYY - HH:mm:ss");
      }
      else if (header.dateYear && !(value instanceof Date) && !isNaN(value) && typeof value !== "string") {
        maskedValue = this.$moment(value.toDate()).format("YYYY");
      }
      else if (header.datePlusWeek && !(value instanceof Date) && !isNaN(value) && typeof value !== "string") {
        maskedValue = this.$moment(value.toDate()).format("DD/MM/YYYY, dddd");
      } else {    
        maskedValue = (header.date && (!value || value && value.trim() === '')) ? '' : value
      }

      const suffix = header.suffix
        ? ' ' + header.suffix
        : ''

      return prefix + maskedValue + suffix      
    },
    clickFilter(item) {
      if (this.activeComponent === 'histCobrancas' && item) {
        this.activeSubComponent = 'histCobrancasSub'
        this.setTableBills(true, item)
      } else {
        this.activeSubComponent = ''
      }
    },
    componentChoice(componentName) {
      this.customActions = []
      this.activeSubComponent = ''
      if (componentName == undefined || this.activeComponent === componentName) {
        this.activeComponent = ''
      } else {        
        this.activeComponent = componentName
      }
      if (this.activeComponent === 'titAbertos') {
        this.titleBills = "Títulos em Aberto"
        this.colorBills = "warning"
        if (['portao_cambui'].includes(this.companySelected.groupCompany)) {
          this.queryBills = "` SELECT CASE WHEN ZAN_DANFE.ZAN_IDFK IS NOT NULL THEN 'S' ELSE 'N' END TEM_DANFE, CASE WHEN ZAN_BOLETO.ZAN_IDFK IS NOT NULL THEN 'S' ELSE 'N' END TEM_BOLETO, SE1.R_E_C_N_O_ AS ID, CASE WHEN CONVERT(VARCHAR(8), GETDATE(), 112) > E1_VENCREA THEN 'SIM' ELSE 'NAO' END AS VENCIDO, SE1.* FROM SE1${gpEmpresa} AS SE1 LEFT OUTER JOIN ZAN${gpEmpresa} AS ZAN_DANFE ON ZAN_DANFE.D_E_L_E_T_ = '' AND ZAN_DANFE.ZAN_ORIGEM = 'SF2' AND E1_PREFIXO + E1_NUM = ZAN_DANFE.ZAN_IDFK LEFT OUTER JOIN ZAN${gpEmpresa} AS ZAN_BOLETO ON ZAN_BOLETO.D_E_L_E_T_ = '' AND ZAN_BOLETO.ZAN_ORIGEM = 'SE1' AND E1_PREFIXO + E1_NUM = ZAN_BOLETO.ZAN_IDFK WHERE SE1.D_E_L_E_T_ = '' AND E1_SALDO > 0 AND E1_FILIAL = '0101'`"
        } 
        else if(['wdcnet'].includes(this.companySelected.groupCompany)){
          this.queryBills = "` SELECT SA1.A1_CGC, SE1.R_E_C_N_O_ AS ID, CASE WHEN CONVERT(VARCHAR(8), GETDATE(), 112) > E1_VENCREA THEN 'SIM' ELSE 'NAO' END AS VENCIDO, SE1.* FROM SE1${gpEmpresa} AS SE1 INNER JOIN SA1010 AS SA1 ON SE1.E1_CLIENTE = SA1.A1_COD WHERE SE1.D_E_L_E_T_ = '' AND E1_SALDO > 0 `"
        } 
        else {
          this.queryBills = "` SELECT SE1.R_E_C_N_O_ AS ID, CASE WHEN CONVERT(VARCHAR(8), GETDATE(), 112) > E1_VENCREA THEN 'SIM' ELSE 'NAO' END AS VENCIDO, SE1.* FROM SE1${gpEmpresa} AS SE1 WHERE SE1.D_E_L_E_T_ = '' AND E1_SALDO > 0 `"
        }
        this.filtersBills = '(item) => {return [["E1_CLIENTE:" + (item.A1_COD ? item.A1_COD : item.SA1 && item.SA1.A1_COD ? item.SA1.A1_COD : null)],["E1_LOJA:" + (item.A1_COD ? item.A1_LOJA : item.SA1 && item.SA1.A1_LOJA ? item.SA1.A1_LOJA : null)]]}'
        this.headersBills = [
          {
            "text": "Filial",
            "value": "E1_FILIAL"
          },
          {
            "text": "Pedido",
            "value": "E1_PEDIDO",
            "hide": true
          },
          {
            "text": "Prefixo",
            "mobile": {
              "subTitle": true
            },
            "value": "E1_PREFIXO"
          },
          {
            "text": "Número",
            "value": "E1_NUM",
            "mobile": {
              "subTitle": true
            }
          },
          {
            "value": "E1_PARCELA",
            "mobile": {
              "title": true
            },
            "text": "Parcela"
          },
          {
            "value": "E1_TIPO",
            "mobile": {
              "title": true
            },
            "text": "Tipo"
          },
          {
            "date": true,
            "mobile": true,
            "value": "E1_EMISSAO",
            "text": "Emissão"
          },
          {
            "text": "Vencimento",
            "value": "E1_VENCREA",
            "mobile": true,
            "date": true
          },
          {
            "text": "Vencido?",
            "value": "VENCIDO",
            "mobile": true,
            "date": true
          },
          {
            "prefix": "R$",
            "value": "E1_VALOR",
            "text": "Valor",
            "type": "float",
            "precisionText": 2,
            "mobile": true
          },
          {
            "type": "float",
            "mobile": true,
            "text": "Saldo",
            "precisionText": 2,
            "prefix": "R$",
            "value": "E1_SALDO"
          }
        ]
        this.dueDateField = 'E1_VENCREA'
        this.orderBills = 'E1_VENCREA'
        this.itemsPerPageBills = 10
        if (['portao_cambui'].includes(this.companySelected.groupCompany)) {
          this.customActions = [
            {
              data: {
                listRegister: true,
                actionRunner: "async ({item, getApi, generatePdf, setLoading}) => { setLoading({stats: true, message: 'Obtendo Boleto...'}); try { const {base64} = await getApi({timeout: 30000, url: '/protheus/', options: {params: {path: 'ufbase64/boleto' + '/' + item.E1_FILIAL + '/' + item.E1_PEDIDO }}}); setLoading({stats: false}); if (base64){ generatePdf({base64str: base64}) }} catch (error) { setLoading({stats: false}); } };",
                color: "cyan darken-4",
                hide: "({item}) => !item || !item.E1_PEDIDO",
                icon: "mdi-barcode",
                name: "Boleto"
              }
            }
          ]
        } else if (!['ore', 'ibramed','samtronic', 'decanter'].includes(this.companySelected.groupCompany)) {
          this.customActions = [
            {
              data: {
                listRegister: true,
                actionRunner: "async ({item, getApi, generatePdf, setLoading}) => { setLoading({stats: true, message: 'Obtendo Boleto...'}); try { const res = await getApi({timeout: 30000, url: '/finnet/', options: {params: {pagador: item.A1_CGC, docNum: item.E1_TIPO.includes('NF') ? `${item.E1_PREFIXO}${item.E1_NUM}${item.E1_PARCELA}${item.E1_TIPO}` : `${item.E1_PREFIXO}${item.E1_NUM}${item.E1_PARCELA}`.trim() }}}); setLoading({stats: false}); if (res && res.length>0){ const link = document.createElement('a'); link.href = res[0].documento_link; link.click();  }} catch (error) { console.error('Erro na busca do boleto',error); setLoading({stats: false}); } };",
                color: "cyan darken-4",
                hide: "({item}) => !item || !item.E1_PEDIDO",
                icon: "mdi-barcode",
                name: "Boleto"
              }
            }
          ]
        } else if (['decanter'].includes(this.companySelected.groupCompany)) {
          this.customActions = [
            {
              data: {
                listRegister: true,
                actionRunner: "async ({item, getApi, generatePdf, setLoading}) => { setLoading({stats: true, message: 'Obtendo Boleto...'}); try { const {base64} = await getApi({timeout: 30000, url: '/protheus/', options: {params: {path: 'ufbase64/boleto?filial='+item.E1_FILIAL+'&num='+item.E1_NUM+'&prefixo='+item.E1_PREFIXO+'&parcela='+item.E1_PARCELA+'&tipo='+item.E1_TIPO }}}); setLoading({stats: false}); if (base64){ generatePdf({base64str: base64}) }} catch (error) { setLoading({stats: false}); } };",
                color: "cyan darken-4",
                hide: "({item}) => !item || !item.E1_PEDIDO",
                icon: "mdi-barcode",
                name: "Boleto"
              }
            }
          ]
        } else {
          this.customActions = []
        }
      } else if (this.activeComponent === 'titBaixados') {
        this.titleBills = "Títulos Pagos"
        this.colorBills = "success"
        if (['portao_cambui'].includes(this.companySelected.groupCompany)) {
          this.queryBills = "` SELECT R_E_C_N_O_ AS ID, E1_FILIAL, E1_NUM, E1_PREFIXO, E1_PARCELA, E1_TIPO, E1_EMISSAO, E1_VENCREA, E1_VALOR, E1_SALDO, E1_BAIXA, DATEDIFF(dd ,CONVERT( datetime, E1_VENCREA , 111), CONVERT(datetime , E1_BAIXA, 111)) as DIASATRASO FROM SE1${gpEmpresa} AS SE1 WHERE SE1.D_E_L_E_T_ = '' AND E1_SALDO = 0 AND E1_FILIAL = '0101' `"
        } else {
          this.queryBills = "` SELECT R_E_C_N_O_ AS ID, E1_FILIAL, E1_NUM, E1_PREFIXO, E1_PARCELA, E1_TIPO, E1_EMISSAO, E1_VENCREA, E1_VALOR, E1_SALDO, E1_BAIXA, DATEDIFF(dd ,CONVERT( datetime, E1_VENCREA , 111), CONVERT(datetime , E1_BAIXA, 111)) as DIASATRASO FROM SE1${gpEmpresa} AS SE1 WHERE SE1.D_E_L_E_T_ = '' AND E1_SALDO = 0 `"
        }
        this.filtersBills = '(item) => {return [["E1_CLIENTE:" + (item.A1_COD ? item.A1_COD.trim() : item.SA1 && item.SA1.A1_COD ? item.SA1.A1_COD.trim() : null)],["E1_LOJA:" + (item.A1_COD ? item.A1_LOJA.trim() : item.SA1 && item.SA1.A1_LOJA ? item.SA1.A1_LOJA.trim() : null)]]}'
        this.headersBills = [
          {
            "text": "Filial",
            "value": "E1_FILIAL"
          },
          {
            "text": "Prefixo",
            "mobile": {
              "subTitle": true
            },
            "value": "E1_PREFIXO"
          },
          {
            "text": "Número",
            "value": "E1_NUM",
            "mobile": {
              "subTitle": true
            }
          },
          {
            "value": "E1_PARCELA",
            "mobile": {
              "title": true
            },
            "text": "Parcela"
          },
          {
            "value": "E1_TIPO",
            "mobile": {
              "title": true
            },
            "text": "Tipo"
          },
          {
            "date": true,
            "mobile": true,
            "value": "E1_EMISSAO",
            "text": "Emissão"
          },
          {
            "text": "Vencimento",
            "value": "E1_VENCREA",
            "mobile": true,
            "date": true
          },
          {
            "text": "Data Baixa",
            "value": "E1_BAIXA",
            "mobile": true,
            "date": true
          },
          {
            "text": "Dias de Atraso",
            "value": "DIASATRASO",
            "mobile": true,
            "type": "integer"
          },
          {
            "prefix": "R$",
            "value": "E1_VALOR",
            "text": "Valor",
            "type": "float",
            "precisionText": 2,
            "mobile": true
          },
          {
            "type": "float",
            "mobile": true,
            "text": "Saldo",
            "precisionText": 2,
            "prefix": "R$",
            "value": "E1_SALDO"
          }
        ]
        this.orderBills = 'E1_VENCREA DESC'
        this.itemsPerPageBills = 10
      } else if (this.activeComponent === 'pedidos') {
        this.titleBills = "Pedidos"
        this.colorBills = "info"
        this.headersBills = []
        // while (this.headersBills.length > 0) {
        //   this.headersBills.pop()
        // }
        if (['portao_cambui'].includes(this.companySelected.groupCompany)) {
          this.queryBills = "` SELECT CASE WHEN ZAN_DANFE.ZAN_IDFK IS NOT NULL THEN 'S' ELSE 'N' END TEM_DANFE, CASE WHEN ZAN_BOLETO.ZAN_IDFK IS NOT NULL THEN 'S' ELSE 'N' END TEM_BOLETO, SC5.R_E_C_N_O_ AS ID, C5_FILIAL, C5_NOTA, C5_NUM, C5_EMISSAO, C5_CLIENTE, C5_LOJACLI, C5_FRETE, ISNULL((SELECT SUM(C6_VALOR) FROM SC6${gpEmpresa} AS SC6 WHERE SC6.D_E_L_E_T_ = '' AND C6_FILIAL = '0101' AND C6_FILIAL = C5_FILIAL AND C6_NUM = C5_NUM),0) + C5_FRETE AS C5_TOTAL, ISNULL((SELECT SUM(CASE WHEN C9_BLCRED = '' THEN (C9_QTDLIB * C9_PRCVEN) ELSE 0 END) FROM SC9${gpEmpresa} AS SC9 WHERE SC9.D_E_L_E_T_ = '' AND C9_FILIAL = C5_FILIAL AND C9_PEDIDO = C5_NUM),0) AS C5_TOTAL_LIBERADO, ISNULL((SELECT SUM(C6_VALOR) FROM SC6${gpEmpresa} AS SC6 WHERE SC6.D_E_L_E_T_ = '' AND C6_FILIAL = C5_FILIAL AND C6_NUM = C5_NUM),0) + C5_FRETE - (ISNULL((SELECT SUM(CASE WHEN C9_BLCRED = '' THEN (C9_QTDLIB * C9_PRCVEN) ELSE 0 END) FROM SC9${gpEmpresa} AS SC9 WHERE SC9.D_E_L_E_T_ = '' AND C9_FILIAL = C5_FILIAL AND C9_PEDIDO = C5_NUM),0) + C5_FRETE) AS C5_SALDO FROM SC5${gpEmpresa} AS SC5  LEFT OUTER JOIN ZAN${gpEmpresa} AS ZAN_DANFE ON ZAN_DANFE.D_E_L_E_T_ = '' AND ZAN_DANFE.ZAN_ORIGEM = 'SF2' AND C5_SERIE + C5_NOTA = ZAN_DANFE.ZAN_IDFK AND C5_SERIE + C5_NOTA <> '' LEFT OUTER JOIN ZAN${gpEmpresa} AS ZAN_BOLETO ON ZAN_BOLETO.D_E_L_E_T_ = '' AND ZAN_BOLETO.ZAN_ORIGEM = 'SE1' AND C5_SERIE + C5_NOTA = ZAN_BOLETO.ZAN_IDFK AND C5_SERIE + C5_NOTA <> '' WHERE SC5.D_E_L_E_T_ = '' AND C5_FILIAL = '0101' `"
        } else if (['ibramed'].includes(this.companySelected.groupCompany)) { 
          this.queryBills = "` SELECT SC5.R_E_C_N_O_ AS ID, C5_FILIAL, (SELECT CASE WHEN QTDVEN > QTDENT THEN 'FATURADO PARCIAL' WHEN QTDVEN=QTDENT THEN 'FATURADO TOTAL' WHEN QTDENT = 0 THEN 'NAO FATURADO' ELSE '' END FROM ( SELECT SUM(C6_QTDVEN)AS QTDVEN,SUM(C6_QTDENT) AS QTDENT FROM SC6${gpEmpresa} WHERE D_E_L_E_T_='' AND C6_NUM=C5_NUM AND C6_FILIAL = C5_FILIAL) T1) as STATUS, C5_NOTA, C5_NUM, C5_EMISSAO, C5_CLIENTE, C5_LOJACLI, C5_FRETE,  ISNULL((SELECT SUM(C6_VALOR) FROM SC6${gpEmpresa} AS SC6 WHERE SC6.D_E_L_E_T_ = '' AND C6_FILIAL = C5_FILIAL AND C6_NUM = C5_NUM),0) + C5_FRETE AS C5_TOTAL, ISNULL((SELECT SUM(CASE WHEN C9_BLCRED = '' THEN (C9_QTDLIB * C9_PRCVEN) ELSE 0 END) FROM SC9${gpEmpresa} AS SC9 WHERE SC9.D_E_L_E_T_ = '' AND C9_FILIAL = C5_FILIAL AND C9_PEDIDO = C5_NUM),0) AS C5_TOTAL_LIBERADO, ISNULL((SELECT SUM(C6_VALOR) FROM SC6${gpEmpresa} AS SC6 WHERE SC6.D_E_L_E_T_ = '' AND C6_FILIAL = C5_FILIAL AND C6_NUM = C5_NUM),0) + C5_FRETE - (ISNULL((SELECT SUM(CASE WHEN C9_BLCRED = '' THEN (C9_QTDLIB * C9_PRCVEN) ELSE 0 END) FROM SC9${gpEmpresa} AS SC9 WHERE SC9.D_E_L_E_T_ = '' AND C9_FILIAL = C5_FILIAL AND C9_PEDIDO = C5_NUM),0) + C5_FRETE) AS C5_SALDO FROM SC5${gpEmpresa} AS SC5 WHERE SC5.D_E_L_E_T_ = ''   `"
          this.headersBills = []
          this.headersBills.push(
            {
            "text": "Status",
            "value": "STATUS",
            "hide": false
           }
          )
        } else {
          this.queryBills = "` SELECT SC5.R_E_C_N_O_ AS ID, C5_FILIAL, C5_NOTA, C5_NUM, C5_EMISSAO, C5_CLIENTE, C5_LOJACLI, C5_FRETE,  ISNULL((SELECT SUM(C6_VALOR) FROM SC6${gpEmpresa} AS SC6 WHERE SC6.D_E_L_E_T_ = '' AND C6_FILIAL = C5_FILIAL AND C6_NUM = C5_NUM),0) + C5_FRETE AS C5_TOTAL, ISNULL((SELECT SUM(CASE WHEN C9_BLCRED = '' THEN (C9_QTDLIB * C9_PRCVEN) ELSE 0 END) FROM SC9${gpEmpresa} AS SC9 WHERE SC9.D_E_L_E_T_ = '' AND C9_FILIAL = C5_FILIAL AND C9_PEDIDO = C5_NUM),0) AS C5_TOTAL_LIBERADO, ISNULL((SELECT SUM(C6_VALOR) FROM SC6${gpEmpresa} AS SC6 WHERE SC6.D_E_L_E_T_ = '' AND C6_FILIAL = C5_FILIAL AND C6_NUM = C5_NUM),0) + C5_FRETE - (ISNULL((SELECT SUM(CASE WHEN C9_BLCRED = '' THEN (C9_QTDLIB * C9_PRCVEN) ELSE 0 END) FROM SC9${gpEmpresa} AS SC9 WHERE SC9.D_E_L_E_T_ = '' AND C9_FILIAL = C5_FILIAL AND C9_PEDIDO = C5_NUM),0) + C5_FRETE) AS C5_SALDO FROM SC5${gpEmpresa} AS SC5 WHERE SC5.D_E_L_E_T_ = ''  `"
        }
        this.filtersBills = '(item) => { return [["C5_CLIENTE:" + (item.A1_COD ? item.A1_COD.trim() : item.SA1 && item.SA1.A1_COD ? item.SA1.A1_COD.trim() : null)],["C5_LOJACLI:" + (item.A1_COD ? item.A1_LOJA.trim() : item.SA1 && item.SA1.A1_LOJA ? item.SA1.A1_LOJA.trim() : null)]]}'
        this.headersBills.push( 
          {
            "text": "Filial",
            "value": "C5_FILIAL"
          },
          {
            "text": "Pedido",
            "value": "C5_NOTA",
            "hide": true
          },
          {
            "text": "Número",
            "value": "C5_NUM",
            "mobile": {
              "subTitle": true
            }
          },          
          {
            "date": true,
            "mobile": true,
            "value": "C5_EMISSAO",
            "text": "Emissão"
          },
          {
            "prefix": "R$",
            "value": "C5_TOTAL",
            "text": "Valor",
            "type": "float",
            "precisionText": 2,
            "mobile": true
          },
          // {
          //   "type": "float",
          //   "mobile": true,
          //   "text": "Saldo",
          //   "precisionText": 2,
          //   "prefix": "R$",
          //   "value": "C5_TOTAL_LIBERADO"
          // },
          // {
          //   "type": "float",
          //   "mobile": true,
          //   "text": "Saldo",
          //   "precisionText": 2,
          //   "prefix": "R$",
          //   "value": "C5_SALDO"
          // }
        )
        this.orderBills = 'C5_NUM DESC'
        this.itemsPerPageBills = 10
        if (['portao_cambui'].includes(this.companySelected.groupCompany)) {
          this.customActions = [
            {
              data: {
                listRegister: true,
                actionRunner: "async ({item, getApi, generatePdf, setLoading}) => { setLoading({stats: true, message: 'Obtendo Danfe...'}); try { const {base64} = await getApi({timeout: 30000, timeout: 30000, url: '/protheus/', options: {params: {path: 'ufbase64/danfe/' + item.C5_FILIAL + '/' + item.C5_NUM}}}); setLoading({stats: false}); if (base64){ generatePdf({base64str: base64}) }} catch (error) { setLoading({stats: false}); } };",
                color: "cyan darken-4",
                hide: "({item}) => !item || !item.TEM_DANFE || item.TEM_DANFE !== 'S'",
                icon: "mdi-receipt-text-outline",
                name: "Danfe"
              }
            },
            {
              data: {
                listRegister: true,
                actionRunner: "async ({item, getApi, generatePdf, setLoading}) => { setLoading({stats: true, message: 'Obtendo Boleto...'}); try { const {base64} = await getApi({timeout: 30000, url: '/protheus/', options: {params: {path: 'ufbase64/boleto/' + item.C5_FILIAL + '/' + item.C5_NUM}}}); setLoading({stats: false}); if (base64){ generatePdf({base64str: base64}) }} catch (error) { setLoading({stats: false}); } };",
                color: "cyan darken-4",
                hide: "({item}) => !item || !item.TEM_BOLETO || item.TEM_BOLETO !== 'S'",
                icon: "mdi-barcode",
                name: "Boleto"
              }
            }
          ]
        } else if (!['ore', 'ibramed','samtronic'].includes(this.companySelected.groupCompany)) {
          this.customActions = [                    
            {
              data: {
                listRegister: true,
                actionRunner: "async ({item, getApi, generatePdf, setLoading}) => { setLoading({stats: true, message: 'Obtendo Danfe...'}); try { const {base64} = await getApi({timeout: 30000, url: '/protheus/', options: {params: {path: 'ufbase64/danfe/' + item.C5_FILIAL + '/' + item.C5_NUM}}}); setLoading({stats: false}); if (base64){ generatePdf({base64str: base64}) }} catch (error) { setLoading({stats: false}); } };",
                color: "cyan darken-4",
                hide: "({item}) => !item || !item?.C5_NOTA.trim()",
                icon: "mdi-receipt-text-outline",
                name: "Danfe"
              }
            },          
            {
              data: {
                listRegister: true,
                actionRunner: "async ({item, getApi, generatePdf, setLoading}) => { setLoading({stats: true, message: 'Obtendo Boleto...'}); try { const {base64} = await getApi({timeout: 30000, url: '/protheus/', options: {params: {path: 'ufbase64/boleto'}}}); setLoading({stats: false}); if (base64){ generatePdf({base64str: base64}) }} catch (error) { setLoading({stats: false}); } };",
                color: "cyan darken-4",
                hide: "({item}) => !item || !item?.C5_NOTA.trim()",
                icon: "mdi-barcode",
                name: "Boleto"
              }
            }
          ]
        }        
      } else if (this.activeComponent === 'faturamento') {
        this.titleBills = "Faturamento"
        this.colorBills = "teal"

        if (['portao_cambui'].includes(this.companySelected.groupCompany)) {
          this.queryBills = "` SELECT CASE WHEN ZAN_DANFE.ZAN_IDFK IS NOT NULL THEN 'S' ELSE 'N' END TEM_DANFE, CASE WHEN ZAN_BOLETO.ZAN_IDFK IS NOT NULL THEN 'S' ELSE 'N' END TEM_BOLETO, SF2.R_E_C_N_O_ AS ID, F2_FILIAL, C5_FILIAL, C5_NUM, F2_SERIE, F2_DOC, F2_NFELETR, F2_EMISSAO, F2_DUPL, F2_VALFAT, F2_FRETE,  F2_HORA, F2_TRANSP, ISNULL(A4_NREDUZ,'') AS A4_NREDUZ FROM SF2${gpEmpresa} AS SF2 LEFT JOIN SC5${gpEmpresa} AS SC5 ON SC5.D_E_L_E_T_ = '' AND C5_FILIAL = F2_FILIAL AND C5_SERIE = F2_SERIE AND F2_DOC = C5_NOTA LEFT JOIN SA4${gpEmpresa} AS SA4 ON SA4.D_E_L_E_T_ = '' AND F2_TRANSP = A4_COD LEFT OUTER JOIN ZAN${gpEmpresa} AS ZAN_DANFE ON ZAN_DANFE.D_E_L_E_T_ = '' AND ZAN_DANFE.ZAN_ORIGEM = 'SF2' AND F2_SERIE + F2_DOC = ZAN_DANFE.ZAN_IDFK LEFT OUTER JOIN ZAN${gpEmpresa} AS ZAN_BOLETO ON ZAN_BOLETO.D_E_L_E_T_ = '' AND ZAN_BOLETO.ZAN_ORIGEM = 'SE1' AND F2_SERIE + F2_DOC = ZAN_BOLETO.ZAN_IDFK WHERE SF2.D_E_L_E_T_ = '' AND F2_FILIAL = '0101'` "
        } 
        else if (['cermag'].includes(this.companySelected.groupCompany)) {
          this.queryBills = "` SELECT SF2.R_E_C_N_O_ AS ID, F2_FILIAL, C5_FILIAL, C5_NUM, F2_SERIE, F2_DOC, F2_NFELETR, F2_EMISSAO, F2_DUPL, F2_VALFAT, F2_FRETE, F2_HORA, F2_TRANSP, ISNULL(A4_NREDUZ,'') AS A4_NREDUZ FROM SF2${gpEmpresa} AS SF2 LEFT JOIN SC5${gpEmpresa} AS SC5 ON SC5.D_E_L_E_T_ = '' AND C5_FILIAL = F2_FILIAL AND C5_SERIE = F2_SERIE AND F2_DOC = C5_NOTA LEFT JOIN SA4010 AS SA4 ON SA4.D_E_L_E_T_ = '' AND F2_TRANSP = A4_COD WHERE SF2.D_E_L_E_T_ = '' ` "
        } else {
          this.queryBills = "` SELECT SF2.R_E_C_N_O_ AS ID, F2_FILIAL, C5_FILIAL, C5_NUM, F2_SERIE, F2_DOC, F2_NFELETR, F2_EMISSAO, F2_DUPL, F2_VALFAT, F2_FRETE, F2_HORA, F2_TRANSP, ISNULL(A4_NREDUZ,'') AS A4_NREDUZ FROM SF2${gpEmpresa} AS SF2 LEFT JOIN SC5${gpEmpresa} AS SC5 ON SC5.D_E_L_E_T_ = '' AND C5_FILIAL = F2_FILIAL AND C5_SERIE = F2_SERIE AND F2_DOC = C5_NOTA LEFT JOIN SA4${gpEmpresa} AS SA4 ON SA4.D_E_L_E_T_ = '' AND F2_TRANSP = A4_COD WHERE SF2.D_E_L_E_T_ = '' ` "
        }
        
        this.filtersBills = '(item) => { return [["F2_CLIENTE:" + (item.A1_COD ? item.A1_COD.trim() : item.SA1 && item.SA1.A1_COD ? item.SA1.A1_COD.trim() : null)],["F2_LOJA:" + (item.A1_COD ? item.A1_LOJA.trim() : item.SA1 && item.SA1.A1_LOJA ? item.SA1.A1_LOJA.trim() : null)]]}'
        this.headersBills = [
          {
            "text": "Filial",
            "value": "F2_FILIAL"
          },
          {
            "text": "Pedido",
            "value": "C5_NUM",
            "hide": true
          },
          {
            "text": "Série Docto.",
            "value": "F2_SERIE",
            "mobile": {
              "subTitle": true
            }
          },
          {
            "text": "Numero",
            "value": "F2_DOC",
            "mobile": {
              "subTitle": true
            }
          },
          {
            "text": "NF Eletr.",
            "value": "F2_NFELETR",
            "mobile": {
              "subTitle": true
            }
          },
          {
            "date": true,
            "mobile": true,
            "value": "F2_EMISSAO",
            "text": "DT Emissão"
          },
          {
            "text": "Num. Titulo",
            "value": "F2_DUPL",
            "mobile": {
              "subTitle": true
            }
          },
          {
            "prefix": "R$",
            "value": "F2_VALFAT",
            "text": "Valor Fatura",
            "type": "float",
            "precisionText": 2,
            "mobile": true
          },
          {
            "prefix": "R$",
            "value": "F2_FRETE",
            "text": "Vlr. Frete",
            "type": "float",
            "precisionText": 2,
            "mobile": true
          },
          {
            "text": "Hora",
            "value": "F2_HORA",
            "mobile": {
              "subTitle": true
            }
          },
          {
            "text": "Transp.",
            "value": "F2_TRANSP",
            "mobile": {
              "subTitle": true
            }
          },
          {
            "text": "Nome Reduz.",
            "value": "A4_NREDUZ",
            "mobile": {
              "subTitle": true
            }
          }
        ]
        this.orderBills = 'ID DESC'
        this.itemsPerPageBills = 10
        if (['portao_cambui'].includes(this.companySelected.groupCompany)) {
          this.customActions = [
            {
              data: {
                listRegister: true,
                actionRunner: "async ({item, getApi, generatePdf, setLoading}) => { setLoading({stats: true, message: 'Obtendo Danfe...'}); try { const {base64} = await getApi({timeout: 30000, url: '/protheus/', options: {params: {path: 'ufbase64/danfe/' + item.F2_FILIAL + '/' + item.C5_NUM}}}); setLoading({stats: false}); if (base64){ generatePdf({base64str: base64}) }} catch (error) { setLoading({stats: false}); } };",
                color: "cyan darken-4",
                hide: "({item}) => !item || !item.TEM_DANFE || item.TEM_DANFE !== 'S'",
                icon: "mdi-receipt-text-outline",
                name: "Danfe"
              }
            },
            {
              data: {
                listRegister: true,
                actionRunner: "async ({item, getApi, generatePdf, setLoading}) => { setLoading({stats: true, message: 'Obtendo Boleto...'}); try { const {base64} = await getApi({timeout: 30000, url: '/protheus/', options: {params: {path: 'ufbase64/boleto/' + item.F2_FILIAL + '/' + item.C5_NUM}}}); setLoading({stats: false}); if (base64){ generatePdf({base64str: base64}) }} catch (error) { setLoading({stats: false}); } };",
                color: "cyan darken-4",
                hide: "({item}) => !item || !item.TEM_BOLETO || item.TEM_BOLETO !== 'S'",
                icon: "mdi-barcode",
                name: "Boleto"
              }
            }
          ]
        } else if (['ibramed'].includes(this.companySelected.groupCompany)) {
          this.customActions = []
          this.customActions.push(
          {
            data: {
              listRegister: true,
              actionRunner: "async ({item, getApi, generatePdf, setLoading}) => { setLoading({stats: true, message: 'Obtendo Danfe...'}); try { const {base64} = await getApi({timeout: 30000, url: '/protheus/', options: {params: {path: 'ufbase64/danfe/' + item.C5_FILIAL + '/' + item.C5_NUM + '/' + item.F2_DOC + '/' + item.F2_SERIE}}}); setLoading({stats: false}); if (base64){ generatePdf({base64str: base64}) }} catch (error) { setLoading({stats: false}); } };",
              color: "cyan darken-4",
              hide: "({item}) => !item || !item.C5_NUM",
              icon: "mdi-receipt-text-outline",
              name: "Danfe"
            }
          })
        } else if (!['ore', 'samtronic'].includes(this.companySelected.groupCompany)) {
          this.customActions = []
          this.customActions.push(
          {
            data: {
              listRegister: true,
              actionRunner: "async ({item, getApi, generatePdf, setLoading}) => { setLoading({stats: true, message: 'Obtendo Danfe...'}); try { const {base64} = await getApi({timeout: 30000, url: '/protheus/', options: {params: {path: 'ufbase64/danfe/' + item.C5_FILIAL + '/' + item.C5_NUM}}}); setLoading({stats: false}); if (base64){ generatePdf({base64str: base64}) }} catch (error) { setLoading({stats: false}); } };",
              color: "cyan darken-4",
              hide: "({item}) => !item || !item.C5_NUM",
              icon: "mdi-receipt-text-outline",
              name: "Danfe"
            }
          })
          this.customActions.push(
          {
            data: {
              listRegister: true,
              actionRunner: "async ({item, getApi, generatePdf, setLoading}) => { setLoading({stats: true, message: 'Obtendo Boleto...'}); try { const {base64} = await getApi({timeout: 30000, url: '/protheus/', options: {params: {path: 'ufbase64/boleto/'}}}); setLoading({stats: false}); if (base64){ generatePdf({base64str: base64}) }} catch (error) { setLoading({stats: false}); } };",
              color: "cyan darken-4",
              hide: "({item}) => !item || !item.C5_NUM",
              icon: "mdi-barcode",
              name: "Boleto"
            }
          })            
        }
      } else if (this.activeComponent === 'histCobrancas') {
        this.titleBills = "Histórico de Cobrança"
        this.colorBills = "error"
        this.queryBills = "`SELECT	ACG_FILORI, ACG_PREFIX, ACG_TITULO, ACG_PARCEL, ACG_TIPO,  ACG_NATURE, ACG_DTVENC, ACG_DTREAL, ACG_VALOR, ACG_ACRESC,  ACG_DESCFI, ACG_STATUS, ACF_OPERAD, U7_NOME, ACG_FILIAL, ACG_CODIGO, ACG_HIST, ACG.R_E_C_N_O_ AS ID  FROM ACG${gpEmpresa} AS ACG INNER JOIN ACF${gpEmpresa} AS ACF ON ACF.D_E_L_E_T_ = '' AND ACG_FILIAL = ACF_FILIAL AND ACG_CODIGO = ACF_CODIGO INNER JOIN SE1${gpEmpresa} AS SE1 ON SE1.D_E_L_E_T_ = '' AND E1_FILIAL = ACG_FILIAL AND E1_NUM = ACG_TITULO AND E1_PREFIXO = ACG_PREFIX AND E1_PARCELA = ACG_PARCEL AND E1_TIPO = ACG_TIPO INNER JOIN SU7${gpEmpresa} AS SU7 ON SU7.D_E_L_E_T_ = '' AND U7_COD = ACF_OPERAD WHERE ACG.D_E_L_E_T_ = '' `"
        this.filtersBills = '(item) => {return [["E1_CLIENTE:" + (item.A1_COD ? item.A1_COD.trim() : item.SA1 && item.SA1.A1_COD ? item.SA1.A1_COD.trim() : null)],["E1_LOJA:" + (item.A1_COD ? item.A1_LOJA.trim() : item.SA1 && item.SA1.A1_LOJA ? item.SA1.A1_LOJA.trim() : null)]]}'
        this.headersBills = [
          {
            "text": "Filial Ori",
            "value": "ACG_FILORI"
          },
          {
            "text": "Número",
            "value": "ACG_PREFIX",
            "mobile": {
              "subTitle": true
            }
          },
          {
            "text": "Número",
            "value": "ACG_TITULO",
            "mobile": {
              "subTitle": true
            }
          },
          {
            "text": "Parcela",
            "value": "ACG_PARCEL",
            "mobile": {
              "subTitle": true
            }
          },
          {
            "text": "Tipo",
            "value": "ACG_TIPO",
            "mobile": {
              "subTitle": true
            }
          },
          {
            "text": "Natureza",
            "value": "ACG_NATURE",
            "mobile": {
              "subTitle": true
            }
          },
          {
            "date": true,
            "mobile": true,
            "value": "ACG_DTREAL",
            "text": "Vencimento"
          },
          {
            "prefix": "R$",
            "value": "ACG_VALOR",
            "text": "Valor",
            "type": "float",
            "precisionText": 2,
            "mobile": true
          },
          {
            "text": "Status",
            "value": "ACG_STATUS",
            "mobile": {
              "subTitle": true
            }
          },
          {
            "text": "Operador",
            "value": "ACF_OPERAD",
            "mobile": {
              "subTitle": true
            }
          },
          {
            "text": "Operador",
            "value": "U7_NOME",
            "mobile": {
              "subTitle": true
            }
          },
          {
            "text": "Histórico",
            "value": "ACG_HIST",
            "mobile": {
              "subTitle": true
            }
          }
        ]
        this.orderBills = 'ACG_DTREAL DESC'
        this.itemsPerPageBills = 5

        this.querySubBills = "`SELECT	ACF_FILIAL, ACF_CODIGO, ACF_DATA, ACF_PENDEN, ACF_HRPEND, CASE WHEN ACF_OPERA = '2' THEN '2=ATIVO' ELSE '1=Receptivo' END AS ACF_OPERA, ACF_OPERAD, U7_NOME, ACF_MOTIVO, U9_DESC, ACF.R_E_C_N_O_ AS ID  FROM ACF${gpEmpresa} AS ACF INNER JOIN SU7${gpEmpresa} AS SU7 ON SU7.D_E_L_E_T_ = '' AND U7_COD = ACF_OPERAD LEFT JOIN SU9${gpEmpresa} AS SU9 ON SU9.D_E_L_E_T_ = '' AND U9_CODIGO = ACF_MOTIVO WHERE ACF.D_E_L_E_T_ = '' `"
        this.headersSubBills = [
          {
            "text": "Fil Atendimento",
            "value": "ACF_FILIAL"
          },
          {
            "text": "Atendimento",
            "value": "ACF_CODIGO",
            "mobile": {
              "subTitle": true
            }
          },
          {
            "text": "Data",
            "value": "ACF_DATA",
            "date": true,
            "mobile": {
              "subTitle": true
            }
          },
          {
            "text": "Retorno",
            "value": "ACF_PENDEN",
            "date": true,
            "mobile": {
              "subTitle": true
            }
          },
          {
            "text": "Parcela",
            "value": "ACF_HRPEND",
            "mobile": {
              "subTitle": true
            }
          },
          {
            "text": "Ligação",
            "value": "ACF_OPERA",
            "mobile": {
              "subTitle": true
            }
          },
          {
            "text": "Operador",
            "value": "ACF_OPERAD",
            "mobile": {
              "subTitle": true
            }
          },
          {
            "text": "Nome",
            "value": "U7_NOME",
            "mobile": {
              "subTitle": true
            }
          },
          {
            "text": "Ocorrência",
            "value": "ACF_MOTIVO",
            "mobile": {
              "subTitle": true
            }
          },
          {
            "text": "Descrição",
            "value": "U9_DESC",
            "mobile": {
              "subTitle": true
            }
          }
        ]
        this.orderSubBills = 'ACF_DATA'
        this.filterSubBills = '(item) => [["ACF_CODIGO:" + item.ACG_CODIGO]]'
      }

      if (this.activeComponent !== '') {
        this.itemsBills = []
        this.setTableBills()
      }
    },

    async setTableBills(sub = false, item = this.computedModel) {
      this.setLoading({stats: true})
      let filters = ``

      const filterBillsUsed = sub ? this.filtersSubBills : this.filtersBills
      const orderBillsUsed = sub ? this.orderSubBills : this.orderBills
      const queryBillsUsed = sub ? this.querySubBills : this.queryBills
      const activeComponentUsed = sub ? this.activeSubComponent : this.activeComponent

      try {
        if (filterBillsUsed) {
          if (filterBillsUsed.includes('=>')) {
            const filtersComputed = eval(filterBillsUsed)
            filters = filtersComputed(item)
          } else if (filterBillsUsed.includes('`'))  {
            filters = eval(filterBillsUsed)
          } else {
            filters = filterBillsUsed
          }
        }
        let path = ""
        if(this.companySelected.idParent == 'wdcnet'){
          path = "/consulta"
        }
        if ( queryBillsUsed ) {
          const result = await this.getExternal({
            setRegister: false,
            allRegs: true,
            path,
            ordem: orderBillsUsed,
            idField: 'ID',
            query: queryBillsUsed,
            filters,
            register: `${activeComponentUsed}.bills`
          })
          if (sub) {
            this.itemsSubBills = result
          } else {
            this.itemsBills = result
          }
        }
      } catch (e) {
        console.error(e)
      }
      this.setLoading({stats: false})
    },
  },
  async created() {
    if (this.model && this.model.collection && this.model.id) {
      this.modelLoaded = await this.getById({collection: this.model.collection, id: this.model.id})
    } else {
      this.modelLoaded = this.model
    }
  }
}
</script>

<style>

</style>