var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-dialog',{attrs:{"width":"1200"},on:{"click:outside":function($event){_vm.productSelect=null;_vm.paymentSelect=null;}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"no-text-transform justify-start overflow-hidden text-left pa-3 ml-5",attrs:{"text":"","outlined":"","height":"50","dark":"","max-width":_vm.$vuetify.breakpoint.lgAndDown ? '800px' : ''}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-2",attrs:{"size":"20"}},[_vm._v("mdi-calculator")]),_vm._v(" Simulador de Financiamento ")],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"grey darken-4 white--text"},[_vm._v(" Simulador de Financiamento ")]),(_vm.products.length && _vm.payments.length)?_c('v-card-text',[_c('v-row',{staticClass:"ma-4"},[_c('v-col',{staticClass:"py-1",attrs:{"cols":"4"}},[_c('v-select',{staticClass:"mt-1",attrs:{"items":_vm.computedProducts,"return-object":"","hide-details":"","label":"Produto","dense":""},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list-item',[_c('v-list-item-content',[_c('v-text-field',{attrs:{"prepend-icon":"mdi-magnify","dense":"","clearable":"","single-line":"","hide-details":"","placeholder":"Filtrar..."},model:{value:(_vm.filterProduct),callback:function ($$v) {_vm.filterProduct=$$v},expression:"filterProduct"}})],1)],1)]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"mr-2",attrs:{"link":"","color":"grey darken-3","dark":"","label":"","small":""}},[_vm._v(" "+_vm._s(item.B1_COD)+" - "+_vm._s(item.B1_DESC.substr(0,25))+" ")])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"mr-2",attrs:{"link":"","color":"grey darken-3","dark":"","label":"","small":""}},[_vm._v(" "+_vm._s(item.B1_COD)+" - "+_vm._s(item.B1_DESC)+" ")])]}}],null,false,486590063),model:{value:(_vm.productSelect),callback:function ($$v) {_vm.productSelect=$$v},expression:"productSelect"}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"4"}},[_c('v-select',{attrs:{"items":_vm.computedPayments,"return-object":"","hide-details":"","dense":"","label":"Condição de Pagamento"},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list-item',[_c('v-list-item-content',[_c('v-text-field',{attrs:{"prepend-icon":"mdi-magnify","dense":"","clearable":"","single-line":"","hide-details":"","placeholder":"Filtrar..."},model:{value:(_vm.filterPayment),callback:function ($$v) {_vm.filterPayment=$$v},expression:"filterPayment"}})],1)],1)]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"mr-2",attrs:{"link":"","color":"grey darken-3","dark":"","label":"","small":""}},[_vm._v(" "+_vm._s(item.E4_CODIGO)+" - "+_vm._s(item.E4_DESCRI.substr(0,25))+" ")])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"mr-2",attrs:{"link":"","color":"grey darken-3","dark":"","label":"","small":""}},[_vm._v(" "+_vm._s(item.E4_CODIGO)+" - "+_vm._s(item.E4_DESCRI)+" ")])]}}],null,false,1205235614),model:{value:(_vm.paymentSelect),callback:function ($$v) {_vm.paymentSelect=$$v},expression:"paymentSelect"}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"4"}},[_c('v-text-field',{staticClass:"mt-1",attrs:{"name":'Valor de Entrada',"label":'Valor de Entrada',"placeholder":'Valor de Entrada',"type":'number',"dense":""},model:{value:(_vm.inputValue),callback:function ($$v) {_vm.inputValue=$$v},expression:"inputValue"}})],1)],1),(this.items.length)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"dense":"","headers":_vm.computedHeaders,"items":_vm.computedItems,"disable-filtering":true,"disable-pagination":true,"disable-sort":true,"hide-default-footer":true}})],1)],1):_vm._e()],1):_vm._e(),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":function($event){_vm.productSelect=null;_vm.paymentSelect=null;_vm.dialog = false}}},[_vm._v(" Sair ")]),_c('v-btn',{attrs:{"disabled":!(_vm.productSelect && _vm.paymentSelect),"color":"primary","text":""},on:{"click":function($event){return _vm.simulate()}}},[_vm._v(" Simular ")])],1),_c('v-snackbar',{attrs:{"value":_vm.showError,"absolute":"","bottom":"","color":"red accent-2"},on:{"click":function($event){_vm.showError = false}}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }