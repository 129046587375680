const hostname = window.location.hostname.replace('ufmanage-', '').replace('.ufsales.com.br', '')
const baseUrl = window.location.origin
let baseApiUrl = ''

switch (hostname) {
  case 'localhost':
    baseApiUrl = 'http://localhost:5000'
    break;

  case 'develop':
    baseApiUrl = 'https://api-ufmanage-develop.ufsales.com.br'
    break;

    default:
    baseApiUrl = 'https://api-ufmanage.ufsales.com.br'
    break;
}

if (process.env.NODE_ENV != 'production') console.info(`"UF Manage - ${process.env.NODE_ENV}!`)

export {baseUrl, baseApiUrl}