<script>
import VTextField from "vuetify/lib/components/VTextField/VTextField.js"
import { createNamespacedHelpers, mapActions } from 'vuex'
const { mapMutations:registersMutations, mapActions:registersActions } = createNamespacedHelpers('registers')
const { mapState:authState } = createNamespacedHelpers('auth')
export default {
  name: 'VTextFieldAsync',
  extends: VTextField,
  props: {
    collection: {
      type: String,
      required: true
    },

    /**
     * Função de tratamento da requisição da busca.
     * Essa função será chamada recebendo como parâmetro a Promise da chamada da busca do algolia
     */
    handler: {
      type: Function,
      required: false
    },

    /**
     * Valor em ms do intervalo de espera de parada de input até realizar a requisição.
     */
    delay: {
      type: Number,
      required: false,
      default: () => 0
    },

    filters: Array,
    filtersIn: Array,
    fieldsToSearch: Array,
    fieldsToMark: Array,
    register: String,
  },
  data() {
    return {
      timer: null,
    }
  },
  computed: {
    ...authState(['user']),
    registerFiltered() {
      return `${this.register}Filtered`
    }
  },
  methods: {
    ...registersMutations(['resetRegister']),
    ...registersActions(['getOnMongo']),
    timeoutSearch(query, filters, filtersIn) {
      if (query || filters || filtersIn) {
        if (this.timer)
          clearTimeout(this.timer)
  
        this.timer = setTimeout(() => {
          this.timer = null
          this.handler(this.asyncSearch(query, filters, filtersIn))
        }, this.delay)
      }
    },
    search(query, filters, filtersIn) {
      if (query || filters?.length > 0 || filtersIn?.length > 0) {
        this.handler(this.asyncSearch(query, filters, filtersIn))
      }
    },
    asyncSearch(query, filters, filtersIn) {
      this.resetRegister(this.registerFiltered)
      const filtersInSelecteds = filtersIn ? filtersIn.filter(f => {return f.selecteds && f.selecteds.length > 0}) : []
      
      if (query || (filters && filters.length > 0) || (filtersInSelecteds && filtersInSelecteds.length > 0)) {
        // Replace especial chars
        const regex = (this.collection == 'users') ? new RegExp(/\+|\/|\(|\)/, "g") : new RegExp(/\+|\/|\.|\(|\)/, "g")
        if (query) query = query.replace(regex, '')

        const orFilters = []
        if (query) {
          for (const header of this.fieldsToSearch) {
            orFilters.push({
              key: header.fill || header.value,
              operator: 'like',
              value: query
            })
          }
        }
     
        this.getOnMongo({
          register: this.registerFiltered, 
          collection: this.collection, 
          filters, 
          orFilters,
          orderBy: this.orderBy, 
          getTotal: true,
          onSnapshot: true,
          // forceFilter: true
        })
        .catch(err => this.loading = false)

        return [] // Não precisa retornar nada pois o observer grava direto na vuex para manter a reatividade
      }
      else return []
    }
  },
  async created() {
    this.$on('click:clear', (query) => {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
    })
    
    this.$on('keydown', (e) => {
      if (e.key == 'Enter') {
        if (this.timer) {
          clearTimeout(this.timer)
          this.timer = null
        }
        const query = this.lazyValue
        this.search(query, this.filters, this.filtersIn)
      }
    })
    
    if (this.delay)
      this.$on('input', (query) => this.timeoutSearch(query, this.filters, this.filtersIn))
    else
      this.$on('input', this.search)
  },
}
</script>