<template>
  <section>
    <Toolbar>
      <template slot="actions">
        <template>
          <v-btn
            v-if="!readonly"
            @click="submit()"
            height="40"
            x-small
            outlined
            color="success"
            class="mr-2 text-caption"
          >
            <v-icon size="20" class="mr-1">mdi-content-save-outline</v-icon>
            Gravar
          </v-btn>

          <v-btn
            v-if="!readonly"
            @click="goBack"
            height="40"
            x-small
            outlined
            color="error"
            class="text-caption"
          >
            <v-icon size="18" class="mr-1">mdi-cancel</v-icon>
            Cancelar
          </v-btn>

          <v-btn
            v-else
            @click="goBack"
            height="40"
            x-small
            outlined
            color="primary"
            class="text-caption"
          >
            <v-icon size="18" class="mr-1">mdi-chevron-left</v-icon>
            Voltar
          </v-btn>
        </template>
      </template>
    </Toolbar>

    <Form
      :inputs="computedInputsMaster"
      :collection="collection"
      :model="item"
      :color="color"
      :readonly="readonly"
      @submit="submit()"
      ref="formMaster"
    >
    </Form>
  </section>
</template>

<script>
import Toolbar from "@/components/Templates/Toolbar";
import {
  createNamespacedHelpers,
  mapActions,
  mapMutations,
  mapState,
} from "vuex";
const {
  mapState: registersState,
  mapActions: registersActions,
  mapMutations: registersMutations,
} = createNamespacedHelpers("registers");
const { mapState: authState, mapActions: authActions } =
  createNamespacedHelpers("auth");
import Form from "@/components/Registers/Form";
// import FormDataTable from "@/components/Registers/FormDataTable"
export default {
  name: "CustomersRegister",
  components: { Form, Toolbar },
  props: {
    collection: {
      type: String,
      required: true,
    },
    register: {
      type: String,
      required: true,
    },
    readonly: {
      required: false,
      type: Boolean,
      default: () => false,
    },
    edit: {
      required: false,
      type: Boolean,
      default: () => false,
    },
    claims: {
      type: Object,
      required: true,
      default: () => ({
        add: false,
        view: false,
        edit: false,
        delete: false,
        copy: false,
        sync: false,
      }),
    },
    color: {
      required: false,
      type: String,
      default: "indigo",
    },
  },
  data() {
    return {
      item: {},
      name: "",
      email: "",
      idCreate: "",
      subCollections: [],
    };
  },
  computed: {
    ...mapState(["companySelected"]),
    ...registersState(["registers"]),
    ...authState(["user"]),
    computedInputsMaster() {
      const headers = [
        { type: "divider", label: "Dados Básicos" },
        { sm: 1, type: "switch", label: "Ativo", value: "active", default: true },
        {
          type: "text-field",
          label: "ID",
          value: "id",
          disabled: !!this.id,
          sm: 1,
          validateOnBlur: true,
        },
        {
          type: "text-field",
          label: "Nome de Exibição",
          value: "displayName",
          disabled: !!this.id,
          sm: 3,
        },
        {
          type: "text-field",
          label: "E-mail",
          value: "email",
          disabled: !!this.id,
          sm: 3,
          validateOnBlur: true,
        },
        {
          type: "text-field",
          label: "Senha",
          value: "password",
          // default: "123456",
          disabled: !!this.id,
          sm: 2,
          validateOnBlur: true,
        },
        {
          type: "jsoneditor",
          label: "Configs",
          value: "configs",
          default: {},
          options: {
            mode: "code",
            templates: [
              {
                label: "Input Text",
                title: "Insere um campo de texto",
                className: "jsoneditor-type-object",
                field: "campo",
                value: {
                  type: "string",
                  order: 1,
                  title: "título",
                  "x-cols": 12,
                  "x-props": {
                    outlined: true,
                    flat: true,
                  },
                },
              },
            ],
          },
          //help: 'https://koumoul-dev.github.io/vuetify-jsonschema-form/latest/examples/#basic',
          md: 6,
        },
        {
          type: "jsoneditor",
          label: "Sigamat",
          value: "sigamat",
          default: {},
          options: {
            mode: "code",
            templates: [
              {
                label: "Input Text",
                title: "Insere um campo de texto",
                className: "jsoneditor-type-object",
                field: "campo",
                value: {
                  type: "string",
                  order: 1,
                  title: "título",
                  "x-cols": 12,
                  "x-props": {
                    outlined: true,
                    flat: true,
                  },
                },
              },
            ],
          },
          //help: 'https://koumoul-dev.github.io/vuetify-jsonschema-form/latest/examples/#basic',
          md: 6,
        },
        {
          type: "jsoneditor",
          label: "SX2",
          value: "SX2",
          default: {},
          options: {
            mode: "code",
            templates: [
              {
                label: "Input Text",
                title: "Insere um campo de texto",
                className: "jsoneditor-type-object",
                field: "campo",
                value: {
                  type: "string",
                  order: 1,
                  title: "título",
                  "x-cols": 12,
                  "x-props": {
                    outlined: true,
                    flat: true,
                  },
                },
              },
            ],
          },
          //help: 'https://koumoul-dev.github.io/vuetify-jsonschema-form/latest/examples/#basic',
          md: 6,
        },
        {
          type: "jsoneditor",
          label: "Integrations",
          value: "integrations",
          default: {},
          options: {
            mode: "code",
            templates: [
              {
                label: "Input Text",
                title: "Insere um campo de texto",
                className: "jsoneditor-type-object",
                field: "campo",
                value: {
                  type: "string",
                  order: 1,
                  title: "título",
                  "x-cols": 12,
                  "x-props": {
                    outlined: true,
                    flat: true,
                  },
                },
              },
            ],
          },
          //help: 'https://koumoul-dev.github.io/vuetify-jsonschema-form/latest/examples/#basic',
          md: 6,
        },
        {
          type: "jsoneditor",
          label: "UserRules",
          value: "userRules",
          default: {},
          options: {
            mode: "code",
            templates: [
              {
                label: "Input Text",
                title: "Insere um campo de texto",
                className: "jsoneditor-type-object",
                field: "campo",
                value: {
                  type: "string",
                  order: 1,
                  title: "título",
                  "x-cols": 12,
                  "x-props": {
                    outlined: true,
                    flat: true,
                  },
                },
              },
            ],
          },
          //help: 'https://koumoul-dev.github.io/vuetify-jsonschema-form/latest/examples/#basic',
          md: 6,
        },
        {
          type: "jsoneditor",
          label: "Reports",
          value: "reports",
          default: [],
          options: {
            mode: "code",
            templates: [
              {
                label: "Input Text",
                title: "Insere um campo de texto",
                className: "jsoneditor-type-object",
                field: "campo",
                value: {
                  type: "string",
                  order: 1,
                  title: "título",
                  "x-cols": 12,
                  "x-props": {
                    outlined: true,
                    flat: true,
                  },
                },
              },
            ],
          },
          //help: 'https://koumoul-dev.github.io/vuetify-jsonschema-form/latest/examples/#basic',
          md: 6,
        },
      ];

      return headers;
    },

    id() {
      return this.$route.params.id || this.item.id;
    },
  },
  methods: {
    ...registersActions(["save", "getById"]),
    ...mapMutations(["setLoading"]),
    // isEdit() {
    //   if (this.id) {
    //     return this.computedInputsMasterEdit;
    //   }
    //   return this.computedInputsMaster;
    // },
    goBack() {
      this.$router.push(`/${this.register}/listar`);
    },
    async submit() {
      if (this.readonlyComputed) {
        this.goBack();
        return;
      }

      if (this.validate()) {
        try {
          await this.send();
        } catch (err) {
          console.error(err);
        }
      }
    },
    validate() {
      return this.$refs.formMaster.validate();
    },
    async send() {
      const item = this.$refs.formMaster.getModel();
      if (this.$route.fullPath == "/customers/cadastrar") {
        this.setLoading({ stats: true }, { root: true });
        const response = await this.$api
          .post(`/customers/default`, { ...item })
          .then(res => this.goBack())
          .catch((err) => console.error(err))
          .finally(() => this.setLoading({ stats: false }, { root: true }))
        return response;
      }
      if (
        !(this.claimRoute === "add" && this.id) &&
        this.claimRoute !== "copy"
      ) {
        const id = this.id;
        if (!item.id && id) item.id = id;
      }
      if (this.$route.name == "/customers/editar") {
        this.setLoading({ stats: true }, { root: true });
        const response = await this.$api
          .patch(`/customers/default`, { ...item })
          .then(res => this.goBack())
          .catch((err) => console.error(err))
          .finally(() => this.setLoading({ stats: false }, { root: true }))
        return response;
      }
    },
    async callDefaults({ prop }) {
      try {
        const { data } = await this.$api.get(`/customers/default/${prop}`);
        return data;
      } catch (e) {
        console.error(e);
      }
    },
    async callSubcollection({ prop }) {
      try {
        let { data } = await this.$api.get(`/customers/${this.id}/${prop}`);
        data = data.data.map(x => {
          delete x.idParent
          delete x.active
          delete x.lifeControl
          delete x.lifeControlIntegratedAt
          delete x.deletado
          delete x.gpEmpresa
          delete x.indice
          return x
        })
        return data;
      } catch (e) {
        console.error(e);
      }
    },
  },
  async created() {
    if (this.id) {
      this.item =
        this.registers[this.register] &&
        this.registers[this.register].find(
          (item) => item.id == this.$route.params.id
        );
      if (!this.item)
        this.item =
          this.registers[`${this.register}Filtered`] &&
          this.registers[`${this.register}Filtered`].find(
            (item) => item.id == this.$route.params.id
          );
      if (!this.item)
        this.item = await this.getById({
          collection: this.collection,
          subCollections: [
            "sigamat",
            "SX2",
            "reports",
            "integrations",
            "userRules",
          ],
          register: this.register,
          id: this.$route.params.id,
          getFilial: false,
        });
      const sigamat = await this.callSubcollection({ prop: "sigamat" });
      const SX2 = await this.callSubcollection({ prop: "SX2" });
      const reports = await this.callSubcollection({ prop: "reports" });
      const integrations = await this.callSubcollection({
        prop: "integrations",
      });
      const userRules = await this.callSubcollection({ prop: "userRules" });
      this.item = {
        ...this.item,
        sigamat,
        SX2,
        reports,
        integrations,
        userRules,
      };
    } else {
      // Call a default json
      const configs = await this.callDefaults({ prop: "customers" });
      this.item = { ...this.item, configs };
      const reports = await this.callDefaults({ prop: "reports" });
      this.item = { ...this.item, reports };
      const SX2 = await this.callDefaults({ prop: "SX2" });
      this.item = { ...this.item, SX2 };
      const integrations = await this.callDefaults({ prop: "integrations" });
      this.item = { ...this.item, integrations };
      const sigamat = await this.callDefaults({ prop: "sigamat" });
      this.item = { ...this.item, sigamat };
      const userRules = await this.callDefaults({ prop: "userRules" });
      this.item = { ...this.item, userRules };
    }
  },
};
</script>