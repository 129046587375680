var render = function () {
var _obj, _obj$1, _obj$2, _obj$3, _obj$4, _obj$5, _obj$6;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{class:[{ red: _vm.computedHasDebit && !_vm.computedExpand}, {orange: !_vm.computedHasDebit && !_vm.computedExpand }, ( _obj = {}, _obj[(_vm.color + "--text")] = _vm.computedExpand, _obj ), ' card-bills lighten-4 mt-6'],style:({ height: 'auto'})},[(_vm.showToolbar)?_c('v-toolbar',{attrs:{"dense":"","flat":"","color":"transparent"}},[_c('v-toolbar-title',{class:[( _obj$1 = {}, _obj$1['red--text'] = _vm.computedHasDebit && !_vm.computedExpand, _obj$1 ), ( _obj$2 = {}, _obj$2['orange--text'] = !_vm.computedHasDebit && !_vm.computedExpand, _obj$2 ), ( _obj$3 = {}, _obj$3[(_vm.color + "--text")] = _vm.computedExpand, _obj$3 ), ' subtitle-2 font-weight-bold']},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('v-spacer'),_c('v-btn',{class:[( _obj$4 = {}, _obj$4['red--text'] = _vm.computedHasDebit && !_vm.computedExpand, _obj$4 ),( _obj$5 = {}, _obj$5['orange--text'] = !_vm.computedHasDebit && !_vm.computedExpand, _obj$5 ), ( _obj$6 = {}, _obj$6[(_vm.color + "--text")] = _vm.computedExpand, _obj$6 )],attrs:{"outlined":"","text":""},on:{"click":function($event){_vm.expand = !_vm.expand}}},[_vm._v(" "+_vm._s(_vm.computedExpand ? 'Ocultar' : 'Mostrar')+" "),_c('v-icon',{attrs:{"right":""},domProps:{"textContent":_vm._s(_vm.computedExpand ? 'mdi-chevron-up' : 'mdi-chevron-down')}})],1)],1):_vm._e(),_c('v-expand-transition',[(_vm.computedExpand)?_c('v-data-table',{staticClass:"text-sm-caption",attrs:{"headers":_vm.computedHeaders,"items":_vm.items,"dense":"","items-per-page":_vm.itemsPerPage},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{class:{ 'red lighten-4': _vm.isDueDate({item: item}), 'green lighten-4': _vm.computedItemsSelecteds[item.id || item.ID] },on:{"click":function($event){return _vm.clickRow({item: item})}}},_vm._l((_vm.computedHeaders),function(header,idx){return _c('td',{key:idx},[(header.value == 'data-table-actions')?_vm._l((_vm.computedCustomActions),function(cAction,idx){return _c('v-tooltip',{key:("customActionBtn-" + idx),attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.showAction({ item: item, customAction: cAction })),expression:"showAction({ item, customAction: cAction })"}],staticClass:"ml-n1 mr-2 no-hover",attrs:{"icon":"","x-small":"","color":cAction.color},on:{"click":function($event){return _vm.clickAction({ item: item, customAction: cAction })}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(cAction.icon))])],1)]}}],null,true)},[_vm._v(" "+_vm._s(cAction.name)+" ")])}):[_c('span',{domProps:{"innerHTML":_vm._s(_vm.formartFields({header: header, value: item[header.value]}))}})]],2)}),0)]}}],null,false,2632580210)}):_vm._e()],1),_c('v-dialog',{attrs:{"scrollable":""},on:{"update:return-value":function($event){_vm.jsPdf.doc = null}},model:{value:(_vm.jsPdf.dialog),callback:function ($$v) {_vm.$set(_vm.jsPdf, "dialog", $$v)},expression:"jsPdf.dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"pl-0"},[_vm._v("Pré-visualização do documento")]),_c('v-spacer'),_c('v-btn',{staticClass:"mr-2 text-caption",attrs:{"height":"40","x-small":"","outlined":"","color":"warning"},on:{"click":function($event){return _vm.savePdf()}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"20"}},[_vm._v("mdi-file-download-outline")]),_vm._v(" Download ")],1),_c('v-dialog',{attrs:{"max-width":"500vw"},on:{"input":_vm.jsPdfHandleDialog},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2 text-caption",attrs:{"height":"40","x-small":"","outlined":"","color":"success"}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"20"}},[_vm._v("mdi-email-send-outline")]),_vm._v(" Enviar por Email ")],1)]}}]),model:{value:(_vm.jsPdf.sendEmail.dialog),callback:function ($$v) {_vm.$set(_vm.jsPdf.sendEmail, "dialog", $$v)},expression:"jsPdf.sendEmail.dialog"}},[_c('Form',{attrs:{"inputs":_vm.jsPdf.sendEmail.inputs,"model":_vm.jsPdf.sendEmail.model},on:{"submit":_vm.sendPdf},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-card-title',[_vm._v("Enviar documento PDF por email")]),_c('v-divider')]},proxy:true},{key:"append",fn:function(){return [_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"text-caption",attrs:{"loading":_vm.jsPdf.sendEmail.sending,"disabled":_vm.jsPdf.sendEmail.sending,"type":"submit","color":"success","height":"40","x-small":"","outlined":""}},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"20"}},[_vm._v("mdi-send")]),_vm._v(" Enviar ")],1),_c('v-btn',{staticClass:"text-caption",attrs:{"height":"40","x-small":"","outlined":"","color":"error"},on:{"click":function($event){$event.stopPropagation();_vm.jsPdf.sendEmail.dialog = false}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"18"}},[_vm._v("mdi-cancel")]),_vm._v(" Cancelar ")],1)],1)]},proxy:true}])})],1),_c('v-btn',{staticClass:"text-caption",attrs:{"height":"40","x-small":"","outlined":"","color":"error"},on:{"click":function($event){$event.stopPropagation();_vm.jsPdf.dialog = false}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"18"}},[_vm._v("mdi-cancel")]),_vm._v(" Cancelar ")],1)],1),_c('v-divider'),(!!_vm.jsPdf.doc || !!_vm.jsPdf.blob || !!_vm.jsPdf.objectStorage)?_c('Iframe',{attrs:{"src":_vm.jsPdfOutput,"type":"application/pdf"}}):_vm._e(),_c('v-divider')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }