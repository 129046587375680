<template>
  <section class="w-100 fill-height">
    <keep-alive include="List,IntegrationsList,UsersList">
      <router-view :key="$route.fullPath + $route.meta.groupCompany"></router-view>
    </keep-alive>
  </section>
</template>

<script>
export default {
  name: 'Content'
}
</script>

<style>

</style>