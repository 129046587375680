export class Table {
  constructor({ headers = [], items = [], inputsMaster = null, formMaster = null, readonly = false, registersActions = {}, formDataTablelegacy = false}) {
    this.registersActions = registersActions
    this.headers = headers
    this.inputsMaster = inputsMaster
    this.formMaster = formMaster
    this.headersHashMap = headers.reduce((acc, {value}, idx) => ({...acc, [value]: idx}), {})
    this.readonly = readonly

    // console.time('Preparação de itens')
    this.rows = items.map((item, idx) => new Row({ item, headers, table: this, line: idx }) )
    if (formDataTablelegacy) { this.computeFirstHeaders() }
    // console.timeEnd('Preparação de itens')
  }

  async computeFirstHeaders() {
    // console.time('computeFirstHeaders')
    const computeFirstHeaders = this.headers.filter(h => h.computeFirst !== false)
    if (computeFirstHeaders && computeFirstHeaders.length > 0) {
      for (const row of this.rows) {
        for (const header of computeFirstHeaders) {
          const cell = row.getCellFromHeader(header.value)
          await row.computeCell(cell)
        }
      }
    }
    // console.timeEnd('computeFirstHeaders')
  }

  async computeRows() {
    for (const row of this.rows) {
      for (const header of this.headers) {
        const cell = row.getCellFromHeader(header.value)
        await row.computeCell(cell)
      }
    }
  }

  validateRows() {
    for (const row of this.rows) {
      row.validate()
    }
  }

  getFormMaster() {
    return this.formMaster
  }

  validate() {
    return this.rows.every(row => row.validate())
  }

  findRow(item) {
    return this.rows.find(row => row.item == item)
  }
}

export class Row {
  constructor({ item = null, headers = null, table, line = -1 }) {
    this.table = table
    const formMaster = this.table.getFormMaster()
    this.line = line
    this.item = item
    if (headers && item) {
      this.cells = headers.map((header, idx) => {
        const value = item[header.value] != undefined
          ? item[header.value]
          : 'default' in header
            ? header.default
            : null

        const name = header.value
        const mask = (value) => {
          const prefix = header.prefix
            ? header.prefix + ' '
            : ''

          const maskedValue = header.type == 'float' || header.type == 'integer'
            ? value.toLocaleString('pt-br', { minimumFractionDigits: header.precisionText, maximumFractionDigits: header.precisionText })
            : value

          const suffix = header.suffix
            ? ' ' + header.suffix
            : ''

          return prefix + maskedValue + suffix
        }
        const set = ({value}) => {
          if (header.set) {
            const item = this.getItem()
            return (item) ? header.set({ value, item, readonly: this.table.readonly }, formMaster) : ''
          } else {
            return value
          }
        }

        const validation = header.validation
        const warning = header.warning
        
        const editable = ({item}) => {
          if (typeof header.editable == 'function') {
            return header.editable({item, formMaster})
          } else {
            return header.editable
          }
        }
        return new Cell({ value, name, set, mask, editable, row: this, header, validation, warning, x: idx, y: line })
      })
    } else if (item) {
      /**
       * @TODO
       */
      this.cells = Object.entries(item)
        .reduce((acc, [key, value]) => [...acc, new Cell({ value, name: key, row: this })], [])
    }
    
    // gambiarra para fazer os filtros e busca funcionarem (identificação da chamada do filtro por coluna)
    headers.forEach(h => {
      this[h.value] = h.value
    })
  }

  validate() {
    const formMaster = this.table.getFormMaster()
    const validated = this.cells.every(cell => cell.validate(formMaster))
    // this.validated = validated
    return validated
  }

  validated() {
    return this.cells.every(cell => cell.validated)
  }
 
  warned() {
    return this.cells.some(cell => cell.warned)
  }

  getCellFromHeader(header) {
    return this.cells[this.table.headersHashMap[header]]
  }

  getValueFromFormMaster(value) {
    const formMaster = this.table.getFormMaster()
    return formMaster[value]
  }

  async process({ cell, value }) {
    cell.setValue({value})

    if (!cell.validated)
      return

    const header = cell.header

    // Array com os dependentes diretos do header alterado.
    // Eliminando a possibilidade do elemento ser dependente dele mesmo na montagem da chain
    let ix = 0
    const headers = this.table.headers.filter(h => h.value != header.value).map(x => {x.ix = ix; ix++; return x})
    const directDependentAttributes = this.getDependentAttributes(header, headers)
    
    // Monta o resto da chain com base nas primeiras dependencias
    let dependencyChain = this.getDependencyChain(directDependentAttributes, headers)

    // Reordena o chain para garantir a ordem de execução com base na ordenação original das colunas
    dependencyChain = dependencyChain.reduce((acc,arr) => [...acc, ...arr.filter(el => !acc.includes(el))], []).sort((a, b) => a.ix - b.ix)
    for (const header of dependencyChain) {
      const cell = this.getCellFromHeader(header.value)
      await this.computeCell(cell)
    }
  }

   /**
   * Retorna um array de headers que dependem *diretamente* do `header` passado no primeiro parâmetro
   */
   getDependentAttributes (header, headers) {
    return headers.filter(h => h.dependents && h.dependents.includes(header.value))
  }

  /**
   * Retorna uma cadeia de headers que são dependentes dos headers passados no primeiro argumento.
   * A ordem dos elementos no array indicam o grau de dependência dos headers.
   */
  getDependencyChain(dependents, headers, dependencyChain = []) {
    if (dependents.length == 0)
      return dependencyChain
    const filteredHeaders = headers.filter(h => !dependents.includes(h))
    const newDependents = dependents
      .map(h => this.getDependentAttributes(h, filteredHeaders))
      .reduce((acc,arr) => [...acc, ...arr.filter(el => !acc.includes(el))], [])

    return this.getDependencyChain(newDependents, filteredHeaders, [...dependencyChain, dependents])
  }

  async computeCell(cell) {
    if (!cell.header || !cell.header.dependents)
      return

    try {
      const item = this.getDependentsItem(cell)
      const computedValue = await cell.header.computed({ item, value: cell.value, readonly: cell.row.table.readonly, ...this.table.registersActions })
      cell.setValue({value: computedValue})
      // console.info('computedCell', cell.header.value, computedValue, {cell, item})
    } catch (err) {
      console.error(err)      
    }
  }

  getDependentsItem(cell) {
    return cell.header.dependents.reduce((acc, dependent) => {
      const header = this.getCellFromHeader(dependent)
      let value
      if (header) {
        value = header.value
      } else {
        value = this.getValueFromFormMaster(dependent)
      }
      return { ...acc, [dependent]: value }
    }, {})
  }

  /**
   * @TODO Considerar o uso do atributo item em vez de calcular o item com base nas celulas
   */
  getItem() {
    return (this.cells) ? this.cells.reduce((acc, cell) => {
      return { ...acc, [cell.header.value]: cell.value }
    }, {}) : null
  }
}

export class Cell {
  constructor({ value = null, validation = [], warning = [], editable, set = value => value, mask = value => `${value}`, name, row, header, x = -1, y = -1 }) {
    this.validation = validation
    this.warning = warning
    this.validated = true
    this.warned = false
    this.x = x
    this.y = y
    this.mask = mask
    this.set = set
    this.name = name
    this.row = row
    this.header = header
    this.editable = editable({item: row.item})
    this.errorMessages = []
    this.warnMessages = []
    this.setValue({value, set: false, verbose: false })
  }

  setValue({value, set = true, mask = true, verbose = true }) {
    const formMaster = this.row.table.getFormMaster()
    if (value == null) {
      if (verbose)
        // console.warn('"value" is null', this.header, this.row)

      this.text = ""
      this.value = value

      if (this.header.required) {
        this.validated = false
        this.errorMessages.push('Campo obrigatório')
      }
      return
    }
    
    const valueBkp = value
    if(this.header.type == 'select') {
      set = false
      if (isNaN(value)) {
        if (verbose) {
          // console.warn(`${this.header.value} "value (${valueBkp})" is NaN`)
        }
        return
      }
    }
    if(this.header.type == 'text' || this.header.type == 'object'){
      set = false
      value = this.set({ value }, formMaster)
    }

    if (this.header.type == 'integer') {
      value = parseInt(value)
      if (isNaN(value)) {
        if (verbose) {
          // console.warn(`${this.header.value} "value (${valueBkp})" is NaN`)
        }
        return
      }
    } else if (this.header.type == 'float') {
      value = parseFloat(value)
      if (isNaN(value)) {
        if (verbose) {
          // console.warn(`${this.header.value} "value (${valueBkp})" is NaN`)
        }
        return
      }
      value = this.fixPrecision(value, this.header.precisionValue)
    }
    this.value = set ? this.fixPrecision(this.set({ value }, formMaster), this.header.precisionValue) : value
    this.text = mask ? this.mask(this.value) : `${this.value}`
    this.validated = (this.validation && this.validation.length > 0) ? this.validate(formMaster) : true
    this.warned = (this.warning && this.warning.length > 0) ? this.warn(formMaster) : false

    this.row.item[this.name] = this.value
  }

  validate(formMaster) {
    this.errorMessages.splice(0)
    const item = this.row.getItem()
    if (item) {
      return this.validation.every(f => {
        const validated = f({...formMaster,...item})
        if (typeof validated == 'string') {
          this.errorMessages.push(validated)
          this.validated = false
          return false
        } else {
          this.validated = validated
          return validated
        }
      })
    } else {
      return true
    }
  }

  warn(formMaster) {
    this.warnMessages.splice(0)
    const item = this.row.getItem()
    if (item) {
      for (const f of this.warning) {
        const warned = f({...formMaster,...item})
        if (typeof warned == 'string') {
          this.warnMessages.push(warned)
        } 
      }
      return this.warnMessages.length > 0
    } else {
      return false
    }
  }

  fixPrecision(value, precision = 4) {
    if (!Number.isFinite(value)) {
      return NaN
    }
    
    let fixed = value
    if (!Number.isInteger(fixed)) {
      let [integerPart, mantissa] = fixed.toString().split('.')
      
      if (mantissa && mantissa.length > precision) {
        const balanceDigit = parseInt(mantissa[precision])
        if (balanceDigit >= 5) {
          let fix = (parseInt(`${integerPart}${mantissa.slice(0, precision)}`) + 1).toString()
          fix = fix.padStart(integerPart.length+precision, '0')
          integerPart = fix.slice(0, fix.length - precision)
          mantissa = fix.slice(-precision)
        } else {
          mantissa = mantissa.slice(0, precision)
        }
        fixed = parseFloat(integerPart + '.' + mantissa)
      }
    }

    return fixed
  }
}

export default { Table, Row, Cell }