<template>
  <v-card class="red lighten-4">
    <v-toolbar dense flat color="transparent">
      <v-toolbar-title class="body-2 font-weight-bold">
        Existem células com erros de validação
      </v-toolbar-title>
      <v-spacer/>
      <v-btn outlined text @click="expand = !expand">
        Detalhes
        <v-icon right v-text="expand ? 'mdi-chevron-up' : 'mdi-chevron-down'"></v-icon>
      </v-btn>
    </v-toolbar>
    <v-expand-transition>
      <v-card-text v-show="expand" class="pt-0 text--primary">
        <ul class="mb-2">
          <li v-for="(cell, idx, key) in invalidCells" :key="key" >
            <template v-for="(idCell, _, key) in invalidIdentifiers[idx].identifierCells">
              <b :key="key+idx">{{idCell.header.text}}:</b> {{idCell.text}} |
            </template>
            <b>Coluna:</b> {{ cell.header.text }} |
            <template v-if="cell.errorMessages.length > 0">
              <b>Mensagem: </b>
              <span v-for="(error, idx, key) in cell.errorMessages" :key="key" v-html="`${error} - `"></span>
            </template>
            <button type="button" @click="goToCell({cell, filter: 'invalidCells'})"><u>Ir para célula.</u></button>
          </li>
        </ul>
        <span><b>Obs.:</b> É necessária a correção de todos os erros para realizar a gravação.</span>
      </v-card-text>
    </v-expand-transition>
  </v-card>
</template>

<script>
export default {
  props: ['table'],
  data() {
    return {
      expand: true,
    }
  },
  computed: {
    invalidCells() {
      if (!this.table)
        return []
      
      const cells = []
      this.table.rows.forEach(row => {
        row.cells.forEach(cell => {
          if (!cell.validated) {
            cells.push(cell)
          }
        })
      })
      return cells
    },
    identifierHeaders() {
      return this.table && this.table.headers.filter(header => header.identifier) || []
    },
    invalidIdentifiers() {
      return this.invalidCells.map(cell => {
        return {
          cell,
          identifierCells: this.identifierHeaders.reduce((arr, header)=> {
            const found = cell.row.getCellFromHeader(header.value)
            if (found)
              return [...arr, found]
            else
              return arr
          }, [])
        }
      })
    },
  },
  watch: {
    invalidCells() {
      this.$emit('change:invalidCells', this.invalidCells)
    },
  },
  methods: {
    goToCell(cell) {
      return this.$emit('click:goToCell', cell)
    }
  }
}
</script>

<style></style>