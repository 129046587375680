<template>
  <section>
    <v-dialog v-model="dialog" v-if="companySelected" width="550">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          text
          outlined
          height="50"
          dark
          :max-width="$vuetify.breakpoint.lgAndDown ? '800px' : ''"
          class="no-text-transform justify-start overflow-hidden text-left pa-3 ml-5"
        >
          <v-icon size="20" class="mr-2">mdi-cached</v-icon>
          Grupo:
          {{ companySelected.M0_NOMECOM || companySelected.groupCompany }} |
          Empresa: {{ companySelected.M0_CODIGO }} -
          {{ companySelected.M0_NOME }} | Filial:
          {{ companySelected.M0_CODFIL }} - {{ companySelected.M0_FILIAL }}
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="grey darken-4 white--text">
          Seleção de Filial
        </v-card-title>

        <v-card-text>
          <v-select
            v-model="companySelect"
            :items="companiesFiltered"
            return-object
            hide-details
            label="Grupo - Empresa - Filial"
            class="mt-2"
          >
            <template v-slot:prepend-item>
              <v-list-item>
                <v-list-item-content>
                  <v-text-field
                    prepend-icon="mdi-magnify"
                    dense
                    clearable
                    single-line
                    hide-details
                    placeholder="Filtrar..."
                    v-model="filter"
                  >
                  </v-text-field>
                </v-list-item-content>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <v-chip link color="grey darken-3" dark label small class="mr-2"
                ><v-icon small left>mdi-home-group</v-icon>
                {{ item.M0_NOMECOM || item.groupCompany }}</v-chip
              >
              <v-chip
                link
                :color="item.empColor || 'grey darken-2'"
                dark
                label
                small
                class="mr-2"
                ><v-icon small left>mdi-domain</v-icon> {{ item.M0_CODIGO }} -
                {{ item.M0_NOME }}</v-chip
              >
              <v-chip
                link
                :color="item.filColor || 'primary'"
                dark
                label
                small
                class="mr-2"
                ><v-icon x-small left>mdi-home-city-outline</v-icon>
                {{ item.M0_CODFIL }} - {{ item.M0_FILIAL }}</v-chip
              >
            </template>
            <template v-slot:item="{ item }">
              <v-chip link color="grey darken-3" dark label small class="mr-2"
                ><v-icon small left>mdi-home-group</v-icon>
                {{ item.M0_NOMECOM || item.groupCompany }}</v-chip
              >
              <v-chip
                link
                :color="item.empColor || 'grey darken-2'"
                dark
                label
                small
                class="mr-2"
                ><v-icon small left>mdi-domain</v-icon> {{ item.M0_CODIGO }} -
                {{ item.M0_NOME }}</v-chip
              >
              <v-chip
                link
                :color="item.filColor || 'primary'"
                dark
                label
                small
                class="mr-2"
                ><v-icon x-small left>mdi-home-city-outline</v-icon>
                {{ item.M0_CODFIL }} - {{ item.M0_FILIAL }}</v-chip
              >
            </template>
          </v-select>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="dialog = false">Cancelar</v-btn>
          <v-btn
            color="primary"
            text
            @click="
              changeCompany({ $router, company: companySelect, user });
              dialog = false;
            "
            >Confirmar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { createNamespacedHelpers, mapState, mapActions } from "vuex";
const { mapState: authState } = createNamespacedHelpers("auth");
export default {
  name: "SelectCompany",
  data: () => ({
    dialog: false,
    companySelect: {},
    filter: "",
  }),
  watch: {
    dialog(newValue) {
      if (newValue) this.companySelect = this.companySelected;
    },
  },
  computed: {
    ...mapState(["companies", "companySelected"]),
    ...authState(["user"]),

    companiesFiltered() {
      let sigamats = [];
      const companies = Object.keys(this.companies).map(
        (x) => this.companies[x].sigamat
      );

      for (const c of companies) {
        sigamats.push(...c);
      }
      return this.filter
        ? sigamats.filter((sigamat) => {
            let { groupCompany, M0_CODIGO, M0_CODFIL, M0_NOME, M0_FILIAL } =
              sigamat; // alterando, pois é um objeto
            return (groupCompany + M0_CODIGO + M0_CODFIL + M0_NOME + M0_FILIAL)
              .trim()
              .toLowerCase()
              .includes(this.filter.trim().toLowerCase())
              ? sigamat
              : "";
          })
        : sigamats;
    },
  },
  methods: {
    ...mapActions(["changeCompany"]),
  },
};
</script>

<style>
</style>