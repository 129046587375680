<template>
  <v-dialog v-model="dialog" width="25vw">
    <template #activator="{ attrs, on }">
      <v-btn
        text
        x-small
        color="primary"
        :disabled="!table || inputs.length == 0"
        v-on="on"
        v-bind="attrs"
      >
        <v-icon size=22 class="mr-1">mdi-pencil-box-multiple-outline</v-icon>
        Edição em Lote
      </v-btn>
    </template>
    <Form
      v-if="dialog"
      :inputs="inputs"
      @submit="apply"
    >
      <template #prepend>
        <v-card-title>Aplicar alterações em lote nas colunas abaixo</v-card-title>
        <v-divider></v-divider>
      </template>
      <template #append>
        <slot name="append"></slot>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="error" type="button" @click.stop="dialog = false">Cancelar</v-btn>
          <v-btn text color="primary" type="submit">Confirmar</v-btn>
        </v-card-actions>
      </template>
    </Form>
  </v-dialog>
</template>

<script>
import Form from "@/components/Registers/Form"
export default {
  props: ['table', 'headers'],
  components: { Form },
  data() {
    return {
      dialog: false,
    }
  },
  computed: {
    inputs() {
      if (!this.headers)
        return []
      
      const inputs = []
      for (const header of this.headers) {
        // Filtra os headers que não possuem a flag editable e batch ao mesmo tempo.
        if (!(header.editable && header.batch))
          continue

        const input = {
          type: 'text-field',
          label: header.text,
          value: header.value,
          textType: header.type == 'float' || header.type == 'integer' ? 'number' : 'text',
        }
        inputs.push(input)
      }

      return inputs
    }
  },
  methods: {
    apply(item) {
      this.dialog = false
      this.$emit('apply', { item, inputs: this.inputs })
    },
  },
}
</script>

<style></style>