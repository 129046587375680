<template>
  <canvas ref="barChart" />
</template>
<script>
import { Chart, registerables } from "chart.js";
export default {
  name: "BarChart",
  data() {
    return {
      chart: null
    }
  },
  props: ["data", "options"],
  watch: {
    data: {
      handler: function ({values, labels}) {        
        this.updateData({values, labels})
      },
      deep: true,
      immediate: false
    }
  },
  methods: {
    addData(label, data) {
      this.chart.data.labels.push(label);
      this.chart.data.datasets.forEach((dataset) => {
        dataset.data.push(data);
      });
      this.chart.update();
    },
    removeData() {
      this.chart.data.labels.pop();
      this.chart.data.datasets.forEach((dataset) => {
        dataset.data.pop();
      });
      this.chart.update();
    },
    updateData({values, labels}) {

      this.chart.data.datasets = []
      this.chart.data.labels = labels
      
      values.forEach(el => {
          const {label, backgroundColor, values} = el;
          if (values && values.length > 0) {
            this.chart.data.datasets.push({
              label: label,
              data: values,          
              backgroundColor: [backgroundColor || `rgba(0, ${Math.floor(Math.random()*255)}, ${Math.floor(Math.random()*255)}, 0.5)`],
              borderColor: ['rgb(239, 235, 233)'],
              borderWidth: 1,
              borderRadius: 5  
            })
          }
        });

      this.chart.update();
    },
    renderChart() {
      let ctx = this.$refs.barChart.getContext("2d");
      this.chart = new Chart(ctx, {
        type: "bar",
        options: {
          plugins: {
          legend: {
              display: true
            }
          },
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        },
      });
    },
  },
  created() {
    Chart.register(...registerables)
  },
  mounted() {
    this.renderChart();
    if (this.data && this.data.values) this.updateData(this.data)
  },
};
</script>