<template>
  <section id="expirationPass" class="w-100 h-100">
    <Background />

    <!-- Alerta de recuperação de senha -->
    <v-alert :value="recoveryAlert.value" :type="recoveryAlert.type" :icon="recoveryAlert.icon" dismissible transition="scale-transition" class="recoveryAlert">
      {{ recoveryAlert.content }}
    </v-alert>

    <!-- Alerta de recuperação de senha -->
    <v-alert 
      :value="alert.value" 
      :type="alert.type" 
      :icon="alert.icon" 
      dismissible 
      transition="scale-transition" 
      class="recoveryAlert">
      {{ alert.content }}
    </v-alert>

    <v-container fill-height>
      <v-row no-gutters class="justify-center">
        <v-col cols="10" sm="6" md="4" xl="3">
          <v-card elevation="5 card-login">
            <v-card-title>
              <span class="info--text font-weight-bold text-small">Trocar Senha</span>
            </v-card-title>
            <v-card-subtitle v-if="$route.meta.forcePass || $route.meta.expirationPass">
              <span class="font-weight-bold text-small">
                {{ computedMsgChange }}
              </span>
            </v-card-subtitle>
            <v-card-text>
              <v-form ref="form" v-model="valid" lazy-validation @submit.prevent>
                <v-text-field 
                  prepend-inner-icon="mdi-key" 
                  v-model="password" 
                  :rules="rules.password" 
                  label="Senha Atual" 
                  :type="showPassword ? 'text' : 'password'" 
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" 
                  @click:append="showPassword = !showPassword" required class="mt-0 pt-0" />

                <v-text-field 
                  prepend-inner-icon="mdi-key" 
                  v-model="newPassword" 
                  :rules="rules.password" 
                  label="Nova Senha" 
                  :type="showNewPassword ? 'text' : 'password'" 
                  :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'" 
                  @click:append="showNewPassword = !showNewPassword" 
                  required 
                  class="mt-0 pt-0" />

                <v-text-field prepend-inner-icon="mdi-key" v-model="confirmPassword" :rules="rules.password" label="Repetir Senha" :type="showNewPassword ? 'text' : 'password'" :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'" @click:append="showNewPassword = !showNewPassword" @keyup.enter="changePassoword" required class="mt-0 pt-0" />

                <v-row dense no-gutters>
                  <v-spacer></v-spacer>
                  <v-dialog width="500">
                    <template v-slot:activator="{on, attrs}">
                      <v-btn style="font-size: 0.6em" class="px-0" small plain v-bind="attrs" v-on="on">
                        Esqueci a senha
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title class="px-4 pb-0">
                        <span class="error--text font-weight-bold text-small">Recupere sua senha</span>
                      </v-card-title>
                      <div class="p-20">
                        <v-card-text>
                          <p>Nos informe seu email de acesso para que possamos enviar um email de recuperação de senha</p>

                          <v-text-field prepend-inner-icon="mdi-email" v-model="recoveryEmail" :rules="rules.email" label="E-mail" @keyup.enter="sendRecoveryMail" required class="mt-0 pt-0" />
                        </v-card-text>
                      </div>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn class="rounded-lg" block color="error" @click="sendRecoveryMail">
                          Enviar email de recuperação
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-row>

                <v-row no-gutters align-center>
                  <v-btn class="rounded-lg" block :disabled="!valid" color="info" @click="changePassword">
                    Trocar senha
                  </v-btn>
                  <v-col cols="12" lg="6">
                    <v-alert :value="res.value" :type="res.type" :icon="res.icon" text dense dismissible transition="scale-transition" class="ma-0 mr-lg-1 wp-preline">{{ res.content }}</v-alert>
                  </v-col>
                </v-row>

                <v-row no-gutters class="px-6 mt-6" align-center>
                  <v-col>
                    <v-btn class="rounded-lg" block color="error" @click="cancel">
                      Cancelar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
  import Background from "@/components/Auth/Background.vue";
  import {createNamespacedHelpers, mapMutations} from "vuex";
  const { mapActions: authActions, mapState: authState } = createNamespacedHelpers("auth");
  const { mapActions:registersActions } = createNamespacedHelpers('registers')
  export default {
    name: "ExpirationPass",
    components: {Background},
    data: () => ({
      keepConnected: false,
      recoveryAlert: {
        value: false,
        type: "info",
        icon: null,
        content: "",
      },
      alert: {
        value: false,
        type: "info",
        icon: null,
        content: "",
      },
      tab: null,
      logging: false,
      valid: true,
      recoveryEmail: "",
      password: "",
      newPassword: "",
      confirmPassword: "",
      res: {
        value: false,
        type: "info",
        icon: null,
        content: "",
      },
      rules: {
        password: [(v) => !!v || "Informe a senha"],
        confirmPassword: [(v) => !!v || "Informe a senha"],
      },
      showPassword: false,
      showNewPassword: false,
      msgChange: ""
    }),
    computed: {
      ...authState(['user']),
      computedMsgChange() {
        return this.msgChange
      }
    },
    methods: {
      ...mapMutations(["setLoading"]),
      ...registersActions(['save']),
      ...authActions(["signIn", "signOut","recoverUserPassword"]),

      samePass() {
        if (this.newPassword !== this.confirmPassword) {
          this.alert = {
              value: true,
              type: "error",
              icon: "mdi-alert-outline",
              content: "As novas senhas são divergentes!",
            };
          return false
        }
        return true
      },

      strongPass() {
        const pattern=/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
        if (!pattern.test(this.newPassword)) {
          this.alert = {
              value: true,
              type: "error",
              icon: "mdi-alert-outline",
              content: "A senha é muito fraca! Utilize ao menos 8 caracteres e, dentre eles, um caracter maiúsculo, um minúsculo, um caracter especial e um numeral!",
            };
          return false
        }
        return true
      },

      async changePassword () {
        if (this.$refs.form.validate() && this.samePass() & this.strongPass()) {
          let user = {...this.user, password: this.password}

          try {
            this.setLoading({stats: true, message: "validando senha"});
            const res = await this.signIn({ user , sessionType: this.$auth.Auth.Persistence.SESSION})
            if (!res.error) { ({
                value: true,
                type: "success",
                icon: "mdi-check-circle-outline",
                content: "Login efetuado!",
              });

              const passChangedAt = this.$db.Timestamp.now();

              user = {
                ...this.user, 
                password: this.newPassword, 
                confirmPassword: this.confirmPassword, 
                passChangedAt, 
                forceChangePass: false 
              }
              const response = await this.save({
                collection: 'users',
                item: user
              })

              console.info('response', response)
              if (response.statusText === 'OK' || !response.error) {
                this.alert = {
                  value: true,
                  type: "success",
                  icon: "mdi-check-circle-outline",
                  content: "Senha alterada com sucesso! Redirecionando à tela de login",
                }
                setTimeout(() =>{
                  if (this.$route.meta.forcePass || this.$route.meta.expirationPass) {
                    this.signOut()
                  } else {
                    this.$router.go(-1)
                  }
                }, 500)
              }
            } else {
              this.alert = {
                value: true,
                type: "error",
                icon: "mdi-alert-outline",
                content: "Erro ao trocar a senha!",
              };
            }
            this.setLoading({stats: false});
          } catch (err) {
            console.error(err);
            this.res = {
              value: true,
              type: "error",
              icon: "mdi-alert-outline",
              content: "Erro interno, favor contactar o administrador.",
            };
            this.setLoading({stats: false}); 
          }
        }
      },

      sendRecoveryMail() {
        this.recoverUserPassword({email: this.recoveryEmail})
          .then((res) => {
            this.recoveryAlert = {
              value: true,
              type: "success",
              icon: "mdi-check-circle-outline",
              content: "Email enviado com sucesso!",
            };
            this.setLoading({stats: false});
          })
          .catch((err) => {
            console.error(err);
            this.recoveryAlert = {
              value: true,
              type: "error",
              icon: "mdi-alert-outline",
              content: "Erro interno, favor contactar o administrador.",
            };
            this.setLoading({stats: false});
          });
      },

      cancel() {
        if (this.$route.meta.forcePass || this.$route.meta.expirationPass) {
          this.signOut()
        } else {
          this.$router.go(-1)
        }
      },
    },
    created() {
      if (this.$route.meta.forcePass) {
        this.msgChange = 'Por favor, para sua segurança, realize a troca de sua senha...'
      } else if (this.$route.meta.expirationPass) {
        this.msgChange = 'A sua senha expirou. Faovr, realizar a troca da mesma...'

      }
      this.setLoading({stats: false});
    },
  };
</script>

<style lang="scss">
  .recoveryAlert {
    position: absolute;
    right: 16px;
    top: 16px;
    margin: 0 auto; // Without this the box extends the width of the page
  }
  .card-login {
    opacity: 0.9;
  }
</style>
