<template>
  <v-dialog
    v-model="dialog"
    width="500"
  >
    <template #activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" height=40 x-small outlined :color="color" :class="classes">
        <slot name='buttonContent'/>
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="headline grey lighten-3">
        <slot name='title'/>
      </v-card-title>

      <v-card-text class="pa-6">
        <slot name='content'/>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          text
          outlined
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
          Fechar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'Toolbar',
  props: {
    color: {
      type: String,
      default: 'primary'
    },
    classes: {
      type: String,
      default: 'mr-2 text-caption'
    },
  },
  data() {
    return {
      dialog: false
    }
  },
}
</script>

<style>

</style>