<script>
import List from "@/views/Registers/List"
export default {
  name: 'IntegrationsList',
  extends: List,
  computed: {
    computedHeaders() {
      return [
        { type: 'switch', text: 'Ativo', value: 'active', default: true, sm: 4, md: 2 },
        { type: 'text-field', text: 'Nome', value: 'name', sm: 4 },
        { type: 'text-field', text: 'Coleção', value: 'collection', sm: 4 },
        { type: 'text-field', textType: 'number', text: 'Menu Ordem', value: 'menuOrder', sm: 4 },
      ]
    }
  }
}
</script>

<style>

</style>