<template>
  <v-toolbar
    class="pl-0"
    color="transparent"
    flat
  >
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-on="on"
          @click="drawer = !drawer"
          v-bind="attrs"
          class="mr-4"
          color="grey darken-2"
          small
          text
          fab
          >
          <v-icon medium>{{ drawer ? 'mdi-arrow-left-drop-circle-outline' : 'mdi-arrow-right-drop-circle-outline' }}</v-icon>
        </v-btn>
      </template>
      <span>{{ drawer ? 'Recolher' : 'Expandir' }} menu lateral</span>
    </v-tooltip>
    <v-icon v-if="$route.matched && $route.matched[0] && $route.matched[0].meta" class='mr-2'>{{$route.matched[0].meta.icon }}</v-icon>
    <v-toolbar-title>{{$route.meta.title}}</v-toolbar-title>

    <v-spacer></v-spacer>

    <slot name='actions'/>
  </v-toolbar>
</template>

<script>
export default {
  name: 'Toolbar',
  props: {
  },
  computed: {
    drawer: {
      get(){
        return this.$store.state.drawer
      },
      set(val){
        this.$store.commit('setDrawer', val)
      } 
    },
  }
}
</script>

<style>

</style>