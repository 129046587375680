<template>
  <section class="w-100 fill-height">
    <Toolbar/>
    
    <v-row align="center" justify="center">
      <!-- Filters / Actions -->
      <v-col cols=12>
        <v-card>
          <v-card-text class="pb-2">
            <v-row align="center" justify="center">
              <v-col cols=2>
                <!-- Period -->
                <v-select
                  v-model="daysBackSelected"
                  :items="daysToback"        
                  hide-details  
                  label="Visualizar últimos"
                  dense>
                </v-select>
              </v-col>
              <v-col cols=2>
                <!-- Sallers -->
                <v-autocomplete
                  v-model="sallersSelected"          
                  :items="this.sallersComputed"
                  label="Filtrar Vendedores"
                  item-text="displayName"
                  item-value="id"
                  chips
                  placeholder="Selecione os Vendedores"
                  small-chips
                  hide-details
                  deletableChips
                  multiple
                  cache-items
                  dense
                >
                  <template v-slot:selection="data">
                    <v-chip 
                      v-if="data.index < formChips"
                      :key="JSON.stringify(data.item)"
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      :disabled="data.disabled"
                      small
                      close
                      color="teal accent-4"
                      dark
                      @click:close="data.parent.selectItem(data.item)"
                    >
                      <span>{{ data.item.displayName }}</span>
                    </v-chip>
                    <span
                      v-if="data.index == formChips"
                      class="grey--text caption"
                    >(+{{ sallersSelected.length - formChips }} outro(s))</span>
                  </template>
                </v-autocomplete>   
              </v-col>
              <v-col cols=2>
                <!-- Departamentos -->
                <v-autocomplete
                  v-model="segmentsSelected"          
                  :items="this.segmentsComputed"
                  label="Filtrar Segmentos"
                  item-text="displayName"
                  item-value="id"
                  chips
                  placeholder="Selecione os Segmentos"
                  small-chips
                  deletableChips
                  hide-details
                  multiple
                  cache-items
                  dense
                >
                  <template v-slot:selection="data">
                    <v-chip 
                      v-if="data.index < formChips"
                      :key="JSON.stringify(data.item)"
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      :disabled="data.disabled"
                      small
                      close
                      color="teal accent-4"
                      dark
                      @click:close="data.parent.selectItem(data.item)"
                    >
                      <span>{{ data.item.displayName }}</span>
                    </v-chip>
                    <span
                      v-if="data.index == formChips"
                      class="grey--text caption"
                    >(+{{ segmentsSelected.length - formChips }} outro(s))</span>
                  </template>
                </v-autocomplete>  
              </v-col>
              <v-col cols=2>
                <!-- Cols -->
                <v-slider
                  v-model="chartsPerLine"
                  :max="4"
                  :min="1"
                  thumb-label="always"
                  thumb-size=20
                  step="1"
                  hide-details
                  class="mt-2"
                >
                  <template v-slot:label>
                    <span class="text-caption">Colunas</span>
                  </template>
                </v-slider>
              </v-col>
              <v-col cols=3>
                <!-- Tipo -->
                <v-radio-group
                  v-model="typeChart"
                  dense
                  hide-details
                  row
                  class="ma-0"
                >
                  <template v-slot:label>
                    <span class="text-caption">Tipo do gráfico</span>
                  </template>
                  <v-radio value="bar"><template v-slot:label><span class="text-caption">Barra</span></template></v-radio>
                  <v-radio value="line"><template v-slot:label><span class="text-caption">Linha</span></template></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols=1>
                <!-- Exportar -->
                <v-dialog
                  v-model="dialog"
                  persistent
                  scrollable
                  max-width="500"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      rounded
                      x-small
                      color="primary"
                      class="ml-auto d-block"
                    >
                      <v-icon x-small>mdi-download</v-icon>
                      Exportar
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="teal accent-4 px-4 py-3">
                      <v-icon dark class="mr-2">mdi-download</v-icon>
                      <span class="headline white--text">Exportar Dados</span>
                    </v-card-title>
                    <v-card-text class="pb-0">
                      <v-form
                        ref="form"
                        v-model="valid"
                        lazy-validation
                      >
                        <v-container class="pb-0">
                          <v-row>
                            <v-col cols=12>
                              <v-menu
                                v-model="menu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    :value="exportDateRangeText"
                                    dense
                                    label="Período"
                                    hint="Selecione duas datas"
                                    persistent-hint
                                    prepend-icon="mdi-calendar"
                                    :rules="[v => (!!v && v.split('~').length == 2) || 'Selecione o período']"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="exportDate.range"
                                  @change="menu = false"
                                  range
                                ></v-date-picker>
                              </v-menu>
                            </v-col>
                            <v-col cols=12>
                              <v-select
                                v-model="exportDate.type"
                                dense
                                :items="exportDateTypes"
                                :rules="[v => !!v || 'Selecione o tipo do arquivo']"
                                label="Tipo do Arquivo"
                              ></v-select>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-form>
                      <v-alert v-if="user" dense border="left" type="info">
                        O arquivo gerado será enviado por email para <strong>{{user.email}}</strong> 
                        <br/>
                        Isso pode levar alguns minutos a depender do período selecionado
                      </v-alert>
                      <v-alert dense border="left" type="info">Os campos de data estão em fuso horário <strong>UTC</strong></v-alert>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn text @click="closeExportForm()">Cancelar</v-btn>
                      <v-btn color="teal" text @click="exportFullData()">Confirmar</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- KPIs -->
      <v-col :cols="12" class="mt-4 mb-2">
        <v-row>
          <!-- KPIS -->
          <v-col cols=6 class="py-0">
            <v-row>
              <v-col cols=6>
                <KPIs title="Faturamento" icon="mdi-clock-outline" :kpi="kpiFaturamento" :values="kpiFaturamentoValues"/>
              </v-col>
              <v-col cols=6>
                <KPIs title="Descontos" icon="mdi-clock-outline" :kpi="kpiDesconto" :values="kpiDescontoValues" color="error darken-1"/>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols=6 class="py-0">
            <v-row no-gutters>
              <v-col cols=4>
                <KPIs title="Melhor Segmento" icon="mdi-source-branch" :kpi="kpiBestSegment" :footer="kpiBestSegmentFooter" color="indigo darken-1"/>
              </v-col>
              <v-col cols=4>
                <KPIs title="Melhor Vendedor" icon="mdi-account-tie-outline" :kpi="kpiBestSaller" :footer="kpiBestSallerFooter" color="cyan darken-1"/>
              </v-col>
              <v-col cols=4>
                <KPIs title="Meta" icon="mdi-clock-outline" progressCircular kpi="65" footer="Trimestre" footerIcon="mdi-clock-outline" color="teal darken-3"/>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- Charts -->
    <v-row>
      <v-col cols=3>
        <v-row>
          <template v-for="{title, data, show = true, type} in pieCharts">
            <v-col v-if="show" :key="title" :cols="12">
              <v-card>
                <v-card-title>
                  {{title}}
                  <v-spacer></v-spacer>
                  <v-tooltip top> 
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        small
                        v-bind="attrs" 
                        v-on="on"
                        color="primary"
                        class="mt-2 ml-auto d-block"
                        @click="exportData({title, data})"
                      >
                        <v-icon small>mdi-download</v-icon>
                      </v-btn>  
                    </template>
                    Exportar dados
                  </v-tooltip>
                </v-card-title>
                <v-card-text class="py-4 pb-8">
                  <component v-if="type" v-bind:is="`${type}Chart`" :ref="`${type}Chart`" :data="data"></component>       
                  <BarChart ref="barcharts" v-else-if="typeChart === 'bar'" :data="data"/>            
                  <LineChart ref="linecharts" v-else-if="typeChart === 'line'" :data="data"/>             
                </v-card-text>
              </v-card>
            </v-col>      
          </template>
        </v-row>
      </v-col>
      <v-col cols=9>
       <v-row> 
          <template v-for="{title, data, show = true, type} in charts">
            <v-col v-if="show" :key="title" :cols="12/chartsPerLine">
              <v-card>
                <v-card-title>
                  {{title}}
                  <v-spacer></v-spacer>
                  <v-tooltip top> 
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        small
                        v-bind="attrs" 
                        v-on="on"
                        color="primary"
                        class="mt-2 ml-auto d-block"
                        @click="exportData({title, data})"
                      >
                        <v-icon small>mdi-download</v-icon>
                      </v-btn>  
                    </template>
                    Exportar dados
                  </v-tooltip>
                </v-card-title>
                <v-card-text>
                  <component v-if="type" v-bind:is="`${type}Chart`" :ref="`${type}Chart`" :data="data"></component>       
                  <BarChart ref="barcharts" v-else-if="typeChart === 'bar'" :data="data"/>            
                  <LineChart ref="linecharts" v-else-if="typeChart === 'line'" :data="data"/>             
                </v-card-text>
              </v-card>
            </v-col>      
          </template>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="5">
        <v-card>
          <v-card-text>
            <GaugeChart />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import Toolbar from "@/components/Templates/Toolbar"
import BarChart from "@/components/Charts/BarChart"
import LineChart from "@/components/Charts/LineChart"
import DoughnutChart from "@/components/Charts/DoughnutChart"
import KPIs from "@/components/Charts/KPIs"
import GaugeChart from "@/components/Charts/Gauge"
import { Parser } from 'json2csv';
import { createNamespacedHelpers } from 'vuex'
const { mapState:authState} = createNamespacedHelpers('auth')
export default {
  name: "Dashboard",
  components: { Toolbar, KPIs, BarChart, LineChart, DoughnutChart, GaugeChart },
  data () {    
    return {
      typeChart: 'bar',
      chartsPerLine: 2,
      daysBack: 7,
      daysBackSelected: '7 dias',
      daysToback: ['7 dias', '15 dias', '30 dias', '60 dias', '90 dias'],
      sallersSelected: [],
      segmentsSelected: [],
      kpis: {
        countRooms: 0,
        countContacts: 0
      },
      valid: true,
      dialog: false,
      menu: false,
      exportDateTypes: ['csv', 'json'],
      exportDate: {
        type: 'csv',
        range: []
      },
      valuesPedidosVendedor: {},
      valuesPedidosSegmento: {},
      valuesDescontosVendedor: {},
      valuesDescontosSegmento: {},
    }
  },
  computed: {
    ...authState(['user']),    
    exportDateRangeText () {
      return this.exportDate.range.map(x => this.$moment(x).format("DD/MM/YYYY")).join(' ~ ')
    },
    formChips() { return 3 },
    sallersComputed() {
      let array = Array(5).fill({})
      array = array.map((x, ix) => {
        return  {
          id: Math.floor(Math.random()*255),
          backgroundColor: `rgba(0, ${Math.floor(Math.random()*255)}, ${Math.floor(Math.random()*255)}, 0.5)`,
          displayName: 'Vendedor '+(ix+1)
        }
      })
      array[0].displayName = 'Waldir Nogueira'
      array[1].displayName = 'Roberta Freitas'
      array[2].displayName = 'João Augusto'
      array[3].displayName = 'Perícles Souza'
      array[4].displayName = 'Cleber Texeira'
      return array
    },
    segmentsComputed() {
      let array = Array(10).fill({})
      array = array.map((x, ix) => {
        return  {
          id: Math.floor(Math.random()*255),
          backgroundColor: `rgba(0, ${Math.floor(Math.random()*255)}, ${Math.floor(Math.random()*255)}, 0.5)`,
          displayName: 'Segmento '+(ix+1)
        }
      })
      array[0].displayName = 'Segurança'
      array[1].displayName = 'Transporte'
      array[2].displayName = 'Energia'
      array[3].displayName = 'Manufaturados'
      array[4].displayName = 'Comunicações'
      array[5].displayName = 'Medicina'
      array[6].displayName = 'Esporte'
      array[7].displayName = 'Representação'
      array[8].displayName = 'Exportação'
      array[9].displayName = 'Agricultura'
      return array
    },
    pieCharts() {
      return [
        {
          title: 'Top 5 Segmentos',
          data: this.top5Segments,
          type: 'doughnut'
        },
        {
          title: 'Top 3 Vendedore',
          data: this.top3Sallers,
          type: 'doughnut'
        },
      ]
    },
    charts() {
      return [
        {
          title: 'Pedidos por Vendedor',
          data: this.pedidosVendedor
        },
        {
          title: 'Pedidos por Segmento',
          data: this.pedidosSegmento
        },
        {
          title: 'Desconto por Vendedor',
          data: this.descontosVendedor
        },
        {
          title: 'Desconto por Segmento',
          data: this.descontosSegmento
        },
      ]
    },
    pedidosVendedor() { return this.filterValues({values: this.valuesPedidosVendedor, filter: 'sallers'})},
    pedidosSegmento() { return this.filterValues({values: this.valuesPedidosSegmento, filter: 'segments'})},
    descontosVendedor() { return this.filterValues({values: this.valuesDescontosVendedor, filter: 'sallers'})},
    descontosSegmento() { return this.filterValues({values: this.valuesDescontosSegmento, filter: 'segments'})},
    top5Segments() {
      let segmentsValues = this.$_.cloneDeep(this.valuesPedidosSegmento.values) 
      segmentsValues = segmentsValues.map(x => {
        x.total = x.values.reduce((accum, curr) => accum + curr)
        return x
      })
      segmentsValues = segmentsValues.sort((a,b) => b.total - a.total).slice(0,5)
      const backgroundColor = segmentsValues.map(x => x.backgroundColor)
      const labels = segmentsValues.map(x => x.label)
      const values = segmentsValues.map(x => x.total)
      let score = values.reduce((accum, curr) => accum + curr).toFixed(2)
      score = score.toString().length > 9 ? (score/1000000).toFixed(2)+" mi" : (score/1000).toFixed(2)+" mil"
      return {labels, values, backgroundColor, score}
    },
    top3Sallers() {
      let sallersValues = this.$_.cloneDeep(this.valuesPedidosVendedor.values) 
      sallersValues = sallersValues.map(x => {
        x.total = x.values.reduce((accum, curr) => accum + curr)
        return x
      })
      sallersValues = sallersValues.sort((a,b) => b.total - a.total).slice(0,3)
      const backgroundColor = sallersValues.map(x => x.backgroundColor)
      const labels = sallersValues.map(x => x.label)
      const values = sallersValues.map(x => x.total)
      let score = values.reduce((accum, curr) => accum + curr).toFixed(2)
      score = score.toString().length > 9 ? (score/1000000).toFixed(2)+" mi" : (score/1000).toFixed(2)+" mil"
      return {labels, values, backgroundColor, score}
    },
    kpiFaturamentoValues() {
      let labels = []
      let values = []
      let content = this.pedidosSegmento
      if (this.sallersSelected.length  > 0) content = this.pedidosVendedor
      if (content.labels && content.labels.length > 0) {
        content.labels.forEach((el, ix) => {
          labels.push(el.substr(-2))
          let value = 0
          for (const el2 of content.values) {
            value += el2.values[ix]
          }
          values.push(value)
        });
      }
      return {
        labels, 
        values
      }
    },
    kpiFaturamento() {
      let content = this.kpiFaturamentoValues
      let ret = content.values.reduce((sum, val) => sum + val).toFixed(2)
      ret = ret.toString().length > 9 ? (ret/1000000).toFixed(2)+" mi" : (ret/1000).toFixed(2)+" mil"
      return ret
    },
    kpiDescontoValues() {
      let labels = []
      let values = []
      let content = this.descontosSegmento
      if (this.sallersSelected.length  > 0) content = this.descontosVendedor
      if (content.labels && content.labels.length > 0) {
        content.labels.forEach((el, ix) => {
          labels.push(el.substr(-2))
          let value = 0
          for (const el2 of content.values) {
            value += el2.values[ix]
          }
          values.push(value)
        });
      }
      return {
        labels, 
        values
      }
    },
    kpiDesconto() {
      let content = this.kpiDescontoValues
      let ret = content.values.reduce((sum, val) => sum + val).toFixed(2)
      ret = ret.toString().length > 9 ? (ret/1000000).toFixed(2)+" mi" : (ret/1000).toFixed(2)+" mil"
      return ret
    },
    kpiBestSegment() {
      let ret = this.top5Segments.values[0].toFixed(2)
      ret = ret.toString().length > 9 ? (ret/1000000).toFixed(2)+" mi" : (ret/1000).toFixed(2)+" mil"
      return ret
    },
    kpiBestSegmentFooter() {
      let ret = this.top5Segments.labels[0]
      return ret
    },
    kpiBestSaller() {
      let ret = this.top3Sallers.values[0].toFixed(2)
      ret = ret.toString().length > 9 ? (ret/1000000).toFixed(2)+" mi" : (ret/1000).toFixed(2)+" mil"
      return ret
    },
    kpiBestSallerFooter() {
      let ret = this.top3Sallers.labels[0]
      return ret
    },
  },
  watch: {
    sallersSelected: {
      handler: async function (val) {
        // Evita sobreposição de dados
        if (val && val.length > 0) this.segmentsSelected = [] 
      }
    },
    segmentsSelected: {
      handler: async function (val) {
        // Evita sobreposição de dados
        if (val && val.length > 0) this.sallersSelected = []
      }
    },
    daysBackSelected: {
      handler: async function () {
        this.daysBack = Number(this.daysBackSelected.replace('dias',''))        
        this.update()        
      }
    },
  },
  methods: {
    closeExportForm () {
      this.$refs.form.resetValidation()
      this.exportDate.range = []
      this.dialog = false
    }, 
  
    filterValues({values, filter}) {
      const ret = this.$_.cloneDeep(values)     
      if (this[`${filter}Selected`].length  > 0 ) {        
        if (ret.values && ret.values.length > 0) {        
          ret.values = ret.values.filter(el => this[`${filter}Selected`].findIndex( f=> f == el.id) > -1 )        
        }      
      }      
      return ret
    }, 

    getValues({filter, min = 1000, max = 9999}) {
      const startOfDay = new Date()
      startOfDay.setDate(startOfDay.getDate() - this.daysBack)
      startOfDay.setHours(0, 0, 0, 0);
      const labels = []   
      const values = []        
      const dayOff = new Date()
      dayOff.setDate(dayOff.getDate()-1)
      while (startOfDay <= dayOff) {   
        labels.push(startOfDay.toISOString().substring(0, 10))
        startOfDay.setDate(startOfDay.getDate() + 1)      
      }  
      this[`${filter}Computed`].forEach(x => {        
        let data = labels.map(el => parseFloat((Math.random() * (max - min + 1)).toFixed(2)))       
        values.push({label: x.displayName, backgroundColor: x.backgroundColor, id: x.id, values: data})
      })
      return {values, labels}     
    },

    update() {
      this.valuesPedidosVendedor = this.getValues({filter: 'sallers'})
      this.valuesPedidosSegmento = this.getValues({filter: 'segments'})
      this.valuesDescontosVendedor = this.getValues({filter: 'sallers', min: 100, max: 999})
      this.valuesDescontosSegmento = this.getValues({filter: 'segments', min: 100, max: 999})
    },

    exportData({title, data}) {
      const exportData = data.labels.map(x => ({Data: x}))
      for (const col of data.values) {
        for (const [ix, value] of col.values.entries()) {
          exportData[ix][col.label] = (typeof value == 'number') ? value.toLocaleString() : value
        }
      }
      const parser = new Parser();
      const csv = parser.parse(exportData);
      const blob = new Blob([csv], { type: 'csv' })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = `${window.location.hostname.replace('.ufsales.com.br', '')}-${title} (${this.daysBackSelect}).csv`
      link.click()
      URL.revokeObjectURL(link.href)
    }, 
  },
  created() {
    this.update()    
  }  
}
</script>

<style scoped  lang="scss">
</style>