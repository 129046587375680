<template>
  <section v-if="values.values">
    <v-card class="mx-auto mt-4">
      <v-sheet
        :color="color"
        elevation="12"
        rounded
        max-width="calc(100% - 32px)"
        class="v-sheet--offset mx-auto"
      >
        <v-sparkline
          :labels="(values.labels && values.labels.length > 0 && values.labels.length <= 30) ? values.labels : []"
          :value="values.values"
          color="white"
          line-width="3"
          padding="16"
          stroke-linecap="round"
          smooth
          auto-draw
        ></v-sparkline>
      </v-sheet>

      <v-card-subtitle class="pt-0 pb-0 mb-n1 font-weight-bold">{{title}}</v-card-subtitle>
      <v-card-title class="pt-0 pb-2 text-h4">
        <v-icon v-if="icon" :color="color" class="mr-2">{{icon}}</v-icon>
        {{kpi}}
      </v-card-title>
      <v-card-text v-if="footer" class="pt-0">
        <v-divider class="my-2"></v-divider>
        <v-icon v-if="footerIcon" class="mr-2" small>{{footerIcon}}</v-icon>
        <span class="text-caption grey--text font-weight-light">{{footer}}</span>
      </v-card-text>
    </v-card>
  </section>
  <section v-else>
    <v-sheet
      :color="color"
      elevation="12"
      rounded
      max-width="calc(100% - 32px)"
      class="v-sheet--offset mx-auto mt-4 py-3 mb-n2 text-center"
    >
      <template v-if="dense">
        <v-card-subtitle class="text-subtitle-1 font-weight-bold text-center pa-0 ma-0">
          <v-icon v-if="icon && !progressCircular" small>{{icon}}</v-icon>
          {{title}}
        </v-card-subtitle>
        <span class="text-subtitle-2 text-center d-block pb-2 white--text">{{footer}}</span>

        <v-progress-circular
          v-if="progressCircular"
          :rotate="360"
          :size="100"
          :width="5"
          :value="kpi"
          color="white"
          class="mx-auto my-2 mb-4"
        >
          <span class="text-h5">{{ kpi }}%</span>
        </v-progress-circular>
        
        <v-card-title v-else :class="`pa-0 ma-0 text-h5 text-center d-block ${colorText}--text`">
          {{kpi}}
        </v-card-title>
      </template>
      <template v-else>
        <v-icon v-if="icon && !progressCircular" x-large class="pt-4">{{icon}}</v-icon>
        <v-card-subtitle class="text-subtitle-1 font-weight-bold text-center pt-2 pb-0">{{title}}</v-card-subtitle>
        <span class="text-subtitle-2 text-center d-block pb-2 white--text">{{footer}}</span>

        <v-progress-circular
          v-if="progressCircular"
          :rotate="360"
          :size="100"
          :width="5"
          :value="kpi"
          color="white"
          class="mx-auto my-2 mb-4"
        >
          <span class="text-h5">{{ kpi }}%</span>
        </v-progress-circular>
        
        <v-card-title v-else :class="`pt-2 pb-4 text-h4 text-center d-block ${colorText}--text`">
          {{kpi}}
        </v-card-title>
      </template>
    </v-sheet> 
  </section>
</template>

<script>
export default {
  name: 'KPIs',
  props: {
    title: String,
    footer: String,
    footerIcon: String,
    icon: String,
    kpi: String,
    progressCircular: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'primary darken-3'
    },
    colorText: {
      type: String,
      default: 'white'
    },
    dense: {
      type: Boolean,
      default: false
    },
    values: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data: () => ({
  }),
}
</script>

<style>
  .v-sheet--offset {
    top: -24px;
    position: relative;
  }
</style>