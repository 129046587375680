import axios from 'axios'
import store from '../store/index'
import Vue from 'vue'
import {baseApiUrl} from './tenant'

const api = axios.create({
  baseURL: baseApiUrl,
  timeout: 600000 
});

const config = (config) => {
  const { tenantId, timeout, isRestNode } = config
  config.headers = {...config.headers, tenantId, timeout, isRestNode }
  return config
}
const success = res => res
const error = async error => {
  const {noToast} = error.config
  if (error.config && error.response && error.response.status === 401) {
    return store.dispatch('auth/refreshToken').then((response) => {
      error.config.headers['Authorization'] = 'Bearer ' + response.token;
      return axios.request(error.config);
    });
  }
  if (error.code == 'ECONNABORTED' && !noToast) { 
    Vue.$toast.error('Requisição cancelada, tempo excedido', {position: 'top-right'});
  } else if (error.code == 'ERR_NETWORK' && !noToast) { 
    Vue.$toast.error('Erro de conexão.', {position: 'top-right'});
  } else if (!noToast) {
    Vue.$toast.error(error?.response?.data?.error || error?.response?.data || 'Erro interno.', {position: 'top-right'});
  }
 
  return Promise.reject(error)
}

api.interceptors.request.use(config)
api.interceptors.response.use(success, error)

export default api