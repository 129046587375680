<script>
import VAutocomplete from "vuetify/lib/components/VAutocomplete/VAutocomplete.js"
import { createNamespacedHelpers, mapActions } from 'vuex'
const { mapActions:registersActions } = createNamespacedHelpers('registers')
export default {
  name: 'VCustomAutocomplete',
  extends: VAutocomplete,
  props: {
    /**
     * Collection
     */
    index: {
      type: String,
      required: true
    },

    /**
     * Função de tratamento da requisição da busca.
     * Essa função será chamada recebendo como parâmetro a Promise da chamada da busca
     */
    handler: {
      type: Function,
      required: false
    },
    handlerLoading: {
      type: Function,
      required: false
    },

    /**
     * Valor em ms do intervalo de espera de parada de input até realizar a requisição.
     */
    delay: {
      type: Number,
      required: false,
      default: 0
    },

    /**
     * Booleano que indica se a query string vazia não deve ser aceita como critério de busca
     */
    noEmpty: {
      type: Boolean,
      default: false,
    },

    /**
     * Texto que aparece quando a query de busca está vazia e a prop `noEmpty` tá marcada como `true`
     */
    emptyText: {
      type: String,
      default: 'Digite algo para pesquisar',
    },

    /**
     * Em caso positivo, limpa o array de itens buscados após escolher um item
     */
    clean: {
      type: Boolean,
      deafult: false,
    },

    clearSearchOnSelect: {
      type: Boolean,
      default: false,
    },
    asyncSearch: Object,
    fieldsToSearch: Array,
    fieldsToShow: Array,
    dependents: Array,
    item: Object,
    trigger:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
      timer: null,
      hasInput: false,
    }
  },
  watch: {
    dependentsValue: {
      handler: function(newValue, oldVal) {
        if (!this.$_.isEqual(newValue, oldVal)) {
          this.timeoutSearch()
        }
      },
      deep: true,
    },
    trigger(newValue, oldVal) {
      if(newValue){
        this.$emit("loadMoreFn")
        this.loadMoreFn()
      }
    },
  },
  computed: {
    dependentsValue() {
      if (!this.item || !this.dependents || this.dependents.length < 1) return []
      let dependentsValue = {}
      for (const dependent of this.dependents) {
        dependentsValue[dependent] = this.item[dependent]
      }
      return dependentsValue      
    }
  },
  methods: {
    ...registersActions(['getOnMongo','nextOnMongo']),
    timeoutSearch(query) {
      if (!this.noEmpty || query) {
        if (this.timer)
          clearTimeout(this.timer)
  
        this.timer = setTimeout(async () => {
          this.timer = null
          this.handler(await this.getData(query))
        }, this.delay)
      }
    },
    async search(query) {
      if (!this.noEmpty || query) {
        this.handler(await this.getData(query))
      }
    },
    async getData(query) {
      this.handlerLoading(this.index, true)
      if (!this.asyncSearch) return this.handlerLoading(this.index, false)
      const _asyncSearch = this.$_.cloneDeep(this.asyncSearch)
      let {collection, register = collection, orderBy, filters, limit =  40, disableIntFilters, resetRegister = false} = _asyncSearch
      if (!collection || !register || !orderBy) return this.handlerLoading(this.index, false)
     
      let orFilters = []
      if (query && query.trim()) {
        for (const field of this.fieldsToSearch) {
          orFilters.push({key: field, operator: 'like', value: query})
        }
      }

      if (typeof filters == 'string') { filters = eval(filters) }
      if (filters && filters.length > 0) {
        const item = this.item // Disponivel para Filtro
        filters = filters.map(filter => {
          let { key, operator, value } = filter
          if (typeof value == 'string' && value.includes('${') && value.includes('item.')) {
            filter.value = eval(value)
          }
          return filter 
        });
      }
     
      const results = await this.getOnMongo({
        register, 
        collection, 
        filters,
        orFilters, 
        orderBy, 
        limit, 
        disableIntFilters,
        resetRegister,
        getFirestore: true 
      })
      const result = results && results.data ? results && results.data : []
      
      this.handlerLoading(this.index, false)
      return {index: this.index, hits: result, query, resetRegister}
    },
    async loadMoreFn() {
      this.handler(await this.loadMoreData())
    },
    async loadMoreData() {
      this.handlerLoading(this.index, true)
      
      const _asyncSearch = this.$_.cloneDeep(this.asyncSearch)
      let {collection, register = collection, limit} = _asyncSearch
      if (!register) return this.handlerLoading(this.index, false)
      
      const results = await this.nextOnMongo({register, limit})
      const result = results && results.data ? results && results.data : []
      if (!result.length > 0) { this.disabledLoadMore = true }
      
      this.handlerLoading(this.index, false)
      return {index: this.index, hits: result, notClear: true}
    }
  },
  async created() {
    // Initialize
    this.timeoutSearch()

    // Search
    this.$on('update:search-input', (query) => {
      if (query == null)
        return
    
      // previne busca desnecessária ao escolher um item da lista
      if (this.value && this.itemText && typeof this.itemText == 'function' && query == this.itemText(this.value))
        return
      
      if (this.delay) { this.timeoutSearch(query) }
      else { this.search(query) }
    })

    // Clear Search on Change
    this.$on('change', () => {
      if (this.clearSearchOnSelect) { 
        this.search("") 
      }
    })
    this.$on('click:clear', () => {
      this.search("") 
    })
  },
}
</script>