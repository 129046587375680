<template>
  <div>
    <DxPivotGrid 
      id="pivotgrid" 
      ref="pivotgrid"
      :data-source="report" 
      :allow-sorting="true" 
      :allow-sorting-by-summary="true" 
      :allow-filtering="true"
      :allow-expand-all="true"
      :show-row-totals="false"
      row-header-layout="tree"
      @exporting="onExporting"
    >
      <DxExport :enabled="true"/>
      <DxFieldChooser
        :enabled="true"
        :allow-search="true"
        apply-changes-mode="onDemand"
      />
      <DxFieldPanel
        :visible="true"
      />
      <DxStateStoring :enabled="true" type="custom" :custom-save="saveState" />
    </DxPivotGrid>
  </div>
</template>

<script>
// #devexpress - libs
import 'devextreme/dist/css/dx.softblue.compact.css';
import {
  DxPivotGrid, 
  DxStateStoring,
  DxFieldChooser,
  DxExport,
  DxFieldPanel
} from 'devextreme-vue/pivot-grid';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import ptMessages from "devextreme/localization/messages/pt.json";
import { locale, loadMessages } from "devextreme/localization";
import { exportPivotGrid } from 'devextreme/excel_exporter';
import config from 'devextreme/core/config';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';

import { createNamespacedHelpers, mapState, mapActions } from "vuex";
const { mapState: authState } = createNamespacedHelpers("auth");

export default {
  name: "DevExpressPivot",
  components: { DxPivotGrid, DxFieldChooser, DxStateStoring, DxFieldPanel, DxExport },
  props: {
    pivotWidth: {
      type: String,
      required: false,
    },
    sourceJson: {
      type: Array,
      required: false,
    },
    settings: {
      type: Object,
      required: false,
    },
    query: String,
    register: String,
  },
  computed: {
    ...mapState(["companySelected"]),
    ...authState(["user"]),
    isAdmin() {
      return (
        this.user &&
        (this.user.superAdmin ||
          (this.user.companies &&
            this.user.companies[this.companySelected.groupCompany] &&
            this.user.companies[this.companySelected.groupCompany]["claims"] &&
            this.user.companies[this.companySelected.groupCompany]["claims"][
              "admin"
            ]))
      );
    },
    report() {
      const sourceJson = this.sourceJson.map(x => {
        delete x.id
        delete x.ID
        delete x.LINE
        // for (const ix in x) {
        //   if (Object.hasOwnProperty.call(x, ix)) {
        //     const el = x[ix];
        //     if (this.$moment(el, 'YYYY-MM-DD', true).isValid()) {
        //       x[ix] = new Date(el)
        //     }
        //   }
        // }
        return x
      })
      
      this.settings?.fields.forEach(f => {
        if (f.calculateSummaryValue) {
          f.calculateSummaryValue = eval(f.calculateSummaryValue);
        }
      });
      
      return  new PivotGridDataSource({
        ...this.settings,
        store: sourceJson || [],
        retrieveFields: true,
      })
    },
  },
  methods: {
    ...mapActions(["downloadFile"]),
    // loadState() {
    //   return [];
    // }
    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('Portal de Vendas');

      exportPivotGrid({
        component: e.component,
        worksheet,
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'UF Manage.xlsx');
        });
      });
    },
    saveState(state) {
      console.info('saveState', state)
      // this.downloadFile({data: JSON.stringify(state), type: 'application/json', label: 'DevExpressConfigs'})
    },
  },
  created() {
    loadMessages(ptMessages);
    locale(navigator.language);
    config({ defaultCurrency: 'BRL' });  
  }
 
};
</script>

<style>
</style>