<script>
import Register from "@/views/Registers/Register"
import { createNamespacedHelpers, mapState } from 'vuex'
const { mapState: registersState } = createNamespacedHelpers('registers')
const { mapState:authState } = createNamespacedHelpers('auth')
export default {
  name: 'UsersRegister',
  extends: Register,
  data() {
    return {
      rules: {
        required: v => !!v || 'Campo obrigatório',
        email: v => /.+@.+\..+/.test(v) || 'Formato de email inválido',
      },
    }
  },
  computed: {
    ...mapState(['companies', 'companySelected']),
    ...registersState(['registers']),
    ...authState(['user']),
    computedInputsMaster() {
      let headers = [
        { type: 'divider', label: 'Dados Básicos' },
        { type: 'switch', label: 'Ativo', value: 'active', default: true  },
        { type: 'text-field', label: 'Nome de Exibição', value: 'displayName', sm: 6,  rules: [this.rules.required] },
        { type: 'text-field', label: 'E-mail', value: 'email', sm: 6, rules: [this.rules.required, this.rules.email], validateOnBlur: true },
        { type: 'password', label: 'Senha', value: 'password', autocomplete: 'new-password', sm: 6 },
        { type: 'password', label: 'Confirmação de Senha', value: 'confirmPassword', autocomplete: 'new-password', sm: 6 },
        { type: 'divider', label: 'Permissões' },
      ]

      const groupCompanies = (this.user.superAdmin) ? Object.keys(this.companies) : [this.companySelected.groupCompany]
      for (const groupCompany of groupCompanies) {
        if(this.user.superAdmin){
            headers.push(
            { 
              type: 'switch', 
              label: groupCompany, 
              value: `companies.${groupCompany}.active`, 
              sm: 1,
              dense: true,
              hideDetails: true
            },
            { 
              type: 'switch', 
              label: 'Admin', 
              value: `companies.${groupCompany}.admin`, 
              sm: 1,
              hide: 'superAdmin',
              dense: true,
              hideDetails: true
            },
            { 
              type: 'select', 
              label: 'Papéis', 
              value: `companies.${groupCompany}.rules`, 
              sm: 2, 
              multiple: true, 
              items: this.companies[groupCompany].userRules, 
              itemText: 'name', 
              itemValue: 'id', 
              hide: `superAdmin`,
              disabled: ({item: formData, input, user})=>{ return formData[`companies.${groupCompany}.admin`] ? true : false } ,//this.$refs['Form']
              dense: true,
              hideDetails: false
            },
            {
              type: 'select',
              label: 'Filiais',
              value: `companies.${groupCompany}.subsidiaries`,
              items: this.companies[groupCompany].sigamat,
              multiple: true,
              itemText: ({element}) => `${element.M0_CODIGO} ${element.M0_NOME} - ${element.M0_CODFIL} ${element.M0_FILIAL}`,
              itemValue: 'id',
              sm: 2,
              hide: 'superAdmin',
              dense: true,
              hideDetails: true
            },
            {
              type: 'text-field',
              label: 'Cód Vendedor ERP',
              value: `companies.${groupCompany}.A3_COD`,
              sm: 2,
              dense: true,
              hideDetails: true
            },
            {
              type: 'text-field',
              label: 'Cód Cliente ERP',
              value: `companies.${groupCompany}.A1_COD`,
              sm: 2,
              dense: true,
              hideDetails: true
            },
            {
              type: 'text-field',
              label: 'Cód Usuário ERP',
              value: `companies.${groupCompany}.cUserID`,
              sm: 2,
              dense: true,
              hideDetails: true
            },
          )
        }else{
          headers.push(
            { 
              type: 'switch', 
              label: groupCompany, 
              value: `companies.${groupCompany}.active`, 
              sm: 1,
              dense: true,
              hideDetails: true,
              rules: [this.rules.required],
              validateOnBlur: true
            },
            { 
              type: 'switch', 
              label: 'Admin', 
              value: `companies.${groupCompany}.admin`, 
              sm: 1,
              hide: 'superAdmin',
              dense: true,
              hideDetails: true
            },
            { 
              type: 'select', 
              label: 'Papéis', 
              value: `companies.${groupCompany}.rules`, 
              sm: 2, 
              multiple: true, 
              items: this.companies[groupCompany].userRules, 
              itemText: 'name', 
              itemValue: 'id', 
              hide: `superAdmin`,
              disabled: ({item: formData, input, user})=>{ return formData[`companies.${groupCompany}.admin`] ? true : false } ,//this.$refs['Form']
              dense: true,
              hideDetails: false
            },
            {
              type: 'select',
              label: 'Filiais',
              value: `companies.${groupCompany}.subsidiaries`,
              items: this.companies[groupCompany].sigamat,
              multiple: true,
              itemText: ({element}) => `${element.M0_CODIGO} ${element.M0_NOME} - ${element.M0_CODFIL} ${element.M0_FILIAL}`,
              itemValue: 'id',
              sm: 2,
              hide: 'superAdmin',
              dense: true,
              hideDetails: true
            },
            {
              type: 'text-field',
              label: 'Cód Vendedor ERP',
              value: `companies.${groupCompany}.A3_COD`,
              sm: 2,
              dense: true,
              hideDetails: true
            },
            {
              type: 'text-field',
              label: 'Cód Cliente ERP',
              value: `companies.${groupCompany}.A1_COD`,
              sm: 2,
              dense: true,
              hideDetails: true
            },
            {
              type: 'text-field',
              label: 'Cód Usuário ERP',
              value: `companies.${groupCompany}.cUserID`,
              sm: 2,
              dense: true,
              hideDetails: true
            },
          )

        }
      }

      headers.push(
        { type: 'divider', label: 'Rotinas' },
        { type: 'switch', label: 'Enviar Login', value: 'sendEmailLogin', method: 'new', sm: 6, md: 4 }
      )

      return headers
    },
    computedDispatch() {
      return [
        {
          action: 'auth/sendSignInLinkToEmail',
          params: { email: 'item.email' },
          method: 'new',
          when: 'before',
          switch: 'sendEmailLogin'
        }
      ]
    }
  },
}
</script>