<template>
  <section>
    <Toolbar>
      <template slot="actions">
        <Dialog v-if="item.errorErp" color="error">
          <template #buttonContent>
            <v-icon size=18 class="mr-1">mdi-alert-outline</v-icon>
            Ver Erros do Erp
          </template>
          <template #title>Erros do ERP</template>
          <template #content><span class="wp-preline">{{item.errorErp}}</span></template>
        </Dialog>
        <CustomerPosition
          v-if="['SA1','SC5','SCJ', 'ADA'].includes(collection) && computedCustomerPositionConfigs && computedCustomerPositionConfigs['show']"
          :model="computedCustomerPositionConfigs['model']"
          :titleButton="'Posição de Cliente'"
          :inputsHeaders="computedCustomerPositionConfigs['inputsHeaders']"
          :inputsSubHeaders="computedCustomerPositionConfigs['inputsSubHeaders']">
          <template #buttonContent>
            <v-icon size=18 class="mr-1">mdi-account-card</v-icon>
            Posição de Cliente
          </template>
        </CustomerPosition>

        <v-dialog v-if="computedExportPdf && (!computedClaims.hidePdf || ((listObjetos && listObjetos.length) || (listDanfes && listDanfes.length)))" v-model="jsPdf.dialog" @update:return-value="jsPdf.doc = null" scrollable>
          <template #activator="{ attrs }">
            <v-btn
              height=40
              x-small
              outlined
              color="warning"
              class="mr-2 text-caption"
              @click="generatePdf({})"
              v-bind="attrs"
              >
              <v-icon size=20 class="mr-1">mdi-file-pdf-outline</v-icon>
              Exportar PDF
            </v-btn>

          </template>
          <v-card>
            <v-toolbar flat>
              <v-card-title class="pl-0">Pré-visualização do documento</v-card-title>
              <v-spacer/>
              <v-btn @click="savePdf()" height=40 x-small outlined color="warning" class="mr-2 text-caption">
                <v-icon size=20 class="mr-1">mdi-file-download-outline</v-icon>
                Download
              </v-btn>
              <v-dialog
                v-model="jsPdf.sendEmail.dialog"
                @input="jsPdfHandleDialog"
                max-width="50vw">
                <template #activator="{ attrs, on }">
                  <v-btn
                    height=40
                    x-small
                    outlined
                    color="success"
                    class="mr-2 text-caption"
                    v-bind="attrs"
                    v-on="on"
                    >
                    <v-icon size=20 class="mr-1">mdi-email-send-outline</v-icon>
                    Enviar por Email
                  </v-btn>
                </template>
                <Form
                  :inputs="jsPdf.sendEmail.inputs"
                  :model="jsPdf.sendEmail.model"
                  @submit="sendPdf"
                >
                  <template #prepend>
                    <v-card-title>Enviar documento PDF por email</v-card-title>
                    <v-divider/>
                  </template>
                  <template #append>
                    <v-divider/>
                    <v-card-actions>
                      <v-spacer/>
                      <v-btn
                        :loading="jsPdf.sendEmail.sending"
                        :disabled="jsPdf.sendEmail.sending"
                        type="submit"
                        class="text-caption"
                        color="success"
                        height=40
                        x-small
                        outlined
                        >
                        <v-icon size=20 class="mr-1">mdi-send</v-icon>
                        Enviar
                      </v-btn>
                      <v-btn @click.stop="jsPdf.sendEmail.dialog = false" height=40 x-small outlined color="error" class="text-caption">
                        <v-icon size=18 class="mr-1">mdi-cancel</v-icon>
                        Cancelar
                      </v-btn>
                    </v-card-actions>
                  </template>
                </Form>
              </v-dialog>
              <v-btn @click.stop="jsPdf.dialog = false" height=40 x-small outlined color="error" class="text-caption">
                <v-icon size=18 class="mr-1">mdi-cancel</v-icon>
                Cancelar
              </v-btn>
            </v-toolbar>
            <v-divider/>
            <Iframe v-if="!!jsPdf.doc || !!jsPdf.blob || !!jsPdf.objectStorage || !!jsPdf.url" :src="jsPdfOutput" type="application/pdf"/>
            <!-- <Iframe v-else-if="!!jsPdf.blob" :src="jsPdfOutput" type="application/pdf"/> -->
            <v-divider/>
          </v-card>
        </v-dialog>

        <v-btn v-for="(cAction, index) of computedCustomActions" 
          v-show="showAction({item, customAction: cAction})" 
          @click="clickAction({item, customAction: cAction})" 
          height=40 x-small outlined :color="cAction.color" 
          class="mr-2 text-caption" :key="index">
          <v-icon size=18 class="mr-1">{{cAction.icon}}</v-icon>
          {{cAction.name}}
        </v-btn>
        
        <template v-if="!computedClaims.sync">
          <v-btn v-if="!readonlyComputed" @click="submit()" height=40 x-small outlined color="success" class="mr-2 text-caption">
            <v-icon size=20 class="mr-1">mdi-content-save-outline</v-icon>
            Gravar
          </v-btn>
          
          <v-btn v-if="!readonlyComputed" @click="goBack" height=40 x-small outlined color="error" class="text-caption">
            <v-icon size=18 class="mr-1">mdi-cancel</v-icon>
            Cancelar
          </v-btn>
        
          <v-btn v-else @click="goBack" height=40 x-small outlined color="primary" class="text-caption">
            <v-icon size=18 class="mr-1">mdi-chevron-left</v-icon>
            Voltar
          </v-btn>
        </template>
        <template v-else>
          <v-btn v-if="!readonlyComputed && computedClaims.add && !computedClaims.hideSketch/*&& (!item.lifeControl || !item.lifeControl.integratedAt)*/" @click="submit()" height=40 x-small outlined color="success" class="mr-2 text-caption">
            <v-icon size=20 class="mr-1">mdi-content-save-outline</v-icon>
            {{(props.labelButtonDraft) ? props.labelButtonDraft : 'Gravar Rascunho'}}
          </v-btn>
          
          <v-btn v-if="!readonlyComputed && computedClaims.sync" @click="submit(true)" height=40 x-small outlined color="primary" class="mr-2 text-caption">
            <v-icon size=20 class="mr-1">mdi-file-send-outline</v-icon>
            {{(props.labelButtonSend) ? props.labelButtonSend : 'Gravar para Envio'}}
          </v-btn>
          
          <v-btn v-if="!readonlyComputed" @click="goBack" height=40 x-small outlined color="error" class="text-caption">
            <v-icon size=18 class="mr-1">mdi-cancel</v-icon>
            Cancelar
          </v-btn>
        
          <v-btn v-else @click="goBack" height=40 x-small outlined color="primary" class="text-caption">
            <v-icon size=18 class="mr-1">mdi-chevron-left</v-icon>
            Voltar
          </v-btn>
        </template>
      </template>
    </Toolbar>

    <Bills
      v-if="computedItemsBills && computedItemsBills.length > 0"
      :openCard="bills.openCard"
      :headers="computedHeadersBills"
      :items="computedItemsBills"
      :color="color" 
      :dueDateField="computedDueDateField"
      :showToolbar="true"
    />

    <v-dialog
      v-model="danfes.dialog"            
      max-width="50vw">
      <v-card>
          <v-card-title>Lista de Pedidos/Danfes</v-card-title>
          <v-divider/>                
          <template v-if="listDanfes.length===0">
            <v-card-text>
              Não existem danfes/pedidos gerados
            </v-card-text> 
          </template>
          <v-list>
            <v-list-item 
              v-for="(list, key) in listDanfes"
              :key="list+key">
              <v-list-item-content>
                <v-list-item-title>{{'Pedido: ' + list.PEDIDO}}</v-list-item-title>
                <v-list-item-subtitle>{{'NF/Série: ' + list.NF + '/' + list.SERIE}}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-icon>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                <v-icon  
                    color="blue accent-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="danfeDownload(list.FILIAL + '/' + list.PEDIDO  )">
                    mdi-file-download                          
                </v-icon>
                  </template>
                  <span>Download Danfe</span>
              </v-tooltip>
              </v-list-item-icon>
            </v-list-item>              
          </v-list>  
          <v-divider/>                                 
          <v-card-actions>
            <v-spacer/>       
            <v-btn @click="danfes.dialog = false" height=40 x-small outlined  class="text-caption">
              <v-icon size=18 class="mr-1">mdi-close</v-icon>
              Fechar
            </v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog> 

    <v-dialog
      v-model="objetos.dialog"            
      max-width="50vw">
      <v-card>
          <v-card-title>Lista de Documentos</v-card-title>
          <v-divider/>                
          <template v-if="listObjetos.length===0">
            <v-card-text>
              Não existem arquivos para esse registro
            </v-card-text> 
          </template>
          <v-list>
            <v-list-item 
              v-for="(list, key) in listObjetos"
              :key="list+key">
              <v-list-item-content>
                <v-list-item-title>{{'Contrato: ' + list.CONTRATO}}</v-list-item-title>
                <v-list-item-subtitle>{{'Arquivo: ' + list.ARQUIVO}}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-icon>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                <v-icon  
                    color="blue accent-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="objetoDownload(list.RECNO  )">
                    mdi-file-download                          
                </v-icon>
                  </template>
                  <span>Download Arquivo</span>
              </v-tooltip>
              </v-list-item-icon>
            </v-list-item>              
          </v-list>  
          <v-divider/>                                 
          <v-card-actions>
            <v-spacer/>       
            <v-btn @click="objetos.dialog = false" height=40 x-small outlined  class="text-caption">
              <v-icon size=18 class="mr-1">mdi-close</v-icon>
              Fechar
            </v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>      

    <v-dialog
      v-model="dialogApproval.menu"            
      max-width="30vw">
      <v-card>
        <v-card-title>
          Aprovação de orçamento
        
          <v-spacer/>  
  
          <v-btn 
            small
            icon
            @click="dialogApproval.menu = false"
            >
            <v-icon small>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        

        <v-divider/>             
        
        <v-card-text>
          <v-textarea 
            v-if="!dialogApproval.justify.hide && !dialogApproval.combo"
            v-model="dialogApproval.justify.message"
            name="dialogApprovalJustify"
            :label="dialogApproval.justify.label || 'Justificatica'"
            rows="4"                
            auto-grow
            ></v-textarea>
            <v-combobox
            v-if="!dialogApproval.justify.hide && dialogApproval.combo"
              v-model="dialogApproval.justify.message"
              :items="comboMotivos.items"
              label="Motivo"
            ></v-combobox>
        </v-card-text>  
          
        <v-divider/>  
        
        <v-card-actions>
          <v-spacer/>  
        
          <div :key="`approval_${i}`" v-for="(btn, i) in dialogApproval.buttons">
            <v-btn 
              v-if="!btn.hide"
              :color="`${btn.color || 'blue accent-2'}`"
              height=40 x-small outlined  
              class="text-caption ml-2"
              @click="toApprove({item: btn.item, sendToErp: btn.sendToErp})"
              >
              {{btn.label}}
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>   

    <!-- to do: ver uma forma de não precisar disso para renderizar as tabs no vJsf-->
    <template>
      <v-tabs v-show="false">
        <v-tab></v-tab>
      </v-tabs>
    </template>
    
    <Form
      :inputs="computedInputsMaster"
      :collection="collection"
      :model="item"
      :color="color"
      :readonly="readonlyComputed"
      @submit="submit()"
      @change="(formMaster) => changeForm(formMaster)"
      ref="formMaster">
    </Form>

    <!-- IMPOSTOS -->
    <v-card v-if="computedTaxCalculation && computedTaxCalculation.active" class="mt-6">
      <v-card-text>
        <v-toolbar flat dense short height=35>
          <v-toolbar-title v-if="!impostosHeader" :class="`subtitle-2 font-weight-bold ${color}--text ml-n4`">Impostos</v-toolbar-title>
          <v-toolbar-title v-if="impostosHeader" :class="`subtitle-2 font-weight-bold ${color}--text ml-n4`">{{ impostosHeader }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            v-if="(!item.bloqueado && !readonlyComputed)"
            @click="calcImps()" 
            small 
            outlined
            :disabled="impostos.loading"
            color="primary" 
            class="no-hover mr-n4">
            Calcular Impostos
          </v-btn>
        </v-toolbar>
        <v-data-table
          :headers="impostos.headers"
          :items="impostos.itens"
          :loading="impostos.loading"
          hide-default-footer
          disable-sort
          dense
          loading-text="Calculando... Por favor aguarde"
        >
          <!-- ITEM -->
          <template v-slot:item="{item, index, headers}">
            <tr>  
              <v-alert v-if="item.Erros || item.ERROS || item.erros" :value="true" dark color="error" icon="mdi-alert-outline" class="mt-3">
                <span class="wp-preline">{{item.Erros || item.ERROS || item.erros}}</span>
              </v-alert>
              <template v-else>
                <td
                  v-for="(header, key) in headers"
                  :key="index+key"
                  class="text-center"
                  >
                    R$ {{isNaN(item[header.value]) ? item[header.value] : item[header.value].toLocaleString('pt-br', { minimumFractionDigits:2, maximumFractionDigits: 2 }) }}
                </td>
              </template>
            </tr>
          </template>

          <!-- No Results  -->
          <template v-slot:no-data>
            {{impostos.noData}}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <FormDataTableV2
      v-if="typeDetails == 'data-table' && onDemand"
      :props="props"
      :tableSA7="tableSA7"
      :inputsMaster="computedInputsMaster"
      :headers="computedHeaders"
      :tableIdForeignValue="tableIdForeignValue"
      :itemsDetails="itemsDetails"
      :inputsDetailsFiltered="inputsDetailsFiltered"
      :filters="computedFilters"
      :xlsxName="computedXlsName"
      :color="color"
      :readonly="readonlyComputed"
      :formMaster="computedItemFormMaster"
      :user="user"
      :userRules="userRules"
      @submit="submit()"
      :filterItems="filterItemsFunction"
      @validated="validatedDetails = $event"
      :exportXls="exportXls"
      ref="formDetails"
      @showCarousel="(event)=>showCarousel(event)"
      >
    </FormDataTableV2>

    <FormDataTable
      v-else-if="typeDetails == 'data-table'"
      :headers="computedHeaders"
      :props="props"
      :items="inputsDetails"
      :inputsDetailsFiltered="inputsDetailsFiltered"
      :filters="computedFilters"
      :xlsxName="computedXlsName"
      :color="color"
      :readonly="readonlyComputed"
      :formMaster="computedItemFormMaster"
      :user="user"
      :userRules="userRules"
      @filterSA7="filterSA7"
      @submit="submit()"
      @calcPreco="calcPreco"
      :filterItems="filterItemsFunction"
      @validated="validatedDetails = $event"
      :exportXls="exportXls"
      ref="formDetails"
      @showCarousel="(event)=>showCarousel(event)"
      >
    </FormDataTable>

    <template v-if="computedImagesDialog && computedImagesDialog.length > 0">
      <v-container>
        <Images
        
        :imageUrls="computedImagesDialog"
        :justCarousel="true"
        @closeDialog="imagesDialog = []"
        />
      </v-container>
    </template>
  </section>
</template>

<script>
import Toolbar from "@/components/Templates/Toolbar"
import Dialog from "@/components/Templates/Dialog"
import { createNamespacedHelpers, mapActions, mapMutations, mapState } from 'vuex'
const { mapState:registersState, mapActions:registersActions, mapMutations:registersMutations } = createNamespacedHelpers('registers')
const { mapState:authState, mapActions:authActions } = createNamespacedHelpers('auth')
import { jsPDF } from "jspdf"
import "jspdf-autotable"
import * as fs from 'file-saver'
import Form from "@/components/Registers/Form"
import Bills from "@/components/Registers/Bills"
import CustomerPosition from "@/components/Registers/CustomerPosition"
import FormDataTable from "@/components/Registers/FormDataTable"
import FormDataTableV2 from "@/components/Registers/FormDataTableV2"
import Iframe from "@/components/Templates/Iframe"
import Images from "@/components/Templates/Images"
import axios from 'axios'
export default {
  name: 'Register',
  components: { Form, FormDataTable, FormDataTableV2, Toolbar, Dialog, Iframe, Bills, CustomerPosition, Images },
  props: {
    props: {
      type: Object,
      default: () => {}
    },
    name: {
      type: String,
      required: false,
    },
    collection: {
      type: String,
      required: true,
    },
    register: {
      type: String,
      required: true,
    },
    externalRequest: Object,
    inputsMaster: {
      type: Array,
      default: () => []
    },
    blockedField: String,
    color: {
      required: false,
      type: String,
      default: "indigo"
    },
    readonly: {
      required: false,
      type: Boolean,
      default: () => false
    },
    edit: {
      required: false,
      type: Boolean,
      default: () => false
    },
    integration: {
      required: false,
      type: Boolean,
      default: () => false
    },
    dispatch: {
      type: Array,
      default: () => []
    },
    collectionDetails: {
      required: false,
      type: String,
    },
    headersDetails: {
      type: Array,
      default: () => []
    },
    tableCollection: {
      type: String,
      default: ""
    },
    tableSubcollection: {
      type: String,
      default: ""
    },
    orderSubcollection: {
      required: false,
    },
    filtersSubcollection: {
      required: false,
    },
    tableIdFrom: {
      type: String,
      default: ""
    },
    tableSA7: {
      type: Boolean,
      default: false
    },
    onDemand: {
      type: Boolean,
      default: false
    },
    tableIdForeign: {
      type: String,
      default: ""
    },
    querySubcollection: {
      type: String,
      default: ""
    },
    fieldsReturn: {
      type: String,
      default: ""
    },
    filtersDetails: {
      type: Array,
      default: () => []
    },
    filterItems: String,
    xlsxName: String,
    typeDetails: String,
    impostosPdfText: String,
    totaisPdfText: String,
    colorPdfTable: String,
    titleOfPrint: String,
    printCompanyHeaders: {
      type: Array,
      default: () => [],
      required: false
    },
    claims: {
      type: Object,
      required: true,
      default: () => ({
        add: false, 
        view: false, 
        edit: false, 
        delete: false, 
        copy: false, 
        sync: false,
        hideSketch: false,
        hidePdf: false
      })
    },
    exportPdf: {
      type: Boolean,
      required: false,
      default: true,
    },
    customActions: {
      type: Array,
      default: () => []
    },
    taxCalculation: Object,
    bills: Object,
    version: {
      type: Boolean,
      default: false
    },
    printLayout: {
      active: Boolean,
      link: String
    },
    exportXls: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      modelTeste: {},
      imagesDialog:[],
      ready: false,
      itemFormMaster: {},
      item: {},
      itemsDetails: [],
      inputsDetailsFiltered: false,
      tableItems: [],
      tableIdForeignValue: null,
      autoSave: {
        active: true,
        firstSaveTime: 5 * 1000,
        saveTime: 60 * 1000,
        timer: null
      },
      impostos: {
        headers: [],
        itens: [],
        noDataDefault: 'Não há dados disponíveis',
        noData: 'Não há dados disponíveis',
        loading: false,
      },
      printSketch: {
        headers: [],
        itens: [],
        noDataDefault: 'Não há dados disponíveis',
        noData: 'Não há dados disponíveis',
        loading: false,
      },
      jsPdf: {
        doc: null,
        dialog: false,
        sendEmail: {
          sending: false,
          dialog: false,
          inputs: [
            { type: 'text-field', value: 'to', label: "Email de Destino" },
            { type: 'text-field', value: 'cc', label: "Cc", md: 6 },
            { type: 'text-field', value: 'bcc', label: "Cco", md: 6 },
            { type: 'text-field', value: 'subject', label: "Assunto" },
            { type: 'textarea', value: 'text',  label: "Corpo do Email", rows: 4 },
          ],
          model: null,
        },
        blob: null
      },
      validatedDetails: false,
      itemsBills: [],
      currentVersion: null,
      customerPositionConfigs: {},
      disableSA7: false,
      danfes: {
        dialog: false,        
      },
      objetos: {
        dialog: false,        
      },
      dialogApproval: {
        menu: false,   
        justify: {} 
      },
      listDanfes: [],
      listObjetos: [],
      comboMotivos: {
      items: [],
      value: '01'
    }      
    }
  },

  computed: {
    ...registersState(['registers']),
    ...mapState(['companies', 'companySelected', 'integrations']),
    ...authState(['user']),
    computedTaxCalculation() {
      return this.taxCalculation
    },

    impostosHeader(){
      const header = this.inputsMaster.filter(x=> x.type == 'impostosHeader')
      return header && header[0] && header[0].value ? header[0].value : false 
    },

    computedCustomerPositionConfigs () {
      return this.customerPositionConfigs
    },

    computedFormMasterItem() {
      return this.$refs.formMaster
    },

    computedItemFormMaster() {
      return this.itemFormMaster
    },

    userRules() {
      if (this.user.superAdmin) {
        return this.computedUserRules
      } else if (!this.user.companies) {
        return []
      }
      return this.computedUserRules.filter(ur => 
        this.user.companies &&
        this.user.companies[this.companySelected.groupCompany] &&
        this.user.companies[this.companySelected.groupCompany]['rules'] && 
        this.user.companies[this.companySelected.groupCompany]['rules'].includes(ur.id)
      )
    },
    
    computedUserRules () {
      return this.registers['userRules']
    },

    readonlyComputed() {
      const readOnly = (
          this.readonly 
          || 
          (
            this.item && this.blockedField
            ? this.item[this.blockedField]
            : false
          )
        )
      return readOnly        
    },

    computedXlsName() {
      const xlsNameComputed = this.xlsxName.includes('=>') ? eval(this.xlsxName)({item: this.item}) : this.xlsxName
      return xlsNameComputed.trim()
    },

    computedInputsMaster() {
      return this.inputsMaster.map( i => {
        const input = { ...i }

        if (input.set)
          input.set = eval(input.set)
        
        if (input.computed) input.computed = eval(input.computed)

        if (typeof input.itemText == 'string' && input.itemText.includes('=>'))
          input.itemText = eval(input.itemText)
        
        if (input.model) 
          input.model = eval(input.model)

        if (input.markers)
          input.markers = Object.entries(input.markers).reduce((acc, [key, val]) => ({...acc, [key.trim().toLocaleLowerCase()]: val}), {})
        
        // envia todos os campos caso esteja cadastrando
        if (!this.id)
          input.send = true

        if (typeof input.hide == 'string' && input.hide.includes('=>')) {
          input.hide = eval(input.hide)
        }
        
        if (typeof input.disabled == 'string' && input.disabled.includes('=>')) {
          input.disabled = eval(input.disabled)
        }

        return input
      })
    },

    inputsDetails() {
      if (this.readonlyComputed || this.props.useDA0 )
        return this.itemsDetails
    
      return this.itemsDetails.length > 0
        ? this.tableItems.map(item => ({...item, 
        ...this.itemsDetails.find(_item => this.headersDetails.filter(h => h.identifier).every(h => item[h.value] == _item[h.value]))})
         || item)
        : this.tableItems
    },

    id() {
      return this.$route.params.id || this.item.id
    },

    claimRoute() {
      return this.$route.meta.claimRoute
    },

    computedHeaders() {
      var { companySelected } = this;
      return this.headersDetails.map(h => {
        const header = { ...h }
        if (header.set) {
          header.set = eval(header.set)
        }

        if (header.computeText) {
          header.text = eval(header.computeText)
        }

        if (header.computed) {
          header.computed = eval(header.computed)
        }
      
        if (header.editable) {
          header.editable = eval(header.editable)
        }
        
        if (header.validation && Array.isArray(header.validation)) {
          header.validation = header.validation.map(strFn => eval(strFn))
        }
       
        if (header.warning && Array.isArray(header.warning)) {
          header.warning = header.warning.map(strFn => eval(strFn))
        }
        
        /**
         * @TODO
         * Código provisório, remover assim que possível
         * Altera os valores default e a função SET para mudar os limites da coluna de desconto
         */
        if (this.A1_XPTDESC && header.value == 'C6_XDESCON') {
          header.default = this.A1_XPTDESC
          header.set = ({ value }) => Math.min(this.A1_XPTDESC, Math.max(0, value))
        }

        if (header.hide && typeof header.hide == 'string') {
          header.hide = eval(header.hide)
        }
        return header
      })
    },

    computedDispatch() {
      return this.dispatch
    },

    computedFilters() {
      return this.filtersDetails.map(filter => {
        if (filter.filter)
          filter.filter = eval(filter.filter)
      })
    },

    computedCustomActions() {
      return this.customActions.map(action => action.data ? action.data : action)
    },

    filterItemsFunction() {
      const _default = _ => true
      try {
        const val = eval(this.filterItems)
        if (!val || !val.apply)
          return _default
        
        return val
      } catch(err) {
        return _default
      }
    },

    method() {
      return this.edit ? 'edit' : 'new'
    },

    jsPdfOutput() {
      if (this.jsPdf.doc)
        return this.jsPdf.doc.output('datauristring')
      else if (this.jsPdf.blob){
        // const base64 = await this.blobTo64({blob: this.jsPdf.blob}) 
        return URL.createObjectURL(this.jsPdf.blob)
      }
      else if (this.jsPdf.url) 
        return this.jsPdf.url

      return null
    },

    company() {
      // let companies = Object.keys(this.companies).map(c => this.companies[c]['sigamat'])
      // let sigamat = []
      // for (const company of companies) {
      //   sigamat.push(...company)
      // }
      // const filial = this.item.filial || this.item.FILIAL || ''
      // const gpEmpresa = this.item.gpEmpresa || this.item.GPEMPRESA || ''
      // return sigamat.find(c => c.id == `${gpEmpresa}${filial}`)
      return this.companySelected
    },

    computedClaims() {
      let claims = this.claims
      const isAdmin = this.user.superAdmin || this.user.companies[this.companySelected.groupCompany].admin

      claims.view = (claims.view && isAdmin) || this.userRules.some(x => x.claims && x.claims[this.register] && x.claims[this.register]?.view)
      claims.add = (claims.add && isAdmin) || this.userRules.some(x => x.claims && x.claims[this.register] && x.claims[this.register]?.add)
      claims.sync = (claims.sync && isAdmin) || this.userRules.some(x => x.claims && x.claims[this.register] && x.claims[this.register]?.sync)
      claims.edit = (claims.edit && isAdmin) || this.userRules.some(x => x.claims && x.claims[this.register] && x.claims[this.register]?.edit)
      claims.hidePdf = (claims.hidePdf && isAdmin) || this.userRules.some(x => x.claims && x.claims[this.register] && x.claims[this.register]?.hidePdf)
      claims.delete = (claims.delete && isAdmin) || this.userRules.some(x => x.claims && x.claims[this.register] && x.claims[this.register]?.delete)
      claims.copy = (claims.copy && isAdmin) || this.userRules.some(x => x.claims && x.claims[this.register] && x.claims[this.register]?.copy)

      return claims
    },

    computedExportPdf() {
      return this.readonlyComputed && this.exportPdf && this.printCompanyHeaders && this.printCompanyHeaders.length > 0
    },

    computedItemsBills() {
      return this.itemsBills
    },

    computedHeadersBills() {
      return this.bills.headers
    },

    computedDueDateField() {
      return this.bills.dueDateField
    },

    computedShowCustomerPosition() {
      return this.customerPositionConfigs['show']
    },

    computedImagesDialog() {
      return this.imagesDialog;
    },
  },

  methods: {
    ...mapActions(['dispatchAction']),
    ...registersActions(['save', 'getById', 'get', 'getApi', 'getGpEmpresa', 'observer','calculaImpostos', 'base64Rascunho', 'calculaPreco', 'getExternal', 'generatePDF']),
    ...mapMutations(['setLoading']),
    ...authActions(['getSA3Subs']),
    ...registersMutations(['resetRegister']),

    showAction({item, customAction}) {
      const showAction = eval(customAction.hide)
      const self = {
        item,
        user: this.user,
        userRules: this.userRules,
        register: this.register
      }
      return typeof showAction === 'function' ? !showAction(self) : !showAction
    },

    clickAction({item, customAction}) {
      const self = {
        item,
        $router: this.$router,
        user: this.user,
        userRules: this.userRules,
        $toast: this.$toast,
        $api: this.$api,
        ...this
      }

      const actionRunner = eval(customAction.actionRunner) //eval('async ({item, getApi, generatePdf, setLoading}) => { setLoading({stats: true, message: "Obtendo Danfe..."}); try { const {base64} = await getApi({url: "/protheus/", options: {params: {path: "Danfe64/" + item.C5_FILIAL + "/" + item.C5_NUM}}}); setLoading({stats: false}); if (base64){ generatePdf({base64str: base64}) }} catch (error) { setLoading({stats: false}); } };')

      actionRunner(self)
    },

    changeForm(formMaster) {
      this.itemFormMaster = formMaster
    },
    
    async submit(submitToErp = false) {
      // MIGRADO PARA API
      // if (submitToErp && this.companySelected?.configs?.claims?.disableSubmitToErp) {
      //   this.$toast.warning('Integração ERP desativada. Entre em contato com administrador do sistema.', {position: 'top'})
      //   return
      // }
      
      // Limpa filtros do table antes de enviar
      if (this.$refs['formDetails'] && this.$refs['formDetails'].clearFilters) { 
        await this.$refs['formDetails'].clearFilters()
        await new Promise(resolve => setTimeout(resolve, 500)) //Atraso na gravação para refazer validações em paralelo
      }
      
      if (this.readonlyComputed) {
        this.goBack()
        return
      }

      if (await this.validateDetails() && await this.validate(submitToErp)) {
        await this.send(submitToErp)
        this.goBack()
      }
    },

    validateDetails () {
      try {
        // itemsDetails Validation
        if (this.typeDetails != 'data-table') return true

        const itemsDetails = this.$refs.formDetails.getModel().filter(this.filterItemsFunction).map(item => ({data: item}))
        if (!itemsDetails || !itemsDetails.length > 0) {
          this.$toast.error('Itens não informados.', {position: 'top-right'})
          return false
        } else if (!this.validatedDetails) {
          this.$toast.error('Erro de Validação nos Itens!', {position: 'top-right'})
          return false
        }

        return true
      } catch (err) {
        console.error(err)
        return false
      }
    },

    async validate(submitToErp) {
      try {
        // Form Validation
        let validate = true

        // Fields validation
        if (!this.$refs.formMaster.validate()) { 
          this.$toast.error('Erro de Validação: Verifique os campos obrigatórios.', {position: 'top-right'}) 
          validate = false
        }

        // Custom Validation
        if (validate && this.props?.validation && typeof this.props.validation == 'function') {
          const item = this.$refs.formMaster.getModel()
          let itemsDetails, totalize
          if (this.typeDetails == 'data-table') {
            itemsDetails = (this.typeDetails == 'data-table') ? this.$refs.formDetails.getModel().filter(this.filterItemsFunction)/*.map(item => ({data: item}))*/ : null
            totalize = this.$refs.formDetails?.getTotalize()
          }
          const self = {
            item,
            itemsDetails,
            totalize,
            user: this.user,
            $router: this.$router,
            $toast: this.$toast,
            $api: this.$api,
            get: this.get,
            getById: this.getById,
            getGpEmpresa: this.getGpEmpresa,
            getExternal: this.getExternal
          }
          const validation = await this.props.validation(self)
          if (!validation || typeof validation == 'string') { 
            this.$toast.error(`Erro de Validação: ${validation}`, {position: 'top-right'})
            validate = false
          } 
          else if (submitToErp && validate && validation.approval) {
            // Em caso de aprovação interrompe a gravação normal e realiza uma gravação alterada
            await this.send(('submitToErp' in validation) ? validation.submitToErp : submitToErp, validation.item)
            this.goBack()
            return false
          }
        }

        return validate
      } catch (err) {
        console.error(err)
        return false
      }
    },
    async calcPreco(preco){
      if (this.$refs['formDetails'] && this.$refs['formDetails'].clearFilters) { await this.$refs['formDetails'].clearFilters() }
      
      if(!preco){
        const item = this.$refs.formMaster.getModel()
        let itemsDetails = this.$refs.formDetails.getModel().filter(this.filterItemsFunction).map(item => ({data: item}))
        if(itemsDetails && itemsDetails.length>0){
          itemsDetails = itemsDetails.map(x=>{
            if(!x.data.id) x.data.id = x.data.Z31_EAN
            return x
          })
        }
        if (!itemsDetails || !itemsDetails.length > 0) return 
        preco = await this.calculaPreco({
          item,
          itemsDetails,
          collectionDetails: this.collectionDetails,
        }).then(res=>{
          this.itemsDetails = this.inputsDetails.map(x=>{
            res.Z31.forEach(y=>{
              if((y.id == x.id)||(y.id == parseInt(x.Z31_EAN))){
                x.Z31_VALOR = y.PRECO
              }            
            })
            return x
        })

        })
        .catch(err => {
          throw err
        })       
      }
    },
    async calcImps(impostos) {
      // Limpa filtros do table antes de calcular
      if (this.$refs['formDetails'] && this.$refs['formDetails'].clearFilters) { await this.$refs['formDetails'].clearFilters() }

      if (!impostos) {
        this.impostos.itens = []
        this.impostos.noData = this.impostos.noDataDefault
        this.impostosMessage = 'Calculando...'
        const item = this.$refs.formMaster.getModel()
        const itemsDetails = this.$refs.formDetails.getModel().filter(this.filterItemsFunction).map(item => ({data: item}))
        if (!itemsDetails || !itemsDetails.length > 0) return this.impostos.noData = 'Não foi preenchido nenhum produto no pedido'
        this.impostos.loading = true
        impostos = await this.calculaImpostos({
          item,
          itemsDetails,
          collectionDetails: this.collectionDetails,
        }).catch(err => {
          this.impostos.loading = false
          throw err
        })
       
        if(impostos.response && [0, 500].includes(impostos.response.status)) {
          impostos = {Erros: impostos.response?.data?.error || 'Requisição cancelada, servidor Protheus offline.'}
        }
        else if(impostos.code == 'ECONNABORTED') { 
          impostos = {Erros: 'Requisição cancelada, tempo excedido.'}
        }
      }
      let headers = []
      if (impostos && impostos.itens && impostos.itens.length > 0) {
        this.impostos.itens = impostos.itens
        headers = Object.entries(impostos.itens[0])
      } else {
        headers = Object.entries(impostos)
        this.impostos.itens.push(impostos)
      }
      this.impostos.headers = headers.sort((a, b) => (a[0] < b[0]) ? -1 : (a[0] > b[0]) ? 1 : 0).map(x => ({align: 'center', text: x[0], value: x[0]}))
      this.impostos.noData = this.impostos.noDataDefault
      this.impostos.loading = false
    },
    
    async imprimePedido() {
      // Limpa filtros do table antes de calcular
      if (this.$refs['formDetails'] && this.$refs['formDetails'].clearFilters) { await this.$refs['formDetails'].clearFilters() }

        const item = this.$refs.formMaster.getModel()
        const itemsDetails = this.$refs.formDetails.getModel().filter(this.filterItemsFunction).map(item => ({data: item}))
        if (!itemsDetails || !itemsDetails.length > 0) return this.printSketch.noData = 'Não foi preenchido nenhum produto no pedido'
        this.printSketch.loading = true
        let sketch64 = await this.base64Rascunho({
          item,
          itemsDetails,
          collectionDetails: this.collectionDetails,
        }).catch(err => {
          this.printSketch.loading = false
          throw err
        })
        if(sketch64.response && [0, 500].includes(sketch64.response.status)) {
          sketch64 = {Erros: sketch64.response?.data?.error || 'Requisição cancelada, servidor Protheus offline.'}
        }
        else if(sketch64.code == 'ECONNABORTED') { 
          sketch64 = {Erros: 'Requisição cancelada, tempo excedido.'}
        }
      
      
      this.printSketch.loading = false
      return sketch64.base64
    },

    async send(submitToErp, item = null) {
      // item
      if (!item) item = this.$refs.formMaster.getModel()
      
      // if (! itemAlt ) item = this.$refs.formMaster.getModel()
      // const item = this.$refs.formMaster.getModel()

      // mask
      const vMaks = this.inputsMaster.filter(x => x.vMask)
      for (const x of vMaks) {
        const vMask = x.vMask.replaceAll("#","")
        item[x.value] = item[x.value].replaceAll(vMask, "")
      }

      // imposto
      if (this.impostos && (!this.impostos.noData || (this.impostos.itens && this.impostos.itens.length > 0))) { item.impostos = {itens: this.impostos.itens} }
      
      // itemsDetails
      let itemsDetails
      if (this.typeDetails == 'data-table') {
        itemsDetails = this.$refs.formDetails.getModel().filter(this.filterItemsFunction).map(item => ({data: item}))
      }

      // totalize
      const totalize = this.$refs.formDetails?.getTotalize()
      if (totalize && totalize.length > 0) {
        item._totalize = {}
        for (const total of totalize) {
          item._totalize[total.value] = total.original
        }
      }
      
      if (!(this.claimRoute === 'add' && this.id) && this.claimRoute !== 'copy') {
        const id = this.id
        if (!item.id && id)
          item.id = id
      }

      // Dispatch Before
      if (this.computedDispatch.length > 0) {
        this.computedDispatch.filter(x => x.when == 'before' && x.method == this.method).forEach(el => {
          if (!el.switch || item[el.switch]) {
            this.dispatchAction({action: el.action, params: this.proccessParams({params: el.params, item})})
          }
        });
      }

      //adiciono o total da venda no item
      // if(this.collection === 'SC5') item['C5_XTOTPED']  = this.$refs.formDetails?.getTotal()

      if (this.props?.forceCalcImp) { 
        this.setLoading({stats: true,  message: "Calculando imposto... Por favor aguarde."})
        await this.calcImps().catch(err => {
          this.$toast.error('Erro no calculo do imposto', {position: 'top-right'})
          throw err
        }).finally(() => this.setLoading({stats: false}))
        
        item.impostos = {itens: this.impostos.itens}  
      }

      item.filialTenantId = this.companySelected.M0_CODFIL

      const response = await this.save({
        integration: this.integration,
        collection: this.collection,
        collectionDetails: this.collectionDetails,
        item,
        itemsDetails,
        submitToErp,
        oldVersion: this.currentVersion
      })
      
      // Dispatch After
      if (this.computedDispatch.length > 0) {
        this.computedDispatch.filter(x => x.when == 'after' && x.method == this.method).forEach(el => {
          if (!el.switch || item[el.switch]) {
            this.dispatchAction({action: el.action, params: this.proccessParams({params: el.params, item})})
          }
        });
      }

      return response
    },

    proccessParams({item, params}) {
      const ret = {}
      for (const key in params) {
        if (Object.prototype.hasOwnProperty.call(params, key)) {
          const el = params[key]
          const pathItem = el.split('.')
          const lenItem = pathItem.length
          if (lenItem == 1) {
            ret[key] = pathItem[0]
          } else {
            for (let ix = 1; ix < pathItem.length; ix++) {
              const el2 = pathItem[ix];
              if (typeof item[el2] != 'undefined') { ret[key] = item[el2] }
              else { ret[key] = '' ; break }
            }
          }
        }
      }
      return ret
    },

    goBack() {      
      this.$router.go(-1)
      // this.$router.push(`/${this.register}/listar`)
    },

    goBonus() {
      this.$router.push(`/${this.register}/bonificar/${this.item.id}`)
    },

    async approval(object) {     
      this.dialogApproval = object 
      
      if (this.dialogApproval.combo) {
        this.getComboBox()
      }
      
      this.$set(this.dialogApproval, 'menu', true)
    },
    async getComboBox() {
      const comboItems = await this.get({
          collection: 'ZZE', 
          limit: 9999,
          forceFilter: false,
          setRegister: false
      })

      this.comboMotivos.items = []
      if (comboItems.length > 0) {
        comboItems.forEach( e => { this.comboMotivos.items.push( e.ZZE_COD+ ' - '+ e.ZZE_DESC) })
      }
    },
    async toApprove({item, sendToErp = false}) {
      let _item = this.$refs.formMaster.getModel()
      
      _item = {
        ..._item,
        ...item,
        [this.dialogApproval.justify.field]: this.dialogApproval.justify.message
      }
    
      this.send(sendToErp, _item)        
      this.goBack()        
      this.$set(this.dialogApproval, 'menu', false)
    },

    async openListDanfes(path) {      
      this.setLoading({ stats: true })
      try {
        const {nfs} = await this.getApi({ url: '/protheus/', options: { params: { path }} })
        if (nfs) {
          this.listDanfes = nfs
        }
      } catch(error) {
        this.setLoading({ stats: false }); 
      }
      this.setLoading({ stats: false })      
      this.danfes.dialog = true;
    },

    async danfeDownload(path, filial) {      
      this.setLoading({ stats: true, message: 'Obtendo Danfe...' }); 
      // let shoies = Object.keys(this.companies).map(c => this.companies[c]['sigamat'])
      let storedCompanies = JSON.parse(localStorage.getItem('storedDefaultCompanies')) || []
      let emp = storedCompanies[0].company.M0_CODIGO
      
      try { 
          const { base64 } = await this.getApi({ url: '/protheus/', options: { params: { path: 'ufbase64/danfe/' + path, tenantId: emp+","+filial } } }); 
          if (base64) { 
            this.generatePdf({ base64str: base64 }) 
          } 
          this.setLoading({ stats: false }); 
      } catch (error) { 
        this.setLoading({ stats: false }); 
        } 
    },

    async openListObjects(path) {      
      this.setLoading({ stats: true })
      try {
        const {objetos} = await this.getApi({ url: '/protheus/', options: { params: { path }} })
        if (objetos) {
          this.listObjetos = objetos
        }
      } catch(error) {
        this.setLoading({ stats: false }); 
      }
      this.setLoading({ stats: false })      
      this.objetos.dialog = true;
    },

    async objetoDownload(path, filial) {      
      this.setLoading({ stats: true, message: 'Obtendo arquivo...' }); 
      // let shoies = Object.keys(this.companies).map(c => this.companies[c]['sigamat'])
      let storedCompanies = JSON.parse(localStorage.getItem('storedDefaultCompanies')) || []
      let emp = storedCompanies[0].company.M0_CODIGO
      
      try {
          const { base64 } = await this.getApi({ url: '/protheus/', options: { params: { path: 'ufbase64/objetocontrato/' + path, tenantId: emp+","+(filial ? filial : '0101') } } }); 
          if (base64) { 
            this.generatePdf({ base64str: base64 }) 
          } 
          this.setLoading({ stats: false }); 
      } catch (error) { 
        this.setLoading({ stats: false }); 
        } 
    },

    async pedidoDownload(path) {      

      let retBase64 = ''

      this.setLoading({ stats: true, message: 'Obtendo pedido...' }); 
      try { 
          const { base64 } = await this.getApi({ url: '/protheus/', options: { params: { path: 'ufbase64/pedido/' + path } } }); 
          retBase64 = base64
          this.setLoading({ stats: false }); 
      } catch (error) { 
        this.setLoading({ stats: false }); 
      } 
      
      return retBase64

    },
    async orcamentoDownload(path) {      

      let retBase64 = ''

      this.setLoading({ stats: true, message: 'Obtendo pedido...' }); 
      try { 
          const { base64 } = await this.getApi({ url: '/protheus/', options: { params: { path: 'ufbase64/orcamento/' + path } } }); 
          retBase64 = base64
          this.setLoading({ stats: false }); 
      } catch (error) { 
        this.setLoading({ stats: false }); 
      } 

      return retBase64
    },

    handleItem(item) {
      return item
    },

    async fetchDetails() {
      if (!this.collection || !this.$route.params.id || !this.collectionDetails) {
        this.ready = true
        return
      }

      const collection = `customers/${this.companySelected.groupCompany}/${this.collection}`
      const ref = this.$db().collection(collection).doc(this.$route.params.id).collection(this.collectionDetails)
      const res = await this.get({collection, ref, limit: 1000})

      if (res)
        this.itemsDetails = res

      if (this.claimRoute !== 'add') {
        // this.currentVersion[this.collectionDetails] = this.itemsDetails
      }
      this.ready = true
      return res
    },

    startAutoSave() {
      this.autoSave.timer = setTimeout(this.tryAutoSave, this.autoSave.firstSaveTime)
    },

    async tryAutoSave() {
      this.autoSave.timer = null
      try {
        const response = await this.send()
        this.$set(this.item, 'id', response.data.data.id)
      } catch (err) {
        console.error(err)
      } finally {
        this.autoSave.timer = setTimeout(this.tryAutoSave, this.autoSave.saveTime)
      }
    },

    /**
     * Transforma { a: { b: 1 } } em {'a.b': 1}
     * Ps: Funciona apenas para um nível de objeto
     * @TODO Generalizar para mais níveis
     * @TODO Funcionar com arrays.
     */
    normalizeItem(item) {
      Object.entries(item).forEach(([key,val]) => {
        if (typeof val == 'object' && val) {
          Object.entries(val).forEach(([k, v]) => {
            if (typeof v == 'object' && v) {
              Object.entries(v).forEach(([i, j]) => {
                const newKey = key + '.' + k + '.' + i
                item[newKey] = j
              })
            }
            const newKey = key + '.' + k
            item[newKey] = v
          })
        } 
      })
    },

    async filterSA7(value) {
      this.disableSA7 = !value
      const item = this.$refs.formMaster.getModel()
      await this.getTableItems({collection: this.tableCollection, querySubcollection: this.querySubcollection, fieldsReturn: this.fieldsReturn, idForeign: this.tableIdForeign, subCollection: this.tableSubcollection, filtersSubcollection: this.filtersSubcollection, orderSubcollection: this.orderSubcollection, id: item[this.tableIdFrom]})
    },

    async getTableItems({collection, id, idForeign, fieldsReturn, subCollection, filtersSubcollection, orderSubcollection, querySubcollection}) {
      
      if(!id){
        this.validatedDetails = false
        return this.tableItems = []
      } 

      let response = []
      let subCollectionItems
      if (querySubcollection) {
        response = await this.getExternal({
          setRegister: false,
          allRegs: true,
          ordem: orderSubcollection,
          idField: idForeign,
          query: querySubcollection,
          register: subCollection,
          filters: [[`${idForeign}:${id}`]]
        })
        subCollectionItems = response && response.RETORNOS ? response.RETORNOS : response
      // } else if (getExternal) {
      //   response = await this.getApi({generic: {tabela: subCollection, campos: fieldsReturn, filtro: `${idForeign}='${id}'`, indice: 1 }}) 
      } else {
        response = await this.getById({collection, id, subCollection, filtersSubcollection, orderSubcollection, setRegister: false})
        if (response && response[subCollection]) {
          subCollectionItems = response[subCollection]
          delete response[subCollection]
        }
      }
     
      // delete response[subCollection]

      if (!response || !subCollectionItems || !subCollectionItems.length > 0){
        this.validatedDetails = false
        return this.tableItems = []
      }
     
      if (subCollection == 'DA1') {
        // Removendo itens duplicados, deixando a data de vigencia ativa mais recente
        const now = this.$moment(new Date()).format("YYYYMMDD")
        const filteredByValidity = subCollectionItems
          // Filtra itens apenas dentro da vigencia
          .filter(x => x.DA1_DATVIG <= now)
          // Ordena itens pelo código e vigencia, DESC
          .sort((a, b) => {
            if (a.DA1_CODPRO+a.DA1_DATVIG > b.DA1_CODPRO+b.DA1_DATVIG) {return -1}
            else if (a.DA1_CODPRO+a.DA1_DATVIG < b.DA1_CODPRO+b.DA1_DATVIG) {return 1}
            else {return  0}
          })
          // Remove duplicados com base no código
          .filter((value, index, self) =>
            index === self.findIndex((t) => (
              t.DA1_CODPRO === value.DA1_CODPRO
            ))
          )
          // Retorna a ordenação padrão
          .sort((a, b) => {
            if (a[orderSubcollection] < b[orderSubcollection]) {return -1}
            else if (a[orderSubcollection] > b[orderSubcollection]) {return 1}
            else {return  0}
          })

        subCollectionItems = filteredByValidity
      }
      
      if (subCollection == 'DA1' && this.tableSA7) {
        // Verifica se existe amarração x cliente (SA7)
        const SA7 = await this.getSA7()
        const SA7Cod = SA7.map(x => x.A7_PRODUTO.trim())
        if (!this.disableSA7) { 
          subCollectionItems = subCollectionItems.filter(x => SA7Cod.includes(x.DA1_CODPRO.trim())).map(x => ({...x, ...SA7.find(y => y.A7_PRODUTO.trim() == x.DA1_CODPRO.trim()), A7_YINCLUIR:false}))
        } else {
          const itemsFilterFunction = this.$refs.formDetails.getModel().filter(this.filterItemsFunction)
          subCollectionItems = subCollectionItems.map(x => {
            const y = itemsFilterFunction.find(z => z.C6_PRODUTO?.trim() === x.DA1_CODPRO.trim())
            if (y) {
              x = {...y,...x};
            }
            x.A7_YINCLUIR = true
            const ix = SA7.findIndex(y => y.A7_PRODUTO.trim() == x.DA1_CODPRO.trim())
            if (ix > -1) {
              x = {
                ...x,
                ...SA7[ix],
                A7_YINCLUIR: false
              }
            }
            return x
          })
        }
        this.inputsDetailsFiltered = true
      }

      this.tableItems = subCollectionItems.map(item => {
        let filteredItem = {}
        this.headersDetails.forEach(header => {
          filteredItem[header.value] = (header.fill && item[header.fill]) ? item[header.fill] : item[header.value]
          
          if (typeof filteredItem[header.value] === 'undefined') {
            filteredItem[header.value] = header.default //this.$refs.formDetails.save(item, header, header.default)
          }
        })
        return filteredItem
      })
    },

    async getSA7() {
      const item = this.$refs.formMaster.getModel()
      if (item && item.SA1 && item.SA1.A1_COD) {
        return this.get({
          collection: 'SA7', 
          filters: [
            {key: "A7_CLIENTE", operator: '==', value: item.SA1.A1_COD},
            {key: "A7_LOJA", operator: '==', value: item.SA1.A1_LOJA},
          ], 
          limit: 9999,
          forceFilter: true,
          setRegister: false
        })
      } else {
        return []
      }
    },

    async setTableBills({item}) {
      let filters = ``

      if (this.bills.filters) {
        if (this.bills.filters.includes('=>')) {
          const filtersComputed = eval(this.bills.filters)
          filters = filtersComputed(item)
        } else if (this.bills.filters.includes('`'))  {
          filters = eval(this.bills.filters)
        } else {
          filters = this.bills.filters
        }
      }

      this.itemsBills = await this.getExternal({
        setRegister: false,
        allRegs: true,
        ordem: this.bills.order,
        idField: this.bills.idField,
        query: this.bills.query,
        filters,
        register: `${this.register}.bills`
      })
    },
    
    /**
     * Gera o pdf para ser exibido na tela e posteriormente baixado enviado por email
     */
    async blobTo64({blob}) {
      return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      })
    },
    async generatePdf({base64str, objectStorage, url}) {
      if (!base64str && this.companySelected.groupCompany == 'decanter' && this.item.C5_NUM) {
        base64str = await this.pedidoDownload(this.item.C5_FILIAL +  '/' + this.item.C5_NUM)        
      } else if (!base64str && this.companySelected.groupCompany == 'decanter' && ( !this.item.status || this.item.status.trim() === 'Rascunho') ) {
        this.setLoading({stats: true})
        base64str = await this.imprimePedido()
      }

      if (!base64str && this.companySelected.groupCompany == 'samtronic' && this.item.CJ_NUM) {
        base64str = await this.orcamentoDownload(this.item.CJ_FILIAL +  '/' + this.item.CJ_NUM)        
      } else if(!base64str && this.companySelected.groupCompany == 'samtronic') {
        base64str = await this.imprimePedido()
      }

      
      this.setLoading({stats: true})
      if(base64str){
        // decode base64 string, remove space for IE compatibility
        var binary = atob(base64str.replace(/\s/g, ''));
        var len = binary.length;
        var buffer = new ArrayBuffer(len);
        var view = new Uint8Array(buffer);
        for (var i = 0; i < len; i++) {
            view[i] = binary.charCodeAt(i);
        }
        const doc = new jsPDF()
        var blob = new Blob( [view], { type: "application/pdf" });
        // const link = document.createElement('a')
        // link.href = URL.createObjectURL(blob)
        // link.click()
        

        // this.jsPdf.doc = blob
        // this.jsPdf.dialog = true
        
        this.jsPdf.blob = blob
        this.jsPdf.dialog = true
        this.setLoading({stats: false})
      } else if (this.printLayout && this.printLayout.active && this.printLayout.link) {
        const header = this.$refs.formMaster.getModel()
        const items = this.$refs.formDetails?.getModel()

        if (this.props?.forceCalcImpPdf) { 
          this.setLoading({stats: true,  message: "Calculando imposto... Por favor aguarde."})
          await this.calcImps().catch(err => {
            this.$toast.error('Erro no calculo do imposto', {position: 'top-right'})
            throw err
          }).finally(() => this.setLoading({stats: false}))
          
          header.impostos = {itens: this.impostos.itens}  
        }

        const dataPrint = { 
          data: {            
            company: this.companySelected, 
            header, 
            items, 
            impostos: this.impostos,
            user: this.user
          }, 
          typeReturn: 'base64', 
          source: 'internal', 
          pathFileEJS: this.printLayout.link 
        }
        const { base64 } = await this.generatePDF({ dataPrint, register: this.collection })
        
        if ( base64 ) {
          const binary = atob(base64.replace(/\s/g, ''));
          const len = binary.length;
          const buffer = new ArrayBuffer(len);
          
          let view = new Uint8Array(buffer);
          for (let i = 0; i < len; i++) {
              view[i] = binary.charCodeAt(i);
          }
          
          const blob = new Blob( [view], { type: "application/pdf" });
          this.jsPdf.blob = blob
          this.jsPdf.dialog = true
        }
        
        this.setLoading({stats: false})
      } else if (url) this.jsPdf.url = url
      else if (objectStorage) {
        var xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        
        xhr.onload = async (event) => {
          this.jsPdf.blob = xhr.response
          // this.jsPdf.doc = await this.blobTo64({blob: xhr.response})
          this.jsPdf.dialog = true
          this.setLoading({stats: false})
        };
        xhr.open('GET', objectStorage.documentUrl);
        xhr.send();
      }
      else {
        try {
          const doc = new jsPDF();
          const fontSize = 7
          let lineHeight = fontSize
          const fontFamily = "helvetica"
          const tableLineWidth = 0.2
          const tableLineColor = [125, 125, 125]
          const hexToNumber = str => eval('0x' + str)
          const fillColor = this.colorPdfTable && [
            hexToNumber(this.colorPdfTable.slice(1,3)),
            hexToNumber(this.colorPdfTable.slice(3,5)),
            hexToNumber(this.colorPdfTable.slice(5,7)),
          ] || [ 41, 128, 186] // azul padrão de header
    
          
          // altere o valor para determinar qual linha deve começar o documento
          let line = 2
    
          const size = {
            x: 210,
            y: 297 
          }
    
          const item = this.$refs.formMaster.getModel()
    
          /**
           * Logo
           * addImage ref: https://artskydj.github.io/jsPDF/docs/module-addImage.html
           */
          const image = (this.company?.configs?.logoPdf) ? await this.loadImage(this.company.configs.logoPdf) : await this.loadImage()
          doc.addImage(image, 'JPEG', 5, lineHeight, 16, 9)
    
          /**
           * Título
           */
          doc.setFont(fontFamily)
          doc.setFontSize(2.5 * fontSize)
          /** @TODO parametrizar o título */

          doc.text(eval(this.titleOfPrint || "'Impressão'"), size.x/2, line * lineHeight, { align: 'center' });
          doc.setFontSize(fontSize)
          line++
    
          /**
           * Empresa
           */
          const colsEmpresa = 3
          const company = this.company
          const printCompanyHeaders = (this.printCompanyHeaders && this.printCompanyHeaders.length > 0) ? this.printCompanyHeaders : []
          const empresaItems = printCompanyHeaders.map(pcH => {
            const valueCompiled = eval(pcH.value)
            return {...pcH, value: valueCompiled}
          })
          
          const empresaBody = []
          for (let row = 0; row < empresaItems.length / colsEmpresa; row++) {
            const rowInputs = empresaItems.slice(row*colsEmpresa, (row*colsEmpresa) + colsEmpresa)
            empresaBody.push(rowInputs.reduce((acc, item) => {
              const label = {
                content: item.label,
                styles: {
                  fillColor,
                  textColor: [255, 255, 255], 
                  fontStyle: 'bold',
                  lineWidth: 0.1,
                  lineColor: [171, 206, 228]
                },
              }
              const value = {
                content: item.value,
              }
    
              return [...acc, label, value]
            }, []))
          }

          empresaBody.push([{
            content: '',
            colSpan: 6,
            styles: {
              cellPadding: 3,
              lineWidth: { top: 0.1, left: 0, right: 0, bottom: 0.2 },
              lineColor: tableLineColor
            },
          }])

          // doc.autoTable({
          //   startY: line * lineHeight,
          //   body: empresaBody,
          //   margin: { top: 25, right: 5, bottom: 10, left: 5 },
          //   styles: { 
          //     halign: 'center', 
          //     fontSize, 
          //     cellPadding: 1 
          //   },
          //   headStyles: { halign: 'center', fontStyle: 'bold' },
          //   tableLineWidth,
          //   tableLineColor,
          // })

          /**
           * Agrupar os inputMaster por quadrante de impressão
           */

          let inputMasterPerBox = []

          let inputsMaster = this.computedInputsMaster.filter(im => im.value && im.print && !im.returnObject)
          if (inputsMaster && !inputsMaster.length > 0) {
            inputsMaster = this.computedInputsMaster.filter(im => im.value && !im.returnObject)
          }
          inputsMaster.forEach(im => {
            const box = (im.print && im.print.box) ? im.print.box : 1
            const imBox = (inputMasterPerBox && inputMasterPerBox.length > 0) ? inputMasterPerBox[box - 1] || [] : []
            inputMasterPerBox[box - 1] = [...imBox, im]
          })
         
          /**
           * Cabeçalho
           */
          // Define o número de colunas por tabela
          const cols = 3

          const body = [...empresaBody]

          inputMasterPerBox.forEach((imBox, index) => {
            const inputs = (imBox && imBox.length > 0 && imBox[0].print && imBox[0].print.order) ? imBox.sort((a,b) => {
              if (a.print.order < b.print.order) {
                return -1
              }
              if (a.print.order > b.print.order) {
                return 1
              }
              return 0
            }) : imBox

            const inputsNoColSpan = inputs.filter(i => !(i.print && i.print.colSpan))
            const inputsColSpan = inputs.filter(i => i.print && i.print.colSpan)
            
            const inputsAll = [{colSpan: false, inputs: inputsNoColSpan}, {colSpan: true, inputs: inputsColSpan}]

            for (const inputsAux of inputsAll) {
              
              const colsAux = inputsAux.colSpan ? 1 : cols

              for (let row = 0; row < inputsAux.inputs.length / colsAux; row++) {
                
                const rowInputs = inputsAux.inputs.slice(row*colsAux, (row*colsAux) + colsAux)
                body.push(rowInputs.reduce((acc, input) => {
                  let text = item[input.value] || eval(`item.${input.value}`) || ''
                  if (input.type == 'select') {
                    const selectItem = input.items.find(i => i.value == item[input.value])
                    if (selectItem) 
                      text = selectItem.text || item[input.value]
                  } else if (input.type === 'switch') {
                    text = item[input.value] ? 'Sim' : 'Não'
                  }
        
                  /**
                   * Configuração do Label
                   * ref: https://github.com/simonbengtsson/jsPDF-AutoTable#options
                   */
                  const label = {
                    content: input.label,
                    styles: {
                      fillColor,
                      textColor: [255, 255, 255], 
                      fontStyle: 'bold',
                      lineWidth: 0.1,
                      lineColor: [171, 206, 228]
                    },
                  }
                  
                  /**
                   * Configuração do texto do valor do item
                   */

                  const colSpan = input.print && input.print.colSpan ? 5 : null

                  const value = typeof text === 'string' ? {content: text.trim(), colSpan} : {content: text, colSpan}
        
                  return [...acc, label, value]
                }, []))
              }
            
            }

            if ((index + 1) < inputMasterPerBox.length) {
              body.push([{
                content: '',
                colSpan: 6,
                styles: {
                  cellPadding: 3,
                  lineWidth: { top: 0.1, left: -1, right: -1, bottom: 0.2 },
                  lineColor: tableLineColor
                },
              }])
            }
    

            // doc.autoTable({
            //   startY: doc.lastAutoTable.finalY + lineHeight,
            //   body,
            //   margin: { top: 25, right: 5, bottom: 10, left: 5 },
            //   styles: {
            //     fontSize,
            //     cellPadding: 1,
            //   },
            //   tableLineWidth,
            //   tableLineColor,
            // })
          })

          doc.autoTable({
            // startY: doc.lastAutoTable.finalY + lineHeight,
            startY: line * lineHeight,
            body,
            margin: { top: 25, right: 5, bottom: 10, left: 5 },
            styles: {
              fontSize,
              cellPadding: 1,
            },
            tableLineWidth,
            tableLineColor
          })
          
          /**
           * Impostos
           */
          if (this.impostos && this.impostos.headers && this.impostos.headers.length > 0) {
            const head = [
              [{ content: 'Impostos', colSpan: this.impostos.headers.length, styles: { halign: 'center' }}],
              this.impostos.headers.map(el => ({content:el.text}))
            ]
            const impostosBody = [this.impostos.headers.map(el => {
              const value = this.impostos.itens[0][el.value]
              return `R$ ${value.toLocaleString('pt-br', { minimumFractionDigits:2, maximumFractionDigits: 2 }) }`
            })]
            doc.autoTable({
              startY: doc.lastAutoTable.finalY + lineHeight,
              head,
              body: impostosBody,
              margin: { top: 25, right: 5, bottom: 10, left: 5 },
              styles: { halign: 'center', fontSize, fillColor, cellPadding: 1 },
              headStyles: { halign: 'center', fontStyle: 'bold' },
              tableLineWidth,
              tableLineColor,
            })
    
            if (this.impostosPdfText)
              doc.text(this.impostosPdfText, 5, doc.lastAutoTable.finalY + (lineHeight/2))
          }
    
          /**
           * Totais
           */
          this.docPdfTotais(doc, { lineHeight, fontSize, fillColor, tableLineWidth, tableLineColor })
    
          /**
           * Tabela de itens
           */
          let headersItens = this.computedHeaders.filter(header => header.print)
          if (headersItens && !headersItens.length > 0) {
            headersItens = this.computedHeaders.filter(header => !header.noprint)
          } else {
            headersItens = headersItens.sort((h1, h2) => {
              if (h1.print.order < h2.print.order) {
                return -1
              }
  
              if (h1.print.order > h2.print.order) {
                return 1
              }
  
              return 0
            })
          }
       
          const head = [headersItens.map(h => h.text)]
          const headKeys = headersItens.map(h => h.value)

          const table = this.$refs.formDetails?.getTable()
       
          if(table){
            const bodyTableItems = []
            for (const row of table.rows) {
              const item = row.item
       
              if (!this.filterItemsFunction(item))
                continue
              
              bodyTableItems.push(headKeys.map(header => {
                const value = row.getCellFromHeader(header).text
                return (typeof value === 'string') ? value.trim() : value
              }))
            }
            // ref: https://github.com/simonbengtsson/jsPDF-AutoTable
            doc.autoTable({
              startY: doc.lastAutoTable.finalY + lineHeight,
              head,
              body: bodyTableItems,
              margin: { top: 25, right: 5, bottom: 10, left: 5 },
              styles: { fontSize, cellPadding: 1  },
              headStyles: { fontStyle: 'bold', fillColor, },
              tableLineWidth,
              tableLineColor,
            })
          }
    
          /**
           * Totais (fim da tabela)
           */
          this.docPdfTotais(doc, { lineHeight, fontSize, fillColor, tableLineWidth, tableLineColor })
    
          this.jsPdf.doc = doc
          this.jsPdf.dialog = true
        } catch(e) {
          console.error(e)
        } finally {
          this.setLoading({stats: false})
        }
      }
    },

    /**
     * Carrega a imagem da logo da empresa para por no PDF
     */
    async loadImage(logo) {
      // puxar a imagem da empresa de alguma variável
      const url = logo ?  require(`@/assets/${this.company.groupCompany}Logo.png`) : require('@/assets/logo.png')
      const response = await axios.get(url, { responseType: 'blob' })
      const blob = response.data
      return new Promise((resolve, reject) => {
        var fr = new FileReader();  
        fr.onload = () => {
          resolve(fr.result)
        };
        fr.onerror = reject;
        fr.readAsDataURL(blob);
      });
    },

    docPdfTotais(doc, { lineHeight, fontSize, fillColor, tableLineWidth, tableLineColor }) {
      const headers = this.computedHeaders.filter(header => {
        if ('exportPdf' in header)
          return header.exportPdf === true || header.exportPdf == 'totais'
        else
          return true
      })
      const totalize = this.$refs.formDetails?.getTotalize().filter(el => !!headers.find(h => h.value == el.value))
      if (totalize) {
        const head = [
          [{ content: 'Totais', colSpan: totalize.length, styles: { halign: 'center' }}],
          totalize.map(el => el.label)
        ]
        const body = [totalize.map(el => el.text)]
        doc.autoTable({
          startY: doc.lastAutoTable.finalY + lineHeight,
          head,
          body,
          margin: { top: 25, right: 5, bottom: 10, left: 5 },
          styles: { halign: 'center', fontSize, fillColor, cellPadding: 1 },
          headStyles: { halign: 'center', fontStyle: 'bold' },
          tableLineWidth,
          tableLineColor,
        })

        if (eval(this.totaisPdfText))
          doc.text(this.totaisPdfText, 5, doc.lastAutoTable.finalY + (lineHeight/2))
      }
    },

    /** @TODO parametrizar o nome do documento */
    savePdf() {
      if (this.jsPdf.doc)
        this.jsPdf.doc.save("pedido.pdf")
      else if (this.jsPdf.blob) {
        fs.saveAs(this.jsPdf.blob, 'Arquivo -' + new Date().valueOf() + '.pdf')
      }

        console.warn('jsPdf.doc not yet defined.')
    },

    async sendPdf({ to, text, bcc, cc, subject }) {
      let response
      try {
        this.jsPdf.sendEmail.sending = true
        if (this.jsPdf.doc) {
          response = await this.$api.post(`/mail/send`, { to, text, id: this.id, bcc, cc, subject, attachment: this.jsPdfOutput })
        
          this.jsPdf.sendEmail.dialog = false
          this.$toast.success('Email enviado!', { position: 'top-right' })
        } else if (this.jsPdf.blob) {
          var reader = new FileReader();
          reader.readAsDataURL(this.jsPdf.blob); 
          reader.onloadend = async () => {
            var base64data = reader.result;                
            response = await this.$api.post(`/mail/send`, { to, text, id: this.id, bcc, cc, subject, attachment: base64data })
        
            this.jsPdf.sendEmail.dialog = false
            this.$toast.success('Email enviado!', { position: 'top-right' })
          }

        }
      } catch (err) {
        response = err
      } finally {
        this.jsPdf.sendEmail.sending = false
      }

      return response
    },

    jsPdfHandleDialog(value) {
      if (value) {
        const item = this.$refs.formMaster.getModel()

        const integration = this.integrations.find(i => i.collection === this.collection)
        const { 
          toDefault, 
          subjectDefault,
          textDefault, 
          enableCcDefault: enableCc, 
          ccDefault, 
          enableBccDefault: enableBcc,
          bccDefault
        } = integration?.emailConfigs || { toDefault: '""', textDefault: '""', subjectDefault: '""', enableCcDefault: false, ccDefault: '""', enableBccDefault: false, bccDefault: '""' }

        const to = eval(toDefault)
        const text = eval(textDefault)
        const subject = eval(subjectDefault)
        const cc = eval(ccDefault)
        const bcc = eval(bccDefault)

        /** @TODO parametrizar */
        // const to = item['SA1'] && item['SA1']['A1_EMAILPDF'] && item['SA1']['A1_EMAILPDF'].trim() || ''
        this.jsPdf.sendEmail.model = {
          to,
          text,
          subject,
          cc,
          bcc
        }

        this.jsPdf.sendEmail.inputs = this.jsPdf.sendEmail.inputs.map(i => {
          if (i.value === 'cc')
            return {...i, hide: !enableCc}
          else if (i.value === 'bcc')
            return {...i, hide: !enableBcc}
          return i
        })
      } else {
        this.jsPDF.sendEmail.model = null
      }
    },
    showCarousel(images) {// showCarousel({item, head })
      this.imagesDialog = images;
    },
  },

  async created() {
    // Filtra registros
    const filterRegister = this.inputsMaster.filter(input => input.filterRegister)
    filterRegister.forEach(el => {
      if (!el.on) el.on = {}
      if (!el.on.change) el.on.change = []

      const arrFilter = Array.isArray(el.filterRegister) ? el.filterRegister : [el.filterRegister]
      arrFilter.forEach(arr => {  
        const filter = eval(arr.filter)
          el.on.change.push(
            (value) => {
              const formMaster = this.$refs.formMaster ? this.$refs.formMaster.getModel() : {}
              const register = this.$_.cloneDeep(this.registers[arr.register])
              if (register && register.length > 0) {
                const ret = filter({register: this.$_.cloneDeep(this.registers[arr.register]), value, item: formMaster })
                this.$set(this.registers, [`${arr.register}Filtered`], ret)
              }
            });        
          this.$set(this.registers, [`${arr.register}Filtered`], this.$_.cloneDeep(this.registers[arr.register]))
        })
      }
    );   

    this.validatedDetails = !this.typeDetails || this.typeDetails === 'none'

    if(this.claimRoute === 'copy'){
      const item = await this.getById({collection: this.collection, register: this.register, id: this.$route.params.id, getFilial: false})
      this.normalizeItem(item)

      const filteredKeys = this.inputsMaster.filter(im => im.claimCopy).map(im => im.value);

      //filtrando as propriedades do objeto item que eu preciso para copiar os dados do cliente
      this.item = Object.assign({}, ...filteredKeys.map(key=> ({[key]:item[key]})));
      await this.fetchDetails()

      if (item[this.tableIdFrom] /*&& !this.readonlyComputed*/ ) {
        // this.itemFormMaster = this.$refs.formMaster.getModel() 
        if (this.onDemand) {
          this.tableIdForeignValue = item[this.tableIdFrom]
        } else {
          await this.getTableItems({collection: this.tableCollection, querySubcollection: this.querySubcollection, fieldsReturn: this.fieldsReturn, idForeign: this.tableIdForeign, subCollection: this.tableSubcollection, filtersSubcollection: this.filtersSubcollection, orderSubcollection: this.orderSubcollection, id: item[this.tableIdFrom]})
        }
      }

      // encontre todos os campos que são identificadores
      const headersIdentifier = this.headersDetails.filter(h => h.identifier)
      // encontre todos os campos que não são copiáveis
      const headersNoCopy = this.headersDetails.filter(h => !h.claimCopy)
      
      // remonte o array itemDetails de acordo com os campos copiáveis
      this.itemsDetails = this.itemsDetails.map(iDetail => {
        // encontre o item corrente no tableItems (versão mais recente na tabela details)
        const tItem = this.tableItems.find(_item => headersIdentifier.every(hi => _item[hi.value] === iDetail[hi.value]))

        // caso esse item não pertença mais a subCollection de origem, marque-o como inválido
        if (!tItem)
          return { invalid: true } // sera filtrado após percorrer

        // substitua os valores dos campos não copiáveis. Alimentando, dessa forma, com dados mais recentes
        iDetail = {...iDetail, ...Object.assign({}, ...headersNoCopy.map(hc=> ({[hc.value]:tItem[hc.value]})))}

        return iDetail
      })
      
      this.itemsDetails = this.itemsDetails.filter(item => item && !item.invalid)
    }

    else if (this.id) {
      let handleItem = false
      let item = this.registers[this.register] && this.registers[this.register].find(item => item.id == this.$route.params.id)
      if (!item) item = this.registers[`${this.register}Filtered`] && this.registers[`${this.register}Filtered`].find(item => item.id == this.$route.params.id)
      if (!item) {
        if (this.externalRequest && this.externalRequest.query) {
          item = await this.getExternal({
            ...this.externalRequest,
            register: this.register,
            id: this.$route.params.id
          })
        } else {
          item = await this.getById({collection: this.collection, register: this.register, id: this.$route.params.id, getFilial: false})
        }
      }

      this.normalizeItem(item)
      
      /**
       * TO-DO
       * Tire isso daqui
       */
      if (this.claimRoute === 'add') {
        const { C5_CLIENTE, C5_LOJACLI, SA1, A1_NOME, A1_END, A1_EST, A1_MUN,  A4_NREDUZ, C5_FILIAL, DA0, C5_TABELA, SA4, C5_TRANSP, DA0_DESCRI, C5_NUM, C5_TPFRETE } = item
        this.item = { C5_CLIENTE, C5_LOJACLI, SA1, A1_NOME, A1_END, A1_EST, A1_MUN, A4_NREDUZ, C5_FILIAL, 
          DA0, C5_TABELA, C5_TPFRETE, E4Blocked: true, DA0_DESCRI, SA4, C5_TRANSP, C5_XTIPO: 'B', C5_XPEDORI: C5_NUM
        }
        
      } else {
        this.item = handleItem ? this.handleItem(item) : item 
        this.currentVersion = this.item
      }

      // se for criação, não puxe os impostos, pois é uma cópia
      if (this.claimRoute !== 'add' && (item.impostos || item.IMPOSTOS || item.Impostos) )  
        this.calcImps(item.impostos || item.IMPOSTOS || item.Impostos)

      if (this.claimRoute !== 'add') 
        await this.fetchDetails()

      if (item[this.tableIdFrom] /*&& !this.readonlyComputed*/ ) {
        // this.itemFormMaster = this.$refs.formMaster.getModel() 
        if (this.onDemand) {
          this.tableIdForeignValue = item[this.tableIdFrom]
        } else { 
          await this.getTableItems({collection: this.tableCollection, querySubcollection: this.querySubcollection, fieldsReturn: this.fieldsReturn, subCollection: this.tableSubcollection, idForeign: this.tableIdForeign, filtersSubcollection: this.filtersSubcollection, orderSubcollection: this.orderSubcollection, id: item[this.tableIdFrom]})
        }
      }

      if (this.bills && this.bills.active) {
        // this.itemFormMaster = this.$refs.formMaster.getModel() 
        await this.setTableBills({item: this.computedItemFormMaster})
      }

      if (['SA1', 'SC5', 'SCJ', 'ADA'].includes(this.collection)) {
        const inputsHeaders = this.inputsMaster.filter(input => ['A1_COD', 'A1_LOJA', 'A1_NOME', 'A1_CGC', 'A1_TEL', 'A1_VENCLC', 'A1_VEND'].includes(input.value))
        const inputsSubHeaders = this.inputsMaster.filter(input => ['A1_ULTCOM', 'A1_PRICOM', 'A1_RISCO', 'A1_DTULCHQ', 'A1_DTULTIT', 'A1_LC', 'A1_SALDUP', 'A1_LCFIN', 'A1_CHQDEVO', 'A1_SALFIN', 'A1_SALFINM', 'A1_MCOMPRA', 'A1_MSALDO', 'A1_MATR', 'A1_METR', 'A1_TITPROT', 'A1_SL_DISPONIVEL'].includes(input.value))
        const model = this.collection === 'SA1' ? this.item : (this.item.SA1 || (this.item.A1_COD && this.item.A1_LOJA)) ? await this.getById({collection: 'SA1', id: this.item.SA1?.id || this.item.A1_COD+this.item.A1_LOJA, getFilial: (this.item.SA1?.id) ? false : true}) : {}
        const show = item.A1_COD || item.C5_CLIENTE || item.CJ_CLIENTE
        
        this.customerPositionConfigs = {...this.customerPositionConfigs, inputsHeaders, inputsSubHeaders, model, show}
      }

    } else {
      this.ready = true
    }
    
    this.$nextTick(() => {
      if (!(this.$refs && this.$refs.formMaster)) {
        return
      }
      this.itemFormMaster = this.$refs.formMaster.getModel() 
      this.$refs.formMaster.$on('change:' + this.tableIdFrom, async (id) => {
        // this.itemFormMaster = this.$refs.formMaster.getModel() 
        if (this.onDemand) {
          this.tableIdForeignValue = id
        } else {
          await this.getTableItems({collection: this.tableCollection, querySubcollection: this.querySubcollection, fieldsReturn: this.fieldsReturn, idForeign: this.tableIdForeign, subCollection: this.tableSubcollection, filtersSubcollection: this.filtersSubcollection, orderSubcollection: this.orderSubcollection, id})
        }
      })
      
      if (this.bills && this.bills.active) {
        this.$refs.formMaster.$on('change:' + this.bills.fieldGetBills, async () => {
          // this.itemFormMaster = this.$refs.formMaster.getModel() 
          await this.setTableBills({item: this.computedItemFormMaster})
        })
      }
    })

    if (this.collection === 'SC5') {
      this.$nextTick(() => {
        if (!(this.$refs && this.$refs.formMaster)) {
          return
        }
        this.$refs.formMaster.$on('change:C5_CLIENTE', async (value) => {
          if (value) {
            this.setLoading({stats: true})
            setTimeout(() => {
              const formMaster = this.$refs.formMaster.getModel() 
              const inputsHeaders = this.inputsMaster.filter(input => ['A1_COD', 'A1_LOJA', 'A1_NOME', 'A1_CGC', 'A1_TEL', 'A1_VENCLC', 'A1_VEND'].includes(input.value))
        // const inputsHeaders'] = this.inputsMaster.filter(input => ['A1_COD', 'A1_LOJA', 'A1_NOME', 'A1_CGC', 'A1_TEL', 'A1_VENCLC', 'A1_VEND'].includes(input.value))
              const inputsSubHeaders = this.inputsMaster.filter(input => ['A1_ULTCOM', 'A1_PRICOM', 'A1_RISCO', 'A1_DTULCHQ', 'A1_DTULTIT', 'A1_LC', 'A1_SALDUP', 'A1_LCFIN', 'A1_CHQDEVO', 'A1_SALFIN', 'A1_SALFINM', 'A1_MCOMPRA', 'A1_MSALDO', 'A1_MATR', 'A1_METR', 'A1_TITPROT'].includes(input.value))
              const model = formMaster['SA1']
              const show = true

              this.customerPositionConfigs = {...this.customerPositionConfigs, inputsHeaders, inputsSubHeaders, model, show}
              this.setLoading({stats: false})
            }, 250)
          } else {
            const show = false

            this.customerPositionConfigs = {...this.customerPositionConfigs, show}
          }
        })
      })
    }
    if (this.collection === 'SCJ') {
      this.$nextTick(() => {
        if (!(this.$refs && this.$refs.formMaster)) {
          return
        }
        this.$refs.formMaster.$on('change:CJ_CLIENTE', async (value) => {
          if (value) {
            this.setLoading({stats: true})
            setTimeout(() => {
              const formMaster = this.$refs.formMaster.getModel() 
              const inputsHeaders = this.inputsMaster.filter(input => ['A1_COD', 'A1_LOJA', 'A1_NOME', 'A1_CGC', 'A1_TEL', 'A1_VENCLC', 'A1_VEND'].includes(input.value))
        // const inputsHeaders'] = this.inputsMaster.filter(input => ['A1_COD', 'A1_LOJA', 'A1_NOME', 'A1_CGC', 'A1_TEL', 'A1_VENCLC', 'A1_VEND'].includes(input.value))
              const inputsSubHeaders = this.inputsMaster.filter(input => ['A1_ULTCOM', 'A1_PRICOM', 'A1_RISCO', 'A1_DTULCHQ', 'A1_DTULTIT', 'A1_LC', 'A1_SALDUP', 'A1_LCFIN', 'A1_CHQDEVO', 'A1_SALFIN', 'A1_SALFINM', 'A1_MCOMPRA', 'A1_MSALDO', 'A1_MATR', 'A1_METR', 'A1_TITPROT'].includes(input.value))
              const model = formMaster['SA1']
              const show = true

              this.customerPositionConfigs = {...this.customerPositionConfigs, inputsHeaders, inputsSubHeaders, model, show}
              this.setLoading({stats: false})
            }, 250)
          } else {
            const show = false
            this.customerPositionConfigs = {...this.customerPositionConfigs, show}
          }
        })
      })
    }

    // this.startAutoSave()
  },

  beforeDestroy() {
    clearTimeout(this.autoSave.timer)
  },
}
</script>

<style></style>
