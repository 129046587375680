<template>
  <v-form @submit.prevent="validate() && submit()" ref="form">
    <v-card
      v-if="totalize && totalize.length > 0"
      :dark="dark"
      class="mt-6 pa-0"
    >
      <v-card-text>
        <v-toolbar flat dense short height="35">
          <v-toolbar-title
            :class="`subtitle-2 font-weight-bold ${color}--text ml-n4`"
            >Totais</v-toolbar-title
          >
        </v-toolbar>
        <v-divider class="mb-3"></v-divider>

        <v-list-item class="ma-0 pa-0">
          <v-list-item-content class="ma-0 pa-0 d-inline-block">
            <v-list-item-subtitle
              v-for="({ label, text }, idx, key) in totalize.filter(x => !x.hide)"
              :key="key"
            >
              <b>{{ label }}:</b> {{ text }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card-text>
    </v-card>

    <v-card :dark="dark" class="mt-6 pa-0">
      <v-card-text>
        <FormDataTableToolbar
          :color="color"
          :readonly="readonly"
          :exportXls="exportXls"
          @exportXlsx="exportXlsx"
          @importXlsx="importXlsx"
        >
          <template #prepend-actions>
            <v-btn 
            v-if="calcPriceButton && !readonly"
            color="primary"
            text
            x-small
            @click="calcPrice"
            >
              <v-icon size=22 class="mr-1">mdi-currency-usd</v-icon>
              Calcular Preço
            </v-btn>
            <FormDataTableBatch
              v-if="false/*!readonly*/"
              :table="table"
              :headers="headers"
              @apply="applyBatch"
            >
              <template #append>
                <v-card v-show="!isFiltered" flat color="warning lighten-3">
                  <v-card-text>
                    <v-icon left>mdi-alert</v-icon>
                    ALERTA: Nenhum filtro foi selecionado, as alterações serão
                    aplicadas em TODOS os itens.
                  </v-card-text>
                </v-card>
              </template>
            </FormDataTableBatch>
          </template>
        </FormDataTableToolbar>
        <v-divider class="mb-3"></v-divider>
        <v-data-table
          v-if="computedFilters && computedFilters.length > 0"
          :headers="computedHeaders"
          :items="table ? table.rows : []"
          :footer-props="{ itemsPerPageOptions: [30, 60, 90] }"
          :page="page"
          @update:page="updatePage"
          @update:items-per-page="updateitemsPerPage"
          @update:sort-by="updateSortBy"
          @update:sort-desc="updateSortDesc"
          dense
          item-key="id"
          loading-text="Carregando... Por favor aguarde"
          :loading="loading"
          @current-items="currentItems = $event"
          ref="dataTable"
        >
          <!-- Toolbar -->
          <template #top>
            <!-- Filters | Search -->
            <FormDataTableFilters
              ref="form-data-table-filters"
              :filters="computedFilters"
              :filtersModels="filtersModels"
              :search="search"
              :async="getTableItems"
              :color="color"
            />

            <!-- Validations Errors -->
            <v-row v-show="warningCells.length > 0" no-gutters class="mb-4">
              <v-col cols="12">
                <FormDataTableWarnings
                  :table="table"
                  @click:goToCell="scrollToCell"
                  @change:warningCells="warningCells = $event"
                />
              </v-col>
            </v-row>

            <!-- Validations Errors -->
            <v-row v-show="invalidCells.length > 0" no-gutters class="mb-4">
              <v-col cols="12">
                <FormDataTableErrors
                  :table="table"
                  @click:goToCell="scrollToCell"
                  @change:invalidCells="invalidCells = $event"
                />
              </v-col>
            </v-row>
          </template>

          <!-- Item -->
          <template #item="{ item: row, index: line }">
            <tr
              class="form-data-table__row"
              :class="{ 'red lighten-4': !row.validated(), 'yellow lighten-4': row.validated() && row.warned() }"
              :ref="`form-data-table-tr--${line}`"
            >
              <td
                v-for="(cell, col) in row.cells"
                v-show="(typeof cell.header.hide == 'function') ? !cell.header.hide({readonly, formMaster, user, userRules, companySelected}) : !cell.header.hide"
                :key="line + col"
                :ref="`form-data-table-td--${line}`"
                :id="`line-${cell.y}`"
                class="pa-0 ma-0 text-center"
              >
              <template v-if="cell.header.type == 'select'">
                <v-select                                  
                  :ref="`form-data-table__button--${col}-${line}`"
                  :items='cell.header.items'
                  v-model="cell.value"
                  :disabled="cell.header.disabled"
                  @change="(value) => {
                      inputSelect({ row, cell }, value)                                        
                  }">
                </v-select> 
              </template>

                <template v-else-if="cell.header.type == 'images'">
                  <v-sheet
                    height="60"
                    width="60"
                    class="rounded-circle overflow-hidden d-flex mx-auto"
                  >
                    <v-img
                      @click="callDialog(cell)"
                      v-if="cell.value && cell.value.length > 0"
                      :src="cell.value[0]"
                      max-height="60"
                      max-width="60"
                      contain
                      class="clickable"
                    ></v-img>
                    <v-gravatar v-else default-img="mp"/>
                  </v-sheet>
                </template>
                <template v-else>
                  <button
                    v-show="editing != cell"
                    :ref="`form-data-table__button--${col}-${line}`"
                    :title="cell.validated ? cell.warned ? cell.warnMessages.map(str => str.replace(/<[^>]*>/g, '')).reduce((acc,cur) => `${acc}\n${cur}`) : undefined : cell.errorMessages.map(str => str.replace(/<[^>]*>/g, '')).reduce((acc,cur) => `${acc}; ${cur}`) "
                    :class="`form-data-table__button ${(cell.header.align == 'center') ? 'mx-auto' : (cell.header.align == 'end') ? 'ml-auto' : '' }  ${(cell.editable) ? 'primary--text' : ''} ${cell.validated? '' : 'form-data-table__button--invalid'} ${cell.warned && cell.validated ? 'form-data-table__button--warned' : ''}`"
                    @keydown.up="line == 0 || focusButton(col, line-1)"
                    @keydown.down="line == currentItems.length-1 || focusButton(col, line+1)"
                    @keydown.left="col == 0 || focusButton(col-1, line, -1)"
                    @keydown.right="col == headers.length-1 || focusButton(col+1, line, 1)"
                    @keydown.exact.tab.prevent="col == headers.length-1 || focusButton(col+1, line, 1)"
                    @keydown.shift.tab.prevent="col == 0 || focusButton(col-1, line, -1)"
                    @keydown.enter="cell.editable && (locked = true, clickButton(cell, col, line))"
                    @keydown.exact.prevent="handleButtonKeydown({cell, col, line}, $event)"
                    type="button"
                  >
                    {{ cell.text }}
                  </button>
                  <input
                    v-if="editing == cell"
                    :ref="`form-data-table__input--${col}-${line}`"
                    :value="stuckInput || cell.value"
                    :style="{ width: cell.header.width + 'px', minWidth: '80px' }"
                    :type="
                      cell.header.type == 'float' || cell.header.type == 'integer'
                        ? 'number'
                        : 'text'
                    "
                    @blur="inputBlur({ row, cell }, $event)"
                    @keydown.exact.enter="
                      line < currentItems.length - 1
                        ? focusButton(col, line + 1)
                        : ($event.target.blur(), focusButtonItself(col, line))
                    "
                    @keydown.shift.enter="
                      line > 0
                        ? focusButton(col, line - 1)
                        : ($event.target.blur(), focusButtonItself(col, line))
                    "
                    @keydown.up="
                      locked ||
                        line == 0 ||
                        ($event.preventDefault(), focusButton(col, line - 1))
                    "
                    @keydown.down="
                      locked ||
                        line == currentItems.length - 1 ||
                        ($event.preventDefault(), focusButton(col, line + 1))
                    "
                    @keydown.left="
                      locked ||
                        col == 0 ||
                        ($event.preventDefault(), focusButton(col - 1, line))
                    "
                    @keydown.right="
                      locked ||
                        col == computedHeaders.length - 1 ||
                        ($event.preventDefault(), focusButton(col + 1, line))
                    "
                    @keydown.esc="
                      (hasCancelled = true),
                        $event.target.blur(),
                        focusButtonItself(col, line)
                    "
                    class="form-data-table__input"
                  />
                </template>
              </td>
            </tr>
          </template>

          <!-- No Data  -->
          <template #no-data>
            <v-alert
              :value="true"
              color="error"
              icon="mdi-alert-outline"
              class="mt-3"
            >
              <span v-if="isSearched">A pesquisa por "<strong>{{ search.value }}</strong>" não retornou registros, confira os filtros selecionados.</span>
              <span v-else-if="isFiltered">Não retornou registros, confira os filtros selecionados.</span>
              <span v-else-if="SA7Filtered">A amarração cliente x produtos não retornou registros.</span>
              <span v-else>Não há dados disponíveis.</span>
            </v-alert>
          </template>
          
          <!-- No Results  -->
          <template #no-results>
            <v-alert
              :value="true"
              color="error"
              icon="mdi-alert-outline"
              class="mt-3"
            >
              <span>Os filtros selecionados não retornaram registros.</span>
            </v-alert>
          </template>

          <!-- footer.page-text -->
          <template v-slot:[`footer.page-text`]="{ pageStart, pageStop }">
            {{ pageStart }} - {{ pageStop }} de {{ serverItemsLength }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { Cell } from "../../plugins/cell";
import { mapState } from "vuex";

const { mapState: registersState, mapActions: registersActions, mapMutations: registersMutations } = createNamespacedHelpers("registers");

import * as XLSX from "xlsx";
import FormDataTableFilters from "@/components/Registers/FormDataTableFilters";
import FormDataTableToolbar from "@/components/Registers/FormDataTableToolbar";
import FormDataTableErrors from "@/components/Registers/FormDataTableErrors";
import FormDataTableWarnings from "@/components/Registers/FormDataTableWarnings";
import FormDataTableBatch from "@/components/Registers/FormDataTableBatch";
import { Table } from "@/plugins/cell";
export default {
  name: "FormDataTable",
  events: ["showCarousel"],
  components: {
    FormDataTableFilters,
    FormDataTableToolbar,
    FormDataTableErrors,
    FormDataTableWarnings,
    FormDataTableBatch,
  },
  props: {
    props: {
      type: Object,
      default: () => {}
    },
    tableSA7: {
      type: Boolean,
      default: false
    },
    inputsMaster: Array,
    headers: Array,
    tableIdForeignValue: String,
    formMaster: Object,
    readonly: {
      type: Boolean,
      default: false,
    },
    filters: {
      type: Array,
      default: () => [],
    },
    itemsDetails: {
      type: Array,
      default: () => [],
    },
    color: {
      type: String,
      default: "teal",
    },
    dark: {
      type: Boolean,
      default: false,
    },
    exportXls: {
      type: Boolean,
      default: true,
    },
    xlsxName: {
      type: String,
      default: "itens",
    },
    filterItems: {
      type: Function,
      default: (item) => item,
    },
    user: Object,
    userRules: Array
  },

  data() {
    return {
      total: "",
      hasCancelled: false,
      currentItems: [],
      search: {
        value: "",
      },
      timeoutSearch: null,
      openFilter: false,
      filtersModels: {
        invalidCells: false,
        warningCells: false,
      },
      loading: false,
      itemsTotal: 0,
      totalSearched: 0,
      page: 1,
      lastPage: 1,
      endOfRegisters: false,
      editing: {},
      stuckInput: "",
      locked: false,
      table: null,
      invalidCells: [],
      warningCells: [],
      duplicateRows: [],
      tableItems: [],
      SA7: null,
      itemsDetailsEditable: [],
      sortBy: null,
      sortMod: null,
      itensPerPage: 30,
      andFilters: [],
      orFilters: [],
      externalFilter: false,
      firstLoad: false
    }
  },

  computed: {
    ...registersState(['registers']),
    ...mapState(["companySelected"]),

    calcPriceButton(){
      return this.props?.calcPrice
    },

    collection() { return this.props.tableCollection },
    subCollection() { return this.props.tableSubcollection },
    register() { 
      let register = (this.subCollection) ? `${this.collection}.${this.subCollection}` : this.collection 
      if (this.isSearched || this.externalFilter) { register = `${register}Filtered` }
      return register
    },
    filtersSubcollection() { return this.props.filtersSubcollection },
    orderBy() {
      let orderBy = this.props.orderSubcollection 
      if (typeof orderBy == 'string') orderBy = {field: orderBy, mod: 'asc'}
      if (this.sortBy) orderBy.field = this.sortBy
      if (this.sortMod) orderBy.mod = this.sortMod
      return orderBy
    },
    serverItemsLength() { return (this.isSearched) ? this.totalSearched : this.itemsTotal }, 
    
    computedHeaders() {
      let computedHeaders = this.headers.filter((h) => (typeof h.hide == 'function') ? !h.hide({readonly: this.readonly, formMaster: this.formMaster, user: this.user, userRules: this.userRules, companySelected: this.companySelected}) : !h.hide);
      if (this.readonly) {
        computedHeaders = computedHeaders.map((h) => {
          return {
            ...h,
            editable: false,
          };
        })
      }
      return computedHeaders
    },
    
    editableHeaders() { return this.computedHeaders.filter(x => x.editable) },
    notEditableHeaders() { return this.computedHeaders.filter(x => !x.editable) },
    searchableHeaders() { return this.computedHeaders.filter(x => x.searchable) },
    filterSearchable() { return this.computedHeaders.filter(x => x.filterSearchable) },
    updateValueHeaders() { return this.computedHeaders.filter(x => x.updateValueEdit) },

    computedFilters() {
      const filters = this.headers.filter((x) => x.filterable === true);
      filters.forEach((el) => {
        const ixH = this.headers.findIndex((x) => x.value === el.value);
        switch (el.filterType) {
          case "switch":
            this.headers[ixH].filter = (header, search, row) => {
              const cell = row.getCellFromHeader(header);
              const validated = cell.value || !this.filtersModels[el.value];
              if (this.filtersModels.invalidCells)
                return validated && !row.validated()
              if (this.filtersModels.warningCells)
                return row.warned()
              return validated
            }
            break;

          case "async":
            this.headers[ixH].filter = (header, search, row) => {
              if (!this.filtersModels[el.value]) return true;
              if (this.filtersModels[el.value].length === 0) return true;
              
              if (this.filtersModels.invalidCells)
                return !row.validated()
              if (this.filtersModels.warningCells)
                return row.warned()
              return true
            }
            break;

          default:
            this.headers[ixH].filterItems = [
              ...new Set(this.items.map((x) => x[el.value])),
            ];
            this.headers[ixH].filter = (header, search, row) => {
              if (!this.filtersModels[el.value]) return true;
              if (this.filtersModels[el.value].length === 0) return true;

              const cell = row.getCellFromHeader(header);
              const validated =
                this.filtersModels[el.value].findIndex((n) => n == cell.value) >
                -1;
              if (this.filtersModels.invalidCells)
                return validated && !row.validated()
              if (this.filtersModels.warningCells)
                return validated && row.warned()
              return validated
            }
            break;
        }
      });
      
      if(!this.props?.hideFilters){
        filters.push({
          filterType: 'switch',
          filterLabel: 'Com aviso',
          value: 'warningCells',
          hide: this.readonly
        })
        filters.push({
          filterType: 'switch',
          filterLabel: 'Com erro',
          value: 'invalidCells',
          hide: this.readonly
        })
      }

      return filters
    },
    
    items() {
      let items = []
      if (this.readonly && !this.updateValueHeaders.length) {
        items = this.$_.cloneDeep(this.itemsDetailsEditable)
      } else {
        items = (this.table && this.table.rows && this.table.rows.length > 0) ? this.table.rows.map(x => x.item) : this.registers[this.register]

        if (!this.tableIdForeignValue || !items || !items.length > 0) return []

        // Removendo itens duplicados, deixando a data de vigencia ativa mais recente
        if (!this.readonly && this.subCollection == 'DA1' && items[0].DA1_CODPRO && items[0].DA1_DATVIG) {
          const now = this.$moment(new Date()).format("YYYYMMDD")
          items = items
            // Filtra itens apenas dentro da vigencia
            .filter(x => x.DA1_DATVIG <= now)
            // Ordena itens pelo código e vigencia, DESC
            .sort((a, b) => {
              if (a.DA1_CODPRO+a.DA1_DATVIG > b.DA1_CODPRO+b.DA1_DATVIG) {return -1}
              else if (a.DA1_CODPRO+a.DA1_DATVIG < b.DA1_CODPRO+b.DA1_DATVIG) {return 1}
              else {return  0}
            })
            // Remove duplicados com base no código
            .filter((value, index, self) =>
              index === self.findIndex((t) => (
                t.DA1_CODPRO === value.DA1_CODPRO
              ))
            )
            // Retorna a ordenação padrão
            .sort((a, b) => {
              if (a[this.props.orderSubcollection] < b[this.props.orderSubcollection]) {return -1}
              else if (a[this.props.orderSubcollection] > b[this.props.orderSubcollection]) {return 1}
              else {return  0}
            })
        }
        
        // Mapeando preenchimento
        items = items.map(item => {
          let filteredItem = {}
          this.props.headersDetails.forEach(header => {
            filteredItem[header.value] = (header.fill && item[header.fill]) ? item[header.fill] : item[header.value]
            
            if (typeof filteredItem[header.value] === 'undefined') {
              filteredItem[header.value] = header.default
            }
          })
          return filteredItem
        })
      
        // Filtro SA7
        if (!this.readonly && this.SA7Filtered) {
          const SA7Cod = this.SA7.map(x => x.A7_PRODUTO.trim())
          items = items.filter(x => SA7Cod.includes(x.DA1_CODPRO.trim()))
            .map(x => ({...x, ...this.SA7.find(y => y.A7_PRODUTO.trim() == x.DA1_CODPRO.trim())}))
        }
        
        // Itens rascunho
        if (this.itemsDetailsEditable.length > 0) {
          // Adicionando items de rascunho que não existem na listagem carregada
          if (!this.isSearched) {
            for (const itemsDetails of this.itemsDetailsEditable) {
              const ix = items.findIndex(_item => this.props.headersDetails.filter(h => h.identifier).every(h => itemsDetails[h.value] == _item[h.value]))
              if (ix == -1) {
                items.push(itemsDetails) 
              }    
            }
          }
        
          // Preenchendo tabela de items com itens de rascunho
          items = items.map(item => {
            if (this.itemsDetailsEditable && this.itemsDetailsEditable.length > 0) {
              let itemDetails = this.itemsDetailsEditable.find(_item => {
                return this.props.headersDetails
                  .filter(h => h.identifier)
                  .every(h => item[h.value] == _item[h.value])
              })

              if (this.props?.notClearItemsDetailsEditable && !!itemDetails && !this.firstLoad) {
                // Metodo para manter preenchimento na alteração de tabela
                for (const h of this.editableHeaders) {
                  item[h.value] = itemDetails[h.value]
                }
              } else {
                let _item = {
                  ...item, 
                  ...itemDetails
                }

                if (itemDetails) {
                  for (const h of this.updateValueHeaders) {
                    if (item && typeof item[h.value] !== 'undefined' && itemDetails[h.value] !== null) {
                      _item[h.value] = item[h.value]
                    }
                  }
                }

                item = _item
              }
            }
            
            return item
          })

          if (this.readonly && this.updateValueHeaders.length) {
            items = items
            .filter(
              item => this.itemsDetailsEditable.some(_item => 
                this.props.headersDetails
                .filter(h => h.identifier)
                .every(h => item[h.value] == _item[h.value])
              )
            )
          }
        }
      }
      return items
    },
    SA7Filtered() {
      return this.SA7 && this.SA7.length > 0
    },
    
    totalize() {
      let totalize = null;
      if (this.itemsDetailsEditable && this.itemsDetailsEditable.length > 0) {
        const itemsDetailsEditable = this.itemsDetailsEditable
        if (this.table) {
          const rows = this.table.rows.map(x => x.item)
          for (let [ix, item] of itemsDetailsEditable.entries()) {
            const iy = rows.findIndex(
              _item => this.props.headersDetails.filter(h => h.identifier).every(h => item[h.value] == _item[h.value])
            )
            if (iy > -1) {
              itemsDetailsEditable[ix] = rows[iy]
            }
          }
        }

        totalize = [];
        this.headers
          .filter((x) => x.totalize)
          .map(x => {
            if (typeof x.totalize === 'string' && x.totalize.includes('=>')) {
              x.totalize = eval(x.totalize)
            }
            return x
          })
          .forEach((elx) => {
            let total = 0
            if (typeof elx.totalize === 'function') {
              total = elx.totalize({itemsDetailsEditable})
            } else {
              total = itemsDetailsEditable
              .map((x) => x[elx.value])
              .reduce((acc, cur) => {
                return (acc || 0) + (cur || 0)}
              ) || 0;
            }
            this.total = total;
            const prefix = elx.prefix ? elx.prefix + " " : "";
            const suffix = elx.suffix ? " " + elx.suffix : "";
            if (elx.totalizeSelecteds){
              const selecteds = itemsDetailsEditable
                .filter((x) => x[elx.value])
                .length
              totalize.push({
                label: elx.totalizeSelectedsLabel || elx.totalizeLabel || elx.text,
                text: selecteds,
                value: `${elx.value}Selecteds`,
                hide: elx.hideTotalize || false
              });
            }
            totalize.push({
              label: elx.totalizeLabel || elx.text,
              original: total,
              text:
                prefix +
                total.toLocaleString("pt-br", {
                  minimumFractionDigits: elx.precisionText,
                  maximumFractionDigits: elx.precisionText,
                }) +
                suffix,
              value: elx.value,
              hide: elx.hideTotalize || false
            });
          });

        const totalizeMaster = this.props.totalizeMaster
        if (totalizeMaster && totalizeMaster.length > 0) {
          for (const [ix, elx] of totalizeMaster.entries()) {
            const original = elx.original.includes('=>') ? eval(elx.original)({totalize, item: this.formMaster, items: this.itemsDetailsEditable}) : elx.original
            const prefix = elx.prefix ? elx.prefix + " " : "";
            const suffix = elx.suffix ? " " + elx.suffix : "";
            totalize.push({
              label: elx.label,
              original,
              text:
                prefix +
                original.toLocaleString("pt-br", {
                  minimumFractionDigits: elx.precisionText || 2,
                  maximumFractionDigits: elx.precisionText || 2,
                }) +
                suffix,
              value: elx.value,
              hide: elx.hideTotalize || false
            });
          }
        }

      }
      return totalize;
    },

    invalidIdentifiers() {
      return this.invalidCells.map((cell) => {
        return {
          cell,
          identifierCells: this.identifierHeaders.reduce((arr, header) => {
            const found = cell.row.getCellFromHeader(header.value);
            if (found) return [...arr, found];
            else return arr;
          }, []),
        };
      });
    },
  
    validated() {
      return (
        !(this.invalidCells.length > 0) &&
        this.items.filter(this.filterItems).length > 0
      );
    },

    isSearched() {
      return !!(this.search && this.search.value)
    },
    
    isFiltered() {
      return this.isSearched || Object.entries(this.filtersModels).some(([_, model]) => {
        if (Array.isArray(model) && model.length > 0) return true;
        if (typeof model == "boolean" && model) return true;
        return false;
      });
    },
  },

  watch: {
    tableIdForeignValue(val, oldVal) {
      this.resetFormDataTable({resetDetails: oldVal != null})
      this.firstLoad = !oldVal
      this.getTableItems({id: val})
    },
    formMaster: {
      handler(val, oldVal) {
        // Recomputa a table caso algum campo do Form afete calculo da celula
        const inputComputedDetails = this.inputsMaster.filter(x => x.computedDetails)
        for (const input of inputComputedDetails) {
          if (this.table && val[input.value] !== oldVal[input.value]) {
            this.table.formMaster = val
            this.table.computeRows()
          }
        }
      },
      deep: true
    },
    itemsDetails(val) {
      this.itemsDetailsEditable = val
    },
    search: {
      async handler(val, oldVal) {
        if (val?.value) {
          this.resetRegister(this.register)
          if (this.timeoutSearch) { clearTimeout(this.timeoutSearch) }
  
          this.timeoutSearch = setTimeout(() => {
            this.timeoutSearch = null
            this.orFilters = []
            this.andFilters = []
      
            // Replace especial chars
            let search = val.value.replace(/\+|\/|\.|\(|\)/g, '')

            // Busca segmentada
            if (search.includes('%%')) {
              search = search.split('%%')
              for (let ix = 1; ix < search.length; ix++) {
                const el = search[ix]
                const header = this.filterSearchable[ix-1]
                if (el && header) {
                  this.andFilters.push({
                    key: header.fill || header.value,
                    operator: 'like',
                    value: el.trim()
                  })
                }
              }
              search = search[0].trim()
            }

            for (const header of this.searchableHeaders) {
              let operator = 'like'
              if (header.andSearchable) { operator = 'string_contains' }
              else if (header.wordSearchable) { operator = 'string_contains_word' }
              
              this.orFilters.push({
                key: header.fill || header.value,
                operator,
                value: search
              })
            }

            this.getTableItems({resetRegister: true})
          }, 1000)
        } else {
          await this.updateTable()
        }
      },
      deep: true
    },
    
    invalidCells() {
      this.$emit("validated", this.validated);
    },
  },

  created() {
    this.fetchFiltersCollections();
  },

  methods: {
    ...registersMutations(['resetRegister']),
    ...registersActions(["get", "getById", "getOnMongo", "nextOnMongo", "calculaPreco"]),

    calcPrice(){
      this.clearFilters()
      if (!this.itemsDetailsEditable || this.itemsDetailsEditable.length < 1) { return this.$toast.error(`Nenhum item preenchido!`, { position: "bottom" }); }
      this.loading = true
      this.calculaPreco({
        item: this.formMaster,
        itemsDetails: this.itemsDetailsEditable,
        collectionDetails: this.props.collectionDetails,
      })
      .then(async res=>{
        if (!res.Z31) throw 'Sem Z31 no retorno'
        const Z31 = res.Z31

        for (const row of Z31) {
          const ix = this.itemsDetailsEditable.findIndex(
            _item => this.props.headersDetails.filter(h => h.identifier).some(h => row.id.trim() == _item[h.value].trim())
          )
          if (ix > -1) {
            this.$set(this.itemsDetailsEditable[ix], 'Z31_VALOR', row.PRECO)
          } 
        }

        await this.updateTable()
        this.$toast.success(`Preços atualizados!`, { position: "bottom" });
      })
      .catch(err => {
        console.error(err)
        this.$toast.error(`Preços não atualizados!`, { position: "bottom" });
      })
      .finally(() => this.loading = false)       
    },
    
    async getTableItems({id = this.tableIdForeignValue, andFilters = [], orFilters = [], resetRegister = this.readonly} = {}) {
      this.externalFilter = andFilters && andFilters.length > 0

      if (this.externalFilter) this.resetRegister(this.register)

      if (this.readonly && !this.updateValueHeaders.length) {
        this.loading = false
        this.updateTable()
        return
      }
      this.resetTableItems()
     
      if (!id) return
      this.loading = true
      const res = await this.getOnMongo({
        register: this.register, 
        collection: this.collection, 
        subCollection: this.subCollection,
        id,
        filters: [...andFilters, ...this.andFilters, ...this.filtersSubcollection], 
        orFilters: [...orFilters, ...this.orFilters],
        orderBy: this.orderBy, 
        limit: this.itensPerPage*3,
        // limit: 200,
        getTotal: true,
        getFirestore: true,
        resetRegister
      })
      .catch(err => this.loading = false)

      if (!this.isSearched && this.itemsDetailsEditable && this.itemsDetailsEditable.length > 0) {
        // Se não for consulta de busca, adiciona itens editados
        const identifier = this.props.headersDetails.filter(h => h.identifier)[0]
        for (const itemsDetails of this.itemsDetailsEditable) {
          orFilters.push({
            key: identifier.fill,
            operator: "==",
            value: itemsDetails[identifier.value],
          })
        }
        
        await this.getOnMongo({
          register: this.register, 
          collection: this.collection, 
          subCollection: this.subCollection,
          id,
          filters: [...andFilters, ...this.andFilters, ...this.filtersSubcollection], 
          orFilters: [...orFilters, ...this.orFilters],
          orderBy: this.orderBy, 
          limit: this.itensPerPage*3,
          getFirestore: true
        })
        .catch(err => this.loading = false)
      }
      
      if (this.isSearched && res && res.meta && res.meta.total) { this.totalSearched = res.meta.total }
      else if (res && res.meta && res.meta.total) { this.itemsTotal = res.meta.total }
      else { this.loading = false }
     
      if (this.subCollection == 'DA1' && this.tableSA7) {
        // Verifica se existe amarração x cliente (SA7)
        await this.getSA7()
      }

      await this.updateTable()
      
      return res
    },
    async updateTable() {
      this.table = null
      let items = this.items

      const headers = this.readonly
        ? this.headers.map((h) => ({ ...h, editable: false }))
        : this.headers;

      // for (const hu of headers.filter((h) => h.unique)) {
      //   const duplicatedItems = items
      //     .map((e) => e[hu.value])
      //     .map((e, i, final) => final.indexOf(e) !== i && i)
      //     .filter((obj) => items[obj])
      //     .map((e) => items[e][hu.value]);

      //   items = items.map((i) => {
      //     if (duplicatedItems.includes(i[hu.value]))
      //       i.duplicate = { header: hu };
      //     return i;
      //   });
      // }

      // Order By
      const header = this.computedHeaders.find(x => x.value == this.orderBy.field || x.fill == this.orderBy.field)
      const field = header.value
      items = items.sort((a, b) => {
        const sortA = a[field]
        const sortB = b[field]

        if (this.orderBy.mod == 'asc') {
          if (sortA < sortB) return -1
          if (sortA > sortB) return 1
          return 0
        } else {
          if (sortA < sortB) return 1
          if (sortA > sortB) return -1
          return 0
        }
      })
      const table = new Table({ headers, items, inputsMaster: this.inputsMaster, formMaster: this.formMaster, readonly: this.readonly, registersActions: {get: this.get, getById: this.getById}})
      await table.computeFirstHeaders()
      
      if (this.itemsDetailsEditable && this.itemsDetailsEditable.length > 0) {
        if (this.props?.notClearItemsDetailsEditable && !this.firstLoad) { await table.computeRows() } 
        table.validateRows()
      }
     
      this.table = table
      this.loading = false
    },

    resetFormDataTable ({resetDetails}) {
      this.search.value = null
      this.orFilters = []
      this.andFilters = []
      if (!this.readonly && !this.props?.notClearItemsDetailsEditable && resetDetails) {
        this.itemsDetailsEditable = []
      }
      this.filtersModels = {
        invalidCells: false,
        warningCells: false,
      }
      this.resetRegister(this.register)
    },

    resetTableItems () {
      this.loading = false
      this.table = null
      this.totalSearched = 0
      this.page = 1
      this.lastPage = 1
      this.endOfRegisters = false
      this.validatedDetails = false
    },

    async getSA7() {
      const item = this.$refs.formMaster.getModel()
      if (item && item.SA1 && item.SA1.A1_COD) {
        this.SA7 = this.get({
          collection: 'SA7', 
          filters: [
            {key: "A7_CLIENTE", operator: '==', value: item.SA1.A1_COD},
            {key: "A7_LOJA", operator: '==', value: item.SA1.A1_LOJA},
          ], 
          limit: 9999,
          forceFilter: true,
          setRegister: false
        })
      }
    },

    callDialog(cell) { 
      this.$emit("showCarousel", cell.value);
    },
    
    submit() {
      this.$emit("submit", this.items);
    },

    validate() {
      return this.$refs.form.validate();
    },

    getModel() {
      return this.items;
    },

    getTotal() {
      return this.total;
    },

    getTable() {
      return this.table;
    },

    getTotalize() {
      return this.totalize;
    },

    updatePage(e) {
      if (e != -1 && (e <= this.lastPage || this.endOfRegisters)) return 
      if (e != -1) this.lastPage = Math.max(e, this.lastPage)
      this.loading = true
      this.nextOnMongo({ register: this.register, limit: this.itensPerPage*3 })
        .then(async res => { 
          if (!res) { this.endOfRegisters = true } 
          else { await this.updateTable() }
        })
        .catch(err => this.endOfRegisters = true)
        .finally(() => this.loading = false)
    },

    updateitemsPerPage(e) {
      this.itensPerPage = e
      this.updatePage(-1)
      this.scrollTop()
    },

    updateSortBy(e) {
      const header = this.computedHeaders.find(x => x.value == e || x.fill == e)
      const field = header.fill || header.value
      if (this.orderBy.field != field) {
        this.sortBy = field
        this.getTableItems()
      }
    },
    updateSortDesc(e) {
      const mod = (e ? 'desc' : 'asc')
      if (this.orderBy.mod != mod) {
        this.sortMod = mod
        this.getTableItems()
      }
    },

    calculateHeaderWidth(cell, line) {
      cell.header.width = this.getColumnWidth(line);
    },

    clickButton(cell, col, line) {
      this.calculateHeaderWidth(cell, line);
      this.editing = cell;
      this.$nextTick(() => {
        this.getInput(col, line).focus();
      });
    },

    handleButtonKeydown({ cell, col, line }, { keyCode, key }) {
      if (!cell.editable) return;

      const isNumber =
        (48 <= keyCode && keyCode <= 57) || (96 <= keyCode && keyCode <= 105);
      const isChar = 65 <= keyCode && keyCode <= 90;
      const isCcdil = keyCode == 186;
      if (isNumber || isChar || isCcdil) {
        if (this.editing == cell) {
          this.stuckInput = this.stuckInput + key;
        } else {
          this.stuckInput = key;
          this.clickButton(cell, col, line);
        }
      }
    },

    async inputBlur({ cell, row }, event) {
      const item = row.item
      const ix = this.itemsDetailsEditable.findIndex(
        _item => this.props.headersDetails.filter(h => h.identifier).every(h => item[h.value] == _item[h.value])
      )
      if (ix > -1) {
        this.$set(this.itemsDetailsEditable, [ix], row.item)
      } else {
        this.itemsDetailsEditable.push(row.item)
      }

      this.editing = null;
      this.locked = false;
      this.stuckInput = "";

      if (this.hasCancelled) this.hasCancelled = false;
      else {
        await row.process({ value: event.target.value, cell });
        this.$emit("validated", this.validated);
      }
    },

    async inputSelect({ cell, row }, value) {

      this.editing = null;
      this.locked = false;
      this.stuckInput = "";

      await row.process({ value: value, cell });
      this.$emit("validated", this.validated);
    },

    getButton(col, line, signal = 1) {
      let colPosition = col;
      for (let i = col; signal ? (i < this.headers.length) : 0; i += signal) {
        const header = this.headers[i];
        if (!header) { break }
        else if (!header.hide) {
          colPosition = i;
          break;
        }
      }
      return this.$refs[`form-data-table__button--${colPosition}-${line}`][0]
    },

    getInput(col, line) {
      return this.$refs[`form-data-table__input--${col}-${line}`][0];
    },

    getTd(line) {
      return this.$refs[`form-data-table-td--${line}`][0];
    },

    focusButton(x, y, signal) {
      const button = this.getButton(x, y, signal)
      button.focus()
      return true
    },

    focusButtonItself(col, line) {
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.focusButton(col, line);
        });
      });
    },

    getColumnWidth(line) {
      const td = this.getTd(line);
      return td.clientWidth;
    },

    async fetchFiltersCollections() {
      await Promise.all(
        this.filters.map(async (filter) => {
          if (filter.method && filter.method.collectionName) {
            const response = await this.get({
              collection: filter.method.collectionName,
              register: filter.method.collectionName,
              orderBy: "indice",
            });
            filter.method.values = response.map(
              (item) => item[filter.method.collectionField]
            );
            return response;
          }
          return Promise.resolve();
        })
      );
    },

    exportXlsx() {
      const headers = this.headers.filter((h) => h.export);
      const header = headers.map((h) => h.text);
      // const items = this.table.rows.map((item) =>
      //   headers.reduce(
      //     (acc, header) => ({ ...acc, [header.text]: item.item[header.value] }),
      //     {}
      //   )
      // )
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet([], { header });

      // adiciona as fórmulas em formato excel para as células das colunas que possuem essa configuração
      // items.forEach((_, idx) => {
      //   headers.forEach((header, i) => {
      //     if (!header.xlsxFormula) return;

      //     const cellKey = `${String.fromCharCode(65 + i)}${idx + 2}`;
      //     try {
      //       ws[cellKey].f = header.xlsxFormula
      //         .trim()
      //         .replaceAll("x", `${idx + 2}`);
      //     } catch (err) {
      //       console.error(err);
      //     }
      //   });
      // });

      XLSX.utils.book_append_sheet(wb, ws, `Sheet`);

      const exportFileName = `${this.xlsxName}.xlsx`;
      XLSX.writeFile(wb, exportFileName);
    },

    importXlsx(file) {
      this.loading = true
      const reader = new FileReader();
      reader.onload = async (e) => {
        try {
          /* Parse data */
          const bstr = e.target.result;
          const wb = XLSX.read(bstr, { type: "binary" });

          /* Seleciona a primeira página */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];

          /*
           * Transforma os dados da página em um array com as linhas da página, onde cada linha também é um array com os itens ordenados pela coluna.
           * data[0] = primeira linha, que é a linha dos headers
           * data[1...n] = todos os dados da tabela
           */
          const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
          const headers = data[0];
          const rows = data.slice(1)

          /*
           * Filtra os headers que possuem valor correspondente com alguma coluna na tabela atual
           * importables = array com os headers editáveis
           * identifiers = array com os headers que são identificadores (é assumido que os valores sao únicos)
           */
          const { importables, identifiers } = headers.reduce(
            ({ importables, identifiers }, value, idx) => {
              const header = this.headers.find((h) => h.text == value);
              const newEditables =
                header && header.import
                  ? [
                      ...importables,
                      {
                        headerText: header.text,
                        headerValue: header.value,
                        value,
                        idx,
                      },
                    ]
                  : importables;
              const newIdentifiers =
                header && header.identifier
                  ? [
                      ...identifiers,
                      {
                        headerText: header.text,
                        headerValue: header.value,
                        headerFill: header.fill,
                        value,
                        idx,
                      },
                    ]
                  : identifiers;
              return {
                importables: newEditables,
                identifiers: newIdentifiers,
              };
            },
            { importables: [], identifiers: [] }
          );
          
          // Faz um primeiro loop pra buscar todos itens (em tela e banco)
          for (const row of rows) {
            /*
             * percorre cada coluna identificadora (`identifiers`) para fazer as validações;
             * busca o item que corresponde com o valor presente em cada coluna identificadora da linha atual da planilha (`row`)
             * verifica se todos os resultados são do mesmo item (para caso de haver mais de uma coluna identificadora e os valores correspondentes no item serem de itens diferentes)
             */
            // let i = 0;
            let currentItemIdx = null;
            for (const identifier of identifiers) {
              if (row[identifier.idx] == undefined) { continue }
              currentItemIdx = this.items.findIndex(
                (item) => {
                  return item[identifier.headerValue] && row[identifier.idx] && item[identifier.headerValue].trim().toLowerCase() == row[identifier.idx].trim().toLowerCase()
                }
              );
              if (currentItemIdx > -1) {break}
            }

            // Se não encontra o item, tenta no banco
            if (currentItemIdx == -1) {
              const orFilters = []
              for (const identifier of identifiers) {
               if (row[identifier.idx] == undefined) { continue }
                orFilters.push({
                  key: identifier.headerFill || identifier.headerValue,
                  operator: "like",
                  value: row[identifier.idx].trim(),
                })
              }
              const item = await this.getOnMongo({
                register: this.register, 
                collection: this.collection, 
                subCollection: this.subCollection,
                id: this.tableIdForeignValue,
                orFilters,
                getFirestore: true,
                noState: true
              })
            }
          }
          
          // Atualiza a table para pegar itens baixados do banco
          await this.updateTable()
          const errors = []
          for (const [rowIx, row] of rows.entries()) {
            if (row.length < 1) { continue }
            let currentItemIdx = null;
            for (const identifier of identifiers) {
              if (row[identifier.idx] == undefined) { continue }
              currentItemIdx = this.items.findIndex(
                (item) => {
                  return item[identifier.headerValue] && row[identifier.idx] && item[identifier.headerValue].trim().toLowerCase() == row[identifier.idx].trim().toLowerCase()
                }
              );
              if (currentItemIdx > -1) {break}
            }

            // Caso não encontre o item registra erro e passa pro próximo
            if (currentItemIdx == -1) { 
              errors.push({ix: rowIx+2, row}) // Adiciona 2 para bater com a linha da planilha
              continue 
            }
  
            const tableRow = this.table.rows[currentItemIdx]//this.table.findRow(item);
            for (const importable of importables) {
              const header = this.headers.find(
                (header) => header.value == importable.headerValue
              );
              const value = row[importable.idx];
              
              if (value == undefined) { 
                errors.push({ix: rowIx+2, row})
                continue
              }

              const cell =
                tableRow.cells[this.table.headersHashMap[header.value]];
              tableRow.process({ cell, value });
  
              const item = tableRow.item
              const ix = this.itemsDetailsEditable.findIndex(
                _item => this.props.headersDetails.filter(h => h.identifier).every(h => item[h.value] == _item[h.value])
              )
              if (ix > -1) {
                this.$set(this.itemsDetailsEditable, [ix], item)
              } else {
                this.itemsDetailsEditable.push(item)
              }
            }
          }

          // @TODO considerar a viabilidade de usar raeder.onloadend
          if (errors.length == 0) {
            this.$toast.success("Arquivo importado!", { position: "bottom" });
          } else {
            for (const error of errors) {
              this.$toast.error(`Item não encontrado - linha ${error.ix}!`, { position: "bottom" });
            }
            this.$toast.warning("Arquivo importado com erros!", { position: "bottom" });
          }
          this.$emit("validated", this.validated);
        } catch (err) {
          this.$toast.error("Falha ao importar", { position: "bottom" });
          console.error(err);
        }
        this.loading = false
      };

      reader.onerror = (err) => {
        this.$toast.error("Falha ao importar", { position: "bottom" });
        console.error(err);
        this.loading = false
      };

      const fileExtension = file.name.split(".").pop().toLowerCase();
      if (!["xlsx", "xls"].includes(fileExtension)) {
        this.$toast.error("Falha ao importar: Formato não suportado.", {
          position: "bottom",
        });
        return;
      }

      reader.readAsBinaryString(file);
    },

    scrollTop() {
      const el = this.$refs.dataTable.$el;
      if (el) {
        this.$nextTick(() => {
          el.scrollIntoView(false);
        });
      }
    },

    scrollToLine(line, options) {
      const el = this.$refs[`form-data-table-tr--${line}`];
      if (el) {
        el.scrollIntoView(options);
      }
    },

    scrollToCell({cell, filter}) {
      this.clearAndActiveFilter(filter)
      this.$nextTick(() => {
        const pos = this.currentCellPosition(cell);
        if (pos) {
          this.scrollToLine(pos.line);
          this.focusButton(pos.col, pos.line);
        } else {
          console.warn(
            `scrollToCell: Couldn't get current position of cell in column "${cell.header.value}"`
          );
        }
      });
    },

    currentCellPosition(cell) {
      const line = this.currentItems.findIndex(({ line }) => line == cell.y);
      const col = this.headers.findIndex((h) => h.value == cell.header.value);
      if (col >= 0 && line >= 0) return { col, line };
      else return null;
    },

    clearFilters() {
      this.$refs['form-data-table-filters'].clearFilters()
    },

    clearAndActiveFilter(filter) {
      this.$refs['form-data-table-filters'].clearFilters({[filter]: true})
    },

    async applyBatch({ item, inputs }) {
      const filteredItems = this.$refs.dataTable.$children[0].filteredItems;
      for (const row of filteredItems) {
        for (const input of inputs) {
          const value = item[input.value];
          const header = input.value;
          if (!value) continue;
  
          const cell = row.getCellFromHeader(header);
          await row.process({ value, cell });
        }
      }
    },
  },
};
</script>

<style lang="scss">
  .v-badge.small .v-badge__badge {
    width: 16px;
    height: 16px;
    min-width: auto;
  }

  .form-data-table__validations {
    background-color: rgba(247, 149, 149, 0.534);
  }

  .form-data-table__row {
    &.form-data-table__row--invalid {
      border: 1px solid rgba(255, 0, 0, 0.33);
      background-color: rgba(247, 149, 149, 0.534);
    }
  }

  .form-data-table__button {
    display: block;
    height: 100%;
    padding: 0 12px;
    white-space: nowrap;

    &:hover {
      background-color: rgba(0, 0, 0, 0.12);
    }

    .form-data-table__button {
      display: block;
      height: 100%;
      padding: 0 12px;
      white-space: nowrap;

      &:hover {
        background-color: rgba(0, 0, 0, .12);
      }

      &.form-data-table__button--invalid {
        border: 1px solid rgba(255, 0, 0, 0.33);
        border-radius: 3px;
        background-color: #fd484860;
      }
      
      &.form-data-table__button--warned {
        border: 1px solid rgb(227, 206, 29);
        border-radius: 3px;
        background-color: rgb(255, 244, 150);
      }

      &:focus {
        background-color: #eeeeee;
        border: 1px solid rgba(0, 0, 0, .33);
        border-radius: 3px;
        padding: 0 11px;
      }
    }

    &:focus {
      background-color: #eeeeee;
      border: 1px solid rgba(0, 0, 0, 0.33);
      border-radius: 3px;
      padding: 0 11px;
    }
  }

  .form-data-table__input {
    display: block;
    height: 100%;
    padding: 0 12px;

    outline: 0;
    border: 1px solid rgba(0, 0, 0, 0.33);
    border-radius: 3px;
  }
</style>
