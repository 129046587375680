<template>
  <v-snackbar
    top
    rounded
    v-model="snackbar.stat"
    :color="snackbar.color"
    :timeout="snackbar.timeout"
  >
    {{ snackbar.text }}

    <!-- <template v-slot:action="{ attrs }">
      <v-btn
        text
        v-bind="attrs"
        @click="snackbar.stat = false"
      >
        Fechar
      </v-btn>
    </template> -->
  </v-snackbar>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'SystemBar',
  data: () => ({
  }),
  computed: {
    ...mapState(['snackbar'])
  },
}
</script>

<style>

</style>