<template>
  <v-footer app dark class="blue-grey darken-4">
    <span class="text-caption ml-auto">
      Powered by <a href="https://userfunction.com.br" target="_blank" class="white--text font-weight-bold">{{this.$appConfig.poweredBy ? this.$appConfig.poweredBy : 'User Function' }}</a> <span>v1.13.3</span>.
    </span>
  </v-footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style>

</style>