<template>
  <div>
    <vue-speedometer
      :needleHeightRatio=".85"
      :width="580"
      :ringWidth="35"
      :value="computedValue"
      :maxValue="100"
      :currentValueText="title"
      :customSegmentLabels="computedSegmentsLabels"
      :needleTransitionDuration="4000"
      needleColor="grey"
      textColor="grey"
      :segmentColors="computedSegmentColors"
      :customSegmentStops="customSegmentStops">
    </vue-speedometer>
  </div>
</template>

<script>
import VueSpeedometer from "vue-speedometer";
export default {
  name: "GaugeChart",
  components: { VueSpeedometer },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    segmentsLabels: {
      type: String,
      default: () => ""
    },
    title: {
      type: String,
      default: () => ""
    },
    value: {
      type: String,
      default: () => ""
    },
    cards: {
      type: Array,
      default: () => []
    },
    options: Object
  },
  data() {
    return {
      segmentsLabelsBuilded: [],
      customSegmentStops: [],
      valueCompiled: 0,
      dataSource: []
    };
  },
  watch: {
    data: {
      handler: function (data) {
        this.dataSource = data
      },
      deep: true,
      immediate: false,
    },
  },
  methods: {
  },
  computed: {
    computedSegmentsLabels() {
      return this.segmentsLabelsBuilded.map(({text, position, color}) => ({text, position, color}))
    },
    computedSegmentColors() {
      return this.segmentsLabelsBuilded.map(({background}) => background)
    },
    computedValue() {
      let score = 0
      if (!this.data || this.data.length === 0) {
        return score
      }

      if (typeof this.valueCompiled === 'function') {
        score = this.valueCompiled(this.data)
      } else {
        score = this.valueCompiled
      }

      return score
    }
  },
  created() {
    const segmentsLabels = eval(this.segmentsLabels)

    this.valueCompiled = eval(this.value)

    if (typeof segmentsLabels === 'function') {
      this.segmentsLabelsBuilded = segmentsLabels()
    } else {
      this.segmentsLabelsBuilded = segmentsLabels
    }
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
  @import "vuetify/src/styles/styles.sass";

  #score {
    left: 31%;
    top: 45%;
  }

  // @media #{map-get($display-breakpoints, 'lg-and-down')} {
  //   #score {
  //     left: 30%;
  //     top: 45%;
  //   }
  // }
</style>