<template>
  <v-navigation-drawer
    v-model="drawer"
    app
    clipped
    dark
  >
    <!-- Rotas de Users -->
    <section id="menuUser">
      <v-list dense>
        <template v-for="(item, index) in menuUser">
          <v-list-item
            v-if="!('children' in item)"
            :key="item.text"
            :to="item.path"
            append-icon="mdi-account"
          >
            <v-list-item-icon>
              <v-icon v-text="item.meta.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.name"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-group
            v-else
            :prepend-icon="item.meta.icon"
            :key="item.name+index"
            no-action
            active-class="grey darken-2 white--text"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="item.name"></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="(child, index) in item.children"
              :key="item.name+child.name+index"
              :to="item.path + '/' + child.path"
              active-class="grey darken-4 white--text no-hover"
              class="ma-0 pl-10"
            >
              <v-list-item-icon class="mr-2">
                <v-icon size=20 v-text="child.meta.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="child.meta.title || child.name"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </template>
      </v-list>
    </section>
    
    <!-- Rotas de Admin -->
    <section id="adminMenu" v-if="isAdmin">
      <v-divider></v-divider>
      <v-subheader class="caption mb-n4 grey--text">
        <v-icon size=20 class="mr-2" color="grey">mdi-shield-account</v-icon>
        Admin
      </v-subheader>
      <v-list dense>
        <template v-for="(item, index) in menuAdmin">
          <v-list-item
            v-if="!('children' in item)"
            :key="item.text"
            :to="item.path"
            append-icon="mdi-account"
          >
            <v-list-item-icon>
              <v-icon v-text="item.meta.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.name"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-group
            v-else
            :prepend-icon="item.meta.icon"
            :key="item.name+index"
            no-action
            active-class="grey darken-2 white--text"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="item.name"></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="(child, index) in item.children"
              :key="item.name+child.name+index"
              :to="item.path + '/' + child.path"
              active-class="grey darken-4 white--text no-hover"
              class="ma-0 pl-10"
            >
              <v-list-item-icon class="mr-2">
                <v-icon size=20 v-text="child.meta.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="child.meta.title || child.name"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </template>
      </v-list>
    </section>

    <!-- Rotas de Super Admin -->
    <section id="superAdminMenu" v-if="isSuperAdmin">
      <v-divider></v-divider>
      <v-subheader class="caption mb-n4 grey--text">
        <v-icon size=20 class="mr-2" color="grey">mdi-shield-star-outline</v-icon>
        Super Admin
        <v-spacer/>
        <v-btn x-small fab icon color="grey" class="mr-n1"
          :key="user.token"
          v-clipboard:copy="user.token"
          v-clipboard:success="clipboardCopy"
          v-clipboard:error="clipboardError"
          >
          <v-icon>mdi-shield-key-outline</v-icon>
        </v-btn>
      </v-subheader>
      <v-list dense>
        <template v-for="(item, index) in menuSuperAdmin">
          <v-list-item
            v-if="!('children' in item)"
            :key="item.text"
            :to="item.path"
            append-icon="mdi-account"
          >
            <v-list-item-icon>
              <v-icon v-text="item.meta.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.name"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-group
            v-else
            :prepend-icon="item.meta.icon"
            :key="item.name+index"
            no-action
            active-class="grey darken-2 white--text"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="item.name"></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="(child, index) in item.children"
              :key="item.name+child.name+index"
              :to="item.path + '/' + child.path"
              active-class="grey darken-4 white--text no-hover"
              class="ma-0 pl-10"
            >
              <v-list-item-icon class="mr-2">
                <v-icon size=20 v-text="child.meta.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="child.meta.title || child.name"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </template>
      </v-list>
    </section>
  </v-navigation-drawer>
</template>

<script>
import { createNamespacedHelpers, mapMutations , mapActions, mapState } from 'vuex'
const { mapState: registersState } = createNamespacedHelpers('registers')
const { mapState:authState, mapActions:authActions } = createNamespacedHelpers('auth')
export default {
  name: 'NavigationDrawer',
  data () {
    return {
      menuStatus: false,
    }
  },
  computed: {
    ...mapState(['companySelected', 'registers']),
    ...registersState(['registers']),
    ...authState(['user']),
    userRules() {
      return this.computedUserRules.filter(ur => this.user.companies[this.companySelected.groupCompany]['rules'].includes(ur.id))
    },
    isAdmin() { 
      return this.user && (
        this.user.superAdmin
        || (
          this.user.companies[this.companySelected.groupCompany] && 
          this.user.companies[this.companySelected.groupCompany].admin
        )
      )
    },
    isSuperAdmin() { return this.user && this.user.superAdmin },
    drawer: {
      get(){
        return this.$store.state.drawer
      },
      set(val){
        this.$store.commit('setDrawer', val)
      } 
    },
    computedRoutes() {
      return this.$_.cloneDeep(this.$router.options.routes) //this.routes
    },
    computedUserRules () {
      return this.registers['userRules']
    },
    menuUser() { return this.menu.filter(route => !route.meta.requiresAdmin && !route.meta.requiresSuperAdmin) },
    menuAdmin() { return this.menu.filter(route => route.meta.requiresAdmin) },
    menuSuperAdmin() { return this.menu.filter(route => route.meta.requiresSuperAdmin) },
    menu() {
      const items = this.computedRoutes
        .filter(route => {
          return route.meta?.menu 
            && (
              route.path == '/' 
              || this.isAdmin 
              || ( 
                !route.meta.requiresAdmin 
                && this.userRules 
                && this.userRules.some(ur => {
                  const claimsUR = ur?.claims[route.register]
                  if (!claimsUR) {
                    return route?.children?.some(rc => rc.meta && rc.meta.menu && this.userRules.find(r => r.claims[rc.register]?.view))
                  }
                  return claimsUR['view']
                })       
              )
            )
        })
        .map(route => {
          delete route.component
          if ('children' in route) {
            route.children = route.children
              .map(childRoute => {
                delete childRoute.component
                return childRoute
              })
              .filter(childRoute => {
                return childRoute.meta.menu 
                && (
                  this.isAdmin 
                  || ( 
                    this.userRules && this.userRules.some(ur => {
                      const claimsUR = ur?.claims[route.register] || ur?.claims[childRoute.register]
                      return claimsUR && claimsUR[[childRoute.meta.claimRoute]]
                    })
                  )
                )
              })
          }
          return route
        })
      
      return items
    }
  },
  methods: {
    ...mapMutations(['showSnackbar']),
    ...mapActions(['setRouter']),
    ...authActions(['signOut']),
    handleClick(e) {
      if(e) this[e]()
    },
    handleVar(e) {
      if(e) return this[e]
    },
    allowed(meta) {
      let allowed = true
      if (meta.requireClaim && (!this.$appConfig.claims || !this.$appConfig.claims[meta.requireClaim])) { allowed = false }
      return allowed
    },
    clipboardCopy: function (e) {
      this.$toast.success('Token copiado');
    },
    clipboardError: function (e) {
      this.$toast.error('Falha ao copiar');
    },
  }
}
</script>

<style>

</style>