import Vue from 'vue'
import VueRouter from 'vue-router'

import FinishSignUp from '@/views/Auth/FinishSignUp'
import SignIn from '@/views/Auth/SignIn'
import Error404 from '@/views/Error404'
import Profile from '@/views/User/Profile'

import Dashboard from '@/views/User/Dashboard'
import List from '@/views/Registers/List'
import Register from '@/views/Registers/Register'
import Content from '@/components/Templates/Content'
import ReportsList from '@/views/Registers/ReportsList'
import ReportsRegister from '@/views/Registers/ReportsRegister'

import UsersList from '@/views/Registers/UsersList'
import UsersRegister from '@/views/Registers/UsersRegister'
import UserRulesRegister from '@/views/Registers/UserRulesRegister'
import UserRulesList from '@/views/Registers/UserRulesList'
import IntegrationsRegister from '@/views/Registers/IntegrationsRegister'
import IntegrationsList from '@/views/Registers/IntegrationsList'
import GeneratedReportsRegister from '@/views/Registers/GeneratedReportsRegister'

import CustomersList from '@/views/Registers/CustomersList'
import CustomersRegister from '@/views/Registers/CustomersRegister'
import ExpirationPass from '../views/Auth/ExpirationPass.vue'

Vue.use(VueRouter)

/**
 * Rotas de Sistema
*/
const initRouter = () => {
  const routes = [
    // {
    //   path: '/register',
    //   name: 'Registrar',
    //   component: SignUp,
    //   meta: { public: true, menuPublic: true }
    // },
    // {
    //   path: '/',
    //   name: 'Dashboard',
    //   component: Dashboard,
    //   meta: { icon: 'mdi-monitor-dashboard', public: false, menu: true },
    // },
    {
      path: '/finishSignUp',
      name: 'FinishSignUp',
      component: FinishSignUp,
      meta: { public: true }
    },
    {
      path: '/samtronic',
      name: 'EntrarSamtronic',
      component: SignIn,
      meta: { public: true, menuPublic: true }
    },
    {
      path: '/login',
      name: 'Entrar',
      component: SignIn,
      meta: { public: true, menuPublic: true }
    },
    {
      path: '/expirationPass',
      name: 'Senha expirada',
      component: ExpirationPass,
      meta: { hideMenu: true, forcePass: false, expirationPass: true}
    },
    {
      path: '/forceChangePass',
      name: 'Trocar expirada',
      component: ExpirationPass,
      meta: { hideMenu: true, forcePass: true, expirationPass: false }
    },
    {
      path: '/changePass',
      name: 'Trocar expirada',
      component: ExpirationPass,
      meta: { hideMenu: false, forcePass: false, expirationPass: false }
    },
    {
      path: '/404',
      name: 'Error404',
      component: Error404,
      meta: { public: true }
    },
    {
      path: '/404company',
      name: 'Error404Company',
      component: Error404,
      meta: { public: true }
    },
    {
      path: '/profile',
      name: 'Profile',
      component: Profile,
      meta: { icon: 'mdi-account-edit' }
    }
  ]

  const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
  })

  return router
}

/**
 * Rotas de Menu
 * Obedece a ordenação do array generatedRoutes (Integrações, Reports, Admin, SuperAdmin)
 * Novas rotas de menu devem ser adicionadas nessa rotina
 */
const createRouter = ({router, integrations = [], userRules = [], reports = [], admin = false, superAdmin = false, groupCompany = null}) => {
  let generatedRoutes = []

  // Rotas das integrações (CRUD)
  const routesFromIntegrations = integrations.map(integration => {
    let orderBy = integration.orderBy || ''
    try {
      if (['{', '['].some(symbol => orderBy.includes(symbol))) { orderBy = JSON.parse(orderBy) }
    } catch (e) {
      console.warn(`Error when trying to define "orderBy" in route ${integration.name}.`, e)
    }

    let filters = ''
    try {
      filters = (integration.filters) ? JSON.parse(integration.filters) : null
    } catch (e) {
      console.warn(`Error when trying to define "filters" in route ${integration.name}.`, e)
    }

    let filtersSaller = ''
    try {
      filtersSaller = (integration.filtersSaller) ? JSON.parse(integration.filtersSaller) : null
    } catch (e) {
      console.warn(`Error when trying to define "filtersSaller" in route ${integration.name}.`, e)
    }
 
    let filtersClient = ''
    try {
      filtersClient = (integration.filtersClient) ? JSON.parse(integration.filtersClient) : null
    } catch (e) {
      console.warn(`Error when trying to define "filtersClient" in route ${integration.name}.`, e)
    }

    let filtersSubcollection = integration.filtersSubcollection || ''
    try {
      if (['{', '['].some(symbol => filtersSubcollection.includes(symbol))) { filtersSubcollection = JSON.parse(filtersSubcollection) }
    } catch (e) {
      console.warn(`Error when trying to define "filtersSubcollection" in route ${integration.name}.`, e)
    }
   
    let orderSubcollection = integration.orderSubcollection || ''
    try {
      if (['{', '['].some(symbol => orderSubcollection.includes(symbol))) { orderSubcollection = JSON.parse(orderSubcollection) }
    } catch (e) {
      console.warn(`Error when trying to define "orderSubcollection" in route ${integration.name}.`, e)
    }

    let validation = integration.validation || ''
    try {
      if (validation.includes('=>')) { validation = eval(validation) }
    } catch (e) {
      console.warn(`Error when trying to define "validation" in route ${integration.name}.`, e)
    }

    const parentRoute = {
      path: `/${integration.register}`,
      name: integration.name,
      register: integration.register,
      meta: {
        menu: true,
        customActions: integration.customActions,
        icon: integration.listIcon || 'mdi-lan-connect',
        requiresAdmin: integration.requiresAdmin || false
      },
    }

    // props comum a todas as rotas do crud
    const props = {
      ...integration,
      integration: true,
      filters,
      filtersSaller,
      filtersClient,
      orderBy,
      filtersSubcollection,
      orderSubcollection,
      validation,
      customActions: integration.customActions,
      claims: {
        add: ('add' in integration) ? integration.add : false,
        sync: ('sync' in integration) ? integration.sync : false,
        view: ('view' in integration) ? integration.view : true,
        edit: ('edit' in integration) ? integration.edit : false,
        hideSketch: ('hideSketch' in integration) ? integration.hideSketch : false,
        hidePdf: ('hidePdf' in integration) ? integration.hidePdf : false,
        delete: ('delete' in integration) ? integration.delete : false,
        copy: ('copy' in integration) ? integration.copy : false,
        disableBonus: ('disableBonus' in integration) ? integration.disableBonus : false,
      },
    }

    return createCrudRoutes({ route: parentRoute, props: {...props, props}, registerComponent: Register, listComponent: List, userRules, groupCompany})
  })
  generatedRoutes = [ ...routesFromIntegrations ]
  
  // Rotas dos Relatórios (views)
  const reportsRoute = {
    path: '/reports',
    name: 'Relatórios',
    register: 'reports',
    meta: {
      menu: true,
      icon: 'mdi-file-chart-check-outline'
    },
  }
  const propsFromReports = reports.map(report => ({
      ...report,
      claims: {
        add: false,
        sync: false,
        view: true,
        edit: false,
        delete: false,
      }
    })
  )
  generatedRoutes.push(createReportRoutes( {route: reportsRoute, userRules, reports: propsFromReports, groupCompany}))
    
  // Rotas de Admin
  if (admin) {
    // Usuários
    const usersRoute = {
      path: '/users',
      name: 'Usuários',
      register: 'users',
      meta: {
        menu: true,
        icon: 'mdi-account-multiple-outline',
        requiresAdmin: true
      },
    }
    const userRoutesProps = {
      collection: 'users',
      register: 'users',
      claims: {
        add: true,
        copy: true,
        sync: false,
        view: true,
        edit: true,
        delete: true,
      },
    }
    generatedRoutes.push(createCrudRoutes({ route: usersRoute, registerComponent: UsersRegister, listComponent: UsersList, props: userRoutesProps, userRules, groupCompany }))

    // Papeis
    const userRulesRoute = {
      path: '/userRules',
      name: 'Papéis de Usuários',
      register: 'userRules',
      meta: {
        menu: true,
        icon: 'mdi-account-key',
        requiresAdmin: true
      },
    }
    const userRulesRoutesProps = {
      collection: 'userRules',
      register: 'userRules',
      claims: {
        add: true,
        copy: true,
        sync: false,
        view: true,
        edit: true,
        delete: true,
      },
    }
    generatedRoutes.push(createCrudRoutes({ route: userRulesRoute, registerComponent: UserRulesRegister, listComponent: UserRulesList, props: userRulesRoutesProps, userRules, groupCompany }))
 
    // Integrações
    const integrationsRoute = {
      path: '/integrations',
      name: 'Integrações',
      register: 'integrations',
      meta: {
        menu: true,
        icon: 'mdi-lan-connect',
        requiresAdmin: true
      },
    }
    const integrationsRoutesProps = {
      collection: 'integrations',
      register: 'integrations',
      orderBy: 'menuOrder',
      integration: true,
      versions: true,
      claims: {
        add: true,
        copy: true,
        sync: false,
        view: true,
        edit: true,
        delete: true,
      },
    }
    generatedRoutes.push(createCrudRoutes({ route: integrationsRoute, registerComponent: IntegrationsRegister, listComponent: IntegrationsList, props: integrationsRoutesProps, userRules, groupCompany }))
 

    // Relatórios
    const reportsRoute = {
      path: '/reports',
      name: 'Gerador de Relatórios',
      register: 'reports',
      meta: {
        menu: true,
        icon: 'mdi-printer',
        requiresAdmin: true
      },
    }
    const reportsRoutesProps = {
      collection: 'reports',
      register: 'reports',
      integration: true,
      claims: {
        add: true,
        sync: false,
        view: true,
        edit: true,
        delete: true,
      },
    }
    generatedRoutes.push(createCrudRoutes({ route: reportsRoute, registerComponent: ReportsRegister, listComponent: ReportsList, props: reportsRoutesProps, userRules, groupCompany }))
  }

  if (superAdmin) {
    // Customers
    const customersRoute = {
      path: '/customers',
      name: 'Customers',
      register: 'customers',
      meta: {
        menu: true,
        icon: 'mdi-account-multiple-outline',
        requiresSuperAdmin: true
      },
    }
    const customerRoutesProps = {
      collection: 'customers',
      register: 'customers',
      claims: {
        add: true,
        copy: true,
        sync: false,
        view: true,
        edit: true,
        delete: true,
      },
    }
    generatedRoutes.push(createCrudRoutes({ route: customersRoute, registerComponent: CustomersRegister, listComponent: CustomersList, props: customerRoutesProps, userRules }))
  }

  return { generatedRoutes }
}

/**
 * Função Auxiliar
 * Rotas dinâmicas de relatórios
 * Adicionadas a rota pai "Relatórios"
 */
const createReportRoutes = ({ route, reports, groupCompany }) => {
  route.component = Content
  route.children = []

  reports.map(propReport => {
    route.children.push({
      path: propReport.id,
      name: `reports_${propReport.id}`,
      component: GeneratedReportsRegister,
      props: propReport,
      register: `reports_${propReport.id}`,
      
      meta: {...route.meta, title: propReport.name, groupCompany, requiresAdmin: true, icon: propReport.icon, menu: true, claimRoute: 'view'}
    })
  })

  return route
}

/**
 * Função Auxiliar
 * Rotas de CRUD (view, add, copy, edit) para as rotas pais
 * Delete não necessita rota.
 */
const createCrudRoutes = ({ route, props, registerComponent, listComponent, groupCompany }) => {
  route.component = Content
  route.children = [];

  if (props.claims.view ) {
    route.children.push({
      path: "listar",
      name: `${route.path}/listar`,
      component: listComponent,
      props,
      meta: { ...route.meta, groupCompany, menu: true, title: `Listar ${route.name}`, claimRoute: 'view', icon: "mdi-format-list-bulleted" }
    },
    {
      path: "detalhes/:id",
      name: `${route.path}/detalhes`,
      component: registerComponent,
      props: { ...props, readonly: true },
      meta: { ...route.meta, menu: false, groupCompany, title: `Detalhes do Item - ${route.name}`, claimRoute: 'view' }
    })
  }

  if (props.claims.add) {
    route.children.push({
      path: "cadastrar",
      name: `${route.path}/cadatrar`,
      component: registerComponent,
      props,
      meta: { ...route.meta, menu: true, groupCompany, title: `Cadastrar ${route.name}`, claimRoute: 'add', icon: "mdi-plus" }
    })

    /**
     * TO-DO
     * Lembrar de fazer esse tratamento para cópia e deixar algo mais genérico
     */
    if (route.register === 'SC5') {
      route.children.push({
        path: "bonificar/:id",
        name: `${route.path}/bonificar`,
        component: registerComponent,
        props,
        meta: { ...route.meta, menu: false, groupCompany, title: `Bonificar ${route.name}`, claimRoute: 'add', icon: "mdi-plus" }
      })
    }
  }

  if (props.claims.copy ) {
    route.children.push({
      path: "copiar/:id",
      name: `Copiar ${route.name}`,
      component: registerComponent,
      props,
      meta: { ...route.meta, menu: false, groupCompany, title: "Copiar", claimRoute: 'copy', icon: "mdi-content-copy" }
    })
  }
  
  if (props.claims.edit) {
    route.children.push({
      path: "editar/:id",
      name: `${route.path}/editar`,
      component: registerComponent,
      props: { ...props, edit: true },
      meta: { ...route.meta, menu: false, groupCompany, title: `Editar Item - ${route.name}`, claimRoute: 'edit' }
    })
  }

  return route
}

export default { createRouter, initRouter }