import Vue from 'vue'
import setProperties from './plugins/vueProperties'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import customMethods from './plugins/customMethods'
import draggable from 'vuedraggable'
import firebaseConfig from './configs/firebase-config.json'
import firebase from 'firebase/compat/app';
import { getAnalytics } from "firebase/analytics";
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import Gravatar from 'vue-gravatar'
import VueNativeNotification from 'vue-native-notification'
import Clipboard from 'v-clipboard'
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'
import browserDetect from "vue-browser-detect-plugin"
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
import VueMask from 'v-mask'
import VJsoneditor from 'v-jsoneditor'

/* Vue Use and Config */
Vue.config.productionTip = false
Vue.use(VueNativeNotification, {
  requestOnNotify: true
})
Vue.component('v-gravatar', Gravatar)
Vue.component('draggable', draggable)
Vue.use(Clipboard)
Vue.use(VueToast, {
  position: 'top',
  duration: 5000,
  dismissible: true,
  pauseOnHover: true
})
Vue.use(browserDetect)
Vue.use(PerfectScrollbar)
Vue.use(VueMask)
Vue.use(VJsoneditor)

/* Vue Filters */
Vue.filter('minutes', (value) => {
  if (!value) return null
  return value.substr(3)
})

const fire = firebase.initializeApp(firebaseConfig)
getAnalytics(fire);
Vue.prototype.$appConfig = firebaseConfig
Vue.prototype.$auth = firebase.auth
Vue.prototype.$db = firebase.firestore

/* Vue Properties */
setProperties(Vue)

new Vue({
  router: router.initRouter(),
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
