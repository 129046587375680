<template>
  <v-overlay
    absolute
    z-index=0
    class="fullcontent">
    <v-img
      :src="backGroundImg"
      height="100vh"
      position="center center"
      gradient="to top right, rgba(0, 9, 58, 0.33), rgba(18, 24, 62, 0.7)"
    ></v-img>
  </v-overlay>
</template>

<script>
import { createNamespacedHelpers, mapState, mapActions } from 'vuex'
const { mapState:authState } = createNamespacedHelpers('auth')
export default {
  computed:{
    ...authState(['user']),
    backGroundImg() {
      return this.$router.history.current.path == '/samtronic' ? 'https://storage.googleapis.com/uf-manage.appspot.com/customers/samtronic/samtronic.png' : require('@/assets/bg.jpg')
    }
  },
  created(){

  }

}
</script>

<style>

</style>