<template>
  <section id="profile" class="w-100 h-100 pa-4">
    <v-form
      ref="form"
      v-if="profile"
      :key="keyForm"
      v-model="valid"
      lazy-validation
    >
      <v-alert
        v-if="user.updatePass"
        text
        outlined
        color="teal"
        icon="mdi-account-alert-outline"
      >
        <h6 class="title">Primeiro Acesso</h6>
        <span>Recomendamos que você cadastre uma nova senha para seu próximo acesso!</span>
      </v-alert>

      <v-text-field
        v-model="profile.displayName"
        :rules="rules.displayName"
        label="Nome de exibição"
        required
        dense
        class="pt-4"
      />
      
      <v-text-field
        v-model="profile.email"
        :rules="rules.email"
        label="E-mail"
        required
        disabled
        dense
      />

      <v-text-field
        v-model="profile.password"
        :rules="rules.password"
        label="Senha"
        :type="showPassword ? 'text' : 'password'"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="showPassword = !showPassword"
        dense
      />
      
      <v-text-field
        v-model="profile.confirmPassword"
        :rules="rules.confirmPassword"
        label="Confime a senha"
        :type="showPassword ? 'text' : 'password'"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="showPassword = !showPassword"
        dense
      />

      <v-text-field
        v-model="profile.phoneNumber"
        label="Telefone"
        hint="551199999999"
        v-mask="'+## (##) #####.####'"
        dense
      />
     
      <v-text-field
        v-model="profile.whatsapp"
        label="Whatsapp"
        hint="551199999999"
        v-mask="'+## (##) #####.####'"
        dense
      />

      <v-text-field
        v-model="profile.photoUrl"
        label="Url da Foto"
        dense
      />
      
      <v-row no-gutters align-center class="mt-2">
        <v-btn
          :disabled="!valid"
          color="success"
          @click="saveProfile"
          class="d-block mt-2 mb-1 ml-auto mt-lg-0"
        >
          Atualizar
        </v-btn>
      </v-row>
    </v-form>
  </section>
</template>

<script>
import { createNamespacedHelpers, mapMutations } from 'vuex'
const { mapState:authState } = createNamespacedHelpers('auth')
const { mapActions:registersActions } = createNamespacedHelpers('registers')
export default {
  name: 'Profile',
  data: () => ({
    valid: true,
    rules: {
      email: [
        v => !!v || 'Informe o e-mail',
        v => /.+@.+\..+/.test(v) || 'Informe um e-mail válido',
      ]
    },
    keyForm: 0,
    showPassword: false
  }),
  computed: {
    ...authState(['user']),
    profile() { 
      if (!this.user) { return }
      return {
        id: this.user.uid,
        type: 'user',
        displayName: this.user.displayName,
        email: this.user.email,
        photoUrl: this.user.photoUrl,
        phoneNumber: this.user.phoneNumber,
        whatsapp: this.user.whatsapp,
        updatePass: false
      } 
    }
  },
  methods: {
    ...mapMutations(['setLoading']),
    ...registersActions(['save', 'remove']),
    
    reloadForm() {
      this.keyForm++
    },
    saveProfile() {
      return this.save({collection: 'users', item: this.profile})
        .then(res => {
          delete this.profile.password
          delete this.profile.confirmPassword
          this.reloadForm()
        })
        .catch(error => console.error(error))
    },
  },
}
</script>

<style>

</style>