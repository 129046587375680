<template>
  <section>
    <Toolbar/>
    <DataTable 
      :opt="optDataTable"
      :title="title"
      :collection="collection"
      :register="register"
      :filters="filters"
      :details="details"
      :claims="claims"
    />
  </section>
</template>

<script>
import Toolbar from "@/components/Templates/Toolbar"
import DataTable from "@/components/Registers/DataTable"
import { createNamespacedHelpers, mapState } from 'vuex'
const { mapState:registersState, mapActions:registersActions } = createNamespacedHelpers('registers')
const { mapState:authState } = createNamespacedHelpers('auth')
export default {
  name: "UsersList",
  components: { DataTable, Toolbar },
  props: {
    claims: {
      type: Object,
      required: true,
      default: () => {}
    },
  },
  data () {
    return {
      title: 'Usuários',
      collection: 'users',
      register: 'users',
      orderBy: 'displayName',
      details: [
        {
          collection: 'userRules', 
          register: 'userRules', 
          filters: [],
        }
      ]
    }
  },
  computed: {
    ...mapState(['companySelected']),
    ...registersState(['registers']),
    ...authState(['user']),
    filters() {
      let filters = null
      if (this.groupCompany && this.companySelected.groupCompany) {
        filters = [
          {
            key: `companies.${this.companySelected.groupCompany}`,
            operator: "!=",
            value: ""
          }
        ]
      }
      return filters
    },
    optDataTable() {
      return {
        vModel: this.vModel,
        headers: this.headers,
        items: this.registers[this.register],
        showSelect: true,
        itemKey: 'id',
        serverItemsLength: true,
        form: {
          newLabel: `Novo ${this.title}`,
          title: this.title,
          maxWidth: '800px',
          importer: true,
        },
        dispatch: [
          {action: 'auth/sendSignInLinkToEmail', params: {email: 'item.email'}, method: 'new', when: 'before', switch: 'sendEmailLogin' , align: 'center', tdClass: 'text-center'}
        ]
      }
    },
    headers() {
      if (!this.user) return []

      let headers = [
        { text: 'ID', value: 'id', copy: true, hide: true },
        { text: 'Ativo', value: 'active', checkbox: true, disabled: true },
        { text: 'Nome de Exibição', value: 'displayName', searchable:true },
        { text: 'E-mail', value: 'email', copy: true, searchable:true }
      ]

      if (this.user.superAdmin) {
        headers.push({ text: 'Grupos', value: 'companiesIds', chips: true, dataTableChips: 4})
      }

      headers = this.companySelected ? [ ...headers, 
        // { text: 'Admin', value: `companies.${this.companySelected.groupCompany}.claims.admin`, checkbox: true },
        // { text: 'Papeis', value: 'rules', detail: 'userRules.name', chips: true },
        { 
          text: 'Ações', 
          value: 'data-table-actions', 
          dispatch: {
            action: 'auth/sendSignInLinkToEmail',
            params: { email: 'item.email' },
            icon: 'mdi-email-send-outline',
            text: 'Enviar e-mail de login',
          }
        },
      ] : []

      return headers
    }
  },
  // watch: {
  //   filters: {
  //     handler: function(newValue) {
  //       if (newValue) this.initRegisters()
  //     },
  //   }
  // },
  methods: {
    // ...registersActions(['observer']),
    // initRegisters() {
    //   this.observer({
    //     collection: this.collection, 
    //     register: this.register, 
    //     filters: this.filters,
    //     orderBy: this.orderBy,
    //     details: this.details
    //   })
    // }
  },
}
</script>

<style>

</style>