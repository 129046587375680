<template>
  <section>
    <div
      class="drop"
      :class="getClasses"
      @dragover.prevent="dragOver"
      @dragleave.prevent="dragLeave"
      @drop.prevent="drop"
      v-if="!table && !justCarousel"
    >
      <div :style="{ display: 'flex', flexDirection: 'row' }">
        <div
          class="img"
          v-for="(image, index) of imageSources"
          :key="index"
          @mouseover="hover = true"
          @mouseleave="hover = false"
        >
          <v-btn
            v-if="hover && !readonly"
            icon
            color="light-grey"
            :style="{ position: 'absolute', top: '-10px', right: '-5px' }"
            @click="removeImage(index)"
          >
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
          <img
            class="image"
            :src="!image['base64'] ? image : image['base64']"
            @click="showDialog()"
          />
        </div>
      </div>

      <h1 v-if="imageSources.length == 0 && !isDragging && !readonly">
        Arraste algumas imagens...
      </h1>
      <h1 v-else-if="imageSources.length == 0">
        Este produto não possui imagens.
      </h1>

      <div class="manual" v-if="!readonly">
        <label for="uploadmyfile">
          <p class="ma-0">
            <span v-if="imageSources.length > 0">
              Arraste algumas imagens
            </span>
            ou clique para selecionar
          </p>
        </label>
        <input
          type="file"
          id="uploadmyfile"
          :accept="'image/*'"
          multiple
          @change="requestUploadFile"
          :disabled="disabled"
          :readonly="readonly"
        />
      </div>
    </div>

    <div v-if="table && !justCarousel">
      <v-avatar size="40">
        <img :src="imageUrls" alt="" @click="showDialog()" />
        <v-icon>mdi-account-circle</v-icon>
      </v-avatar>
    </div>

    <v-dialog v-model="dialog" width="500">
      <v-card :style="{ position: 'relative' }">
        <v-carousel>
          <v-carousel-item v-for="(image, index) of imageSources" :key="index">
            <img :src="!image['base64'] ? image : image['base64']" />
          </v-carousel-item>
        </v-carousel>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
export default {
  name: "DropImages",
  events: ["setImages","closeDialog"],
  props: ["imageUrls", "readonly", "disabled", "table", "justCarousel"],
  data: function () {
    return {
      dialog: false,
      isDragging: false,
      imageSources: [],
      hover: false,
    };
  },
  computed: {
    getClasses() {
      return { isDragging: this.isDragging };
    },
  },
  watch: {
    dialog(newValue){
      if(!newValue){
        this.$emit("closeDialog")
      }
    }
  },
  methods: {
    removeImage(index) {
      this.imageSources.splice(index, 1);
      this.$emit("setImages", this.imageSources);
      this.$el.querySelector("#uploadmyfile").value = "";
    },
    showDialog() {
      this.dialog = true;
    },
    dragOver(e) {
      if (!this.readonly) this.isDragging = true;
    },
    dragLeave(e) {
      this.isDragging = false;
    },
    async drop(e) {
      if (!this.disabled) {
        let files = [...e.dataTransfer.files];
        let images = files.filter((file) => file.type.indexOf("image/") >= 0);
        let promises = [];
        images.forEach((file) => {
          promises.push(this.getBase64(file));
        });
        let sources = await Promise.all(promises);

        sources.map((image) => {
          let newImage = {
            base64: image,
            contentType: image.substring(
              "data:".length,
              image.indexOf(";base64")
            ),
          };
          if (!this.imageSources.includes(newImage)) {
            this.imageSources = [...this.imageSources, newImage];
          }
        });

        this.isDragging = false;
        this.$emit("setImages", this.imageSources);
      }
    },
    requestUploadFile() {
      var src = this.$el.querySelector("#uploadmyfile");
      this.drop({ dataTransfer: src });
    },
    getBase64(file) {
      const reader = new FileReader();
      return new Promise((resolve) => {
        reader.onload = (ev) => {
          resolve(ev.target.result);
        };
        reader.readAsDataURL(file);
      });
    },
  },

  created() {
    this.imageSources = this.$_.cloneDeep(this.imageUrls);
    if(this.justCarousel)
      this.showDialog()
  },
};
</script>

<style scoped>
.drop {
  width: 100%;
  min-height: 100px;
  border-radius: 25px;
  border: 4px dashed grey;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  transition: background-color 0.2s ease-in-out;
  font-family: sans-serif;
  overflow: hidden;
  position: relative;
}
.isDragging {
  background-color: #999;
  border-color: #fff;
}
.img {
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  align-items: center;
  position: relative;
}
.image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 10px;
  cursor: pointer;
}
img {
  width: 500px;
  height: 500px;
  object-fit: contain;
  cursor: pointer;
}
.manual {
  bottom: -10px;
  width: 100%;
  margin-top: 10px;
  text-align: center;
  font-size: 0.8rem;
  text-decoration: underline;
}
#uploadmyfile {
  display: none;
}
</style>