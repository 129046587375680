var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[(_vm.companySelected)?_c('v-dialog',{attrs:{"width":"550"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"no-text-transform justify-start overflow-hidden text-left pa-3 ml-5",attrs:{"text":"","outlined":"","height":"50","dark":"","max-width":_vm.$vuetify.breakpoint.lgAndDown ? '800px' : ''}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-2",attrs:{"size":"20"}},[_vm._v("mdi-cached")]),_vm._v(" Grupo: "+_vm._s(_vm.companySelected.M0_NOMECOM || _vm.companySelected.groupCompany)+" | Empresa: "+_vm._s(_vm.companySelected.M0_CODIGO)+" - "+_vm._s(_vm.companySelected.M0_NOME)+" | Filial: "+_vm._s(_vm.companySelected.M0_CODFIL)+" - "+_vm._s(_vm.companySelected.M0_FILIAL)+" ")],1)]}}],null,false,3391867716),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"grey darken-4 white--text"},[_vm._v(" Seleção de Filial ")]),_c('v-card-text',[_c('v-select',{staticClass:"mt-2",attrs:{"items":_vm.companiesFiltered,"return-object":"","hide-details":"","label":"Grupo - Empresa - Filial"},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list-item',[_c('v-list-item-content',[_c('v-text-field',{attrs:{"prepend-icon":"mdi-magnify","dense":"","clearable":"","single-line":"","hide-details":"","placeholder":"Filtrar..."},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1)],1)]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"mr-2",attrs:{"link":"","color":"grey darken-3","dark":"","label":"","small":""}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-home-group")]),_vm._v(" "+_vm._s(item.M0_NOMECOM || item.groupCompany))],1),_c('v-chip',{staticClass:"mr-2",attrs:{"link":"","color":item.empColor || 'grey darken-2',"dark":"","label":"","small":""}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-domain")]),_vm._v(" "+_vm._s(item.M0_CODIGO)+" - "+_vm._s(item.M0_NOME))],1),_c('v-chip',{staticClass:"mr-2",attrs:{"link":"","color":item.filColor || 'primary',"dark":"","label":"","small":""}},[_c('v-icon',{attrs:{"x-small":"","left":""}},[_vm._v("mdi-home-city-outline")]),_vm._v(" "+_vm._s(item.M0_CODFIL)+" - "+_vm._s(item.M0_FILIAL))],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"mr-2",attrs:{"link":"","color":"grey darken-3","dark":"","label":"","small":""}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-home-group")]),_vm._v(" "+_vm._s(item.M0_NOMECOM || item.groupCompany))],1),_c('v-chip',{staticClass:"mr-2",attrs:{"link":"","color":item.empColor || 'grey darken-2',"dark":"","label":"","small":""}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-domain")]),_vm._v(" "+_vm._s(item.M0_CODIGO)+" - "+_vm._s(item.M0_NOME))],1),_c('v-chip',{staticClass:"mr-2",attrs:{"link":"","color":item.filColor || 'primary',"dark":"","label":"","small":""}},[_c('v-icon',{attrs:{"x-small":"","left":""}},[_vm._v("mdi-home-city-outline")]),_vm._v(" "+_vm._s(item.M0_CODFIL)+" - "+_vm._s(item.M0_FILIAL))],1)]}}],null,false,1154196449),model:{value:(_vm.companySelect),callback:function ($$v) {_vm.companySelect=$$v},expression:"companySelect"}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.changeCompany({ $router: _vm.$router, company: _vm.companySelect, user: _vm.user });
            _vm.dialog = false;}}},[_vm._v("Confirmar")])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }