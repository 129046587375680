<template>
  <section>
    <Toolbar/>
    <DataTable 
      :opt="optDataTable"
      :title="title"
      :collection="collection"
      :integration="true"
      :register="register"
      :filters="filters"
      :details="details"
      :claims="claims"
    />
  </section>
</template>

<script>
import Toolbar from "@/components/Templates/Toolbar"
import DataTable from "@/components/Registers/DataTable"
import { createNamespacedHelpers } from 'vuex'
const { mapState:registersState, mapActions:registersActions } = createNamespacedHelpers('registers')
export default {
  name: "ReportsList",
  components: { DataTable, Toolbar },
  props: {
    claims: {
      type: Object,
      required: true,
      default: () => {}
    },
  },
  data () {
    return {
      title: 'Relatórios',
      collection: 'reports',
      register: 'reports',
      filters: [],
      orderBy: 'name',
      details: []
    }
  },
  computed: {
    ...registersState(['registers']),
    optDataTable() {
      return {
        vModel: this.vModel,
        headers: this.headers,
        items: this.registers[this.register],
        showSelect: true,
        itemKey: 'id',
        serverItemsLength: true,
        form: {
          newLabel: `Novo ${this.title}`,
          title: this.title,
          maxWidth: '800px',
          importer: true,
        },
        // dispatch: [
        //   {action: 'auth/sendSignInLinkToEmail', params: {email: 'item.email'}, method: 'new', when: 'before', switch: 'sendEmailLogin' , align: 'center', tdClass: 'text-center'}
        // ]
      }
    },
    headers() {
      return [
        { text: 'ID', value: 'id', copy: true, hide: true },
        { text: 'Ativo', value: 'active', checkbox: true, readonly: true },
        { text: 'Nome', value: 'name' },
        { 
          text: 'Ações', 
          value: 'data-table-actions'
        }
      ]
    }
  },
  methods: {
    ...registersActions(['observer']),
    initRegisters() {
      this.observer({
        collection: this.collection, 
        register: this.register, 
        filters: this.filters,
        orderBy: this.orderBy,
        details: this.details
      })
    }
  },
  mounted() {
    this.initRegisters()
  }
}
</script>

<style>

</style>