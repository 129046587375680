<template>
  <v-card :style="{ height: 'auto'}" :class="[{ red: computedHasDebit && !computedExpand}, {orange: !computedHasDebit && !computedExpand }, {[`${color}--text`]: computedExpand}, ' card-bills lighten-4 mt-6']">
    <v-toolbar dense flat color="transparent" v-if="showToolbar">
      <v-toolbar-title
        :class="[{ ['red--text']: computedHasDebit && !computedExpand}, {['orange--text']: !computedHasDebit && !computedExpand }, {[`${color}--text`]: computedExpand}, ' subtitle-2 font-weight-bold']"
      >
        {{ title }}
      </v-toolbar-title>
      <v-spacer />
      <v-btn outlined text @click="expand = !expand" :class="[{['red--text']: computedHasDebit && !computedExpand},{['orange--text']: !computedHasDebit && !computedExpand }, {[`${color}--text`]: computedExpand}]">
        {{ computedExpand ? 'Ocultar' :  'Mostrar'}}
        <v-icon
          right
          v-text="computedExpand ? 'mdi-chevron-up' : 'mdi-chevron-down'"
        ></v-icon>
      </v-btn>
    </v-toolbar>
    <v-expand-transition>
      <v-data-table class="text-sm-caption" v-if="computedExpand" :headers="computedHeaders" :items="items" dense :items-per-page="itemsPerPage">
        <template v-slot:item="{ item }">
          <tr
            @click="clickRow({item})"
            :class="{ 'red lighten-4': isDueDate({item}), 'green lighten-4': computedItemsSelecteds[item.id || item.ID] }"
          >
            <td
              v-for="(header,idx) in computedHeaders"
              :key="idx"
            >

              <!-- ACTIONS -->
              <template v-if="header.value == 'data-table-actions'">
                <!-- CUSTOM ACTIONS -->
                <v-tooltip
                  v-for="(cAction, idx) of computedCustomActions"
                  :key="`customActionBtn-${idx}`"
                  top
                >
                  <template #activator="{ on, attrs }">
                    <v-btn
                      v-show="showAction({ item, customAction: cAction })"
                      @click="clickAction({ item, customAction: cAction })"
                      icon
                      x-small
                      :color="cAction.color"
                      class="ml-n1 mr-2 no-hover"
                      v-on="on"
                      v-bind="attrs"
                    >
                      <v-icon small>{{ cAction.icon }}</v-icon>
                    </v-btn>
                  </template>
                  {{ cAction.name }}
                </v-tooltip>
              </template>

              <!-- OTHERS -->
              <template v-else>
                <span v-html="formartFields({header, value: item[header.value]})"></span>
              </template>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-expand-transition>

    <v-dialog v-model="jsPdf.dialog" @update:return-value="jsPdf.doc = null" scrollable>
      <v-card>
        <v-toolbar flat>
          <v-card-title class="pl-0">Pré-visualização do documento</v-card-title>
          <v-spacer/>
          <v-btn @click="savePdf()" height=40 x-small outlined color="warning" class="mr-2 text-caption">
            <v-icon size=20 class="mr-1">mdi-file-download-outline</v-icon>
            Download
          </v-btn>
          <v-dialog
            v-model="jsPdf.sendEmail.dialog"
            @input="jsPdfHandleDialog"
            max-width="500vw">
            <template #activator="{ attrs, on }">
              <v-btn
                height=40
                x-small
                outlined
                color="success"
                class="mr-2 text-caption"
                v-bind="attrs"
                v-on="on"
                >
                <v-icon size=20 class="mr-1">mdi-email-send-outline</v-icon>
                Enviar por Email
              </v-btn>
            </template>
            
            <Form
              :inputs="jsPdf.sendEmail.inputs"
              :model="jsPdf.sendEmail.model"
              @submit="sendPdf"
            >
              <template #prepend>
                <v-card-title>Enviar documento PDF por email</v-card-title>
                <v-divider/>
              </template>
              <template #append>
                <v-divider/>
                <v-card-actions>
                  <v-spacer/>
                  <v-btn
                    :loading="jsPdf.sendEmail.sending"
                    :disabled="jsPdf.sendEmail.sending"
                    type="submit"
                    class="text-caption"
                    color="success"
                    height=40
                    x-small
                    outlined
                    >
                    <v-icon size=20 class="mr-1">mdi-send</v-icon>
                    Enviar
                  </v-btn>
                  <v-btn @click.stop="jsPdf.sendEmail.dialog = false" height=40 x-small outlined color="error" class="text-caption">
                    <v-icon size=18 class="mr-1">mdi-cancel</v-icon>
                    Cancelar
                  </v-btn>
                </v-card-actions>
              </template>
            </Form>
          </v-dialog>
          <v-btn @click.stop="jsPdf.dialog = false" height=40 x-small outlined color="error" class="text-caption">
            <v-icon size=18 class="mr-1">mdi-cancel</v-icon>
            Cancelar
          </v-btn>
        </v-toolbar>
        <v-divider/>
        <Iframe v-if="!!jsPdf.doc || !!jsPdf.blob || !!jsPdf.objectStorage" :src="jsPdfOutput" type="application/pdf"/>
        <!-- <Iframe v-else-if="!!jsPdf.blob" :src="jsPdfOutput" type="application/pdf"/> -->
        <v-divider/>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import {
  createNamespacedHelpers,
  mapMutations
} from "vuex";
import { jsPDF } from "jspdf"
import "jspdf-autotable"
import * as fs from 'file-saver'
import Iframe from "@/components/Templates/Iframe"
import Form from "@/components/Registers/Form"
const { mapState: registersState, mapActions: registersActions } =
  createNamespacedHelpers("registers");
export default {
  components: {
    Iframe,
    Form
  },
  props: {
    headers: [],
    items: [],
    openCard: {
      type: Boolean,
      default: false 
    },
    color: {
      type: String,
      default: "teal"
    },
    title: {
      type: String,
      default: "Títulos em Aberto"
    },
    itemsPerPage: {
      type: Number,
      default: 10
    },
    showToolbar: {
      type: Boolean,
      default: false
    },
    dueDateField: String,
    customActions: Array
  },
  data() {
    return {
      jsPdf: {
        doc: null,
        dialog: false,
        sendEmail: {
          sending: false,
          dialog: false,
          inputs: [
            { type: 'text-field', value: 'to', label: "Email de Destino" },
            { type: 'text-field', value: 'cc', label: "Cc", md: 6 },
            { type: 'text-field', value: 'bcc', label: "Cco", md: 6 },
            { type: 'text-field', value: 'subject', label: "Assunto" },
            { type: 'textarea', value: 'text',  label: "Corpo do Email", rows: 4 },
          ],
          model: null,
        },
        blob: null
      },
      hasDebit: false,
      expand: false,
      itemsDueDate: [],
      itemsSelecteds: {},
      exportPdf: {
        type: Boolean,
        required: false,
        default: true,
      }
    };
  },
  computed: {
    ...registersState(["loading"]),
    computedHasDebit(){
      return this.items && this.items.some(i => this.isDueDate({item: i}))
    },
    computedExpand(){
      return this.expand
    },
    computedItemsSelecteds(){
      return this.itemsSelecteds
    },
    computedCustomActions() {
      return this.customActions
    },
    computedHeaders() { 
      return this.headers.filter(h => !h.hide);
    },

    computedExportPdf() {
      return this.exportPdf 
    },

    jsPdfOutput() {
      if (this.jsPdf.doc)
        return this.jsPdf.doc.output('datauristring')
      else if (this.jsPdf.blob){
        // const base64 = await this.blobTo64({blob: this.jsPdf.blob}) 
        const url =  URL.createObjectURL(this.jsPdf.blob)
        return url
      }
      else if (this.jsPdf.url) 
        return this.jsPdf.url

      return null
    }
  },
  watch: {
    items(newValue) {
      if (this.items && this.items.length > 0) {
        this.itemsSelecteds = Object.assign({},...this.items.map(item => ({[item.id || item.ID]: false})))
      } else {
        this.itemsSelecteds = {}
      }
    }
  },
  methods: {
    ...mapMutations(["setLoading"]),
    ...registersActions([
      "get",
      "getById",
      "getApi",
      "getExternal",
    ]),
    isDueDate({item}) {
      if (!this.dueDateField) {
        return false;
      }
      let today = this.$moment(new Date(), 'YYYYMMDD')
      today = today.format('YYYYMMDD')
      return (item && item[this.dueDateField] && today) ? today.trim() > item[this.dueDateField].trim() : false
    },
    clickRow({item}){
      const isSelected = this.itemsSelecteds[item.id || item.ID]
      Object.keys(this.itemsSelecteds).forEach(key => this.itemsSelecteds[key] = false)
      this.itemsSelecteds[item.id || item.ID] = !isSelected
      this.$emit('clickRow', !isSelected ? item : null)
    },
    formartFields({header, value}){
      if (!value) return value
      let maskedValue = ``

      const prefix = header.prefix ? header.prefix + ' ' : ''

      // valor numérico
      if (header.type == 'float' || header.type == 'integer') {
        maskedValue =  value.toLocaleString('pt-br', { minimumFractionDigits: header.precisionText, maximumFractionDigits: header.precisionText })
      //data em formato string, geralmente como vem do Protheus (YYYYMMDD)
      } else if (header.date && !(value instanceof Date) && !isNaN(value) && typeof value === "string") {
        maskedValue = this.$moment(value, 'YYYYMMDD').format("DD/MM/YYYY");
      } else if (header.date && !(value instanceof Date) && !isNaN(value) && typeof value !== "string") {
        maskedValue = this.$moment(value.toDate()).format("DD/MM/YYYY");
      }
      else if (header.dateHour && !(value instanceof Date) && !isNaN(value) && typeof value !== "string") {
        maskedValue = this.$moment(value.toDate()).format("DD/MM/YYYY - HH:mm:ss");
      }
      else if (header.dateYear && !(value instanceof Date) && !isNaN(value) && typeof value !== "string") {
        maskedValue = this.$moment(value.toDate()).format("YYYY");
      }
      else if (header.datePlusWeek && !(value instanceof Date) && !isNaN(value) && typeof value !== "string") {
        maskedValue = this.$moment(value.toDate()).format("DD/MM/YYYY, dddd");
      } else {    
        maskedValue = value
      }

      const suffix = header.suffix
        ? ' ' + header.suffix
        : ''

      return prefix + maskedValue + suffix      
    },
    showAction({ item, customAction }) {
      const showAction = eval(customAction.hide);
      return typeof showAction === "function"
        ? !showAction({ item })
        : !showAction;
    },
    clickAction({ item, customAction }) {
      const self = {
        item,
        $router: this.$router,
        user: this.user,
        $toast: this.$toast,
        $api: this.$api,
        ...this,
      };
      const actionRunner = eval(customAction.actionRunner);
      actionRunner(self);
    },
    async generatePdf({base64str, objectStorage, url}) {
      this.setLoading({stats: true})
      if (url) this.jsPdf.url = url
      else if (objectStorage) {
        var xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        
        xhr.onload = async (event) => {
          this.jsPdf.blob = xhr.response
          // this.jsPdf.doc = await this.blobTo64({blob: xhr.response})
          this.jsPdf.dialog = true
          this.setLoading({stats: false})
        };
        xhr.open('GET', objectStorage.documentUrl);
        xhr.send();
      }
      else if(base64str){
        // decode base64 string, remove space for IE compatibility
        var binary = atob(base64str.replace(/\s/g, ''));
        var len = binary.length;
        var buffer = new ArrayBuffer(len);
        var view = new Uint8Array(buffer);
        for (var i = 0; i < len; i++) {
            view[i] = binary.charCodeAt(i);
        }
        const doc = new jsPDF()
        var blob = new Blob( [view], { type: "application/pdf" });
        // const link = document.createElement('a')
        // link.href = URL.createObjectURL(blob)
        // link.click()
        

        // this.jsPdf.doc = blob
        // this.jsPdf.dialog = true
        this.jsPdf.blob = blob
        this.jsPdf.dialog = true
        this.setLoading({stats: false})
      }
      else {
        try {
          const doc = new jsPDF();
          const fontSize = 7
          let lineHeight = fontSize
          const fontFamily = "helvetica"
          const tableLineWidth = 0.2
          const tableLineColor = [125, 125, 125]
          const hexToNumber = str => eval('0x' + str)
          const fillColor = this.colorPdfTable && [
            hexToNumber(this.colorPdfTable.slice(1,3)),
            hexToNumber(this.colorPdfTable.slice(3,5)),
            hexToNumber(this.colorPdfTable.slice(5,7)),
          ] || [ 41, 128, 186] // azul padrão de header
    
          
          // altere o valor para determinar qual linha deve começar o documento
          let line = 2
    
          const size = {
            x: 210,
            y: 297 
          }
    
          const item = this.$refs.formMaster.getModel()
    
          /**
           * Logo
           * addImage ref: https://artskydj.github.io/jsPDF/docs/module-addImage.html
           */
          const image = await this.loadImage()
          doc.addImage(image, 'JPEG', 5, lineHeight, 16, 9)
    
          /**
           * Título
           */
          doc.setFont(fontFamily)
          doc.setFontSize(2.5 * fontSize)
          /** @TODO parametrizar o título */

          doc.text(eval(this.titleOfPrint || "'Impressão'"), size.x/2, line * lineHeight, { align: 'center' });
          doc.setFontSize(fontSize)
          line++
    
          /**
           * Empresa
           */
          const colsEmpresa = 3
          const company = this.company
          const printCompanyHeaders = (this.printCompanyHeaders && this.printCompanyHeaders.length > 0) ? this.printCompanyHeaders : []
          const empresaItems = printCompanyHeaders.map(pcH => {
            const valueCompiled = eval(pcH.value)
            return {...pcH, value: valueCompiled}
          })
          
          const empresaBody = []
          for (let row = 0; row < empresaItems.length / colsEmpresa; row++) {
            const rowInputs = empresaItems.slice(row*colsEmpresa, (row*colsEmpresa) + colsEmpresa)
            empresaBody.push(rowInputs.reduce((acc, item) => {
              const label = {
                content: item.label,
                styles: {
                  fillColor,
                  textColor: [255, 255, 255], 
                  fontStyle: 'bold',
                  lineWidth: 0.1,
                  lineColor: [171, 206, 228]
                },
              }
              const value = {
                content: item.value,
              }
    
              return [...acc, label, value]
            }, []))
          }
          doc.autoTable({
            startY: line * lineHeight,
            body: empresaBody,
            margin: { top: 25, right: 5, bottom: 10, left: 5 },
            styles: { halign: 'center', fontSize, cellPadding: 1 },
            headStyles: { halign: 'center', fontStyle: 'bold' },
            tableLineWidth,
            tableLineColor,
          })

          /**
           * Agrupar os inputMaster por quadrante de impressão
           */

          let inputMasterPerSquad = []

          let inputsMaster = this.computedInputsMaster.filter(im => im.value && im.print && !im.returnObject)
          
          if (inputsMaster && !inputsMaster.length > 0) {
            inputsMaster = this.computedInputsMaster.filter(im => im.value && !im.returnObject)
          }

          inputsMaster.forEach(im => {
            const squad = (im.print && im.print.squad) ? im.print.squad : 1
            const imSquad = (inputMasterPerSquad && inputMasterPerSquad.length > 0) ? inputMasterPerSquad[squad - 1] : []
            inputMasterPerSquad[squad - 1] = [...imSquad, im]
          })
          
          /**
           * Cabeçalho
           */
          // Define o número de colunas por tabela
          const cols = 3

          inputMasterPerSquad.forEach(imSquad => {
            const inputs = (imSquad && imSquad.length > 0 && imSquad[0].print && imSquad[0].print.order) ? imSquad.sort((a,b) => {
              if (a.print.order < b.print.order) {
                return -1
              }
              if (a.print.order > b.print.order) {
                return 1
              }
              return 0
            }) : imSquad

            const body = []

            for (let row = 0; row < inputs.length / cols; row++) {
              const rowInputs = inputs.slice(row*cols, (row*cols) + cols)
              body.push(rowInputs.reduce((acc, input) => {
                let text = item[input.value] || eval(`item.${input.value}`) || ''
                if (input.type == 'select') {
                  const selectItem = input.items.find(i => i.value == item[input.value])
                  if (selectItem) 
                    text = selectItem.text || item[input.value]
                } else if (input.type === 'switch') {
                  text = item[input.value] ? 'Sim' : 'Não'
                }
      
                /**
                 * Configuração do Label
                 * ref: https://github.com/simonbengtsson/jsPDF-AutoTable#options
                 */
                const label = {
                  content: input.label,
                  styles: {
                    fillColor,
                    textColor: [255, 255, 255], 
                    fontStyle: 'bold',
                    lineWidth: 0.1,
                    lineColor: [171, 206, 228]
                  },
                }
                
                /**
                 * Configuração do texto do valor do item
                 */

                const value = typeof text === 'string' ? {content: text.trim()} : {content: text}
      
                return [...acc, label, value]
              }, []))
            }
            doc.autoTable({
              startY: doc.lastAutoTable.finalY + lineHeight,
              body,
              margin: { top: 25, right: 5, bottom: 10, left: 5 },
              styles: {
                fontSize,
                cellPadding: 1,
              },
              tableLineWidth,
              tableLineColor,
            })
          })
          
          /**
           * Impostos
           */
          if (this.impostos && this.impostos.headers && this.impostos.headers.length > 0) {
            const head = [
              [{ content: 'Impostos', colSpan: this.impostos.headers.length, styles: { halign: 'center' }}],
              this.impostos.headers.map(el => ({content:el.text}))
            ]
            const impostosBody = [this.impostos.headers.map(el => {
              const value = this.impostos.itens[0][el.value]
              return `R$ ${value.toLocaleString('pt-br', { minimumFractionDigits:2, maximumFractionDigits: 2 }) }`
            })]
            doc.autoTable({
              startY: doc.lastAutoTable.finalY + lineHeight,
              head,
              body: impostosBody,
              margin: { top: 25, right: 5, bottom: 10, left: 5 },
              styles: { halign: 'center', fontSize, fillColor, cellPadding: 1 },
              headStyles: { halign: 'center', fontStyle: 'bold' },
              tableLineWidth,
              tableLineColor,
            })
    
            if (this.impostosPdfText)
              doc.text(this.impostosPdfText, 5, doc.lastAutoTable.finalY + (lineHeight/2))
          }
    
          /**
           * Totais
           */
          this.docPdfTotais(doc, { lineHeight, fontSize, fillColor, tableLineWidth, tableLineColor })
    
          /**
           * Tabela de itens
           */
          let headersItens = this.computedHeaders.filter(header => header.print)

          if (headersItens && !headersItens.length > 0) {
            headersItens = this.computedHeaders
          } else {
            headersItens = headersItens.sort((h1, h2) => {
              if (h1.print.order < h2.print.order) {
                return -1
              }
  
              if (h1.print.order > h2.print.order) {
                return 1
              }
  
              return 0
            })
          }
          const head = [headersItens.map(h => h.text)]
          const headKeys = headersItens.map(h => h.value)

          const table = this.$refs.formDetails?.getTable()
          if(table){
            const bodyTableItems = []
            for (const row of table.rows) {
              const item = row.item
              if (!this.filterItemsFunction(item))
                continue
              
              bodyTableItems.push(headKeys.map(header => row.getCellFromHeader(header).text))
            }
            // ref: https://github.com/simonbengtsson/jsPDF-AutoTable
            doc.autoTable({
              startY: doc.lastAutoTable.finalY + lineHeight,
              head,
              body: bodyTableItems,
              margin: { top: 25, right: 5, bottom: 10, left: 5 },
              styles: { fontSize, cellPadding: 1  },
              headStyles: { fontStyle: 'bold', fillColor, },
              tableLineWidth,
              tableLineColor,
            })
          }
    
          /**
           * Totais (fim da tabela)
           */
          this.docPdfTotais(doc, { lineHeight, fontSize, fillColor, tableLineWidth, tableLineColor })
    
          this.jsPdf.doc = doc
          this.jsPdf.dialog = true
        } catch(e) {
          console.error(e)
        } finally {
          this.setLoading({stats: false})
        }
      }
    },

    jsPdfHandleDialog(value) {
      if (value) {
        const { 
          toDefault, 
          subjectDefault,
          textDefault, 
          enableCcDefault: enableCc, 
          ccDefault, 
          enableBccDefault: enableBcc,
          bccDefault
        } = { toDefault: '""', textDefault: '""', subjectDefault: '""', enableCcDefault: false, ccDefault: '""', enableBccDefault: false, bccDefault: '""' }

        const to = eval(toDefault)
        const text = eval(textDefault)
        const subject = eval(subjectDefault)
        const cc = eval(ccDefault)
        const bcc = eval(bccDefault)

        /** @TODO parametrizar */
        // const to = item['SA1'] && item['SA1']['A1_EMAILPDF'] && item['SA1']['A1_EMAILPDF'].trim() || ''
        this.jsPdf.sendEmail.model = {
          to,
          text,
          subject,
          cc,
          bcc
        }

        this.jsPdf.sendEmail.inputs = this.jsPdf.sendEmail.inputs.map(i => {
          if (i.value === 'cc')
            return {...i, hide: !enableCc}
          else if (i.value === 'bcc')
            return {...i, hide: !enableBcc}
          return i
        })
      } else {
        this.jsPDF.sendEmail.model = null
      }
    },

    /** @TODO parametrizar o nome do documento */
    savePdf() {
      if (this.jsPdf.doc)
        this.jsPdf.doc.save("pedido.pdf")
      else if (this.jsPdf.blob) {
        fs.saveAs(this.jsPdf.blob, 'Arquivo -' + new Date().valueOf() + '.pdf')
      }

        console.warn('jsPdf.doc not yet defined.')
    },

    async sendPdf({ to, text, bcc, cc, subject }) {
      let response
      try {
        this.jsPdf.sendEmail.sending = true
        if (this.jsPdf.doc) {
          response = await this.$api.post(`/mail/send`, { to, text, id: this.id, bcc, cc, subject, attachment: this.jsPdfOutput })
        
          this.jsPdf.sendEmail.dialog = false
          this.$toast.success('Email enviado!', { position: 'top-right' })
        } else if (this.jsPdf.blob) {
          var reader = new FileReader();
          reader.readAsDataURL(this.jsPdf.blob); 
          reader.onloadend = async () => {
            var base64data = reader.result;                
            response = await this.$api.post(`/mail/send`, { to, text, id: this.id, bcc, cc, subject, attachment: base64data })
        
            this.jsPdf.sendEmail.dialog = false
            this.$toast.success('Email enviado!', { position: 'top-right' })
          }

        }
      } catch (err) {
        response = err
      } finally {
        this.jsPdf.sendEmail.sending = false
      }

      return response
    }
  },

  created() {
    this.expand = this.openCard
    this.itemsSelecteds = Object.assign({},...this.items.map(item => ({[item.id || item.ID]: false})))
  }
  
};
</script>

<style>
  .card-bills {
    margin-bottom: 1em;
  }
</style>