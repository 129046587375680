import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import routerInstance from '../router/index'
import registers from './registers'
import crypto from 'crypto'
import axios from 'axios'

const gApi = axios.create();

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {auth, registers},
  state: {
    loading: {
      stats: false,
      message: 'carregando',
    },
    alerts: [],
    lastAlert: 'Avisos',
    snackbar: {
      stat: false,
      text: '',
      color: 'info',
      timeout: 3000,
    },
    drawer: false,
    drawerRight: false,
    drawerForm: false,
    fixNDR: false,
    demo: false,
    companies: [],
    companySelected: {},
    collectionStructure: null,
    algolia: null,
    prothues: null,
    _configs: [],
    integrations: []
  },
  mutations: {
    setLoading (state, payload) { state.loading = payload },
    pushAlert (state, payload) { 
      state.alerts.push(payload) 
      state.lastAlert = payload.type 
    },
    showSnackbar (state, payload) { 
      state.snackbar = payload 
      state.snackbar.stat = true 
    },
    setDrawer (state, payload) { state.drawer = payload },
    setDrawerRight (state, payload) { state.drawerRight = payload },
    setFixNDR (state, payload) { state.fixNDR = payload },
    setDrawerForm(state, payload) { state.drawerForm = payload },
    setDemo (state, payload) { state.demo = payload },
    setCollectionStructure (state, payload) { state.collectionStructure = payload },
    setAlgolia (state, payload) { state.algolia = payload },
    setProtheus (state, payload) { state.protheus = payload },
    setCompanies (state, payload) { state.companies = payload },
    setCompanySelect (state, payload) {
      state.companySelected = payload 
      this._vm.$api.defaults.params = {
        groupCompany: payload.groupCompany,
        gpEmpresa: payload ? payload.M0_CODIGO+'0' : '',
        filial: payload? payload.M0_CODFIL : ''
      }
    },
    setIntegrations (state, payload) {
      this._vm.$set(state, 'integrations', payload) 
    }
  },
  actions: {
    // GENERAL
    async initSystem({state, commit, dispatch}, {$router}) {
      dispatch('auth/authChanged', {$router}, {root: true})
    },

    async getConfigs({commit, dispatch}, { user, $router }) {
      let companies = []
      
      const algolia = await dispatch('registers/getById', { collection: '_configs', id: 'algolia' }, {root: true})
      commit('setAlgolia', algolia)

      if (user.superAdmin) {
        const customers = await dispatch('registers/get', { collection: 'customers', register: 'customers', filters: [{key: 'active', operator: '==', value: true}]}, {root: true})
        companies = customers.map(c => c.id)
      } else if (user.companiesIds && user.companiesIds.length > 0) {
        companies = user.companiesIds
      } else {
        return (!$router.history.current.path.includes('/404company')) ? $router.push('/404company') : null
      }
      
      let companiesGroup = {}

      await Promise.all(
        companies.map(async key => {
          const company = await dispatch('registers/getById', { collection: 'customers', register: 'customers', id: key, subCollections: ['sigamat', 'userRules'] }, {root: true});
          if (company && company.sigamat) {
            let sigamatFiltered = user.superAdmin ? company.sigamat : company.sigamat.filter(c => user.companies[key].subsidiaries.includes(c.id))
            sigamatFiltered = sigamatFiltered.map(s => ({
              ...s, 
              groupCompany: key,
              configs: company.configs,
              userRules: company.userRules,
            }))
            companiesGroup = {...companiesGroup, [company.id]: {...company, sigamat: sigamatFiltered }}
          }
        })
      )
      
      commit('setCompanies', companiesGroup) 
      
      const lastCompany = await dispatch('setLastCompany', {companies: companiesGroup, user})
      commit('setCompanySelect', lastCompany);
      
      const collectionStructure = await dispatch('registers/getById', { collection: 'SX2', register: 'collectionStructure', id: lastCompany.M0_CODIGO, customerId: lastCompany.groupCompany }, {root: true});
      commit('setCollectionStructure', collectionStructure)
      
      const filtersUsers = [{key: 'active', operator: '==', value: true}]
      if (!user.superAdmin) { filtersUsers.push( {key: 'companiesIds', operator: 'array-contains', value: lastCompany.groupCompany}) }
      dispatch('registers/observer', {
        collection: 'users', 
        register: 'users', 
        orderBy: ['displayName'], 
        filters: filtersUsers,
      }, {root:true})  
         
      await dispatch('setRouter', {user, $router, company: lastCompany })
      return lastCompany
    },

    async setRouter({dispatch, state, commit, rootState}, { user, $router, oldCompany = null, company = null }) {
      const superAdmin = user.superAdmin
      const admin = user && (
          user.superAdmin || (
            user.companies && 
            company && 
            company.groupCompany && 
            user.companies[company.groupCompany] && 
            user.companies[company.groupCompany].admin
          )
        )

      if (oldCompany && oldCompany.groupCompany !== company.groupCompany) {
        commit('registers/resetRegisters', { root: true }) 
        $router = routerInstance.initRouter()
      }
      const integrations = await dispatch('registers/get', {collection: `integrations`, registers: 'integrations', orderBy: 'menuOrder', filters: [{key: "active", operator: "==", value: true}]}, {root: true});
      commit('setIntegrations', integrations)
      const reports = await dispatch('registers/get', {collection: `reports`, registers: 'reports', filters: [{key: "active", operator: "==", value: true}]}, {root: true});
      const filtersUsers = [{key: 'active', operator: '==', value: true}]
      if (!user.superAdmin) { filtersUsers.push( {key: 'companiesIds', operator: 'array-contains', value: company.groupCompany}) }
      dispatch('registers/observer', {
        collection: 'users', 
        register: 'users', 
        orderBy: ['displayName'], 
        filters: filtersUsers,
      }, {root:true})   

      // aproveita para subir a registers userRules
      await dispatch('registers/get', {collection: `userRules`, registers: 'userRules'}, {root: true}); 
  
      integrations.forEach(integration => {
        let {register, collection, filters = '', orderBy = '', details, externalRequest} = integration
        if (
          (oldCompany && (oldCompany.groupCompany !== company.groupCompany || oldCompany.M0_CODIGO !== company.M0_CODIGO))
          ||
          (state.collectionStructure[register] && state.collectionStructure[register].filial === 'e')
        ) {
          // Reset Observers e Registers
          if (rootState.registers.observers[register]) {
            rootState.registers.observers[register].snapshots.forEach(el => {
              el()
            });
          }
          commit('registers/resetRegister', register, { root: true }) 

          try {
            if (['{', '['].some(symbol => orderBy.includes(symbol)))
              orderBy = JSON.parse(orderBy)
          } catch (e) {
            console.warn(`Error when trying to define "orderBy" in changeCompany ${integration.name}.`, e)
          }
          try {
            filters = (integration.filters) ? JSON.parse(integration.filters) : null
          } catch (e) {
            console.warn(`Error when trying to define "filters" in changeCompany ${integration.name}.`, e)
          }
          
          if (externalRequest && externalRequest.query) {
            dispatch('registers/getExternal',{
              ...externalRequest,
              register, 
              user
            })
          } else {
            // Start Observers
            dispatch('registers/observer', {
              collection, 
              register, 
              filters,
              orderBy,
              details,
              user
            }, {root: true})
          }
        }
      });

      const { generatedRoutes, beforeEach } = routerInstance.createRouter({ integrations, reports, admin, superAdmin, groupCompany: company.groupCompany})
      for (const gr of generatedRoutes) {
        const routerIndex = $router.options.routes.findIndex(r => r.name === gr.name)
        
        if (routerIndex > -1) {
          // $router.removeRoute($router.options.routes[routerIndex]['name'])
          $router.options.routes.splice(routerIndex, 1)
        }

        $router.addRoute(gr)
        $router.options.routes.push(gr)
      }
  
      // if (typeof $router === 'undefined')  $router.beforeEach(beforeEach)
    },
    async setLastCompany({commit}, {companies, user}){
      // ler do localstorage se está definido uma filial padrão para o usuário que logou
      // caso não tenha, define a filial matriz como padrão -> companies.filter(x => user.companies.includes(x.id))[0]
      // caso tenha localstorage mas não para o usuário que logou, adicionar o usuário e definir a matriz como padrão

      let storedCompanies = JSON.parse(localStorage.getItem('storedDefaultCompanies')) || []
      let defaultCompany = storedCompanies?.find(sc => sc.user_id === user.uid)
      if (!defaultCompany || !defaultCompany.company || !defaultCompany.company.groupCompany || !companies[defaultCompany.company.groupCompany]) {
        const groupCompany = Object.keys(companies)[0]
        let company = companies && companies[groupCompany] ? companies[groupCompany]['sigamat'][0] : null

        if (defaultCompany) {
          const indexCompany = storedCompanies.findIndex(sc => sc.user_id === user.uid)
          storedCompanies[indexCompany] = {user_id: user.uid, company: {...company, groupCompany} }
        } else {
          defaultCompany = { user_id: user.uid, company: {...company, groupCompany} }
          storedCompanies.push(defaultCompany)
        }
        localStorage.setItem('storedDefaultCompanies', JSON.stringify(storedCompanies))
      } else { //atualize os dados da company selecionada
        const { groupCompany } = defaultCompany.company
        const defaultCompanyUpdated = companies[groupCompany].sigamat.find(s => s.id === defaultCompany.company.id)
        if (defaultCompanyUpdated) {
          const indexCompany = storedCompanies.findIndex(sc => sc.user_id === user.uid)
          defaultCompany = {user_id: user.uid, company: {...defaultCompanyUpdated, groupCompany} }
          storedCompanies[indexCompany] = defaultCompany
        } else { // caso não exista mais a company
          let company = companies && companies[groupCompany] ? companies[groupCompany]['sigamat'][0] : null
          defaultCompany = { user_id: user.uid, company: {...company, groupCompany} }
          storedCompanies.push(defaultCompany)
        }
        localStorage.setItem('storedDefaultCompanies', JSON.stringify(storedCompanies))
      }
      


      return defaultCompany.company
    },
    async changeCompany({state, commit, dispatch}, {$router, company, user}) {
      commit('setLoading', {stats: true})
      const oldCompany = this._vm.$_.cloneDeep(state.companySelected)
      if (oldCompany && (oldCompany.groupCompany !== company.groupCompany || oldCompany.id !== company.id)) {
        if(company.configs && company.configs.initialRoute){
          $router.push(company.configs.initialRoute)          
        } else {
          $router.push('/')
        }
        document.location.reload()
      }

      const storedCompanies = JSON.parse(localStorage.getItem('storedDefaultCompanies'))
      const storedCompany = storedCompanies.find(companyWithUser => companyWithUser.user_id === user.uid)

      storedCompany.company = company
      localStorage.setItem("storedDefaultCompanies", JSON.stringify(storedCompanies))  
      commit('setCompanySelect', company)
  
      // Update User
      await dispatch('auth/updateUser', {}, {root:true})
      
      await dispatch('setRouter', {user, $router, oldCompany, company})
      
      commit('setLoading', {stats: false})
    },
    dispatchAction({dispatch}, {action, params}) {
      dispatch(action, params, { root: true })
    },
    notify({}, {title, body, image, badge, icon, toast = true}) {
      this._vm.$notification.show(title, {
        lang: 'PT',
        body,
        image,
        badge,
        icon,
      }, {})
      if (toast) { this._vm.$toast.info(`${title} - ${body}`) }
    },
    gravatarUrl({}, email) {
      let hash = crypto.createHash('md5').update(email || '').digest("hex")
      return `https://www.gravatar.com/avatar/${hash}?s=200&d=mm&r=g`
    },
    downloadFileByUrl({}, {url, type, label}) {
      gApi.get(url, { responseType: 'blob' })
        .then(response => {
          const blob = new Blob([response.data], { type: response.data.type })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = label
          link.click()
          URL.revokeObjectURL(link.href)
        }).catch(e => console.error(e))
    },
    downloadFile({}, {data, type, label}) {
      const blob = new Blob([data], {type})
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = label
      link.click()
      URL.revokeObjectURL(link.href)
    },
  }
})
