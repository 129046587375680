<template>
  <header>
    <v-row justify="space-around" align="center" class="my-6" >
      <v-col cols="auto">
      </v-col>
      <v-col cols="auto">
        <v-list dark nav>
          <v-list-item
            v-for="item in menu"
            v-show="(item.meta) ? allowed(item.meta) : false"
            :key="item.name"
            link
            :to="item.path"
            class="no-hover float-left"
          >
            <template>
              <v-list-item-content>
                <v-list-item-title class="text-none text-h6 font-weight-light">{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </header>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Loading',
  data: () => ({}),
  computed: {
    ...mapState(['loading']),
    menu() {
      // const items = this.$router.options.routes
      // .filter(x => x.meta.menuPublic)
      // .map(x => {
      //   delete x.component
      //   return x
      // })
      // return items
      return []
    }
  },
  methods: {
    allowed(meta) {
      let allowed = true
      if (meta.requireClaim && (!this.$appConfig.claims || !this.$appConfig.claims[meta.requireClaim])) { allowed = false }
      return allowed
    },
  }
}
</script>
 
<style>

</style>