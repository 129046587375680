<template>
  <section id="signin" class="w-100 h-100">
    <Background />

    <!-- Alerta de recuperação de senha -->
    <v-alert :value="recoveryAlert.value" :type="recoveryAlert.type" :icon="recoveryAlert.icon" dismissible transition="scale-transition" class="recoveryAlert">
      {{ recoveryAlert.content }}
    </v-alert>

    <v-container fill-height>
      <v-row no-gutters class="justify-center">
        <v-col cols="10" sm="6" md="4" xl="3">
          <v-card elevation="5 card-login">
            <v-card-title>
              <span class="info--text font-weight-bold text-small">Login</span>
            </v-card-title>
            <v-card-text>
              <v-form ref="form" v-model="valid" lazy-validation @submit.prevent>
                <v-text-field prepend-inner-icon="mdi-email" v-model="user.email" :rules="rules.email" label="E-mail" @keyup.enter="signin" required class="mt-0 pt-0" />

                <v-text-field prepend-inner-icon="mdi-key" v-model="user.password" :rules="rules.password" label="Senha" :type="showPassword ? 'text' : 'password'" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" @click:append="showPassword = !showPassword" @keyup.enter="signin" required class="mt-0 pt-0" />

                <v-row dense no-gutters>
                  <v-checkbox class="shrink mr-0 mt-0" v-model="keepConnected">
                    <template v-slot:label>
                      <span style="font-size: 0.5em ">MANTER CONECTADO</span>
                    </template>
                  </v-checkbox>
                  <v-spacer></v-spacer>
                  <v-dialog width="500">
                    <template v-slot:activator="{on, attrs}">
                      <v-btn style="font-size: 0.6em" class="px-0" small plain v-bind="attrs" v-on="on">
                        Esqueci a senha
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title class="px-4 pb-0">
                        <span class="error--text font-weight-bold text-small">Recupere sua senha</span>
                      </v-card-title>
                      <div class="p-20">
                        <v-card-text>
                          <p>Nos informe seu email de acesso para que possamos enviar um email de recuperação de senha</p>

                          <v-text-field prepend-inner-icon="mdi-email" v-model="recoveryEmail" :rules="rules.email" label="E-mail" @keyup.enter="signin" required class="mt-0 pt-0" />
                        </v-card-text>
                      </div>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn class="rounded-lg" block color="error" @click="sendRecoveryMail">
                          Enviar email de recuperação
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-row>

                <v-row no-gutters align-center>
                  <v-btn class="rounded-lg" block :disabled="!valid" color="info" @click="signin">
                    Login
                  </v-btn>
                  <v-col cols="12" lg="6">
                    <v-alert :value="res.value" :type="res.type" :icon="res.icon" text dense dismissible transition="scale-transition" class="ma-0 mr-lg-1 wp-preline">{{ res.content }}</v-alert>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
  import Background from "@/components/Auth/Background.vue";
  import {createNamespacedHelpers, mapMutations} from "vuex";
  const {mapActions: authActions} = createNamespacedHelpers("auth");
  export default {
    name: "SignIn",
    components: {Background},
    data: () => ({
      keepConnected: false,
      recoveryAlert: {
        value: false,
        type: "info",
        icon: null,
        content: "",
      },
      tab: null,
      logging: false,
      valid: true,
      recoveryEmail: "",
      res: {
        value: false,
        type: "info",
        icon: null,
        content: "",
      },
      user: {},
      rules: {
        email: [(v) => !!v || "Informe o e-mail", (v) => /.+@.+\..+/.test(v) || "Informe um e-mail válido"],
        password: [(v) => !!v || "Informe a senha"],
      },
      showPassword: false,
    }),
    methods: {
      ...mapMutations(["setLoading"]),
      ...authActions(["signIn", "recoverUserPassword"]),

      sendRecoveryMail() {
        this.recoverUserPassword({email: this.recoveryEmail})
          .then((res) => {
            this.recoveryAlert = {
              value: true,
              type: "success",
              icon: "mdi-check-circle-outline",
              content: "Email enviado com sucesso!",
            };
            this.setLoading({stats: false});
          })
          .catch((err) => {
            console.error(err);
            this.recoveryAlert = {
              value: true,
              type: "error",
              icon: "mdi-alert-outline",
              content: "Erro interno, favor contactar o administrador.",
            };
            this.setLoading({stats: false});
          });
      },

      signin() {
        if (this.$refs.form.validate()) {
          let sessionType = this.keepConnected ? this.$auth.Auth.Persistence.LOCAL : this.$auth.Auth.Persistence.SESSION;
          this.setLoading({stats: true, message: "validando acesso"});
          this.signIn({user: this.user, sessionType: sessionType})
            .then((res) => {
              if (!res.error) {
                this.res = {
                  value: true,
                  type: "success",
                  icon: "mdi-check-circle-outline",
                  content: "Login efetuado!",
                };
              } else {
                this.res = {
                  value: true,
                  type: "error",
                  icon: "mdi-alert-outline",
                  content: res.error,
                };
              }
              this.setLoading({stats: false});
            })
            .catch((err) => {
              console.error(err);
              this.res = {
                value: true,
                type: "error",
                icon: "mdi-alert-outline",
                content: "Erro interno, favor contactar o administrador.",
              };
              this.setLoading({stats: false});
            });
        }
      },
    },
    created() {
      this.setLoading({stats: false});
    },
  };
</script>

<style lang="scss">
  .recoveryAlert {
    position: absolute;
    right: 16px;
    top: 16px;
    margin: 0 auto; // Without this the box extends the width of the page
  }
  .card-login {
    opacity: 0.9;
  }
</style>
