const moment = require('moment')

moment.locale('pt-BR');
moment().utcOffset(-4);

import Vue from 'vue'

/* Vue Filters */
Vue.filter('date', (value) => {
  if (!value) return null
  if (!(value instanceof Date) && !isNaN(value) && typeof value != 'string') { value = value.toDate() }
  return moment(value).format("DD/MM/YYYY")
})
Vue.filter('hour', (value) => {
  if (!value) return null
  if (!(value instanceof Date) && !isNaN(value) && typeof value != 'string') { value = value.toDate() }
  return moment(value).format("HH:mm")
})
Vue.filter('timeago', (value) => {
  if (!value) return null
  if (!(value instanceof Date) && !isNaN(value) && typeof value != 'string') { value = value.toDate() }
  return moment(value).fromNow()
})

export default moment