<script>
import VTextField from "vuetify/lib/components/VTextField/VTextField.js"
import { createNamespacedHelpers, mapActions } from 'vuex'
const { mapActions:registersActions } = createNamespacedHelpers('registers')
export default {
  name: 'VTextFieldAlgolia',
  extends: VTextField,
  props: {
    /**
     * Collection do algolia
     */
    index: {
      type: String,
      required: true
    },

    /**
     * Função de tratamento da requisição da busca.
     * Essa função será chamada recebendo como parâmetro a Promise da chamada da busca do algolia
     */
    handler: {
      type: Function,
      required: false
    },

    /**
     * Valor em ms do intervalo de espera de parada de input até realizar a requisição.
     */
    delay: {
      type: Number,
      required: false,
      default: () => 0
    },

    facetFilters: Array,
    attributesToHighlight: Array,
  },
  data() {
    return {
      timer: null,
    }
  },
  async created() {
    this.$on('click:clear', (query) => {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
    })
    
    this.$on('keydown', (e) => {
      if (e.key == 'Enter') {
        if (this.timer) {
          clearTimeout(this.timer)
          this.timer = null
        }
        const query = this.lazyValue
        this.search(query, this.facetFilters)
      }
    })
    
    if (this.delay)
      this.$on('input', (query) => this.timeoutSearch(query, this.facetFilters))
    else
      this.$on('input', this.search)
  },
  methods: {
    ...registersActions(['searchAlgolian']),
    timeoutSearch(query, facetFilters) {
      if (query || facetFilters) {
        if (this.timer)
          clearTimeout(this.timer)
  
        this.timer = setTimeout(() => {
          this.timer = null
          this.handler(this.algoliaSearch(query, facetFilters))
        }, this.delay)
      }
    },
    search(query, facetFilters) {
      if (query || facetFilters.length > 0)
        this.handler(this.algoliaSearch(query, facetFilters))
    },
    async algoliaSearch(query, facetFilters) {
      if (query || facetFilters.length > 0){
        const ret = await this.searchAlgolian({index: this.index, query, facetFilters, attributesToHighlight: this.attributesToHighlight})
        return ret
      }
      else return []
    }
  }
}
</script>