import {baseUrl, baseApiUrl} from './tenant'
import moment from './moment'
import api from './axios'
import after from 'lodash/after'
import before from 'lodash/before'
import cloneDeep from 'lodash.clonedeep'
import zipObjectDeep from 'lodash.zipobjectdeep'
import isEqual from 'lodash.isequal'

/* Vue Properties */
export default function (Vue) {
  Vue.prototype.$api = api
  Vue.prototype.$config = {
    baseUrl,
    baseApiUrl,
    userKey: process.env.VUE_APP_USERKEY,
    emailForSigninKey: process.env.VUE_APP_EMAILFORSIGNINKEY,
    versionAppKey: process.env.VUE_APP_VERSION_KEY,
    flexMonsterKey: process.env.VUE_APP_FLEXMONSTER_KEY
  }
  Vue.prototype.$_ = {before, after, cloneDeep, zipObjectDeep, isEqual}
  Vue.prototype.$moment = moment

  /**
  * Máscara numérica
  * Transforma o separador de decimal de ponto para vírgula e adiciona os pontos a cada 3 unidades na parte inteira.
  */
  Vue.prototype.$toLocaleNumber = (num, precision) => {
    if (!num && num != 0) return
    let str = num
    if (typeof num == "number")
      str = num.toString()
    
    // trata a parte inteira
    const numOfIntegerDigits = num == 0 ? 1 : Math.floor(Math.log10(num)) + 1
    const integerPart = str.slice(0, numOfIntegerDigits)
    const arrStr = Array.from(integerPart)
    let r = numOfIntegerDigits % 3
    if (r == 0)
      r = 3
    const start = integerPart.slice(0, r)
    const localeIntegerPart = arrStr.slice(r).reduce((acc, cur, idx) => acc + (idx % 3 == 0 ? '.' : "") + cur, start)
    
    // trata a mantissa
    let mantissa
    if ((!precision && precision != 0) || precision < 0) {
      mantissa = str.includes('.')
        ? "," + str.slice(numOfIntegerDigits + 1)
        : ""
    } else if (precision == 0) {
      mantissa = ""
    } else { // precision > 0
      mantissa = str.slice(numOfIntegerDigits + 1)
      
      // se a quantidade de dígitos após a vírgula exigidos for maior que a do valor atual, adiciona zeros a direita.
      if (precision >= mantissa.length) {
        const distance = precision - mantissa.length
        for (let i = 0; i < distance; i++) {
          mantissa = mantissa + '0'
        }
      } else {
        const distance = mantissa.length - precision
        mantissa = mantissa.slice(0, mantissa.length - distance)
      }

      mantissa = "," + mantissa
    }

    // monta o valor como string
    const localeNumber = localeIntegerPart + mantissa
    // console.info('TO_LOCALE_NUMER', num, '->', localeNumber)
    return localeNumber
  }
}
