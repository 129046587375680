<script>
import Register from "@/views/Registers/Register";
export default {
  name: "ReportsRegister",
  extends: Register,
  data() {
    return {
      rules: {
        required: v => !!v || 'Campo obrigatório',
        // email: v => /.+@.+\..+/.test(v) || 'Formato de email inválido',
      },
      routesInputs: []
    }
  },
  computed: {
    computedInputsMaster() {
      return [
        {type: "divider", label: "Dados Básicos"},
        {type: "switch", label: "Ativo", value: "active", sm: 2, default: true},
        {type: "switch", label: "Usa Rest Node?", value: "isRestNode", sm: 2, default: false},
        {type: "text-field", label: "Título", value: "name", sm: 7, rules: [this.rules.required]},
        {type: "text-field", isIcon: true, label: "Ícone", value: "icon", sm: 3, rules: [this.rules.required]},
        {type: 'divider', label: '' },
        {
          type: 'jsf',
          label: '',
          root: true,
          schema: {
            type: 'object',
            'x-options': {
              editMode: "inline",
              dialogProps: {maxWidth: '100%'}
            },
            "x-display": "tabs",
            "x-props": {
              "grow": true
            },
            allOf: [
              {
                title: "Tabelas",
                type: "object",
                props: {
                  dataTables: {
                    type: 'array',
                    title: 'Tabelas',
                    description: 'Cadastre as tabelas que aparecerão no relatório',
                    "x-class": "fullscreen",
                    items: {
                      type: 'object',
                      "x-display": "tabs",
                      "x-props": {
                        grow: true
                      },
                      allOf: [
                        {
                          title: "Dados da Tabela",
                          type: "object",
                          properties: {
                            title: {
                              type: "string",
                              title: "Título",
                              description: "Digite aqui o título da sua tabela",
                              "x-cols": "3"
                            },
                            register: {
                              type: "string",
                              title: "ID Register",
                              description: "Digite aqui um identificador para register",
                              "x-cols": "2"
                            },
                            searchable: {
                              "x-cols": '2',
                              type: "boolean",
                              title: "Pesquisável?",
                              description: "Define se esta tabela permitirá pesquisa"
                            },
                            itemsPerPage: {
                              "x-cols": '2',
                              type: "number",
                              title: "Itens Por página",
                              description: "Define a quantidade de itens por página padrão"
                            },
                            allRegs: {
                              "x-cols": '3',
                              type: "boolean",
                              title: "Todos registros?",
                              description: "Força trazer todos os registos"
                            },
                            mobileInline: {
                              "x-cols": '3',
                              type: "boolean",
                              title: "Mobile em Linha",
                              description: "Relatório linha mobile"
                            },
                            order: {
                              type: 'object',
                              title: 'Ordenação',
                              description: 'Defina a ordenação padrão da tabela',
                              properties: {
                                by: {
                                  "x-cols": '10',
                                  type: "string",
                                  title: "Campo",
                                  description: "Define a ordem padrão da consulta"
                                },
                                desc: {
                                  "x-cols": '2',
                                  type: "boolean",
                                  title: "Decrescente?",
                                  description: "Define se a ordem será decrescente ou não"
                                }
                              }  
                            },
                            query: {
                              type: "string",
                              title: "Query",
                              "x-display": "textarea",
                              description: "Digite aqui sua query padrão"
                            }
                          }
                        },
                        {
                          title: "Cabeçalho",
                          type: "object",
                          properties: {
                            headers: {
                              type: "array",
                              title: "Cabeçalho",
                              description: "Informe os campos do cabeçalho da tabela",
                              "x-cols": 12,
                              items: {
                                type: "object",
                                required: [
                                  "text",
                                  "value",
                                  "type"
                                ],
                                properties: {
                                  searchable: {
                                    "x-cols": 2,
                                    type: "boolean",
                                    title: "Pesquisável?",
                                    description: "Define se este campo será utilizado como índice no campo de pesquisa"
                                  },
                                  searchableContent: {
                                    type: "string",
                                    title: "Conteúdo Pesquisável",
                                    description: "Conteúdo pesquisável na consulta. (Só terá resultado, caso o mesmo seja Pesquisável. Se estiver vazio, será considerado o valor 'Campo')",
                                    "x-cols": "10"
                                  },
                                  showable: {
                                    "x-cols": 2,
                                    type: "boolean",
                                    title: "Exibível?",
                                    description: "Define se este campo será mostrado na tabela"
                                  },
                                  export: {
                                    "x-cols": 2,
                                    type: "boolean",
                                    title: "Exportável?",
                                    description: "Define se este campo será exportado na tabela"
                                  },
                                  value: {
                                    type: "string",
                                    title: "Campo",
                                    description: "Nome do campo da consulta",
                                    "x-cols": 3
                                  },
                                  type: {
                                    type: "string",
                                    title: "Tipo",
                                    description: "Tipo de dado do campo",
                                    enum: [
                                      "string",
                                      "number",
                                      "money",
                                      "date",
                                      "dateTime",
                                      "percent"
                                    ],
                                    "x-cols": 2
                                  },
                                  text: {
                                    type: "string",
                                    title: "Nome do cabeçalho",
                                    description: "Nome a ser exibido no cabeçalho da tabela",
                                    "x-cols": 2
                                  },
                                  className: {
                                    type: "string",
                                    title: "Classe do campo",
                                    description: "Classe CSS do campo",
                                    "x-cols": 2
                                  },
                                  groupLevels: {
                                    type: "array",
                                    title: "Níveis de agrupamento",
                                    description: "Define se a coluna estará em níveis de agrupamento",
                                    "x-cols": 5,
                                    items: {
                                      type: "integer",
                                      anyOf: [
                                        {
                                          const: 1,
                                          title: "Nível 1"
                                        },
                                        {
                                          const: 2,
                                          title: "Nível 2"
                                        },
                                        {
                                          const: 3,
                                          title: "Nível 3"
                                        },
                                        {
                                          const: 4,
                                          title: "Nível 4"
                                        }
                                      ]
                                    }
                                  },
                                  typeReduce: {
                                    type: "string",
                                    title: "Tipo de Acumulador",
                                    description: "Define se a coluna será um acumulador de valor e o tipo de acumulador (totalizador, média, maior ou menor valo)r",
                                    "x-cols": 5,
                                    anyOf: [
                                      {
                                        const: "sum",
                                        title: "Soma"
                                      },
                                      {
                                        const: "mix",
                                        title: "Média"
                                      },
                                      {
                                        const: "max",
                                        title: "Maior Valor"
                                      },
                                      {
                                        const: "min",
                                        title: "Menor Valor"
                                      }
                                    ]
                                  }
                                }
                              }
                            }
                          }
                        },
                        {
                          title: "Gráficos",
                          type: "object",
                          properties: {
                            charts: {
                              type: "array",
                              title: "Gráficos",
                              description: "Informe quais gráficos deverão ser mostrados relacionados a esta tabela",
                              "x-cols": 12,
                              items: {
                                type: "object",
                                required:[
                                  "type",
                                  "id"
                                ],
                                properties: {
                                  id: {
                                    type: "string",
                                    title: "Código",
                                    description: "Código do gráfico",
                                    "x-cols": 2
                                  },
                                  type: {
                                    type: "string",
                                    title: "Tipo",
                                    description: "Tipo de gráfico a ser montado",
                                    enum: [
                                      "",
                                      "gauge"
                                    ],
                                    "x-cols": 4
                                  },
                                  title: {
                                    type: "string",
                                    title: "Nome do Gráfico",
                                    description: "Nome a ser exibido no cabeçalho do Gráfico",
                                    "x-cols": 6
                                  },
                                  segmentsLabels: {
                                    title: "Configurações de Labels",
                                    type: "string",
                                    "x-cols": 12,
                                    "x-display": "textarea",
                                    description: "Insira um array como texto ou uma Arrow Function que retorne um array (Ex: [{text: 'Text1', position: 'INSIDE', color: 'black'}])"
                                  },
                                  value: {
                                    title: "Valor do Ponteiro",
                                    type: "string",
                                    "x-cols": 12,
                                    description: "Insira o valor do ponteiro do gráfico como texto ou uma Arrow Function que retorne o valor desejável"
                                  }
                                }
                              }
                            }
                          }
                        },
                        {
                          title: "Totalizador",
                          type: "object",
                          properties: {
                            queryTotalize: {
                              type: "string",
                              title: "Query",
                              "x-display": "textarea",
                              description: "Digite aqui sua query padrão"
                            },
                            fieldsTotalize: {
                              type: "array",
                              title: "Campos",
                              description: "Informe os campos do totalizador",
                              "x-cols": 12,
                              items: {
                                type: "object",
                                required: [
                                  "text",
                                  "value",
                                  "type"
                                ],
                                properties: {
                                  text: {
                                    type: "string",
                                    title: "Nome do campo",
                                    description: "Nome do campo",
                                    "x-cols": 2
                                  },
                                  value: {
                                    type: "string",
                                    title: "Valor do Campo",
                                    description: "valor do campo",
                                    "x-cols": 3
                                  },
                                  type: {
                                    type: "string",
                                    title: "Tipo",
                                    description: "Tipo de dado do campo",
                                    enum: [
                                      "string",
                                      "number",
                                      "money",
                                      "date",
                                      "dateTime",
                                      "percent"
                                    ],
                                    "x-cols": 2
                                  }
                                }
                              }
                            }
                          }
                        },
                        {
                          title: "Filtros Assinc.",
                          type: "object",
                          properties: {
                            filtersAsync: {
                              type: "array",
                              title: "Filtros",
                              description: "Informe os filtros",
                              "x-cols": 12,
                              items: {
                                type: "object",
                                required: [
                                  "label",
                                  "source",
                                  "value"
                                ],
                                properties: {
                                  filterIndex: {
                                    type: "integer",
                                    title: "Ordem do filtro",
                                    description: "Ordem do filtro",
                                    "x-cols": 1
                                  },
                                  source: {
                                    type: "string",
                                    title: "Tipo",
                                    description: "Tipo de dado do campo",
                                    enum: [
                                      "customautocomplete",
                                      "date-picker",
                                      "select"
                                    ],
                                    "x-cols": 2
                                  },
                                  label: {
                                    type: "string",
                                    title: "Nome do campo",
                                    description: "Nome do campo",
                                    "x-cols": 2
                                  },
                                  value: {
                                    type: "string",
                                    title: "Variável do filtro",
                                    description: "Variável do filtro",
                                    "x-cols": 1
                                  },
                                  collection: {
                                    type: "string",
                                    title: "Tabela do filtro",
                                    description: "Apenas para customautocomplete",
                                    "x-cols": 1
                                  },
                                  filters: {
                                    type: "string",
                                    title: "Pré-filtro (&&)",
                                    description: "Filtragem da tabela ou dos itens com operador &&",
                                    "x-cols": 6
                                  },
                                  orFilters: {
                                    type: "string",
                                    title: "Pré-filtro (Ou)",
                                    description: "Filtragem da tabela ou dos itens com operador || (ou)",
                                    "x-cols": 6
                                  },
                                  fieldsToShow: {
                                    type: "array",
                                    items: {
                                      type: "string"
                                    },
                                    title: "Campos para mostrar",
                                    description: "Apenas para customautocomplete",
                                    "x-cols": 6
                                  },
                                  fieldsToSearch: {
                                    type: "array",
                                    items: {
                                      type: "string"
                                    },
                                    title: "Campos para filtrar",
                                    description: "Apenas para customautocomplete",
                                    "x-cols": 6
                                  },
                                  filterKey: {
                                    type: "string",
                                    title: "Campo do filtro",
                                    description: "Apenas para customautocomplete",
                                    "x-cols": 2
                                  },
                                  filterItemKey: {
                                    type: "string",
                                    title: "Valor do filtro",
                                    description: "Apenas para customautocomplete",
                                    "x-cols": 2
                                  },
                                  distinct: {
                                    type: "string",
                                    title: "Distinct (Campo)",
                                    description: "Apenas para customautocomplete",
                                    "x-cols": 2
                                  },
                                  disableIntFilters: {
                                    type: "boolean",
                                    title: "disableIntFilters",
                                    description: "Apenas para customautocomplete",
                                    "x-display": "switch",
                                    "x-cols": 2
                                  },
                                  range: {
                                    type: "boolean",
                                    title: "Período",
                                    "x-display": "switch",
                                    description: "Apenas para date-picker",
                                    "x-cols": 2
                                  },
                                  multiple: {
                                    type: "boolean",
                                    title: "Múltiplo",
                                    "x-display": "switch",
                                    description: "Apenas para select",
                                    "x-cols": 2
                                  },
                                  items: {
                                    type: "string",
                                    title: "Itens pré-definidos",
                                    "x-display": "switch",
                                    description: "Apenas para select e quando os valores forem pré-definidos",
                                    "x-cols": 6
                                  },
                                  removeUnderscore: {
                                    type: "boolean",
                                    title: "Remove Underline",
                                    "x-display": "switch",
                                    description: "Apenas para date-picker",
                                    "x-cols": 2
                                  },
                                  default: {
                                    type: "string",
                                    title: "Padrão",
                                    description: "Padrão inicial do campo",
                                    "x-cols": 2
                                  },
                                }
                              }
                            }
                          }
                        },
                      ],
                    }
                  }
                }
              },
              {
                type: "object",
                title: "Filtros",
                props: {
                  filters: {
                    type: 'array',
                    title: 'Filtros',
                    description: 'Cadastre aqui os filtros que poderam afetar todas as tabelas',
                    "x-class": "fullscreen",
                    items: {
                      type: 'object',
                      required: [
                        'textField',
                        'field'
                      ],
                      properties: {
                        text: {
                          type: 'string',
                          title: 'Texto',
                          description: 'Nome do campo do filtro',
                          "x-cols": "3"
                        },
                        field: {
                          type: 'string',
                          title: 'Campo',
                          description: 'Campo a ser filtrado nas consultas (este campo deve estar presente nas consultas)',
                          "x-cols": "3"
                        },
                        cols: {
                          type: 'number',
                          title: 'Colunas',
                          description: 'Tamanho da coluna que o filtro vai ocupar no card',
                          "x-cols": "2"
                        },
                        chips: {
                          type: 'boolean',
                          title: 'Chips?',
                          description: 'Indica se os valores filtrados serão mostrados como chips',
                          "x-cols": "2"
                        },
                        multiple: {
                          type: 'boolean',
                          title: 'Múltiplo?',
                          description: 'Indica se o filtro poderá ser feito por múltiplos valores',
                          "x-cols": "2"
                        },
                        itemText: {
                          type: 'string',
                          title: 'Texto do Select',
                          description: 'Indica qual(is) campos da consulta serão mostrados no select (utilize o "+" para concatenar campos)',
                          "x-cols": "2"
                        },
                        itemValue: {
                          type: 'string',
                          title: 'Valor do Select',
                          description: 'Indica qual campo da consulta será o valor do select',
                          "x-cols": "2"
                        },
                        fieldsToSearch: {
                          type: "array",
                          title: "Campos pesquisáveis",
                          description: "Informe os campos que serão pesquisáveis no filtro do select",
                          "x-cols": 12,
                          items: {
                            type: "string"
                          }
                          
                        },
                        query: {
                          type: "string",
                          title: "Query",
                          "x-display": "textarea",
                          description: "Digite aqui sua query para formar os filtros"
                        },
                        order: {
                          type: 'object',
                          title: 'Ordenação',
                          description: 'Defina a ordenação padrão da tabela',
                          properties: {
                            by: {
                              "x-cols": '10',
                              type: "string",
                              title: "Campo",
                              description: "Define a ordem padrão da consulta"
                            },
                            desc: {
                              "x-cols": '2',
                              type: "boolean",
                              title: "Decrescente?",
                              description: "Define se a ordem será decrescente ou não"
                            }
                          }
                        },
                        default: {
                          type: 'string',
                          title: 'Valor default',
                          description: 'Indica qual valor default do filtro (Use crase [`] como string. Deixe em branco quando não tiver valor default)',
                          "x-cols": "12"
                        },
                      }
                    }
                  }
                }
              },
                            {
                title: "Pivot Table",
                type: "object",
                props: {
                  pivotTables: {
                    type: 'array',
                    title: 'Pivots',
                    description: 'Cadastre as tabelas que aparecerão no relatório',
                    "x-class": "fullscreen",
                    items: {
                      type: 'object',
                      "x-display": "tabs",
                      "x-props": {
                        grow: true
                      },
                      properties: {
                        title: {
                          type: "string",
                          title: "Título",
                          description: "Digite aqui o título da sua tabela",
                          "x-cols": "3"
                        },
                        register: {
                          type: "string",
                          title: "ID Register",
                          description: "Digite aqui um identificador para register",
                          "x-cols": "2"
                        },
                        itemsPerPage: {
                          "x-cols": '2',
                          type: "number",
                          title: "Itens Por página",
                          description: "Define a quantidade de itens por página padrão"
                        },
                        loadingTime: {
                          "x-cols": '2',
                          type: "number",
                          title: "Estivativa do carregamento (seg)",
                          description: "Tempo estimado do carregamento completo para barra de progresso"
                        },
                        query: {
                          type: "string",
                          title: "Query",
                          "x-display": "textarea",
                          description: "Digite aqui sua query padrão"
                        },
                        settings: {
                          type: "string",
                          title: "Configurações",
                          "x-display": "textarea",
                          description: "Digite aqui o settings padrão"
                        },
                        devExpressSettings: {
                          type: "string",
                          title: "Configurações DevExpress",
                          "x-display": "textarea",
                          description: "Digite aqui o settings padrão"
                        },
                        order: {
                          type: 'object',
                          title: 'Ordenação',
                          description: 'Defina a ordenação padrão da tabela',
                          properties: {
                            by: {
                              "x-cols": '10',
                              type: "string",
                              title: "Campo",
                              description: "Define a ordem padrão da consulta"
                            },
                            desc: {
                              "x-cols": '2',
                              type: "boolean",
                              title: "Decrescente?",
                              description: "Define se a ordem será decrescente ou não"
                            }
                          }  
                        }
                      }
                    }
                  }
                }
              },
              {
                type: "object",
                title: "Filtros Pivot",
                props: {
                  pivotFilters: {
                    type: 'array',
                    title: 'Filtros',
                    description: 'Cadastre aqui os filtros que poderam afetar todos os Pivots',
                    "x-class": "fullscreen",
                    items: {
                      type: 'object',
                      required: [
                        'textField',
                        'field'
                      ],
                      properties: {
                        type: {
                          type: 'string',
                          title: 'Tipo',
                          oneOf: [
                            {
                              "const": "date-picker",
                              "title": "Data"
                            },
                            {
                              "const": "custom-autocomplete-sa3",
                              "title": "Vendedor"
                            },
                            {
                              "const": "custom-autocomplete-sa1",
                              "title": "Cliente"
                            },
                            {
                              "const": "select",
                              "title": "Seleção"
                            },
                            {
                              "const": "custom-autocomplete",
                              "title": "Tabela"
                            },
                            {
                              "const": "text-field",
                              "title": "Texto"
                            }
                          ],
                          description: 'Tipo do filtro',
                          "x-cols": "2"
                        },
                        label: {
                          type: 'string',
                          title: 'Texto',
                          description: 'Nome do campo do filtro',
                          "x-cols": "3"
                        },
                        value: {
                          type: 'string',
                          title: 'Parâmetro',
                          description: 'Parâmetro',
                          "x-cols": "3"
                        },
                        hint: {
                          type: 'string',
                          title: 'Dica',
                          description: 'Dica',
                          "x-cols": "4"
                        },
                        fromTo: {
                          type: 'boolean',
                          title: 'De Até',
                          "x-display": 'switch',
                        },
                        multiple: {
                          type: 'boolean',
                          title: 'Seleção múltipla',
                          "x-display": 'switch',
                        },
                        select: {
                          type: 'object',
                          title: 'Seleção',
                          description: '',
                          properties: {
                            items: {
                              type: 'array',
                              title: 'Items da Seleção',
                              items: {
                                type: "string"
                              },
                              description: 'Nome do campo do filtro',
                              "x-cols": "12"
                            }
                          }
                        },
                        customAutocomplete: {
                          type: 'object',
                          title: 'Tabela',
                          description: '',
                          properties: {
                            collection: {
                              type: 'string',
                              title: 'Tabela',
                              description: 'Defina a tabela de pesquisa',
                              "x-cols": "2"
                            },
                            filters: {
                              type: 'string',
                              title: 'Filtros',
                              description: 'Defina os filtros fixos da pesquisa',
                              "x-cols": "5"
                            },
                            limit: {
                              type: 'number',
                              title: 'Limite',
                              description: 'Defina o limite da pesquisa',
                              "x-cols": "1"
                            },
                            orderBy: {
                              "x-cols": '2',
                              type: "string",
                              title: "Ordenação",
                              description: "Define o campo de ordenação da consulta"
                            },
                            orderByMod: {
                              "x-cols": '2',
                              type: "boolean",
                              title: "Decrescente?",
                              description: "Define se a ordem será decrescente",
                              "x-display": 'switch',
                            },
                            fieldsToSearch: {
                              type: "array",
                              title: "Campos pesquisáveis",
                              description: "Informe os campos que serão pesquisáveis no filtro da tabela",
                              "x-cols": 6,
                              items: {
                                type: "string"
                              }
                            },
                            fieldsToShow: {
                              type: "array",
                              title: "Campos Vísiveis",
                              description: "Informe os campos que serão vísiveis no filtro da tabela",
                              "x-cols": 6,
                              items: {
                                type: "string"
                              }
                            }
                          }
                        },
                      }
                    }
                  }
                }
              }
            ]
          }
        }
      ];
    }
  },
  created() {
  }
};
</script>

<style scoped>
  .v-card {
    margin: 0!important;
    padding: 0!important;
  }
</style>