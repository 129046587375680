<script>
import VAutocomplete from "vuetify/lib/components/VAutocomplete/VAutocomplete.js"
import { createNamespacedHelpers, mapActions } from 'vuex'
const { mapActions:registersActions } = createNamespacedHelpers('registers')
export default {
  name: 'VAutocompleteAlgolia',
  extends: VAutocomplete,
  props: {
    /**
     * Collection do algolia
     */
    index: {
      type: String,
      required: true
    },

    /**
     * Função de tratamento da requisição da busca.
     * Essa função será chamada recebendo como parâmetro a Promise da chamada da busca do algolia
     */
    handler: {
      type: Function,
      required: false
    },

    /**
     * Valor em ms do intervalo de espera de parada de input até realizar a requisição.
     */
    delay: {
      type: Number,
      required: false,
      default: 0
    },

    /**
     * Booleano que indica se a query string vazia não deve ser aceita como critério de busca
     */
    noEmpty: {
      type: Boolean,
      default: false,
    },

    /**
     * Texto que aparece quando a query de busca está vazia e a prop `noEmpty` tá marcada como `true`
     */
    emptyText: {
      type: String,
      default: 'Digite algo para pesquisar',
    },

    /**
     * Em caso positivo, limpa o array de itens buscados após escolher um item
     */
    clean: {
      type: Boolean,
      deafult: false,
    },

    filters: String,
    restrictSearchableAttributes: Array,
    attributesToHighlight: Array,
  },
  data() {
    return {
      timer: null,
      hasInput: false,
    }
  },
  async created() {
    this.$on('click:clear', () => {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
    })
    
    this.$on('keydown', (e) => {
      if (e.key == 'Enter') {
        if (this.timer) {
          clearTimeout(this.timer)
          this.timer = null
        }
        const query = this.lazyValue
        this.search(query, this.filters)
      }
    })

    this.$on('click:clear', () => {
      this.items.splice(0)
    })

    this.$on('blur', () => {
      if ((this.internalSearch || this.noEmpty) && this.clean) {
        this.items.splice(0)
      }
    })

    if (!this.noEmpty) {
      this.$on('focus', () => {
        if (!this.internalSearch && this.items.length == 0) {
          this.search("")
        }
      })
    }

    if (this.delay) {
      this.$on('update:search-input', (query) => {
        if (query == null)
          return
        
        // previne busca desnecessária ao escolher um item da lista
        if (this.value && query == this.itemText(this.value))
          return
        
        this.timeoutSearch(query, this.filters)
      })
    }
    else {
      this.$on('update:search-input', (query) => {
        // previne busca desnecessária ao escolher um item da lista
        if (this.value && query == this.itemText(this.value))
          return

        this.search(query)
      })
    }
  },
  methods: {
    ...registersActions(['searchAlgolian']),
    timeoutSearch(query, filters) {
      if (!this.noEmpty || query) {
        if (this.timer)
          clearTimeout(this.timer)
  
        this.timer = setTimeout(() => {
          this.timer = null
          this.handler(this.algoliaSearch(query, filters))
        }, this.delay)
      }
    },
    search(query, filters) {
      if (!this.noEmpty || query) {
        this.handler(this.algoliaSearch(query, filters))
      }
    },
    algoliaSearch(query, filters) {
      const result = this.searchAlgolian({index: this.index, query, facetFilters: filters, restrictSearchableAttributes: this.restrictSearchableAttributes, attributesToHighlight: this.attributesToHighlight})
      return result
    }
  }
}
</script>