<template>
  <section>
    <Toolbar>
      <template
        slot="actions"
      >
        <!-- v-if="computedFiltersProcessed && computedFiltersProcessed.length > 0" -->
        <!-- Filters  -->
        <v-dialog v-model="dialog" max-width="50%">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :color="
                computedFiltersProcessed.find((cp) => cp.selecteds.length > 0)
                  ? 'warning'
                  : 'primary'
              "
              class="text-caption"
              :outlined="
                !computedFiltersProcessed.find((cp) => cp.selecteds.length > 0)
              "
              v-bind="attrs"
              v-on="on"
            >
              <v-icon size="20" class="mr-1">mdi-filter-outline</v-icon>
              Filtros
              {{
                computedFiltersProcessed.find((cp) => cp.selecteds.length > 0)
                  ? " aplicados"
                  : ""
              }}
            </v-btn>
          </template>
          
          <v-card>
            <v-card-title class="text-h5 grey lighten-3">
              <v-icon class="mr-1">mdi-filter-outline</v-icon>
              Filtros
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                outlined
                @click="dialog = false;"
                class="text-caption mr-2"
              >
                Cancelar
              </v-btn>
              <v-btn
                color="primary"
                class="text-caption"
                outlined
                @click="
                  dialog = false;
                  applyFilter();
                "
              >
                <!-- <v-icon small class="mr-1">mdi-filter-outline</v-icon> -->
                Ok
              </v-btn>
            </v-card-title>

            <v-card-text class="pt-2 pb-4 px-3">
              <v-row class="ma-0">
                <v-col
                  v-for="(key, index) of filtersProcessed"
                  :key="index"
                  :cols="key.cols"
                >
                  
                  <v-select
                    v-model="key.selecteds"
                    :items="
                      searchInFilters[key.field] &&
                      searchInFilters[key.field].length > 0
                        ? registers[`reportFilters${key.field}Filtered`]
                        : registers[`reportFilters${key.field}`]
                    "
                    :label="'Selecione o(s) ' + key.text + '(s) '"
                    :multiple="key.multiple"
                    :ref="`reportFiltersSelect${key.field}`"
                    hide-details
                    :item-text="
                      typeof key.itemText == 'function'
                        ? (item) => key.itemText(item)
                        : key.itemText
                    "
                    :item-value="key.itemValue"
                    dense
                  >
                    <template v-slot:prepend-item>
                      <v-list-item
                        disabled
                        v-bind:style="{ fontSize: '.5em!important' }"
                      >
                        <!-- <v-list-item-avatar color="grey lighten-3">
                          <v-icon>
                            mdi-check-circle
                          </v-icon>
                        </v-list-item-avatar> -->

                        <v-list-item-content v-if="likesAll(key) && key.multiple">
                          <v-list-item-title>
                            Todos selecionados!
                          </v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-content v-else-if="likesSome(key)">
                          <v-list-item-title>
                            {{ key.selecteds.length }} selecionados
                          </v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-content v-else>
                          <v-list-item-title>
                            Nenhum item selecionado
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            Selecione algum registro para filtrar!
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mb-2"></v-divider>
                      <v-list-item>
                        <v-list-item-content>
                          <v-text-field-external
                            v-model="searchInFilters[key.field]"
                            :externalRequest="key.externalRequest"
                            :handler="search"
                            :delay="750"
                            :fieldsToSearch="key.fieldsToSearch"
                            :register="`reportFilters${key.field}`"
                            :ref="`externalSearchInFilter${key.field}`"
                            outlined
                            dense
                            clearable
                            hide-details
                            height="40"
                            label="Digite para pesquisar"
                            append-icon="mdi-magnify"
                            class="mw-100"
                            single-line
                          >
                          </v-text-field-external>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mb-2"></v-divider>

                      <v-list-item ripple @click="toggle(key)" v-if="key.multiple">
                        <v-list-item-action>
                          <v-icon
                            :color="
                              key.selecteds.length > 0 ? 'indigo darken-4' : ''
                            "
                          >
                            {{ icon(key) }}
                          </v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            Selecione todos
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>

                    <template v-if="key.chips" v-slot:selection="data">
                      <v-chip
                        v-if="data.index < 20"
                        :key="JSON.stringify(data.item)"
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        :disabled="data.disabled"
                        small
                        close
                        color="primary accent-4"
                        dark
                        @click:close="
                          key.multiple
                            ? data.parent.selectItem(data.item)
                            : (key.selecteds = [])
                        "
                        class="my-2"
                      >
                        <span
                          v-html="
                            data.item[
                              typeof key.itemText == 'function'
                                ? (item) => key.itemText(item)
                                : key.itemText
                            ]
                          "
                        >
                        </span>
                      </v-chip>
                      <span v-if="data.index == 20" class="grey--text caption"
                        >(+{{ key.selecteds.length - 20 }} outro(s))</span
                      >
                    </template>

                    <template v-slot:item="{ item }">
                      <v-icon
                        v-if="
                          key.selecteds !== null &&
                          key.selecteds.includes(item[key.itemValue])
                        "
                        color="primary"
                        class="mr-3"
                      >
                        mdi-checkbox-marked
                      </v-icon>
                      <v-icon v-else class="mr-3">
                        mdi-checkbox-blank-outline
                      </v-icon>

                      <span
                        dense
                        v-html="
                          item[
                            typeof key.itemText == 'function'
                              ? (item) => key.itemText(item)
                              : key.itemText
                          ]
                        "
                      ></span>
                    </template>

                    <!-- <template v-slot:append-item>
                    </template> -->
                  </v-select>
                </v-col>
              </v-row>
              <v-row v-for="filter in pivotFilters" :key="filter.value" class="ma-0">
                <template>
                  <v-col 
                    v-for="n in (filter.fromTo && filter.type != 'date-picker') ? [0, 1] : [0]" 
                    :key="n" 
                    :cols="(filter.fromTo && filter.type != 'date-picker') ? 6 : 12" 
                    :class="`${(filter.type == 'date-picker') ? 'pb-0' : 'py-2'}`"
                    >
                    <!-- text-field -->
                    <template v-if="filter.type == 'text-field'">
                      <v-text-field
                        v-model="filtersModels[filter.value][n]"
                        :label="filter.label"
                        :hint="filter.hint"
                        persistent-hint
                        outlined
                        dense
                        height=40   
                      ></v-text-field>
                    </template>

                    <!-- SELECT -->
                    <v-select
                      v-else-if="filter.type == 'select'"
                      v-model="filtersModels[filter.value][n]"
                      :label="filter.label"
                      :hint="filter.hint"
                      :items="filter.select.items"
                      :multiple="filter.multiple"
                      persistent-hint
                      chips
                      small-chips
                      deletable-chips
                    >
                    </v-select>

                    <!-- CustomAutocomplet -->
                    <v-custom-autocomplete
                      v-if="filter.type.includes('custom-autocomplete')"
                      v-model="filtersModels[filter.value][n]"
                      :label="filter.label"
                      :index="`${filter.value}_${n}`"
                      :items="autocompleteHits[`${filter.value}_${n}`]"
                      :filter="(item, queryText, itemText) => filter.customAutocomplete.fieldsToShow.some(x => item[x].toLowerCase().includes(queryText.toLowerCase()))"
                      item-value="id"
                      :loading="autocompleteLoading[`${filter.value}_${n}`]"
                      :handler="handlerAutocomplete"
                      :handlerLoading="handlerAutocompleteLoading"
                      :delay="750"
                      :asyncSearch="filter.customAutocomplete"
                      :fieldsToShow="filter.customAutocomplete.fieldsToShow"
                      :fieldsToSearch="filter.customAutocomplete.fieldsToSearch"
                      :menu-props="{closeOnContentClick:true}"
                      :multiple="filter.multiple"
                      :hint="filter.hint"
                      persistent-hint
                      clearSearchOnSelect
                      return-object
                      chips
                      small-chips
                      outlined
                    >
                      <template v-slot:item="{item}">
                        <div v-for="(field, ix) in filter.customAutocomplete.fieldsToShow" :key="field">
                          <span v-if="ix > 0" class="mx-1">-</span>
                          <span v-html="item._highlightResult && item._highlightResult[field] && item._highlightResult[field].matchedWords && item._highlightResult[field].matchedWords.length > 0 ? item._highlightResult[field].value : item[field]" />
                        </div>
                      </template>
                      <template v-slot:selection="data">
                        <v-chip v-if="data.index < formChips" :key="JSON.stringify(data.item.id)" v-bind="data.attrs" :input-value="data.selected" :disabled="data.disabled" color="primary accent-4" small dark>
                          <div v-for="(field, ix) in filter.customAutocomplete.fieldsToShow" :key="field">
                            <span v-if="ix > 0" class="mx-1">-</span>
                            <span v-html="data.item._highlightResult && data.item._highlightResult[field] && data.item._highlightResult[field].matchedWords && data.item._highlightResult[field].matchedWords.length > 0 ? data.item._highlightResult[field].value : data.item[field]" />
                          </div>
                        </v-chip>
                        <span v-if="data.index == formChips" class="grey--text caption">(+{{ filtersModels[filter.value][n].length - formChips }} outro(s))</span>
                      </template>
                    </v-custom-autocomplete>

                    <!-- DatePicker -->
                    <v-menu
                      v-else-if="filter.type == 'date-picker'"
                      v-model="filter.menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :value="filter.modelFormated"
                          :label="filter.label"
                          prepend-icon="mdi-calendar"
                          readonly
                          outlined
                          dense
                          height=40   
                          v-bind="attrs"
                          v-on="on"
                          persistent-hint
                          :hint="`${filter.fromTo ? 'Selecione duas datas' : 'Selecione uma data' }`"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="filtersModels[filter.value]"
                        :range="filter.fromTo"
                        @change="filter.menu = false; formatDatePickerTextField($event, filter)"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </template>

              </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <!-- <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                class="text-caption"
                outlined
                @click="dialog = false">
                <v-icon small class="mr-1">mdi-cancel</v-icon>
                Cancelar
              </v-btn> 
            </v-card-actions> -->
          </v-card>
        </v-dialog>
      </template>
    </Toolbar>

    <v-row v-if="computedDataTables && computedDataTables.length > 0">
      <v-col v-for="(dTable, index) of computedDataTables" :key="index">
        <v-dialog v-model="computedConfigsShowed[dTable.register]">
          <template>
            <v-card outlined elevation="5">
              <v-card-title> Colunas a exibir </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col
                    v-for="(head, ix) in computedHeadersShowed[dTable.register]"
                    :key="ix"
                  >
                    <v-switch
                      v-model="computedHeadersShowed[dTable.register][ix]"
                      :disabled="
                        !dTable.headers.find((h) => h.value === ix).showable
                      "
                      :label="dTable.headers.find((h) => h.value === ix).text"
                    >
                    </v-switch>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </template>
        </v-dialog>

        <v-card
          class="lighten-4 mt-6'"
          v-if="dTable.charts && dTable.charts.length > 0"
        >
          <v-toolbar dense flat color="transparent">
            <v-toolbar-title class="subtitle-2 font-weight-bold">
              Gráficos
            </v-toolbar-title>
            <v-spacer />
            <v-btn outlined text @click="expandCharts = !expandCharts">
              {{ computedExpandCharts ? "Esconder" : "Mostrar" }}
              <v-icon
                right
                v-text="
                  computedExpandCharts ? 'mdi-chevron-up' : 'mdi-chevron-down'
                "
              ></v-icon>
            </v-btn>
          </v-toolbar>
          <v-expand-transition v-if="computedExpandCharts">
            <v-row align="center" no-gutters class="pb-4">
              <v-col cols="3" class="mt-4">
                <KPIs
                  v-if="
                    computedKpiCharts &&
                    computedKpiCharts[dTable.register] &&
                    computedKpiCharts[dTable.register]['metaTotal']
                  "
                  :dense="true"
                  :title="
                    computedKpiCharts[dTable.register]['metaTotal']['title']
                  "
                  icon=""
                  :kpi="
                    computedKpiCharts[dTable.register]['metaTotal']['value']
                  "
                  color="yellow"
                  colorText="black"
                  class="pt-6"
                />
                <KPIs
                  v-if="
                    computedKpiCharts &&
                    computedKpiCharts[dTable.register] &&
                    computedKpiCharts[dTable.register]['valorTotal']
                  "
                  :dense="true"
                  :title="
                    computedKpiCharts[dTable.register]['valorTotal']['title']
                  "
                  icon=""
                  :kpi="
                    computedKpiCharts[dTable.register]['valorTotal']['value']
                  "
                  color="yellow"
                  colorText="black"
                  class="pt-6"
                />
              </v-col>
              <v-col
                col="6"
                v-for="(chart, indexChart) in dTable.charts"
                :key="indexChart"
                class="text-middle"
              >
                <GaugeChart
                  v-if="chart.type === 'gauge'"
                  :title="chart.title"
                  :value="chart.value"
                  :data="computedItems[dTable.register]"
                  :segmentsLabels="chart.segmentsLabels"
                />
              </v-col>
              <v-col cols="3" class="mt-4">
                <KPIs
                  v-if="
                    computedKpiCharts &&
                    computedKpiCharts[dTable.register] &&
                    computedKpiCharts[dTable.register]['performance']
                  "
                  :dense="true"
                  :title="
                    computedKpiCharts[dTable.register]['performance']['title']
                  "
                  icon=""
                  :kpi="
                    computedKpiCharts[dTable.register]['performance']['value']
                  "
                  color="yellow"
                  colorText="black"
                  class="pt-6"
                />
                <KPIs
                  v-if="
                    computedKpiCharts &&
                    computedKpiCharts[dTable.register] &&
                    computedKpiCharts[dTable.register]['saldoTotal']
                  "
                  :dense="true"
                  :title="
                    computedKpiCharts[dTable.register]['saldoTotal']['title']
                  "
                  icon=""
                  :kpi="
                    computedKpiCharts[dTable.register]['saldoTotal']['value']
                  "
                  color="yellow"
                  colorText="black"
                  class="pt-5"
                />
              </v-col>
            </v-row>
          </v-expand-transition>
        </v-card>

        <!-- <v-divider class="my-2" v-if="computedExpandCharts"></v-divider> -->

        <!-- <v-row>
          <v-col v-if="useGauge" cols="4">
            <v-card>
            </v-card>
          </v-col>
          <v-col :cols="useGauge ? '8' : '12'"> -->
        <v-data-table
          :headers="getProcessedHeaders({ dTable, level: 1 })"
          :items="computedItems[dTable.register]"
          dense
          :footer-props="{
            'items-per-page-options': [10, 20, 30, 50, 100],
          }"
          :show-expand="
            computedLevels &&
            computedLevels[dTable.register] &&
            computedLevels[dTable.register][2] &&
            computedLevels[dTable.register][2].length > 0
          "
          item-key="ID"
          @update:options="update({ options: $event, dTable })"
          :sort-by="dTable.order.by"
          :sort-desc="dTable.order.desc"
          single-expand
          :loading="loading && loading[`report${dTable.register}`]"
          loading-text="Carregando... Aguarde por favor."
          class="elevation-1 pt-1"
        >
          <!-- TOP -->
          <template v-slot:top>
            <v-toolbar flat dense>
              <v-toolbar-title>
                <small>
                  {{ dTable.title }}
                </small>
              </v-toolbar-title>

              <v-spacer></v-spacer>

              <v-text-field-external
                v-show="computedSearchEnableds[dTable.register]"
                v-model="searchInputs[dTable.register]"
                :externalRequest="dTable.externalRequest"
                :handler="search"
                :delay="750"
                :filtersIn="computedFiltersProcessed"
                :fieldsToSearch="
                  dTable.headers
                    .filter((x) => x.searchable)
                    .map((x) => x.searchableContent || x.value)
                "
                :fieldsToMark="
                  dTable.headers
                    .filter((x) => x.searchable && x.searchableContent)
                    .map(({ searchableContent, value }) => ({
                      searchableContent,
                      value,
                    }))
                "
                :register="`report${dTable.register}`"
                :ref="`externalSearchInput${dTable.register}`"
                outlined
                dense
                clearable
                hide-details
                height="40"
                label="Digite para pesquisar"
                append-icon="mdi-magnify"
                class="mw-100"
                single-line
              >
              </v-text-field-external>

              <v-spacer></v-spacer>

              <template>
                <v-tooltip left>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      :disabled="false"
                      @click="enableSearch({ dTable })"
                      class="mx-1"
                      :icon="
                        !(
                          searchInputs[dTable.register] &&
                          searchInputs[dTable.register].length > 0
                        )
                      "
                      small
                      :color="
                        searchInputs[dTable.register] &&
                        searchInputs[dTable.register].length > 0
                          ? 'warning'
                          : !computedSearchEnableds[dTable.register]
                          ? ''
                          : 'info'
                      "
                      v-on="on"
                      :outlined="
                        !computedSearchEnableds[dTable.register] &&
                        searchInputs[dTable.register] &&
                        searchInputs[dTable.register].length > 0
                      "
                      v-bind="attrs"
                    >
                      <v-icon v-if="computedSearchEnableds[dTable.register]"
                        >mdi-text-box-search</v-icon
                      >
                      <v-icon v-else>mdi-text-box-search-outline</v-icon>
                      {{
                        searchInputs[dTable.register] &&
                        searchInputs[dTable.register].length > 0
                          ? "Pesquisar"
                          : ""
                      }}
                    </v-btn>
                  </template>
                  Pesquisar
                  {{
                    searchInputs[dTable.register] &&
                    searchInputs[dTable.register].length > 0
                      ? ` (ativo)`
                      : ""
                  }}
                </v-tooltip>

                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      :disabled="false"
                      @click="showConfigs({ dTable })"
                      class="mx-1"
                      icon
                      small
                      :color="
                        !computedConfigsShowed[dTable.register] ? '' : 'info'
                      "
                      v-on="on"
                      v-bind="attrs"
                    >
                      <v-icon v-if="computedConfigsShowed[dTable.register]"
                        >mdi-cog</v-icon
                      >
                      <v-icon v-else>mdi-cog-outline</v-icon>
                    </v-btn>
                  </template>
                  Configurações
                </v-tooltip>

                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      :disabled="!dTable.headers.some((h) => h.export)"
                      @click="exportXlsx({ dTable })"
                      class="mx-1"
                      icon
                      small
                      :color="'success'"
                      v-on="on"
                      v-bind="attrs"
                    >
                      <v-icon>mdi-file-excel-outline</v-icon>
                    </v-btn>
                  </template>
                  Exportar Excel
                </v-tooltip>
              </template>
            </v-toolbar>
          </template>

          <template v-slot:item="{ index, isExpanded, expand, item, headers }">
            <tr :class="isExpanded ? 'grey lighten-1' : ''">
              <template v-for="(head, ix) in headers">
                <td
                  v-if="head.value === 'data-table-expand'"
                  :key="head.value + index"
                  style="width: 24px"
                  class="px-2"
                >
                  <v-icon
                    v-if="!isExpanded"
                    @click="
                      setItemsPerLevel({ dTable, level: 2, itemFilter: item });
                      expand(true);
                    "
                    >mdi-chevron-down</v-icon
                  >
                  <v-icon v-else @click="expand(false)">mdi-chevron-up</v-icon>
                </td>
                <td
                  v-else
                  :key="head.value + index + ix"
                  :style="`${ix < 2 ? '' : 'width: 17%;'}`"
                  :class="head.className"
                >
                  <span v-html="proccessItem({ item, head })"> </span>
                </td>
              </template>
            </tr>
          </template>
          <template v-slot:expanded-item="{ headers }">
            <td
              :colspan="headers.length"
              :style="{ padding: '1', border: '.01em dotted gray' }"
            >
              <!-- 2o nivel -->
              <v-data-table
                :headers="getProcessedHeaders({ dTable, level: 2 })"
                :items="computedItems[`${dTable.register}2`]"
                :loading="loading && loading[`report${dTable.register}Level2`]"
                loading-text="Carregando... Aguarde por favor."
                :hide-default-header="true"
                :hide-default-footer="true"
                item-key="ID"
                :single-expand="true"
                :disable-pagination="true"
                :expand-sync="true"
                :show-expand="
                  computedLevels &&
                  computedLevels[dTable.register] &&
                  computedLevels[dTable.register][3] &&
                  computedLevels[dTable.register][3].length > 0
                "
                dense
                class="elevation-2 pt-2"
              >
                <template
                  v-slot:item="{ index, expand, isExpanded, item, headers }"
                >
                  <tr :class="isExpanded ? 'grey lighten-1' : ''">
                    <template v-for="(head, ix) in headers">
                      <td
                        v-if="head.value === 'data-table-expand'"
                        :key="head.value + index"
                        style="width: 24px"
                        class="pl-4 pr-2"
                      >
                        <v-icon
                          v-if="!isExpanded"
                          @click="
                            setItemsPerLevel({
                              dTable,
                              level: 3,
                              itemFilter: item,
                            });
                            expand(true);
                          "
                          >mdi-chevron-down</v-icon
                        >
                        <v-icon v-else @click="expand(false)"
                          >mdi-chevron-up</v-icon
                        >
                      </td>
                      <td
                        v-else
                        :key="head.value + index + ix"
                        :class="head.cellClass"
                        :style="`${ix < 2 ? '' : 'width: 17%;'}`"
                      >
                        <span v-html="proccessItem({ item, head })"></span>
                      </td>
                    </template>
                  </tr>
                </template>
                <template v-slot:expanded-item="{ headers }">
                  <td
                    :colspan="headers.length"
                    :style="{ padding: '1', border: '.01em dotted gray' }"
                  >
                    <!-- 3o nivel -->
                    <v-data-table
                      :headers="getProcessedHeaders({ dTable, level: 3 })"
                      :items="computedItems[`${dTable.register}3`]"
                      :hide-default-header="true"
                      :hide-default-footer="true"
                      :loading="
                        loading && loading[`report${dTable.register}Level3`]
                      "
                      loading-text="Carregando... Aguarde por favor."
                      item-key="ID"
                      single-expand
                      :disable-pagination="true"
                      :expand-sync="false"
                      :show-expand="
                        computedLevels &&
                        computedLevels[dTable.register] &&
                        computedLevels[dTable.register][4] &&
                        computedLevels[dTable.register][4].length > 0
                      "
                      dense
                      class="elevation-3 pt-3"
                    >
                      <template
                        v-slot:item="{
                          index,
                          expand,
                          isExpanded,
                          item,
                          headers,
                        }"
                      >
                        <tr :class="isExpanded ? 'grey lighten-1' : ''">
                          <template v-for="(head, ix) in headers">
                            <td
                              v-if="head.value === 'data-table-expand'"
                              :key="head.value + index"
                              style="width: 24px"
                              class="pl-6"
                            >
                              <v-icon
                                v-if="!isExpanded"
                                @click="
                                  setItemsPerLevel({
                                    dTable,
                                    level: 4,
                                    itemFilter: item,
                                  });
                                  expand(true);
                                "
                                >mdi-chevron-down</v-icon
                              >
                              <v-icon v-else @click="expand(false)"
                                >mdi-chevron-up</v-icon
                              >
                            </td>
                            <td
                              v-else
                              :key="head.value + index + ix"
                              :class="head.cellClass"
                              :style="`${ix < 2 ? '' : 'width: 17%;'}`"
                            >
                              <span
                                v-html="proccessItem({ item, head })"
                              ></span>
                            </td>
                          </template>
                        </tr>
                      </template>
                      <template v-slot:expanded-item="{ headers }">
                        <td
                          :colspan="headers.length"
                          :style="{ padding: '1', border: '.01em dotted gray' }"
                        >
                          <!-- 4o nivel -->
                          <v-data-table
                            :headers="getProcessedHeaders({ dTable, level: 4 })"
                            :items="computedItems[`${dTable.register}4`]"
                            :hide-default-header="true"
                            :hide-default-footer="true"
                            :loading="
                              loading &&
                              loading[`report${dTable.register}Level4`]
                            "
                            loading-text="Carregando... Aguarde por favor."
                            item-key="ID"
                            single-expand
                            :disable-pagination="true"
                            :expand-sync="false"
                            :show-expand="false"
                            dense
                            class="elevation-4 pt-4"
                          >
                            <template v-slot:item="{ index, item, headers }">
                              <tr
                                :class="`${
                                  index % 2 == 0 ? 'grey lighten-5' : ''
                                }`"
                              >
                                <td
                                  :key="0 + index"
                                  style="width: 24px"
                                  class="px-8"
                                />
                                <template v-for="(head, ix) in headers">
                                  <td
                                    :key="head.value + index"
                                    :class="head.cellClass"
                                    :style="`${ix < 1 ? '' : 'width: 17%;'}`"
                                  >
                                    <span
                                      v-html="proccessItem({ item, head })"
                                    ></span>
                                  </td>
                                </template>
                              </tr>
                            </template>
                          </v-data-table>
                        </td>
                      </template>
                    </v-data-table>
                  </td>
                </template>
              </v-data-table>
            </td>
          </template>

          <template
            v-if="dTable.serverItemsLength && counters"
            v-slot:[`footer.page-text`]="{ pageStart, pageStop }"
          >
            {{ pageStart }} - {{ pageStop }} de
            {{ registersCount[dTable.register] }}
          </template>
        </v-data-table>
        <!-- </v-col>
        </v-row> -->
      </v-col>
    </v-row>

    <!-- <v-divider></v-divider> -->
        
    <v-row v-if="computedPivotTables && computedPivotTables.length > 0">
      <v-col v-for="(pivot, index) of computedPivotTables" :key="index">
        <section v-if="!loadingPivots || loadingPivots[pivot.register]">
          <v-skeleton-loader
            type="table"
          ></v-skeleton-loader>
          <v-progress-linear
            color="primary"
            height="5"
            :value="loadingTimePivots[pivot.register]"
            striped
          ></v-progress-linear>
        </section>
        <DevExpressPivot
          v-else-if="registersPivots && registersPivots[pivot.register] && registersPivots[pivot.register].length > 0"
          :query="pivot.query"
          :register="pivot.register"
          :sourceJson="registersPivots[pivot.register]"
          :settings="
            pivot.devExpressSettings && pivot.devExpressSettings.trim()
              ? JSON.parse(pivot.devExpressSettings)
              : {}
          "
        />
        <!-- <FlexMonsterPivot
          v-else-if="registersPivots && registersPivots[pivot.register] && registersPivots[pivot.register].length > 0"
          :query="pivot.query"
          :register="pivot.register"
          :sourceJson="registersPivots[pivot.register]"
          :settings="
            pivot.settings && pivot.settings.trim()
              ? JSON.parse(pivot.settings)
              : {}
          "
        /> -->
        <v-alert
          v-else
          outlined
          type="warning"
          prominent
          border="left"
          class="mx-auto mt-12"
        >
          Sem dados para demonstrar
        </v-alert>
      </v-col>
    </v-row>
  </section>
</template>

<script>
// import FlexMonsterPivot from "@/components/Charts/FlexMonsterPivot";
import DevExpressPivot from "@/components/Charts/DevExpressPivot";
import GaugeChart from "@/components/Charts/Gauge";
import Toolbar from "../../components/Templates/Toolbar.vue";
import VTextFieldExternal from "../../components/VTextFieldExternal.vue";
import KPIs from "@/components/Charts/KPIs";
import VCustomAutocomplete from "@/components/VCustomAutocomplete.vue";
import { createNamespacedHelpers, mapMutations, mapState } from "vuex";
const { mapState: registersState, mapActions: registersActions } =
  createNamespacedHelpers("registers");
import * as XLSX from "xlsx";

export default {
  name: "GeneratedReports",
  components: { Toolbar, KPIs, VTextFieldExternal, GaugeChart, DevExpressPivot, VCustomAutocomplete },
  props: {
    name: {
      type: String,
      required: true,
    },
    isRestNode: {
      type: Boolean,
      required: false,
    },
    dataTables: {
      type: Array,
      required: false,
    },
    filters: {
      type: Array,
      required: false,
    },
    pivotTables: {
      type: Array,
      required: false,
    },
    pivotFilters: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      dialog: false,
      dataTablesProcessed: [],
      filtersProcessed: [],
      pivotProcessed: {},
      searchInFilters: {},
      searchInputs: {},
      searchEnableds: {},
      configsShowed: {},
      headersShowed: {},
      myCounters: {},
      useGauge: true,
      expandCharts: true,
      registersPivots: {},
      loadingPivots: {},
      loadingTimePivots: {},
      filtersModels: {},
      autocompleteHits: {},
      autocompleteLoading: {},
      formChips: 4,
    };
  },
  computed: {
    ...registersState(["registers", "counters", "loading"]),
    ...mapState(["companySelected"]),
    computedKpiCharts() {
      let kpiCharts = {};
      if (
        ["ore"].includes(
          this.companySelected && this.companySelected.groupCompany
            ? this.companySelected.groupCompany
            : ""
        )
      ) {
        this.computedDataTables.map((dTable) => {
          if (dTable.register.includes("venda_meta_report")) {
            const metaTotal = this.computedItems[dTable.register].reduce(
              (acc, d) => {
                return acc + d["META"];
              },
              0
            );
            const valorTotal = this.computedItems[dTable.register].reduce(
              (acc, d) => {
                return acc + d["VALOR_VENDIDO"];
              },
              0
            );
            // const performance = this.computedItems[dTable.register].reduce((acc, d) => { return acc + d['PERCENTUAL_META_ATINGIDA'] }, 0)
            const saldoTotal = this.computedItems[dTable.register].reduce(
              (acc, d) => {
                return acc + (d["VALOR_VENDIDO"] - d["META"]);
              },
              0
            );

            const performancePerCent = valorTotal ? valorTotal / metaTotal : 0;

            // (data) =>{ const result = (data.reduce((acc,{VALOR_VENDIDO}) => acc +(VALOR_VENDIDO),0))/(data.reduce((acc,{META}) => acc +(META),0)); return result > 100 ? 100 : result }
            kpiCharts[dTable.register] = {
              metaTotal: {
                value: new Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                }).format(metaTotal),
                title: "Meta",
              },
              valorTotal: {
                value: new Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                }).format(valorTotal),
                title: "Venda",
              },
              performance: {
                value: new Intl.NumberFormat("pt-BR", {
                  style: "percent",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(performancePerCent),
                title: "Performance",
              },
              saldoTotal: {
                value: new Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                }).format(saldoTotal),
                title: "Saldo",
              },
            };
          }
        });
      }
      return kpiCharts;
    },
    computedSearchEnableds() {
      return this.searchEnableds;
    },
    computedConfigsShowed() {
      return this.configsShowed;
    },
    computedHeadersShowed() {
      return this.headersShowed;
    },
    computedDataTables() {
      return this.dataTablesProcessed.map((dTable) => {
        return { ...dTable };
      });
    },
    computedPivotTables() {
      return this.pivotProcessed;
    },
    computedFiltersProcessed() {
      return this.filtersProcessed;
    },
    registersCount() {
      let dataTablesCounters = {};
      for (const dTable of this.computedDataTables) {
        if (
          (this.searchInputs[dTable.register] &&
            this.searchInputs[dTable.register].length > 0) ||
          this.computedFiltersProcessed.find((cp) => cp.selecteds.length > 0)
        ) {
          dataTablesCounters[dTable.register] =
            this.counters[`report${dTable.register}Filtered`] || 0;
        } else {
          dataTablesCounters[dTable.register] =
            this.counters[`report${dTable.register}`] || 0;
        }
      }
      return dataTablesCounters;
    },
    computedLevels() {
      let levels = {};
      for (const dTable of this.computedDataTables) {
        levels[dTable.register] = {};

        for (const h of dTable.headers.filter((h) => !h.typeReduce)) {
          const groupLevels =
            h.groupLevels && h.groupLevels.length > 0 ? h.groupLevels : [1];
          for (const level of groupLevels) {
            // console.info('levels[dTable.register]', levels[dTable.register]);
            if (!levels[dTable.register][level]) {
              levels[dTable.register] = {
                ...levels[dTable.register],
                [level]: [h],
              };
            } else {
              levels[dTable.register][level].push(h);
            }
          }
        }
        for (const key of Object.keys(levels[dTable.register])) {
          if (key > "1") {
            levels[dTable.register][key] = [
              ...new Set(
                [
                  ...levels[dTable.register][key],
                  ...levels[dTable.register][key - 1],
                ].map(JSON.stringify)
              ),
            ].map(JSON.parse);
          }
        }
      }

      return levels;
    },
    computedAccReduces() {
      let accReduces = {};
      for (const dTable of this.computedDataTables) {
        accReduces[dTable.register] = [];
        for (const h of dTable.headers.filter((h) => h.typeReduce)) {
          accReduces[dTable.register].push(h);
        }
      }

      return accReduces;
    },
    computedItems() {
      let items = {};
      for (const dTable of this.computedDataTables) {
        const isFiltered =
          (this.searchInputs[dTable.register] &&
            this.searchInputs[dTable.register].length > 0) ||
          this.computedFiltersProcessed.find((cp) => cp.selecteds.length > 0);
        if (!this.loading[`report${dTable.register}`]) {
          items[dTable.register] =
            this.registers[
              `report${dTable.register}${isFiltered ? "Filtered" : ""}${
                dTable.allRegs ? "All" : ""
              }`
            ] || [];
        } else {
          items[dTable.register] = [];
        }

        for (const level of Object.keys(this.computedLevels[dTable.register])) {
          if (level === "1") continue;
          if (
            !this.loading[`report${dTable.register}`] &&
            !this.loading[`report${dTable.register}Level${level}`]
          ) {
            items[`${dTable.register}${level}`] =
              this.registers[`report${dTable.register}Level${level}Filtered`] ||
              [];
          } else {
            items[`${dTable.register}${level}`] = [];
          }
        }
      }

      return items;
    },
    computedExpandCharts() {
      return this.expandCharts;
    },
  },
  watch: {
    searchInputs(val) {
      // this.setMyCounters();
    }
  },
  methods: {
    ...registersActions(["getExternal"]),
    ...mapMutations(["setLoading"]),
    formatDatePickerTextField(value, filter) {
      if (filter.fromTo) {
        value = this.$moment(value[0]).format('DD/MM/YYYY')+' - '+this.$moment(value[1]).format('DD/MM/YYYY')
      } else {
        value = this.$moment(value).format('DD/MM/YYYY')
      }
      filter.modelFormated = value
    },
    getProcessedHeaders({ dTable, level }) {
      return dTable.headers
        .filter(
          (h) =>
            h.showable &&
            this.computedHeadersShowed[dTable.register][h.value] &&
            (!dTable.headers.some(
              (h) => h.groupLevels && h.groupLevels.length > 0
            ) ||
              h.groupLevels?.includes(level) ||
              h.typeReduce)
        )
        .map((h) => {
          if (h.text.includes("${")) {
            h.text = eval(h.text);
          }
          return h;
        });
    },
    async search(req) {
      try {
        await req;
      } catch (err) {
        console.error(err);
      }
    },
    enableSearch({ dTable }) {
      const searchEnabled = this.searchEnableds[dTable.register];
      this.searchEnableds = {
        ...this.searchEnableds,
        [dTable.register]: !searchEnabled,
      };
    },
    showConfigs({ dTable }) {
      const showConfig = this.configsShowed[dTable.register];
      this.configsShowed = {
        ...this.configsShowed,
        [dTable.register]: !showConfig,
      };
    },
    proccessItem({ item, head }) {
      let ret = this.$_.cloneDeep(item);
      if (!ret) return;
      if (
        ret._highlightResult &&
        ret._highlightResult[head.value] &&
        ret._highlightResult[head.value].matchedWords &&
        ret._highlightResult[head.value].matchedWords.length > 0
      ) {
        ret = ret._highlightResult;
      }
      /* NESTED */

      const pathItem = head.value.split(".");
      const lenItem = pathItem.length;
      if (lenItem == 1) {
        ret = ret[pathItem];
      } else {
        for (let ix = 0; ix < pathItem.length; ix++) {
          const el = pathItem[ix];
          if (ret && typeof ret[el] != "undefined") {
            ret = ret[el];
          } else {
            ret = head.checkbox ? false : "";
            break;
          }
        }
      }

      /* FORMATS */
      if (!ret && ret !== 0) return;
      if (ret && ret.value) ret = ret.value;
      if (head.upper) {
        ret = ret.toUpperCase();
      }
      if (head.lower) {
        ret = ret.toLowerCase();
      }
      if (head.trim) {
        ret = ret.trim();
      }

      /* FORMAT DATES */
      if (
        head.type === "date" ||
        head.type === "dateHour" ||
        head.type === "dateYear" ||
        head.type === "datePlusWeek"
      ) {
        if (Object.prototype.hasOwnProperty.call(ret, "toDate")) {
          ret = ret.toDate();
        } else if (ret.seconds && ret.nanoseconds) {
          // Retorno via Algolia não contem metodo toDate (agora o firebase tbm)
          ret = new this.$db.Timestamp(ret.seconds, ret.nanoseconds).toDate();
          // Retorno via Algolia não contem metodo toDate. (agora tem o underline na frente)
        } else if (ret._seconds && ret._nanoseconds) {
          ret = new this.$db.Timestamp(ret._seconds, ret._nanoseconds).toDate();
        }
      }
      if (head.type === "date") {
        if (typeof ret === "string" && ret.trim() === "") {
          return;
        }
        ret = this.$moment(ret).format("DD/MM/YYYY");
      }
      if (head.type === "dateHour") {
        ret = this.$moment(ret).format("DD/MM/YYYY - HH:mm:ss");
      }
      if (head.type === "dateYear") {
        ret = this.$moment(ret).format("YYYY");
      }
      if (head.type === "datePlusWeek") {
        ret = this.$moment(ret).format("DD/MM/YYYY, dddd");
      }

      if (head.type === "money")
        ret = new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(ret);
      if (head.type === "percent")
        ret = new Intl.NumberFormat("pt-BR", {
          style: "percent",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(ret / 100);

      // if (options.slice) { obj = (obj) ? obj.slice(options.slice) : '' }
      // if (options.multiplier) { obj = (obj) ? obj * options.multiplier : '' }

      return ret;
    },
    toggle(key) {
      this.$nextTick(() => {
        if (this.likesAll(key)) {
          key.selecteds = [];
        } else {
          const data = this.registers[`reportFilters${key.field}`] || [];
          const dataValue = data.map((d) => d[key.itemValue]).slice() || [];
          key.selecteds = dataValue; //key.data.map(d => d.value).slice()
        }
      });
    },
    icon(key) {
      if (this.likesAll(key)) return "mdi-close-box";
      if (this.likesSome(key)) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    likesAll(key) {
      const data = this.registers[`reportFilters${key.field}`] || [];
      return key.selecteds.length === data.length;
    },
    likesSome(key) {
      return key.selecteds.length > 0 && !this.likesAll(key);
    },
    applyFilter() {
      if (this.computedDataTables && this.computedDataTables.length > 0) {
        this.computedDataTables.forEach((dTable) => {
          const {
            query,
            register,
            order = { by: "ID", desc: false },
            page = 1,
            allRegs,
          } = dTable;
          const input = `externalSearchInput${register}`;
          const filtersIn = [
            ...this.computedFiltersProcessed.filter(
              (f) => f.selecteds.length > 0
            ),
          ];
          if (this.$refs[input]) {
            this.$refs[input][0].search(
              this.searchInputs[register],
              [],
              filtersIn
            );
          } else {
            const ordem = `${order.by} ${order.by && order.desc ? "DESC" : ""}`;
  
            const externalRequest = {
              query,
              idField: "ID",
              register: `report${register}`,
              allRegs,
              ordem,
              page,
              perPage: 101,
              filtersIn,
              isRestNode: this.isRestNode
            };
            this.getExternal(externalRequest);
          }
        });
      } 
      else if (this.pivotTables && this.pivotTables.length > 0) {
        this.updatePivot()
      }
    },
    async update({
      dTable,
      allRegs = false,
      async = false,
      options: { sortBy: sort, sortDesc: desc, itemsPerPage, page: pageIndex },
    }) {
      if (pageIndex) dTable.page = pageIndex;
      if (itemsPerPage) dTable.itemsPerPage = itemsPerPage;
      if (sort) dTable.order.by = sort.length > 0 ? sort[0] : "";
      if (desc !== null) dTable.order.desc = desc;

      const {
        query,
        register,
        order = { by: "ID", desc: false },
        page = 1,
        itemsPerPage: perPage,
        headers,
        allRegs: allRegsTable,
      } = dTable;

      // console.info('dTable', dTable);
      let groupBy = null;
      if (
        headers.some((h) => h.groupLevels && h.groupLevels.some((gl) => gl > 1))
      ) {
        const fieldsGroupID = `${headers
          .filter((h) => h.groupLevels.some((gl) => gl === 1))
          .map((h) => h.value)
          .join("+")} AS ID `;
        const fieldsGroup = headers
          .filter((h) => h.groupLevels.some((gl) => gl === 1))
          .map((h) => h.value)
          .join(", ");
        const fieldsAcc = headers
          .filter((h) => h.typeReduce)
          .map((h) => `SUM(${h.value}) AS ${h.value}`)
          .join(", ");
        groupBy = { fieldsAcc, fieldsGroup, fieldsGroupID };
      }
      const ordem = `${order.by} ${order.by && order.desc ? "DESC" : ""}`;
      const filtersIn = [
        ...this.computedFiltersProcessed.filter((f) => f.selecteds.length > 0),
      ];
      const externalRequest = {
        query,
        idField: "ID",
        register: `report${register}`,
        ordem,
        page,
        perPage: allRegsTable || allRegs ? 9999999 : 101,
        nextPage: true,
        search: this.searchInputs[register],
        fieldsToSearch: dTable.headers
          .filter((x) => x.searchable)
          .map((x) => x.searchableContent || x.value),
        fieldsToMark: dTable.headers
          .filter((x) => x.searchable && x.searchableContent)
          .map(({ searchableContent, value }) => ({
            searchableContent,
            value,
          })),
        filtersIn,
        allRegs: allRegsTable || allRegs,
        async,
        groupBy,
        isRestNode: this.isRestNode
      };
      if (async) {
        this.setLoading({ stats: true, message: "Buscando registros..." });
        await this.getExternal(externalRequest);
        this.setLoading({ stats: false });
      } else {
        this.getExternal(externalRequest);
      }
    },
    async getAll({ dTable }) {
      const options = {
        sortBy: [dTable.order.by],
        sortDesc: dTable.order.desc,
        page: 1,
      };

      await this.update({ dTable, async: true, allRegs: true, options });

      const items =
        (this.searchInputs[dTable.register] &&
          this.searchInputs[dTable.register].length > 0) ||
        this.computedFiltersProcessed.find((cp) => cp.selecteds.length > 0)
          ? this.registers[`report${dTable.register}FilteredAll`]
          : this.registers[`report${dTable.register}All`] || [];
      return items;
    },
    async exportXlsx({ dTable }) {
      const headers = dTable.headers.filter((h) => h.export);

      if (!headers || headers.length === 0) {
        this.$toast.error("Esta tabela não possui campos exportáveis.");
        return;
      }

      const header = headers.map((h) => h.text);
      const itemsRegistereds = await this.getAll({ dTable });

      if (!itemsRegistereds || itemsRegistereds.length === 0) {
        this.$toast.error("Nenhum item a ser exportado.");
        return;
      }

      const items = itemsRegistereds.map((item) =>
        headers.reduce(
          (acc, header) => ({
            ...acc,
            [header.text]:
              typeof item[header.value] === "string"
                ? item[header.value]
                    .split("<em>")
                    .join("")
                    .split("</em>")
                    .join("")
                : item[header.value],
          }),
          {}
        )
      );
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(items, { header });

      this.setLoading({ stats: true, message: "Montando arquivos" });
      // adiciona as fórmulas em formato excel para as células das colunas que possuem essa configuração
      items.slice(1).forEach((_, idx) => {
        headers.forEach((header, i) => {
          if (!header.xlsxFormula) return;

          const cellKey = `${String.fromCharCode(65 + i)}${idx + 2}`;
          try {
            ws[cellKey].f = header.xlsxFormula
              .trim()
              .replaceAll("x", `${idx + 2}`);
          } catch (err) {
            console.error(err);
          }
        });
      });
      this.setLoading({ stats: false });

      XLSX.utils.book_append_sheet(wb, ws, `Sheet`);

      const exportFileName = `${dTable.title}.xlsx`;
      XLSX.writeFile(wb, exportFileName);
    },
    setItemsPerLevel({ dTable, level, itemFilter }) {
      // aqui é value pois vai estar agrupado e pode não estar com o valor pesquisável no cabeçalho
      const fieldsToSearch = dTable.headers
        .filter((x) => x.searchable)
        .map((x) => x.searchableContent || x.value);
      const fieldsGroup = this.computedLevels[dTable.register][level]
        .map((h) => h.value)
        .join(",");
      const fieldsGroupID = `${this.computedLevels[dTable.register][level]
        .map((h) => h.value)
        .join("+")} AS ID `;
      const filters = this.computedLevels[dTable.register][level - 1].map(
        (h) => [
          `${h.searchableContent || h.value}:${itemFilter[h.value]
            .split("<em>")
            .join("")
            .split("</em>")
            .join("")}`,
        ]
      );
      // const filters = indexLevelToFilter.map(key => [`${key}:${itemFilter[key]}`])
      const fieldsAcc = this.computedAccReduces[dTable.register].map(
        (h) => `SUM(${h.value}) AS ${h.value}`
      );
      const groupBy = { fieldsGroup, fieldsGroupID, fieldsAcc };
      const { query, register } = dTable;

      const input = `externalSearchInput${register}`;
      let contentToMarkForced;
      if (
        this.$refs[input] &&
        this.$refs[input][0] &&
        this.$refs[input][0]["value"]
      ) {
        contentToMarkForced = this.$refs[input][0]["value"];
      }
      const externalRequest = {
        query,
        idField: "ID",
        register: `report${register}Level${level}`,
        filters,
        groupBy,
        ordem: "ID",
        fieldsToSearch,
        search: contentToMarkForced,
        isRestNode: this.isRestNode
      };
      this.getExternal(externalRequest);
    },
    initRegisters() {
      // Prepara os filtros do pivot
      if (this.pivotFilters && this.pivotFilters.length > 0) {
        this.dialog = true

        for (const [ix, filter] of this.pivotFilters.entries()) {
          switch (filter.type) {
            case 'date-picker':
              // Não pode fazer o default
              break;
    
            case 'custom-autocomplete':
              this.filtersModels[filter.value] = filter.fromTo ? Array({}, {}) : Array({})
              break;
        
            case 'custom-autocomplete-sa1':
              this.pivotFilters[ix] = {
                ...this.pivotFilters[ix],
                value: 'SA1',
                label: 'Cliente',
                customAutocomplete: {
                  fieldsToShow: [
                    "A1_COD",
                    "A1_NOME",
                    "A1_CGC",
                  ],
                  collection: "SA1",
                  orderBy: "A1_NOME",
                  fieldsToSearch: [
                    "A1_COD",
                    "A1_NOME",
                    "A1_CGC"
                  ]
                }
              }
              this.filtersModels['SA1'] = filter.fromTo ? Array({}, {}) : Array({})
              break;
        
            case 'custom-autocomplete-sa3':
              this.pivotFilters[ix] = {
                ...this.pivotFilters[ix],
                value: 'SA3',
                label: 'Vendedor',
                customAutocomplete: {
                  fieldsToShow: [
                    "A3_COD",
                    "A3_NOME",
                  ],
                  collection: "SA3",
                  orderBy: "A3_NOME",
                  fieldsToSearch: [
                    "A3_COD",
                    "A3_NOME",
                  ]
                },
              }
              this.filtersModels['SA3'] = filter.fromTo ? Array({}, {}) : Array({})
              break;
          
            default:
              this.filtersModels[filter.value] = filter.fromTo ? Array('', '') : Array('')
              break;
          }
        }
      }

      const filtersDefault = this.filters.filter(f => f.default)

      let filtersIn = []

      if (filtersDefault) {
        filtersIn = filtersDefault.map(fd => (
          {...fd, selecteds: eval(fd.default)}
        ))
      }

      this.dataTablesProcessed =
        this.dataTables && this.dataTables.length > 0
          ? this.dataTables.map((dTable) => {
              const {
                query,
                register,
                headers,
                filters,
                order = { by: "ID", desc: false },
                itemsPerPage: perPage = 10,
                allRegs,
              } = dTable;
              const ordem = `${order.by} ${order.desc ? "DESC" : "ASC"}`;
              let idField = "ID";
              let groupBy = null;
              if (
                headers.some(
                  (h) => h.groupLevels && h.groupLevels.some((gl) => gl > 1)
                )
              ) {
                const fieldsGroupID = `${headers
                  .filter((h) => h.groupLevels.some((gl) => gl === 1))
                  .map((h) => h.value)
                  .join("+")} AS ID `;
                const fieldsGroup = headers
                  .filter((h) => h.groupLevels.some((gl) => gl === 1))
                  .map((h) => h.value)
                  .join(", ");
                const fieldsAcc = headers
                  .filter((h) => h.typeReduce)
                  .map((h) => `SUM(${h.value}) AS ${h.value}`)
                  .join(", ");
                groupBy = { fieldsAcc, fieldsGroup, fieldsGroupID };
              }

              const externalRequest = {
                query,
                allRegs,
                idField,
                register: `report${register}`,
                ordem,
                filtersIn,
                perPage: 101,
                groupBy,
                isRestNode: this.isRestNode
              };
              this.getExternal(externalRequest);
              return {
                ...dTable,
                filters,
                serverItemsLength: true,
                externalRequest,
              };
            })
          : [];

      this.filtersProcessed =
        this.filters && this.filters.length > 0
          ? this.filters.map((f, ix) => {
              let {
                query,
                itemValue: idField,
                itemText,
                order = { by: "ID", desc: false },
              } = f;
              const ordem = `${order.by} ${order.desc ? "DESC" : "ASC"}`;
              const externalRequest = {
                query,
                idField,
                register: `reportFilters${f.field}`,
                ordem,
                perPage: 999999999,
                forceTrim: true,
                isRestNode: this.isRestNode
              };

              if (itemText.includes("=>") || itemText.includes("`")) {
                itemText = eval(itemText);
              }
              this.getExternal(externalRequest);

              let selecteds = [];

              if (f.default) {
                selecteds = eval(f.default)
              }

              return {
                ...f,
                itemText,
                data: [],
                selecteds,
                externalRequest,
              };
            })
          : [];

      this.updatePivot()
    },
    updatePivot() {
      if (this.dialog) return

      this.pivotProcessed =
        this.pivotTables && this.pivotTables.length > 0
          ? this.pivotTables.map((pivot) => {
              const {
                query,
                register,
                order = { by: "ID", desc: false },
                itemsPerPage,
                loadingTime
              } = pivot;
              this.$set(this.registersPivots, [register], null)
              this.$set(this.loadingPivots, [register], true)
              this.$set(this.loadingTimePivots, [register], 0)
              setInterval(() => { if (this.loadingTimePivots[register] < 100) {this.$set(this.loadingTimePivots, [register], this.loadingTimePivots[register]+(100/(loadingTime || 60)/10))} }, 100);
              const ordem = `${order.by} ${order.desc ? "DESC" : "ASC"}`;
              const params = []
              for (const key in this.filtersModels) {
                if (Object.hasOwnProperty.call(this.filtersModels, key)) {
                  const el = this.filtersModels[key];
                  const filter = this.pivotFilters.find(x => x.value == key)
                 
                  if (!el) return
                  switch (filter.type) {
                    case 'date-picker':
                      params[key.trim()] = (filter.fromTo) ? el.map(x => x.replace(/-/g, '')) : el.replace(/-/g, '')
                      break;
                  
                    default:
                      params[key.trim()] = (filter.fromTo) ? el : el[0]
                      break;
                  }
                }
              }
              const externalRequest = {
                query,
                idField: "ID",
                register,
                ordem,
                perPage: itemsPerPage,
                setRegister: false,
                params,
                isRestNode: this.isRestNode
              };
              this.getExternal(externalRequest)
                .then(res => {
                  if (res.code) {
                    this.$set(this.registersPivots, [register], [])
                  } else {
                    this.$set(this.registersPivots, [register], res)
                  }
                })
                .finally(() => this.$set(this.loadingPivots, [register], false))
              return { ...pivot, externalRequest };
            })
          : [];
    },
    async handlerAutocomplete(req) {
      try {
        const {index, hits, query} = await req;
        if (this.autocompleteHits[index]) {
          for (const el of hits) {
            this.autocompleteHits[index]
            const ix = this.autocompleteHits[index].findIndex(x => x.id == el.id)
            if (ix > -1) { this.autocompleteHits[index][ix] = el } 
            else { this.autocompleteHits[index].push(el) } 
          }
        } else {
          this.$set(this.autocompleteHits, index, hits) 
        }
      } catch (err) {
        console.error(err);
      }
    },
    async handlerAutocompleteLoading(index, bool) {
      try {
        this.$set(this.autocompleteLoading, index, bool);
      } catch (err) {
        console.error(err);
      }
    },
  },
  created() {
    this.initRegisters();
    this.searchEnableds = {
      ...Object.assign(
        {},
        ...this.dataTables.map((dt) => ({ [dt.register]: false }))
      ),
    };
    this.configsShowed = this.searchEnableds; //{...Object.assign({}, ...this.dataTables.map(dt=>  ( {[dt.register]: false} ))) }
    this.headersShowed = {
      ...Object.assign(
        {},
        ...this.dataTables.map((dt) => ({
          [dt.register]: Object.assign(
            {},
            ...dt.headers.map((h) => ({ [h.value]: h.showable }))
          ),
        }))
      ),
    };
  },
};
</script>

<style>
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>
