<template>
  <v-container fluid class="px-4">
    <v-row>
      <!-- Limpar Filtros -->
      <v-col cols=12 class="ma-0 py-0 mb-3">
        <v-btn @click="clearFilters" outlined rounded x-small color="primary">
          <v-icon left>mdi-close</v-icon>
          Limpar Todos os Filtros
        </v-btn>
      </v-col>
      <v-col
        v-for="filter in computedFilters"
        :key="filter.filterKey"
        cols=12
        :md="(['algolia', 'customautocomplete'].includes(filter.source)) ? 4 : 3"
        :lg="(['algolia', 'customautocomplete'].includes(filter.source)) ? 3 : 2"
      >
        <v-row>
          <v-col cols="12" md="12">
            <v-row>
              <v-col cols="12" class="py-0 pt-1">
                <template v-if="filter.source == 'select'">
                  <v-select
                    v-model="model[filter.value]"
                    @change="setAlgoliaFilters()"
                    :label="filter.label"
                    :items="filter.items"
                    :loading="filter.loading"
                    outlined
                    dense
                    clearable
                    hide-details
                    height=40                
                    >
                  </v-select>   
                </template>
                <template v-else-if="filter.source == 'date-picker'">
                  <v-menu
                    v-model="auxData[filter.value].menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="auxData[filter.value].modelFormated"
                        :label="filter.label"
                        prepend-icon="mdi-calendar"
                        persistent-hint
                        :hint="`${(filter.range) ? 'Selecionar um período' : ''}`"
                        readonly
                        outlined
                        dense
                        height=40   
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="model[filter.value]"
                      :range="filter.range"
                      @change="auxData[filter.value].menu = false; formatDatePickerTextField($event, filter); setAlgoliaFilters()"
                    ></v-date-picker>
                  </v-menu>
                </template>        
                <template v-else-if="filter.source == 'input'">        
                  <v-select
                    v-model="model[filter.filterKey]"
                    @change="setAlgoliaFilters()"
                    :label="filter.label"
                    :items="filter.values"
                    :loading="filter.loading"
                    outlined
                    dense
                    clearable
                    hide-details
                    height=40                
                    >
                  </v-select>           
                </template>        
                <template v-else-if="filter.source == 'algolia'">        
                  <v-autocomplete-algolia
                    v-model="model[filter.algoliaIndex]"
                    :items="items[filter.algoliaIndex]"
                    :handler="req => searchFilter(req, filter)"
                    :delay="750"
                    :label="filter.label"
                    :index="filter.algoliaIndex"
                    :loading="filter.loading"
                    :multiple="filter.multiple"
                    :attributesToHighlight="filter.fieldsToShow"
                    :restrictSearchableAttributes="filter.fieldsToSearch"
                    :facetFilters="filter.facetFilters"
                    :item-text="filter.itemText"
                    :no-data-text="filter.noDataText"
                    :no-empty="filter.noEmpty"
                    :chips="filter.chips"
                    :deletable-chips="filter.deletableChips"
                    :small-chips="filter.smallChips"
                    @change="setAlgoliaFilters()"
                    @update:search-input="filter.noDataText = $event ? $vuetify.noDataText : filter.noDataTextDefault"
                    height=40
                    no-filter
                    hide-details
                    return-object
                    outlined
                    dense
                    clean
                  >
                    <template #item="{item}">
                      <div
                        v-for="(field, ix) in filter.fieldsToShow"
                        :key="field"
                      >
                        <span v-if="ix > 0" class="mx-1">-</span>
                        <span 
                          v-html="( 
                            item._highlightResult 
                            && item._highlightResult[field] 
                            && item._highlightResult[field].matchedWords
                            && item._highlightResult[field].matchedWords.length > 0
                          ) ? item._highlightResult[field].value : item[field]"/>
                      </div>
                    </template>
                    <template #selection>
                      <div class="d-none"></div>
                    </template>
                    <!-- <template v-if="filter.chips && filter.multiple" #selection="{ item, index, attrs, selected, disabled }">
                      <v-chip 
                        v-if="index < filter.formChips"
                        :key="JSON.stringify(item)"
                        v-bind="attrs"
                        :input-value="selected"
                        :disabled="disabled"
                        :color="`${color} accent-4`"
                        small
                        close
                        dark
                        @click:close="(filter.multiple) ? parent.selectItem(item) : clearField({field: filter.value})"
                      >
                        <span>{{ filter.itemText ? filter.itemText.apply ?  filter.itemText(item) : item[filter.itemText] : item }}</span>
                      </v-chip>
                      <span
                        v-if="index == filter.formChips"
                        class="grey--text caption"
                      >(+{{ model[filter.algoliaIndex].length - filter.formChips }} outro(s))</span>
                    </template> -->
                  </v-autocomplete-algolia>
                </template>   
                
                <!-- CUSTOMAUTOCOMPLETE -->
                <template v-else-if="filter.source == 'customautocomplete'">
                  <v-custom-autocomplete
                    v-model="model[filter.value]"
                    @click:clear="autocompleteHits[filter.value] = []"
                    :index="filter.value"
                    :items="autocompleteHits[filter.value]"
                    :loading="autocompleteLoading[filter.value]"
                    :filter="(item, queryText, itemText) => filter.fieldsToShow.some(x => item[x].toLowerCase().includes(queryText.toLowerCase()))"
                    :handler="handlerAutocomplete"
                    :handlerLoading="handlerAutocompleteLoading"
                    :delay="750"
                    :asyncSearch="filter.asyncSearch"
                    :fieldsToShow="filter.fieldsToShow"
                    :fieldsToSearch="filter.fieldsToSearch"
                    :item="model"
                    :label="filter.filterLabel || filter.label"
                    :return-object="filter.returnObject"
                    :item-value="filter.itemValue"
                    :item-text="filter.itemText"
                    :chips="filter.chips"
                    :placeholder="filter.placeholder"
                    :hint="filter.hint"
                    :persistent-hint="filter.persistentHint"
                    :small-chips="filter.smallChips"
                    :deletableChips="filter.deletableChips"
                    :multiple="filter.multiple"
                    dense
                    clearable
                    no-filter
                    hide-details
                    outlined
                    clean
                    @change="setAlgoliaFilters()"
                  >
                    <template v-slot:item="{item}">
                      <div v-for="(field, ix) in filter.fieldsToShow" :key="field">
                        <span v-if="ix > 0" class="mx-1">-</span>
                        <span v-html="item._highlightResult && item._highlightResult[field] && item._highlightResult[field].matchedWords && item._highlightResult[field].matchedWords.length > 0 ? item._highlightResult[field].value : item[field]" />
                      </div>
                    </template>
                    <template v-if="filter.chips" v-slot:selection="data">
                      <section>
                        <v-chip v-if="data.index < formChips" :key="JSON.stringify(data.item)" v-bind="data.attrs" :input-value="data.selected" :disabled="data.disabled" :color="`${color} accent-4`" small close dark @click:close="filter.multiple ? data.parent.selectItem(data.item) : clearField({field: filter.value})">
                          <span>{{ data.item[filter.itemText] || data.item }}</span>
                        </v-chip>
                        <span v-if="data.index == formChips" class="grey--text caption">(+{{ formData[filter.value].length - formChips }} outro(s))</span>
                      </section>
                    </template>
                  </v-custom-autocomplete>
                </template>   
                
                <!-- SWITCH -->
                <template v-else-if="filter.source == 'switch'">        
                  <v-switch
                    v-model="model[filter.filterKey]"
                    :label="filter.label"
                    hide-details
                    @change="setAlgoliaFilters()"
                    inset
                    dense
                  />        
                </template>   
              </v-col>
            </v-row>
          </v-col>

          <!-- FILTROS ATIVOS -->
          <v-col cols="12" md="12" class="mb-1">
            <v-row v-if="!['date-picker', 'switch'].includes(filter.source)">
              <v-col
                v-show="filter.multiple ? model[filter.filterValue].length > 0 : !!model[filter.filterValue]"
                cols="12"
                class="pt-2 pb-0 mb-n2"
                >
                <!-- <span v-text="filter.label + ':'" class="mr-2 caption"></span> -->
                <v-chip-group v-if="filter.multiple" column multiple max="0" class="mt--2">
                  <v-chip
                    v-for="(item, idx) in model[filter.filterValue]"
                    :key="filter.filterValue + idx"
                    small
                    class="py-0 my-0 activeFilterChip"
                    close
                    @click:close="removeFilterItem(filter, item)"
                    >
                    1 {{ filter.itemText ? filter.itemText.apply ? filter.itemText(item) : item[filter.itemText] : item }}
                  </v-chip>
                </v-chip-group>
                <v-chip-group v-else-if="!!model[filter.filterValue]" column multiple max="0" class="mt--2">
                  <v-chip
                    small
                    class="py-0 my-0 activeFilterChip"
                    close
                    @click:close="removeFilterItem(filter, model[filter.filterValue])"
                    >
                    {{ filter.itemText ? filter.itemText.apply ? filter.itemText(model[filter.filterValue]) : model[filter.filterValue][filter.itemText] : model[filter.filterValue] }}
                  </v-chip>
                </v-chip-group>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import VAutocompleteAlgolia from '@/components/VAutocompleteAlgolia'
import VCustomAutocomplete from "../VCustomAutocomplete.vue";
import { Console } from 'console';
export default {
  name: 'DataTableFilters',
  props: ['filters'],
  components: { VAutocompleteAlgolia, VCustomAutocomplete },
  data() {
    return {
      items: {},
      model: {},
      algoliaFilters: [],
      chips: null,
      auxData: {},
      filtersData: [],
      autocompleteHits: {},
      autocompleteLoading: {},
    }
  },
  computed: {
    ...mapState(['companySelected']),
    isFiltered() {
      return this.computedFilters.some(filter => {
        if (filter.multiple) {
          return this.model[filter.algoliaIndex].length > 0
        } else {
          return !!this.model[filter.algoliaIndex]
        }
      })
    },
    computedFilters() {
      return this.filtersData
    },
  },
  methods: {
    clearFilters() {
      this.model = {}
      this.resetAuxData()
      this.setAlgoliaFilters()
    },
    async searchFilter(req, filter) {
      filter.loading = true
      try {
        const { hits } = await req
        this.items[filter.algoliaIndex].splice(0)
        if (hits)
          this.items[filter.algoliaIndex].push(...hits)
      } catch (err) {
        console.error(err)
        this.items[filter.algoliaIndex].splice(0)
      } finally {
        filter.loading = false
      }
    },
    setAlgoliaFilters() {
      const algoliaFilters = []
      this.computedFilters.forEach(filter => {  
        const prop = filter.filterValue
        if (!this.model[prop])
          return

        if (filter.multiple) {          
          if (this.model[prop].length == 0) {
            return
          } else {            
            const arr = this.model[prop].map(item => `${filter.filterKey || filter.value}:${(filter.source == 'input' || filter.type == 'select') ? item : item[filter.itemKey || filter.filterItemKey] }`)
            algoliaFilters.push(arr)
          }
        } if (filter.range){
          let value = this.model[prop]
          algoliaFilters.push([`${filter.filterKey || filter.value}~~${value}`])
        } else {
          let key = filter.filterKey || filter.value
          let operator = key === 'status' ? 'like' : ':'
          let value = (typeof this.model[prop] == 'object') ? this.model[prop][filter.itemKey || filter.filterItemKey] : this.model[prop]
          if (filter.source == 'date-picker' && this.companySelected?.groupCompany == 'ore') value = value.replaceAll('-', '')
          if (filter.filterKey == 'hideSketch') {
            key = 'status'
            operator = '!='
            value = (value) ? 'Rascunho' : ''
          }
          algoliaFilters.push([`${key}${operator}${value}`])
        }
      })
      this.algoliaFilters = algoliaFilters
    
      this.$emit('change', algoliaFilters)
    },
    removeFilterItem(filter, item) {
      if (filter.multiple) {
        const idx = this.model[filter.filterValue].indexOf(item)
        this.model[filter.filterValue].splice(idx, 1)
      } else {
        this.model[filter.filterValue] = null
      }
      if (filter.source == 'date-picker') { this.auxData[filter.value].modelFormated = '' }
      this.setAlgoliaFilters()
    },
    formatDatePickerTextField(value, filter) {
      if (filter.range) {
        value = value.map(x => this.$moment(x).format("DD/MM/YYYY")).join(' ~ ')
      } else {
        value = new Date(value+" 00:00")
        if (filter.dateYear && (value instanceof Date)) {
          value = this.$moment(value).format("YYYY");
        }
        else if (filter.datePlusWeek && (value instanceof Date)) {
          value = this.$moment(value).format("DD/MM/YYYY, dddd");
        } else {
          value = this.$moment(value).format("DD/MM/YYYY");
        }
      }
      this.$set(this.auxData[filter.value], 'modelFormated', value);
    },
    resetAuxData() {
      this.auxData = {}
      this.filtersData.forEach(async filter => {
        if (filter.source == 'date-picker') {
          this.$set(this.auxData, filter.value, {menu: false, modelFormated: ''});
        }
      })
    },

    async handlerAutocomplete(req) {
      const {index, hits, query} = await req;
      try {
        this.$set(this.autocompleteHits, index, hits) 
      } catch (err) {
        this.$set(this.autocompleteHits, index, []) 
        console.error(err);
      }
    },

    async handlerAutocompleteLoading(index, bool) {
      try {
        this.$set(this.autocompleteLoading, index, bool);
      } catch (err) {
        console.error(err);
      }
    },
  },
  created() {
    this.filtersData = this.filters.map(x => {
      if(['lifeControl.createdAt'].includes(x.value)) {
        x.source = 'date-picker'
        x.range = true
      }
      if(!x.source) x.source = x.type
      if(!x.filterValue) x.filterValue = x.algoliaIndex || x.filterKey || x.value
      if (x.fieldsToShow) {
        x.itemText = (item) => {
          let str = item[x.fieldsToShow[0]]?.trim();
          x.fieldsToShow.slice(1).forEach((field) => {
            str = str + " - " + item[field]?.trim();
          });

          return str;
        };
      }
      return x
    })
    this.filtersData.forEach(async filter => {
      if (filter.source == 'collection') {
        /** @TODO */
        // const algoliaClient = algoliasearch(this.algolia?.appId, this.algolia?.searchApiKey)
        // const algoliaIndex = algoliaClient.initIndex(filter.collectionName)
        // try {
        //   filter.loading = true
        //   const response = await algoliaIndex.search()
          
        //   if (response?.hits) {
        //     filter.values = response.hits.map(hit => hit[filter.collectionField])
        //   } else {
        //     console.warn('Error in algolia search on filter ' + filter.name)
        //     filter.values = []
        //   }
        // } catch (err) {
        //   console.error(err)
        // } finally {
        //   filter.loading = false
        // }
      } 
      else if (filter.source == 'algolia') {
        // v-model
        if (filter.multiple) {
          this.$set(this.model, filter.algoliaIndex, [])
        } else {
          this.$set(this.model, filter.algoliaIndex, null)
        }

        // prop items 
        this.$set(this.items, filter.algoliaIndex, [])

        
        // prop item-text
        filter.itemText = (item) => {
          if (!filter.fieldsToShow)
            return 'id'
          
          let str = item[filter.fieldsToShow[0]]
          filter.fieldsToShow.slice(1).forEach(field => {
            str = str + ' - ' + item[field]
          })

          return str
        }
        
        // prop no-data-text
        filter.noDataTextDefault = 'Digite algo para pesquisar'
        filter.noDataText = filter.noDataTextDefault

        // prop loading
        filter.loading = false
        
        if (filter.multiple) {
          filter.chips = true
          filter.deletableChips = true
          filter.smallChips = true
          filter.formChips = 1
        }
      }
      else if (filter.source == 'input') {
        filter.values = filter.values.sort()
      }
      else if (filter.markers) {
        const { markers } =filter
        let markersObj = Object.keys(markers)
        .filter(m => m.trim() !== 'mobile')
        .map(m => m.toUpperCase())
        
        filter.items = markersObj.map(m => ({ value: m, text: m}))
        .sort((m1, m2) => m1.value > m2.value ? 1 : m2.value > m1.value ? -1 : 0)
        // filter.values = filter.markers
        filter.source = 'select'
      }
    })
    this.resetAuxData()
  },
}
</script>

<style>
  .activeFilterChip .v-chip__content {
    position: relative!important;
    padding-left: 15px;
  }
  .activeFilterChip .v-chip__content button {
    position: absolute;
    left: -12px;
  }
</style>