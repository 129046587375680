<template>
  <section>
    <v-data-table
      v-model="selecteds"
      :headers="opt.headers"
      :items="items"
      :show-select="false/*opt.showSelect*/"
      :single-select="opt.singleSelect"
      :item-key="opt.itemKey"
      @update:page="updatePage"
      @update:items-per-page="updateitemsPerPage"
      :class="`${opt.classes} mb-3`"
      :loading="
        loading && searchInput
          ? loading[`${register}Filtered`]
          : loading[register]
      "
      :footer-props="{ itemsPerPageOptions: [15, 30, 60] }"
      loading-text="Carregando... Aguarde por favor."
      dense
    >
      <!-- TOP -->
      <template v-slot:top>
        <v-toolbar flat color="white">
          <!-- Search -->
          <template>
            <v-text-field
              v-if="noServerSide"
              v-model="searchInput"
              ref="noServerSideSearchInput"
              dense
              outlined
              clearable
              hide-details
              height="40"
              label="Digite para pesquisar"
              class="mw-20"
            >
            </v-text-field>
  
            <v-text-field-external
              v-else-if="externalRequest && externalRequest.query"
              v-model="searchInput"
              :externalRequest="externalRequest"
              :handler="search"
              :delay="750"
              :filters="computedFilters"
              :fieldsToSearch="opt.headers.filter((x) => x.searchable).map((x) => x.value)"
              :register="register"
              ref="externalSearchInput"
              dense
              clearable
              hide-details
              height="40"
              label="Digite para pesquisar"
              class="mw-20"
            >
            </v-text-field-external>
  
            <v-text-field-algolia
              v-else-if="searchInputAlgolia"
              v-model="searchInput"
              :index="collection"
              :handler="search"
              :delay="750"
              :facetFilters="computedFilters"
              :attributesToHighlight="opt.headers.map((x) => x.value)"
              ref="algoliaSearchInput"
              outlined
              dense
              clearable
              hide-details
              height="40"
              label="Digite para pesquisar"
              class="mw-20"
            >
            </v-text-field-algolia>
  
            <v-text-field-async
              v-else
              v-model="searchInput"
              :collection="collection"
              :handler="search"
              :delay="750"
              :filters="computedFilters"
              :fieldsToSearch="opt.headers.filter((x) => x.searchable)"
              :register="register"
              ref="asyncSearchInput"
              dense
              clearable
              hide-details
              height="40"
              label="Digite para pesquisar"
              class="mw-20"
            >
            </v-text-field-async>
          </template>
  
          <v-btn
            v-if="computedDataFilters && computedDataFilters.length > 0"
            height="40"
            small
            text
            outlined
            class="ml-2"
            @click="openFilter = !openFilter"
            >Filtros <v-icon right>mdi-filter-menu</v-icon></v-btn
          >
  
          <v-spacer />
  
          <slot name="buttonsToolbar" />
  
          <v-btn
            v-if="claims.add"
            :to="`/${register}/cadastrar`"
            width="35"
            height="35"
            fab
            dark
            color="indigo darken-2"
            class="mr-2"
            ><v-icon size="20">mdi-plus</v-icon></v-btn
          >
  
          <!-- BUNDLE -->
          <template v-if="bundleActions">
            <v-divider class="mx-4" inset vertical></v-divider>
  
            <!-- EXCLUDE -->
            <v-dialog
              v-if="claims.delete"
              v-model="dialogBundleRemove"
              persistent
              max-width="500"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="error"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  @click="importer()"
                  small
                  >Deletar {{ selecteds.length }} itens</v-btn
                >
              </template>
              <v-card>
                <v-card-title class="headline">
                  Tem certeza que quer deletar os {{ selecteds.length }} itens
                  selecionados?
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text @click="dialogBundleRemove = false">Cancelar</v-btn>
                  <v-btn color="error" text @click="removeItens()">DELETAR</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
  
          <v-dialog v-model="dialogRemoveItem" persistent max-width="500">
            <v-card>
              <v-card-title class="headline">
                Tem certeza que quer deletar o item?
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="closeDialogRemoveItem()">Cancelar</v-btn>
                <v-btn color="error" text @click="removeItem(itemToRemove)"
                  >DELETAR</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
  
        <!-- FILTERS -->
        <v-expand-transition
          v-if="computedDataFilters && computedDataFilters.length > 0"
        >
          <v-row v-show="openFilter" no-gutters class="mb-4">
            <v-col cols="12">
              <DataTableFilters
                ref="DataTableFilters"
                :filters="computedDataFilters"
                v-on:change="updateSearch"
              />
            </v-col>
          </v-row>
        </v-expand-transition>
      </template>
  
      <!-- BODY - ROW -->
      <template v-slot:item="{ headers, item, isSelected, select }">
        <tr>
          <td v-for="head in headers" :key="head.value" :class="head.tdClass">
            <!-- SELECT ITEM -->
            <template v-if="head.value == 'data-table-select'">
              <v-simple-checkbox
                color="indigo accent-4"
                :value="isSelected"
                @input="select($event)"
              ></v-simple-checkbox>
            </template>
  
            <!-- STATUS -->
            <template v-else-if="head.value == 'data-table-status'">
              <v-tooltip v-if="item.errorIntegration" top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon size="20" color="error" v-bind="attrs" v-on="on"
                    >mdi-close</v-icon
                  >
                </template>
                <span>Erro na sincronia</span>
              </v-tooltip>
              <v-tooltip v-else-if="item.aguardando" top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon size="20" v-bind="attrs" v-on="on"
                    >mdi-clock-outline</v-icon
                  >
                </template>
                <span>Aguardando</span>
              </v-tooltip>
              <v-tooltip
                v-else-if="
                  proccessItem({
                    item,
                    head: { value: 'lifeControl.integratedAt' },
                  }) == null
                "
                top
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon size="20" color="success" v-bind="attrs" v-on="on"
                    >mdi-check</v-icon
                  >
                </template>
                <span>Gravado</span>
              </v-tooltip>
              <v-tooltip v-else top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon size="20" color="indigo" v-bind="attrs" v-on="on"
                    >mdi-check-all</v-icon
                  >
                </template>
                <span>Sincronizado</span>
              </v-tooltip>
            </template>
  
            <!-- ACTIONS -->
            <template v-else-if="head.value == 'data-table-actions'">
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <v-btn
                    v-if="claims.view"
                    :to="`/${register}/detalhes/${item.id}`"
                    icon
                    x-small
                    color="success lighten-1"
                    class="mr-2 no-hover"
                    v-on="on"
                    v-bind="attrs"
                  >
                    <v-icon small>mdi-eye</v-icon>
                  </v-btn>
                </template>
                Visualizar
              </v-tooltip>

              <v-tooltip v-if="claims.edit && !isBlocked({ item })" top>
                <template #activator="{ on, attrs }">
                  <v-btn
                    :to="`/${register}/editar/${item.id}`"
                    icon
                    x-small
                    color="indigo accent-4"
                    class="mr-2 no-hover"
                    v-on="on"
                    v-bind="attrs"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                Editar
              </v-tooltip>

              <v-tooltip
                v-if="
                  claims.add 
                  && !claims.disableBonus
                  && register.includes('SC5') 
                  && item.C5_NUM 
                  && !item.C5_XPEDORI
                "
                top
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    :to="`/${register}/bonificar/${item.id}`"
                    icon
                    x-small
                    color="indigo accent-4"
                    class="mr-2 no-hover"
                    v-on="on"
                    v-bind="attrs"
                  >
                    <v-icon small>mdi-gift</v-icon>
                  </v-btn>
                </template>
                Bonificar
              </v-tooltip>

              <!-- <v-tooltip v-if="claims.copy" top>
                <template #activator="{ on, attrs }">
                  <v-btn
                    :to="`/${register}/copiar/${item.id}`"
                    icon
                    x-small
                    color="indigo accent-4"
                    class="mr-2 no-hover"
                    v-on="on"
                    v-bind="attrs"
                  >
                    <v-icon small>mdi-content-copy</v-icon>
                  </v-btn>
                </template>
                Copiar
              </v-tooltip> -->
              <v-tooltip 
                v-if="
                  claims.delete
                  && !isBlocked({ item })
                  && item.status
                  && ['rascunho', 'erro no envio'].includes(item.status.toLowerCase())
                "
                top>
                <template #activator="{ on, attrs }">
                  <v-btn
                    @click="confirmRemove(item)"
                    icon
                    x-small
                    color="error darken-1"
                    class="ml-n1 mr-2 no-hover"
                    v-on="on"
                    v-bind="attrs"
                  >
                    <v-icon small>mdi-delete</v-icon>
                  </v-btn>
                </template>
                Remover
              </v-tooltip>
              <v-tooltip
                v-if="claims.delete && !isBlocked({ item }) && head.dispatch"
                top
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    @click="
                      dispatchAction({
                        action: head.dispatch.action,
                        params: proccessParams({
                          params: head.dispatch.params,
                          item,
                        }),
                      })
                    "
                    icon
                    x-small
                    color="indigo accent-4"
                    class="ml-n1 mr-2 no-hover"
                    v-on="on"
                    v-bind="attrs"
                  >
                    <v-icon small>{{ head.dispatch.icon }}</v-icon>
                  </v-btn>
                </template>
                {{ head.dispatch.text }}
              </v-tooltip>
  
              <!-- CUSTOM ACTIONS -->
              <v-tooltip
                v-for="(cAction, idx) of customActions"
                :key="`customActionBtn-${idx}`"
                top
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    v-show="showAction({ item, customAction: cAction })"
                    @click="clickAction({ item, customAction: cAction })"
                    icon
                    x-small
                    :color="cAction.color"
                    class="ml-n1 mr-2 no-hover"
                    v-on="on"
                    v-bind="attrs"
                  >
                    <v-icon small>{{ cAction.icon }}</v-icon>
                  </v-btn>
                </template>
                {{ cAction.name }}
              </v-tooltip>
              
              <!-- CustomerPosition -->
              <CustomerPosition
                v-if="['SA1','SC5'].includes(register) && (register === 'SA1') ? item.id : ((item.SA1 && item.SA1.id) || (item.C5_CLIENTE && item.C5_LOJACLI))"
                :model="register === 'SA1' ? item : (item.SA1) ? item.SA1 : (item.C5_CLIENTE && item.C5_LOJACLI) ? {collection: 'SA1', id: item.C5_CLIENTE+item.C5_LOJACLI} : {}"
                :key="item.id"
                :titleButton="'Posição de Cliente'"
                :iconButton="true"
                :inputsHeaders="customerPositionConfigs.inputsHeaders"
                :inputsSubHeaders="customerPositionConfigs.inputsSubHeaders">
                <template #buttonContent>
                  <v-icon size=18 class="mr-1">mdi-account-card</v-icon>
                </template>              
              </CustomerPosition>
            </template>
  
            <!-- Markers Chips -->
            <template v-else-if="head.markers && head.chips">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    v-bind="attrs"
                    v-on="on"
                    class="text-capitalize w-100 justify-center"
                    dark
                    small
                    :color="head.markers[
                        proccessItem({
                          item,
                          head: { ...head, lower: true, trim: true },
                        })
                      ] || 'transparent'"
                    >
                    {{
                      stringReducer(
                        proccessItem({
                          item,
                          head: { ...head, lower: true, trim: true },
                        }),
                        head.stringMax
                      )
                    }}
                    </v-chip
                  >
                </template>
                <span class="text-capitalize">{{
                  proccessItem({
                    item,
                    head: { ...head, lower: true, trim: true },
                  })
                }}</span>
              </v-tooltip>
            </template>
  
            <!-- Markers -->
            <v-tooltip v-else-if="head.markers" top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon>
                  <v-badge
                    v-bind="attrs"
                    v-on="on"
                    inline
                    :color="
                      head.markers[
                        proccessItem({
                          item,
                          head: { ...head, lower: true, trim: true },
                        })
                      ] || 'transparent'
                    "
                    class="small"
                  />
                </v-btn>
              </template>
              <span class="text-capitalize">{{
                proccessItem({ item, head: { ...head, lower: true, trim: true } })
              }}</span>
            </v-tooltip>
  
            <!-- CHECKBOX -->
            <template v-else-if="head.checkbox">
              <v-simple-checkbox
                color="indigo accent-4"
                :value="proccessItem({ item, head })"
                @input="toggleCk({ item, head })"
                :disabled="
                  typeof head.disabled == 'string'
                    ? item[head.disabled]
                    : head.disabled
                "
              ></v-simple-checkbox>
            </template>
  
            <!-- CHIPS -->
            <template v-else-if="head.chips">
              <span
                v-for="(tag, ix) in proccessItem({ item, head })"
                :key="tag + ix"
              >
                <v-chip
                  v-if="ix < (head.dataTableChips || dataTableChips)"
                  small
                  color="indigo accent-4"
                  dark
                  class="mr-1 mb-1"
                >
                  {{ tag }}
                </v-chip>
                <span
                  v-if="ix == (head.dataTableChips || dataTableChips)"
                  class="grey--text caption"
                  >(+{{
                    proccessItem({ item, head }).length -
                    (head.dataTableChips || dataTableChips)
                  }}
                  outro(s))</span
                >
              </span>
            </template>
  
            <!-- COPY ITEM -->
            <template v-else-if="head.copy">
              <v-btn
                x-small
                fab
                icon
                color="indigo"
                class="mr-1"
                :key="proccessItem({ item, head, copy: true })"
                v-clipboard:copy="proccessItem({ item, head, copy: true })"
                v-clipboard:success="clipboardCopy"
                v-clipboard:error="clipboardError"
              >
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
              <span
                v-if="!head.hide"
                v-html="proccessItem({ item, head })"
              ></span>
            </template>
  
            <!-- CLICK ITEM -->
            <template v-else-if="head.click">
              <v-btn
                outlined
                x-small
                fab
                color="indigo"
                class="no-hover mr-1"
                v-show="showValueColumn({ item, head })"
                :href="proccessItem({ item, head })"
                target="_blank"
              >
                <v-icon>mdi-cursor-default-click-outline</v-icon>
              </v-btn>
            </template>
  
            <!-- AVATAR -->
            <template v-else-if="head.avatar">
              <v-list-item-avatar>
                <img
                  v-if="proccessItem({ item, head })"
                  alt="Avatar"
                  :src="proccessItem({ item, head })"
                />
                <v-gravatar v-else default-img="mm" />
              </v-list-item-avatar>
            </template>
  
            <template v-else-if="head.type == 'images'">
              <v-avatar size="40">
                <img
                  class="hover"
                  v-if="proccessItem({ item, head })"
                  :src="proccessItem({ item, head })"
                  alt="Avatar"
                  @click="callDialog({ item, head })"
                />
                <v-gravatar v-else default-img="blank" />
              </v-avatar>
            </template>
  
            <!-- OTHERS -->
            <template v-else>
              <span v-html="proccessItem({ item, head })"></span>
            </template>
          </td>
        </tr>
      </template>
  
      <template
        v-if="opt.serverItemsLength"
        v-slot:[`footer.page-text`]="{ pageStart, pageStop }"
      >
        {{ pageStart }} - {{ pageStop }} de {{ registersCount }}
      </template>
    </v-data-table>

    <v-dialog v-model="jsPdf.dialog" @update:return-value="jsPdf.doc = null" scrollable>
      <v-card>
        <v-toolbar flat>
          <v-card-title class="pl-0">Pré-visualização do documento</v-card-title>
          <v-spacer/>
          <v-btn @click="savePdf()" height=40 x-small outlined color="warning" class="mr-2 text-caption">
            <v-icon size=20 class="mr-1">mdi-file-download-outline</v-icon>
            Download
          </v-btn>
          <v-dialog
            v-model="jsPdf.sendEmail.dialog"
            @input="jsPdfHandleDialog"
            max-width="500vw">
            <template #activator="{ attrs, on }">
              <v-btn
                height=40
                x-small
                outlined
                color="success"
                class="mr-2 text-caption"
                v-bind="attrs"
                v-on="on"
                >
                <v-icon size=20 class="mr-1">mdi-email-send-outline</v-icon>
                Enviar por Email
              </v-btn>
            </template>
            
            <Form
              :inputs="jsPdf.sendEmail.inputs"
              :model="jsPdf.sendEmail.model"
              @submit="sendPdf"
            >
              <template #prepend>
                <v-card-title>Enviar documento PDF por email</v-card-title>
                <v-divider/>
              </template>
              <template #append>
                <v-divider/>
                <v-card-actions>
                  <v-spacer/>
                  <v-btn
                    :loading="jsPdf.sendEmail.sending"
                    :disabled="jsPdf.sendEmail.sending"
                    type="submit"
                    class="text-caption"
                    color="success"
                    height=40
                    x-small
                    outlined
                    >
                    <v-icon size=20 class="mr-1">mdi-send</v-icon>
                    Enviar
                  </v-btn>
                  <v-btn @click.stop="jsPdf.sendEmail.dialog = false" height=40 x-small outlined color="error" class="text-caption">
                    <v-icon size=18 class="mr-1">mdi-cancel</v-icon>
                    Cancelar
                  </v-btn>
                </v-card-actions>
              </template>
            </Form>
          </v-dialog>
          <v-btn @click.stop="jsPdf.dialog = false" height=40 x-small outlined color="error" class="text-caption">
            <v-icon size=18 class="mr-1">mdi-cancel</v-icon>
            Cancelar
          </v-btn>
        </v-toolbar>
        <v-divider/>
        <Iframe v-if="!!jsPdf.doc || !!jsPdf.blob || !!jsPdf.objectStorage" :src="jsPdfOutput" type="application/pdf"/>
        <!-- <Iframe v-else-if="!!jsPdf.blob" :src="jsPdfOutput" type="application/pdf"/> -->
        <v-divider/>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { Cell } from "../../plugins/cell";
import VTextFieldAlgolia from "../VTextFieldAlgolia.vue";
import VTextFieldExternal from "../VTextFieldExternal.vue";
import VTextFieldAsync from "../VTextFieldAsync.vue";
import DataTableFilters from "./DataTableFilters.vue";
import CustomerPosition from "@/components/Registers/CustomerPosition"
import {
  createNamespacedHelpers,
  mapActions,
  mapState,
  mapMutations
} from "vuex";
import Iframe from "@/components/Templates/Iframe"
import { jsPDF } from "jspdf"
import "jspdf-autotable"
import * as fs from 'file-saver'
const { mapState: registersState, mapActions: registersActions } =
  createNamespacedHelpers("registers");
const { mapState: authState } = createNamespacedHelpers("auth");
import algoliasearch from "algoliasearch/lite";
export default {
  name: "DataTable",
  events: ["showCarousel"],
  components: {
    VTextFieldAlgolia,
    VTextFieldExternal,
    VTextFieldAsync,
    DataTableFilters,
    CustomerPosition,
    Iframe
  },
  props: {
    opt: Object,
    collection: String,
    register: String,
    filters: Array,
    details: Array,
    claims: Object,
    blockedField: String,
    blockedFunction: Function,
    externalRequest: Object,
    searchInputAlgolia: Boolean,
    integration: Boolean,
    noServerSide: Boolean,
    dataFilters: {
      type: Array,
      default: () => [],
    },
    inputsMaster: {
      type: Array,
      default: () => []
    },
    customActions: Array,
  },
  data() {
    return {
      jsPdf: {
        doc: null,
        dialog: false,
        sendEmail: {
          sending: false,
          dialog: false,
          inputs: [
            { type: 'text-field', value: 'to', label: "Email de Destino" },
            { type: 'text-field', value: 'cc', label: "Cc", md: 6 },
            { type: 'text-field', value: 'bcc', label: "Cco", md: 6 },
            { type: 'text-field', value: 'subject', label: "Assunto" },
            { type: 'textarea', value: 'text',  label: "Corpo do Email", rows: 4 },
          ],
          model: null,
        },
        blob: null
      },
      avatars: {},
      openFilter:
        this.$route.params.filter && this.$route.params.filter.length > 0,
      dialog: false,
      dialogImporter: false,
      dialogRemoveItem: false,
      selected: [],
      saveValid: true,
      importValid: true,
      selecteds: [],
      bundleActions: false,
      dialogBundleRemove: false,
      itemLoaded: {},
      itemComputed: {},
      itemToRemove: {},
      showPassword: false,
      method: "new",
      searchAutocomplete: null,
      loadingAutocomplete: false,
      querySelection: null,
      dataTableChips: 1,
      formChips: 4,
      vjsf: {},
      searchInput: "",
      searchResult: [],
      filtersComponent: [],
      filterItems: {},
      imagesDialog: false,
      chipsExpanded: true,
      customerPositionConfigs: {
        show: true,
        inputsHeaders: []
      },
      itensPerPage: 15,
    };
  },
  computed: {
    ...registersState(["registers", "counters", "loading"]),
    ...mapState(["algolia", "companySelected"]),
    ...authState(["user"]),

    jsPdfOutput() {
      if (this.jsPdf.doc)
        return this.jsPdf.doc.output('datauristring')
      else if (this.jsPdf.blob){
        // const base64 = await this.blobTo64({blob: this.jsPdf.blob}) 
        return URL.createObjectURL(this.jsPdf.blob)
      }
      else if (this.jsPdf.url) 
        return this.jsPdf.url

      return null
    },

    computedShowCustomerPosition() {
      return this.customerPositionConfigs['show']
    },

    items() {
      let items = [];

      if (this.noServerSide) {
        const searcher = this.searchInput.toLowerCase().trim();
        const { headers } = this.opt;
        items = this.opt.items
          .filter((i) =>
            headers
              .filter((h) => h.searchable)
              .map((h) => (i[h.value] + "").toLowerCase().trim())
              .join("")
              .includes(searcher)
          )
          .map((i) => {
            for (const h of headers.filter((h) => h.searchable)) {
              i[h.value] = (i[h.value] + "")
                .toUpperCase()
                .trim()
                .replace(searcher.toUpperCase, `${searcher.toUpperCase()}`);
            }
            return i;
          });
      } else if (this.externalRequest && this.externalRequest.query) {
        items =
          (this.searchInput && this.searchInput.length > 0) ||
          (this.filtersComponent && this.filtersComponent.length > 0)
            ? this.registers[`${this.register}Filtered`]
            : this.registers[this.register] || [];
      } else if (this.searchInputAlgolia) {
        items =
          (this.searchInput && this.searchInput.length > 0) ||
          (this.filtersComponent && this.filtersComponent.length > 0)
            ? this.searchResult
            : this.opt.items || [];
      } else {
        items =
          (this.searchInput && this.searchInput.length > 0) ||
          (this.filtersComponent && this.filtersComponent.length > 0)
            ? this.registers[`${this.register}Filtered`]
            : this.opt.items || [];
      }
      return items;
    },
    registersCount() {
      if (this.searchInputAlgolia) {
        if (
          (this.searchInput && this.searchInput.length > 0) ||
          (this.filtersComponent && this.filtersComponent.length > 0)
        ) {
          return this.searchResult?.length || 0;
        } else {
          return this.counters[this.register] || 0;
        }
      } else {
        return (this.searchInput && this.searchInput.length > 0) ||
          (this.filtersComponent && this.filtersComponent.length > 0)
          ? this.counters[`${this.register}Filtered`]
          : this.counters[this.register] || 0;
      }
    },
    computedFilters() {
      return this.filtersComponent
    },
    computedDataFilters() {
      let filters = this.opt.allHeaders || this.opt.headers
      filters = filters 
        .filter((x) => x.filterable)
        .concat(
          this.dataFilters.filter((x) => !x.delete).map((f) => f.data || f)
        ).sort((a,b) => a.filterIndex-b.filterIndex);

      if (!this.user) return null;

      if (this.companySelected.groupCompany == 'bricoflex') {
        filters.push({
          filterKey: "hideSketch",
          label: "Esconder Rascunho",
          source: "switch"
        })
      }
      
      const filtersReturn = this.user.superAdmin ||
      this.user.companies[this.companySelected.groupCompany].admin ? 
      filters : 
      filters.filter((filter) => 
        filter.filterable?.userRules?.some((ur) => this.userRules.some(uUR => uUR.id === ur)) ||
        filter.userRules?.some((ur) => this.userRules.some(uUR => uUR.id === ur))
      );

      return filtersReturn
    },

    userRules() {
      if (this.user.superAdmin) {
        return this.computedUserRules
      } else if (!this.user.companies) {
        return []
      }
      return this.computedUserRules.filter(ur => 
        this.user.companies &&
        this.user.companies[this.companySelected.groupCompany] &&
        this.user.companies[this.companySelected.groupCompany]['rules'] && 
        this.user.companies[this.companySelected.groupCompany]['rules'].includes(ur.id)
      )
    },
    computedUserRules () {
      return this.registers['userRules']
    },
    computedChipsExpanded() {
      return this.chipsExpanded;
    },
  },
  watch: {
    selecteds: {
      handler: function (newValue) {
        this.bundleActions = newValue.length > 1;
      },
    },
    itemLoaded: {
      handler: function (newValue) {
        const inputs = this.opt.form.inputs;
        const jsoneditorValues = inputs
          .filter((x) => x.type == "jsoneditor")
          .map((x) => x.value);
        jsoneditorValues.forEach((el) => {
          if (!newValue[el]) return;
          this.itemComputed[el] = this.$_.cloneDeep(newValue[el]);
          const properties = Object.fromEntries(
            Object.entries(this.itemComputed[el].properties).sort(
              ([, a], [, b]) => a.order - b.order
            )
          );
          this.itemComputed[el].properties = properties;
        });
      },
      deep: true,
    },
    "$route.params.filter": {
      handler: function (newValue) {
        if (newValue && newValue.length > 0) {
          this.openFilter = true;
          this.$refs.DataTableFilters.setFilter(newValue.split("+"));
        } else {
          this.openFilter = false;
        }
      },
      deep: true,
    },
  },
  created() {
    const inputsHeaders = this.inputsMaster.filter(input => ['A1_COD', 'A1_LOJA', 'A1_NOME', 'A1_CGC', 'A1_TEL', 'A1_VENCLC', 'A1_VEND'].includes(input.value))
    const inputsSubHeaders = this.inputsMaster.filter(input => ['A1_ULTCOM', 'A1_PRICOM', 'A1_RISCO', 'A1_DTULCHQ', 'A1_DTULTIT', 'A1_LC', 'A1_SALDUP', 'A1_LCFIN', 'A1_CHQDEVO', 'A1_SALFIN', 'A1_SALFINM', 'A1_MCOMPRA', 'A1_MSALDO', 'A1_MATR', 'A1_METR', 'A1_TITPROT', 'A1_SL_DISPONIVEL'].includes(input.value))    
    this.customerPositionConfigs = {...this.customerPositionConfigs, inputsSubHeaders, inputsHeaders}
    this.dataFilters.forEach(async (filter) => {
      if (filter.source == "collection") {
        /** @TODO */
        const algoliaClient = algoliasearch(
          this.algolia?.appId,
          this.algolia?.searchApiKey
        );
        const algoliaIndex = algoliaClient.initIndex(filter.collectionName);
        try {
          filter.loading = true;
          const response = await algoliaIndex.search();

          if (response?.hits) {
            filter.values = response.hits.map(
              (hit) => hit[filter.collectionField]
            );
          } else {
            console.warn("Error in algolia search on filter " + filter.name);
            filter.values = [];
          }
        } catch (err) {
          console.error(err);
        } finally {
          filter.loading = false;
        }
      } else if (filter.source == "algolia") {
        // prop items
        this.$set(this.filterItems, filter.algoliaIndex, []);

        // prop item-text
        filter.itemText = (item) => {
          if (!filter.fieldsToShow) return "id";

          let str = item[filter.fieldsToShow[0]];
          filter.fieldsToShow.slice(1).forEach((field) => {
            str = str + " - " + item[field];
          });

          return str;
        };

        // prop no-data-text
        filter.noDataTextDefault = "Digite algo para pesquisar";
        filter.noDataText = filter.noDataTextDefault;
      }
    });
  },
  methods: {
    callDialog({ item, head }) {
      this.$emit("showCarousel", { item, head });
    },
    ...mapActions(["dispatchAction"]),
    ...registersActions([
      "save",
      "remove",
      "import",
      "nextObserver",
      "nextOnMongo",
      "getDetails",
      "get",
      "getById",
      "getApi",
      "removeBundle",
      "getExternal",
    ]),
    ...mapMutations(["setLoading"]),

    showValueColumn({ item, head }) {
      if (typeof head.hide === "string") {
        return !item[head.hide];
      } else if (typeof head.hide === "function") {
        const hide = head.hide({ item });
        return !hide;
      }

      return !head.hide;
    },

    isBlocked({ item }) {
      if (this.blockedField) return item[this.blockedField];

      if (this.blockedFunction) return this.blockedFunction({ item });

      return false;
    },

    showAction({ item, customAction }) {
      const showAction = eval(customAction.hide);
      return typeof showAction === "function"
        ? !showAction({ item })
        : !showAction;
    },

    clickAction({ item, customAction }) {
      const self = {
        item,
        $router: this.$router,
        user: this.user,
        $toast: this.$toast,
        $api: this.$api,
        ...this,
      };
      const actionRunner = eval(customAction.actionRunner);
      actionRunner(self);
    },
    initialize() {
      const inputs = this.opt.form.inputs;
      inputs.forEach((el, ix) => {
        this.$set(inputs[ix], "showPassword", false);
      });
    },
    updatePage(e) {
      if (e === 1) return;
      if (this.externalRequest && this.externalRequest.query) {
        this.getExternal({
          ...this.externalRequest,
          register: this.register,
          page: e,
          nextPage: true,
          search: this.searchInput,
          fieldsToSearch: this.opt.headers
            .filter((x) => x.searchable)
            .map((x) => x.value),
          filters: this.filtersComponent,
        });
      } else if ((this.searchInput && this.searchInput.length > 0) || (this.filtersComponent && this.filtersComponent.length > 0)) {
        this.nextOnMongo({register: `${this.register}Filtered`})
      } else {
        this.nextObserver({ register: this.register, limit: this.itensPerPage*2 });
      }
    },
    updateitemsPerPage(e) {
      this.itensPerPage = e
      this.updatePage(-1)
    },
    selectAll({ items, value, itemValue }) {
      this.$nextTick(() => {
        if (
          items &&
          this.itemLoaded[value] &&
          this.itemLoaded[value].length >= items.length
        ) {
          this.$set(this.itemLoaded, value, []);
        } else if (items) {
          this.$set(
            this.itemLoaded,
            value,
            itemValue ? items.map((x) => x[itemValue]).slice() : items.slice()
          );
        }
      });
    },
    querySelections(query, { collection, register, filters, orderBy }) {
      clearTimeout(this.querySelection);
      this.querySelection = setTimeout(async () => {
        if (!query || !collection || !register || !orderBy) return;
        this.loadingAutocomplete = true;
        this.get({ collection, register, orderBy, filters, query }).then(
          () => (this.loadingAutocomplete = false)
        );
      }, 500);
    },
    proccessItem({ item, head, copy = false }) {
      let ret = this.$_.cloneDeep(item);
      if (!ret) return;
      const self = {
        registers: this.registers,
        item,
      };
      if (
        ret._highlightResult &&
        ret._highlightResult[head.value] &&
        ret._highlightResult[head.value].matchedWords &&
        ret._highlightResult[head.value].matchedWords.length > 0
      )
        ret = ret._highlightResult;

      /* NESTED */
      const pathItem = head.value.split(".");
      const lenItem = pathItem.length;
      if (lenItem == 1) {
        ret = ret[pathItem];
      } else {
        for (let ix = 0; ix < pathItem.length; ix++) {
          const el = pathItem[ix];
          if (ret && typeof ret[el] != "undefined") {
            ret = ret[el];
          } else {
            ret = head.checkbox ? false : "";
            break;
          }
        }
      }

      if (head.computedList) {
        const headComputed = eval(head.computedList);
        ret = headComputed(self);
      }

      /* ASSIST */
      const detail = head.detail;
      if (detail && ret) {
        const pathAssist = detail.split(".");
        const lenAssist = pathAssist.length;
        if (Array.isArray(ret)) {
          ret.forEach((el, ix) => {
            let retEl = this.registers[pathAssist[0]]
              ? this.registers[pathAssist[0]].find((x) => x.id === el)
              : null;
            if (retEl) {
              for (let i = 1; i < lenAssist; i++) {
                const el2 = pathAssist[i];
                retEl = retEl ? retEl[el2] : "";
              }
              ret[ix] = retEl;
            }
          });
        } else {
          let retEl = this.registers[pathAssist[0]]
            ? this.registers[pathAssist[0]].find((x) => x.id === ret)
            : null;
          for (let i = 1; i < lenAssist; i++) {
            const el = pathAssist[i];
            retEl = retEl ? retEl[el] : "";
          }
          ret = retEl;
        }
      }

      /* Images */
      if (head.type == "images") {
        return ret && Array.isArray(ret) && ret.length > 0 ? ret[0].base64 : "";
      }

      /* FORMATS */
      if (!ret) {
        return;
      }

      if (ret && ret.value) {
        ret = ret.value;
      }

      if (head.slice && !copy) {
        ret = ret.slice(head.slice);
      }

      if (head.upper) {
        ret = ret.toUpperCase();
      }

      if (head.lower) {
        ret = ret.toLowerCase();
      }

      if (head.trim) {
        ret = ret.trim();
      }

      /* FORMAT DATES */
      if (head.date || head.dateHour || head.dateYear || head.datePlusWeek) {
        if (Object.prototype.hasOwnProperty.call(ret, "toDate")) {
          ret = ret.toDate();
        } else if (ret.seconds && ret.nanoseconds) {
          // Retorno via Algolia não contem metodo toDate (agora o firebase tbm)
          ret = new this.$db.Timestamp(ret.seconds, ret.nanoseconds).toDate();
          // Retorno via Algolia não contem metodo toDate. (agora tem o underline na frente)
        } else if (ret._seconds && ret._nanoseconds) {
          ret = new this.$db.Timestamp(ret._seconds, ret._nanoseconds).toDate();
        }
      }

      if (head.date) {
        if (typeof ret === "string" && ret.trim() === "") {
          return;
        }
        ret = this.$moment(ret).format("DD/MM/YYYY");
      }

      if (head.dateHour) {
        ret = this.$moment(ret).format("DD/MM/YYYY - HH:mm:ss");
      }

      if (head.dateYear) {
        ret = this.$moment(ret).format("YYYY");
      }

      if (head.datePlusWeek) {
        ret = this.$moment(ret).format("DD/MM/YYYY, dddd");
      }

      if (head.money) {
        if (typeof ret == 'string') ret = parseFloat(ret)
        ret =
          "R$ " +
          ret.toLocaleString("pt-br", {
            minimumFractionDigits: head.precisionText || 2,
            maximumFractionDigits: head.precisionText || 2,
          });
      }

      return ret;
    },
    proccessParams({ item, params }) {
      const ret = {};
      for (const key in params) {
        if (Object.prototype.hasOwnProperty.call(params, key)) {
          const el = params[key];
          const pathItem = el.split(".");
          const lenItem = pathItem.length;
          if (lenItem == 1) {
            ret[key] = pathItem[0];
          } else {
            for (let ix = 1; ix < pathItem.length; ix++) {
              const el2 = pathItem[ix];
              if (typeof item[el2] != "undefined") {
                ret[key] = item[el2];
              } else {
                ret[key] = "";
                break;
              }
            }
          }
        }
      }
      return ret;
    },
    newItem() {
      this.itemLoaded = {};
      this.method = "new";
      const inputs = this.opt.form.inputs;
      inputs.forEach((el) => {
        if (el.default) {
          this.itemLoaded[el.value] = el.default;
        }
      });
    },
    importer() {
      this.itemLoaded = {};
      this.method = "import";
    },
    closeDialog() {
      this.dialog = false;
    },
    closeDialogImporter() {
      this.dialogImporter = false;
    },
    clipboardCopy: function (e) {
      this.$toast.success("Copiado");
    },
    clipboardError: function (e) {
      this.$toast.error("Falha ao copiar");
    },
    toggleCk({ item, head }) {
      const pathItem = head.value.split(".");
      const lenItem = pathItem.length;
      let schema = item;
      for (let i = 0; i < lenItem - 1; i++) {
        const el = pathItem[i];
        if (!schema[el]) schema[el] = {};
        schema = schema[el];
      }
      schema[pathItem[lenItem - 1]] = !schema[pathItem[lenItem - 1]];

      this.saveItem(item);
    },
    removeChip({ value, item, itemValue }) {
      const ix = this.itemLoaded[value].findIndex((x) => x == item[itemValue]);
      if (ix > -1) this.itemLoaded[value].splice(ix, 1);
    },
    editItem(item) {
      // Details
      if (this.details) {
        this.details.forEach((detail) => {
          this.getDetails({
            item: this.$_.cloneDeep(item),
            detail: this.$_.cloneDeep(detail),
            endSlice: this.formChips + 1,
          });
        });
      }

      // Order Json
      const inputs = this.opt.form.inputs;
      const jsoneditorValues = inputs
        .filter((x) => x.type == "jsoneditor")
        .map((x) => x.value);
      jsoneditorValues.forEach((el) => {
        if (!item[el]) return;
        const properties = Object.fromEntries(
          Object.entries(item[el].properties).sort(
            ([, a], [, b]) => a.order - b.order
          )
        );
        item[el].properties = properties;
      });

      // Load Item
      this.itemLoaded = this.$_.cloneDeep(item);
      this.method = "edit";
      this.dialog = true;
    },
    saveItem(item) {
      if (this.$refs.save && !this.$refs.save.validate()) return;

      // Dispatch Before
      if (this.opt.dispatch) {
        this.opt.dispatch
          .filter((x) => x.when == "before" && x.method == this.method)
          .forEach((el) => {
            if (!el.switch || item[el.switch]) {
              this.dispatchAction({
                action: el.action,
                params: this.proccessParams({ params: el.params, item }),
              });
            }
          });
      }

      this.save({ collection: this.collection, item, integration: this.integration })
        .then(() => {
          // Dispatch After
          if (this.opt.dispatch) {
            this.opt.dispatch
              .filter((x) => x.when == "after" && x.method == this.method)
              .forEach((el) => {
                if (!el.switch || item[el.switch]) {
                  this.dispatchAction({
                    action: el.action,
                    params: this.proccessParams({ params: el.params, item }),
                  });
                }
              });
          }

          this.closeDialog();
        })
        .catch((error) => console.error(error));
    },
    confirmRemove(item) {
      this.itemToRemove = this.$_.cloneDeep(item);
      this.dialogRemoveItem = true;
    },
    removeItem(item) {
      this.remove({
        collection: this.collection,
        integration: this.integration,
        item,
      });
      this.dialogRemoveItem = false;
    },
    removeItens() {
      this.removeBundle({
        collection: this.collection,
        integration: this.integration,
        itens: this.selecteds,
      })
        .then(() => {
          this.selecteds = [];
          this.dialogBundleRemove = false;
        })
        .catch((error) => {});
    },
    closeDialogRemoveItem() {
      this.itemToRemove = this.$_.cloneDeep({});
      this.dialogRemoveItem = false;
    },
    sendImport(item) {
      if (this.$refs.import && !this.$refs.import.validate()) return;

      this.import({ collection: this.collection, item })
        .then(() => this.closeDialogImporter())
        .catch((error) => {});
    },
    async search(req) {
      try {
        const { hits } = await req;
        this.searchResult = hits;
      } catch (err) {
        console.error(err);
        this.searchResult = [];
      }
    },
    async searchFilter(req, filter) {
      try {
        const { hits } = await req;
        this.filterItems[filter.algoliaIndex].splice(0);
        if (hits) this.filterItems[filter.algoliaIndex].push(...hits);
      } catch (err) {
        console.error(err);
        this.filterItems[filter.algoliaIndex].splice(0);
      }
    },
    updateSearch(filters) {
      this.filtersComponent = filters;
      let input

      if (this.externalRequest && this.externalRequest.query) {
        input ="externalSearchInput"
      } else if (this.searchInputAlgolia) {
        input = "algoliaSearchInput";
      } else {
        input = "asyncSearchInput";
      }
      
      this.$refs[input].search(this.searchInput, this.filtersComponent);
    },
    stringReducer(title, max = 25) {
      if (String(title).length > max) return title.slice(0, max) + "...";
      return title;
    },
    async generatePdf({base64str, objectStorage, url}) {
      this.setLoading({stats: true})
      if (url) this.jsPdf.url = url
      else if (objectStorage) {
        var xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        
        xhr.onload = async (event) => {
          this.jsPdf.blob = xhr.response
          // this.jsPdf.doc = await this.blobTo64({blob: xhr.response})
          this.jsPdf.dialog = true
          this.setLoading({stats: false})
        };
        xhr.open('GET', objectStorage.documentUrl);
        xhr.send();
      }
      else if(base64str){
        // decode base64 string, remove space for IE compatibility
        var binary = atob(base64str.replace(/\s/g, ''));
        var len = binary.length;
        var buffer = new ArrayBuffer(len);
        var view = new Uint8Array(buffer);
        for (var i = 0; i < len; i++) {
            view[i] = binary.charCodeAt(i);
        }
        const doc = new jsPDF()
        var blob = new Blob( [view], { type: "application/pdf" });
        // const link = document.createElement('a')
        // link.href = URL.createObjectURL(blob)
        // link.click()
        

        // this.jsPdf.doc = blob
        // this.jsPdf.dialog = true
        this.jsPdf.blob = blob
        this.jsPdf.dialog = true
        this.setLoading({stats: false})
      }
      else {
        try {
          const doc = new jsPDF();
          const fontSize = 7
          let lineHeight = fontSize
          const fontFamily = "helvetica"
          const tableLineWidth = 0.2
          const tableLineColor = [125, 125, 125]
          const hexToNumber = str => eval('0x' + str)
          const fillColor = this.colorPdfTable && [
            hexToNumber(this.colorPdfTable.slice(1,3)),
            hexToNumber(this.colorPdfTable.slice(3,5)),
            hexToNumber(this.colorPdfTable.slice(5,7)),
          ] || [ 41, 128, 186] // azul padrão de header
    
          
          // altere o valor para determinar qual linha deve começar o documento
          let line = 2
    
          const size = {
            x: 210,
            y: 297 
          }
    
          const item = this.$refs.formMaster.getModel()
    
          /**
           * Logo
           * addImage ref: https://artskydj.github.io/jsPDF/docs/module-addImage.html
           */
          const image = await this.loadImage()
          doc.addImage(image, 'JPEG', 5, lineHeight, 16, 9)
    
          /**
           * Título
           */
          doc.setFont(fontFamily)
          doc.setFontSize(2.5 * fontSize)
          /** @TODO parametrizar o título */

          doc.text(eval(this.titleOfPrint || "'Impressão'"), size.x/2, line * lineHeight, { align: 'center' });
          doc.setFontSize(fontSize)
          line++
    
          /**
           * Empresa
           */
          const colsEmpresa = 3
          const company = this.company
          const printCompanyHeaders = (this.printCompanyHeaders && this.printCompanyHeaders.length > 0) ? this.printCompanyHeaders : []
          const empresaItems = printCompanyHeaders.map(pcH => {
            const valueCompiled = eval(pcH.value)
            return {...pcH, value: valueCompiled}
          })
          
          const empresaBody = []
          for (let row = 0; row < empresaItems.length / colsEmpresa; row++) {
            const rowInputs = empresaItems.slice(row*colsEmpresa, (row*colsEmpresa) + colsEmpresa)
            empresaBody.push(rowInputs.reduce((acc, item) => {
              const label = {
                content: item.label,
                styles: {
                  fillColor,
                  textColor: [255, 255, 255], 
                  fontStyle: 'bold',
                  lineWidth: 0.1,
                  lineColor: [171, 206, 228]
                },
              }
              const value = {
                content: item.value,
              }
    
              return [...acc, label, value]
            }, []))
          }
          doc.autoTable({
            startY: line * lineHeight,
            body: empresaBody,
            margin: { top: 25, right: 5, bottom: 10, left: 5 },
            styles: { halign: 'center', fontSize, cellPadding: 1 },
            headStyles: { halign: 'center', fontStyle: 'bold' },
            tableLineWidth,
            tableLineColor,
          })

          /**
           * Agrupar os inputMaster por quadrante de impressão
           */

          let inputMasterPerSquad = []

          let inputsMaster = this.computedInputsMaster.filter(im => im.value && im.print && !im.returnObject)
          
          if (inputsMaster && !inputsMaster.length > 0) {
            inputsMaster = this.computedInputsMaster.filter(im => im.value && !im.returnObject)
          }

          inputsMaster.forEach(im => {
            const squad = (im.print && im.print.squad) ? im.print.squad : 1
            const imSquad = (inputMasterPerSquad && inputMasterPerSquad.length > 0) ? inputMasterPerSquad[squad - 1] : []
            inputMasterPerSquad[squad - 1] = [...imSquad, im]
          })
          
          /**
           * Cabeçalho
           */
          // Define o número de colunas por tabela
          const cols = 3

          inputMasterPerSquad.forEach(imSquad => {
            const inputs = (imSquad && imSquad.length > 0 && imSquad[0].print && imSquad[0].print.order) ? imSquad.sort((a,b) => {
              if (a.print.order < b.print.order) {
                return -1
              }
              if (a.print.order > b.print.order) {
                return 1
              }
              return 0
            }) : imSquad

            const body = []

            for (let row = 0; row < inputs.length / cols; row++) {
              const rowInputs = inputs.slice(row*cols, (row*cols) + cols)
              body.push(rowInputs.reduce((acc, input) => {
                let text = item[input.value] || eval(`item.${input.value}`) || ''
                if (input.type == 'select') {
                  const selectItem = input.items.find(i => i.value == item[input.value])
                  if (selectItem) 
                    text = selectItem.text || item[input.value]
                } else if (input.type === 'switch') {
                  text = item[input.value] ? 'Sim' : 'Não'
                }
      
                /**
                 * Configuração do Label
                 * ref: https://github.com/simonbengtsson/jsPDF-AutoTable#options
                 */
                const label = {
                  content: input.label,
                  styles: {
                    fillColor,
                    textColor: [255, 255, 255], 
                    fontStyle: 'bold',
                    lineWidth: 0.1,
                    lineColor: [171, 206, 228]
                  },
                }
                
                /**
                 * Configuração do texto do valor do item
                 */

                const value = typeof text === 'string' ? {content: text.trim()} : {content: text}
      
                return [...acc, label, value]
              }, []))
            }
            doc.autoTable({
              startY: doc.lastAutoTable.finalY + lineHeight,
              body,
              margin: { top: 25, right: 5, bottom: 10, left: 5 },
              styles: {
                fontSize,
                cellPadding: 1,
              },
              tableLineWidth,
              tableLineColor,
            })
          })
          
          /**
           * Impostos
           */
          if (this.impostos && this.impostos.headers && this.impostos.headers.length > 0) {
            const head = [
              [{ content: 'Impostos', colSpan: this.impostos.headers.length, styles: { halign: 'center' }}],
              this.impostos.headers.map(el => ({content:el.text}))
            ]
            const impostosBody = [this.impostos.headers.map(el => {
              const value = this.impostos.itens[0][el.value]
              return `R$ ${value.toLocaleString('pt-br', { minimumFractionDigits:2, maximumFractionDigits: 2 }) }`
            })]
            doc.autoTable({
              startY: doc.lastAutoTable.finalY + lineHeight,
              head,
              body: impostosBody,
              margin: { top: 25, right: 5, bottom: 10, left: 5 },
              styles: { halign: 'center', fontSize, fillColor, cellPadding: 1 },
              headStyles: { halign: 'center', fontStyle: 'bold' },
              tableLineWidth,
              tableLineColor,
            })
    
            if (this.impostosPdfText)
              doc.text(this.impostosPdfText, 5, doc.lastAutoTable.finalY + (lineHeight/2))
          }
    
          /**
           * Totais
           */
          this.docPdfTotais(doc, { lineHeight, fontSize, fillColor, tableLineWidth, tableLineColor })
    
          /**
           * Tabela de itens
           */
          let headersItens = this.computedHeaders.filter(header => header.print)

          if (headersItens && !headersItens.length > 0) {
            headersItens = this.computedHeaders
          } else {
            headersItens = headersItens.sort((h1, h2) => {
              if (h1.print.order < h2.print.order) {
                return -1
              }
  
              if (h1.print.order > h2.print.order) {
                return 1
              }
  
              return 0
            })
          }
          const head = [headersItens.map(h => h.text)]
          const headKeys = headersItens.map(h => h.value)

          const table = this.$refs.formDetails?.getTable()
          if(table){
            const bodyTableItems = []
            for (const row of table.rows) {
              const item = row.item
              if (!this.filterItemsFunction(item))
                continue
              
              bodyTableItems.push(headKeys.map(header => row.getCellFromHeader(header).text))
            }
            // ref: https://github.com/simonbengtsson/jsPDF-AutoTable
            doc.autoTable({
              startY: doc.lastAutoTable.finalY + lineHeight,
              head,
              body: bodyTableItems,
              margin: { top: 25, right: 5, bottom: 10, left: 5 },
              styles: { fontSize, cellPadding: 1  },
              headStyles: { fontStyle: 'bold', fillColor, },
              tableLineWidth,
              tableLineColor,
            })
          }
    
          /**
           * Totais (fim da tabela)
           */
          this.docPdfTotais(doc, { lineHeight, fontSize, fillColor, tableLineWidth, tableLineColor })
    
          this.jsPdf.doc = doc
          this.jsPdf.dialog = true
        } catch(e) {
          console.error(e)
        } finally {
          this.setLoading({stats: false})
        }
      }
    },

    jsPdfHandleDialog(value) {
      if (value) {
        const { 
          toDefault, 
          subjectDefault,
          textDefault, 
          enableCcDefault: enableCc, 
          ccDefault, 
          enableBccDefault: enableBcc,
          bccDefault
        } = { toDefault: '""', textDefault: '""', subjectDefault: '""', enableCcDefault: false, ccDefault: '""', enableBccDefault: false, bccDefault: '""' }

        const to = eval(toDefault)
        const text = eval(textDefault)
        const subject = eval(subjectDefault)
        const cc = eval(ccDefault)
        const bcc = eval(bccDefault)

        /** @TODO parametrizar */
        // const to = item['SA1'] && item['SA1']['A1_EMAILPDF'] && item['SA1']['A1_EMAILPDF'].trim() || ''
        this.jsPdf.sendEmail.model = {
          to,
          text,
          subject,
          cc,
          bcc
        }

        this.jsPdf.sendEmail.inputs = this.jsPdf.sendEmail.inputs.map(i => {
          if (i.value === 'cc')
            return {...i, hide: !enableCc}
          else if (i.value === 'bcc')
            return {...i, hide: !enableBcc}
          return i
        })
      } else {
        this.jsPDF.sendEmail.model = null
      }
    },

    /** @TODO parametrizar o nome do documento */
    savePdf() {
      if (this.jsPdf.doc)
        this.jsPdf.doc.save("pedido.pdf")
      else if (this.jsPdf.blob) {
        fs.saveAs(this.jsPdf.blob, 'Arquivo -' + new Date().valueOf() + '.pdf')
      }

        console.warn('jsPdf.doc not yet defined.')
    },

    async sendPdf({ to, text, bcc, cc, subject }) {
      let response
      try {
        this.jsPdf.sendEmail.sending = true
        if (this.jsPdf.doc) {
          response = await this.$api.post(`/mail/send`, { to, text, id: this.id, bcc, cc, subject, attachment: this.jsPdfOutput })
        
          this.jsPdf.sendEmail.dialog = false
          this.$toast.success('Email enviado!', { position: 'top-right' })
        } else if (this.jsPdf.blob) {
          var reader = new FileReader();
          reader.readAsDataURL(this.jsPdf.blob); 
          reader.onloadend = async () => {
            var base64data = reader.result;                
            response = await this.$api.post(`/mail/send`, { to, text, id: this.id, bcc, cc, subject, attachment: base64data })
        
            this.jsPdf.sendEmail.dialog = false
            this.$toast.success('Email enviado!', { position: 'top-right' })
          }

        }
      } catch (err) {
        response = err
      } finally {
        this.jsPdf.sendEmail.sending = false
      }

      return response
    }
  },
};
</script>

<style>
.hover {
  cursor: pointer;
}
</style>