<template>
  <v-overlay :value="loading.stats" z-index=250 class="text-center">
    <v-progress-circular indeterminate size="64"></v-progress-circular>
    <h4 class="mt-4">{{loading.message}}</h4>
  </v-overlay>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Loading',
  data: () => ({}),
  computed: {
    ...mapState(['loading'])
  }
}
</script>
 
<style>

</style>