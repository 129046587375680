<template>
  <v-container fluid class="pa-0">
    <v-row>
      <v-col sm=auto>
        <!-- Search --> 
        <v-text-field 
          v-model="search.value"
          label="Digite para pesquisar"
          outlined
          dense
          clearable
          hide-details
        >
        </v-text-field>
      </v-col>

      <!-- switchFilters -->
      <v-col 
        sm=auto
        v-for="filter in switchFilters"
        :key="filter.value"
        >
        <v-switch
          v-if="filter.filterType == 'switch' && !filter.hide"
          v-model="filtersModels[filter.value]"
          :label="filter.filterLabel || filter.text"
          :disabled="filter.disabled"
          dense
          inset
          hide-details
          class="pa-0 ma-0 pt-2"
        ></v-switch>
      </v-col>
    </v-row>

    <!-- selectionFilters -->
    <v-row class="mt-0">
      <v-col 
        sm=auto
        v-for="filter in selectionFilters"
        :key="filter.value"
        class="pb-0 pr-0"
      >
      <v-custom-autocomplete
        v-if="filter.filterType == 'async'"
        v-model="filtersModels[filter.value]"
        :label="filter.filterLabel || filter.text"
        :index="filter.value"
        :items="autocompleteHits[filter.value]"
        :filter="(item, queryText, itemText) => filter.fieldsToShow.some(x => item[x].toLowerCase().includes(queryText.toLowerCase()))"
        :item-value="filter.itemValue"
        :loading="autocompleteLoading[filter.value]"
        :handler="handlerAutocomplete"
        :handlerLoading="handlerAutocompleteLoading"
        :delay="750"
        :asyncSearch="filter.asyncSearch"
        :fieldsToShow="filter.fieldsToShow"
        :fieldsToSearch="filter.fieldsToSearch"
        :menu-props="{closeOnContentClick:true}"
        clearSearchOnSelect
        hideDetails
        return-object
        chips
        deletable-chips
        multiple
        dense
        outlined
        @change="(value) => filterAsync(value)"
      >
        <template v-slot:item="{item}">
          <div v-for="(field, ix) in filter.fieldsToShow" :key="field">
            <span v-if="ix > 0" class="mx-1">-</span>
            <span v-html="item._highlightResult && item._highlightResult[field] && item._highlightResult[field].matchedWords && item._highlightResult[field].matchedWords.length > 0 ? item._highlightResult[field].value : item[field]" />
          </div>
        </template>
        <template v-slot:selection="data">
          <v-chip v-if="data.index < formChips" :key="JSON.stringify(data.item.id)" v-bind="data.attrs" :input-value="data.selected" :disabled="data.disabled" :color="`${color} accent-4`" small close dark @click:close="data.parent.selectItem(data.item)">
            <div v-for="(field, ix) in filter.fieldsToShow" :key="field">
              <span v-if="ix > 0" class="mx-1">-</span>
              <span v-html="data.item._highlightResult && data.item._highlightResult[field] && data.item._highlightResult[field].matchedWords && data.item._highlightResult[field].matchedWords.length > 0 ? data.item._highlightResult[field].value : data.item[field]" />
            </div>
            <!-- <span>{{ data.item }}</span> -->
          </v-chip>
          <span v-if="data.index == formChips" class="grey--text caption">(+{{ formData[filter.value].length - formChips }} outro(s))</span>
        </template>
      </v-custom-autocomplete>
      <v-select
        v-else
        v-model="filtersModels[filter.value]"
        :items="filter.filterItems"
        :label="filter.filterLabel || filter.text"
        chips
        hideDetails
        deletable-chips
        multiple
        dense
        outlined
      > 
        <template v-slot:prepend-item>
          <v-list-item
            ripple
            @click="selectAll(filter)"
          >
            <v-list-item-action class="my-0">
              <v-icon 
                v-if="filtersModels[filter.value] && filter.filterItems && filtersModels[filter.value].length > 0 && filtersModels[filter.value].length >= filter.filterItems.length" 
                :color="filtersModels[filter.value] && filtersModels[filter.value].length > 0 ? 'indigo' : ''">
                mdi-close-box
              </v-icon>
              <v-icon 
                v-else-if="filtersModels[filter.value] && filter.filterItems && filtersModels[filter.value].length > 0 && filtersModels[filter.value].length < filter.filterItems.length" 
                :color="filtersModels[filter.value] && filtersModels[filter.value].length > 0 ? 'indigo' : ''">
                mdi-minus-box
              </v-icon>
              <v-icon
                v-else
                :color="filtersModels[filter.value] && filtersModels[filter.value].length > 0 ? 'indigo' : ''"
                >
                mdi-checkbox-blank-outline
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="text-uppercase">
                Selecionar Todos
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="mt-2"></v-divider>
        </template>
        <template v-slot:item="{ item, on, attrs }">
          <v-list-item v-bind="attrs" v-on="on">
            <v-list-item-action class="my-0">
              <v-icon 
                v-if="filtersModels[filter.value] && filtersModels[filter.value].includes(item)" 
                color="primary">
                mdi-checkbox-marked
              </v-icon>
              <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-text="item" class="text-uppercase">
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <template v-slot:selection="{ item, index }">
          <v-chip v-if="index === 0">
            <span>{{ item }}</span>
          </v-chip>
          <span v-if="index === 1" class="grey--text caption">
            (+{{ filtersModels[filter.value].length - 1 }} others)
          </span>
        </template>
      </v-select>
      </v-col>
    </v-row>
    
    <!-- Limpar Filtros -->
    <v-row>
      <v-btn @click="clearFilters" text small color="primary" class="ml-3 mb-2 mt-2">
        <v-icon left>mdi-close</v-icon>
        Limpar Todos os Filtros
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import VCustomAutocomplete from "../VCustomAutocomplete.vue";
export default {
  props: ['filters', 'filtersModels', 'search', 'async', 'color'],
  components: {VCustomAutocomplete},
  data() {
    return {
      openFilter: true,
      formChips: 4,
      autocompleteHits: {},
      autocompleteLoading: {}
    }
  },
  computed: {
    switchFilters() { return this.filters.filter(x => x.filterType == 'switch') },
    selectionFilters() { return this.filters.filter(x => x.filterType != 'switch') }
  },
  methods: {
    selectAll ({ filterItems, value }) {
      if ( !filterItems || !value )
        return

      if (!this.filtersModels[value] || this.filtersModels[value].length == 0) {
        this.$set(this.filtersModels, value, [...filterItems]) 
      } else {
        this.filtersModels[value].splice(0)
      }
    },

    clearFilters(payload = {}) {
      const {invalidCells = false, warningCells = false} = payload
      this.search.value = ''
      Object.entries(this.filtersModels).forEach(([key,val]) => {
        if (typeof val == 'boolean')
          this.filtersModels[key] = false
        else if (Array.isArray(val))
          this.filtersModels[key].splice(0)
        else
          console.warn('unknown filter type', key, val)
      })
      this.filtersModels['invalidCells'] = invalidCells
      this.filtersModels['warningCells'] = warningCells
      this.filterAsync()
    },

    async handlerAutocomplete(req) {
      try {
        const {index, hits, query} = await req;
        if (this.autocompleteHits[index]) {
          for (const el of hits) {
            this.autocompleteHits[index]
            const ix = this.autocompleteHits[index].findIndex(x => x.id == el.id)
            if (ix > -1) { this.autocompleteHits[index][ix] = el } 
            else { this.autocompleteHits[index].push(el) } 
          }
        } else {
          this.$set(this.autocompleteHits, index, hits) 
        }
      } catch (err) {
        console.error(err);
      }
    },

    async handlerAutocompleteLoading(index, bool) {
      try {
        this.$set(this.autocompleteLoading, index, bool);
      } catch (err) {
        console.error(err);
      }
    },

    filterAsync () {
      if (!this.async) return
      const filters = this.filters.filter(x => x.filterType == 'async')
      if (!filters || !filters.length > 0) return
      let andFilters = filters.map(x => {
        if (!this.filtersModels[x.value] || this.filtersModels[x.value].length == 0) return null
        return {
          key: x.value,
          operator: "array_contains_any",
          value: (x.itemValue) ? this.filtersModels[x.value].map(y => y[x.itemValue]) : this.filtersModels[x.value],
        }
      })
      andFilters = andFilters.filter(x => x && x.value.length > 0)
      this.async({andFilters})
    }
  }
}
</script>