<template>
  <v-toolbar flat dense short height=35>
    <v-toolbar-title :class="`subtitle-2 font-weight-bold ${color}--text ml-n4`">Itens</v-toolbar-title>
    <template v-if="exportXls">
      <v-spacer></v-spacer>
      <!-- Download XLSX -->
      <slot name="prepend-actions"></slot>
      <v-tooltip top>
        <template v-slot:activator="{ attrs, on }">
          <v-btn
            @click="exportXlsx()"
            color="primary"
            text
            x-small
            v-on="on"
            v-bind="attrs"
            >
            <v-icon size=22 class="mr-1">mdi-file-download-outline</v-icon>
            Download
          </v-btn>
        </template>
        <span>Download planilha</span>
      </v-tooltip>

      <!-- Upload XLSX -->
      <v-file-input
        v-if="!readonly"
        @change="importXlsx"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        class="d-contents"
        prepend-icon=""
        ref="xlsxInput"
        hide-input>
        <template slot="append-outer">
          <v-tooltip top>
            <template v-slot:activator='{ on, attrs }'>
              <v-btn
                @click="triggerImportXlsx"
                color="primary"
                x-small
                text
                v-on="on"
                v-bind="attrs"
                >
                <v-icon size=22 class="mr-1">mdi-file-upload-outline</v-icon>
                Upload
              </v-btn>
            </template>
            <span>Upload planilha</span>
          </v-tooltip>
        </template>
      </v-file-input>
      <slot name="append-actions"></slot>
    </template>
  </v-toolbar>
</template>

<script>
export default {
  props: ['readonly', 'exportXls', 'color'],
  methods: {
    exportXlsx() {
      this.$emit('exportXlsx')
    },
    importXlsx(file) {
      this.$emit('importXlsx', file)
      this.resetValueImportXlsx()
    },
    triggerImportXlsx() {
      this.$refs.xlsxInput.$refs.input.click()
    },
    resetValueImportXlsx() {
      this.$refs.xlsxInput.$refs.input.value = ''
    },
  }
}
</script>