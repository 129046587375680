<template>
  <div id="app">
    <v-app v-if="(collectionStructure && user) || $route.meta.public">
      <Loading/>
      <SnackBar/>
      
      <AppBar v-if="showing"/>
      <NavigationDrawer v-if="showing"/>
      
      <v-main class="brown lighten-5 mvh-100">
        <perfect-scrollbar class="h-100">
          <v-container fluid fill-height class="mh-100 pt-0">
            <keep-alive include="Content">
              <router-view/>
            </keep-alive>
          </v-container>
        </perfect-scrollbar>
      </v-main>

      <Footer v-if="showing"/>
    </v-app>
  </div>
</template>

<script>
import Loading from '@/components/Loading'
import AppBar from "@/components/Templates/AppBar"
import SnackBar from "@/components/Templates/SnackBar"
import NavigationDrawer from "@/components/Templates/NavigationDrawer"
import Footer from "@/components/Templates/Footer"
import { createNamespacedHelpers, mapState, mapActions } from 'vuex'
const { mapState:authState } = createNamespacedHelpers('auth')
export default {
  name: 'App',
  data: () => ({
    authorized: false,
    closeNavigationDrawerTimer: null
  }),
  components: { Loading, AppBar, SnackBar, NavigationDrawer, Footer },
  computed: {
    ...mapState(['collectionStructure']),
    ...authState(['user']),
    showing() { return !this.$route.meta.public && !this.$route.meta.hideMenu }
  },
  methods: {
    ...mapActions(['initSystem']),
  },
  created() {
    this.initSystem({$router: this.$router})

    if (this.$config.versionAppKey) {
      const versionStoraged = localStorage.getItem(this.$config.versionAppKey)
  
      if (!versionStoraged || versionStoraged !== process.env.PACKAGE_VERSION) {
        localStorage.setItem(this.$config.versionAppKey, process.env.PACKAGE_VERSION);
  
        if (versionStoraged) {
          this.$toast.info('O sistema foi atualizado! Aguarde enquanto limpamos o cache do seu navegador.');
          setTimeout(() => {
            window.location.reload(true);
          }, 2500)
        } 
      }
    }
  },
  watch: {
    $route(route) {
      if (this.closeNavigationDrawerTimer)
        clearTimeout(this.closeNavigationDrawerTimer)
      
      this.closeNavigationDrawerTimer = setTimeout(() => {
        this.$store.commit('setDrawer', false)
        this.closeNavigationDrawerTimer = null
      }, 2000)
    }
  }
}
</script>

<style lang="scss">
</style>